import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import logo from "../../../assets/svg/logo.svg";
import SafetichaLogo from "../../../assets/svg/SafetichaLogo.svg";
import AppButton from "../../buttons/AppButton";
import { useNavigate } from "react-router-dom";

import NavProductsDropdown from "./NavProductsDropdown";
import NavEventDropDown from "./NavEventDropDown";
import CartIcon from "../../../assets/svg/dashboard-icons/CartIcon";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { cartCourses } = useSelector((state) => state.cartReducer);

  const [cartItems, setCartItems] = useState([]);

  useEffect(()=>{
    if(cartCourses.length){
      const items = cartCourses.filter(x => x.type == "resource");
      setCartItems(items);
    }

  }, [cartCourses])

  const openNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      maxWidth="1600px"
      margin="0 auto"
      component="nav"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingTop={{ xs: "30px", md: "15px" }}
      px={{ xs: "30px", md: "0px" }}
    >
      <Box display={{ xs: "none", md: "block" }}>
        <img src={logo} style={{cursor:"pointer",}} height={70} alt="logo" onClick={()=>navigate("/")} />
      </Box>

      <Box display={{ xs: "block", md: "none" }} zIndex="105" >
        <img src={SafetichaLogo} style={{cursor:"pointer"}} height={45} alt="logo" onClick={()=>navigate("/")}/>
      </Box>

      <Box
              position="relative"
              sx={{ cursor: "pointer" , display: { xs: "flex",  md: "none" }, marginLeft: "auto" , marginRight:"30px"}}
            >
              {/* cart notification */}
              <Box
                position="absolute"
                right="-13px"
                top="-15px"
                bgcolor="#ff0000"
                color="white"
                p="3px 7px"
                fontWeight="900"
                borderRadius="30px"
                fontSize="12px"
              >
                {cartItems?.length}
              </Box>

              {/* cart icon */}
              <CartIcon
                onClick={() => {
                  navigate("/cart");
                }}
              />
            </Box>
      <MenuIcon onClick={openNav} fontSize="large" sx={styles.hamburger} />

      <Box
        component="ul"
        flexDirection={{ xs: "column", md: "row" }}
        backgroundColor={{ xs: "white", md: "inherit" }}
        position={{ xs: "fixed", md: "relative" }}
        height={{ xs: "100%", md: "auto" }}
        width={{ xs: "100%", md: "auto" }}
        top={{ xs: "0", md: "auto" }}
        left={{ xs: "0", md: "auto" }}
        zIndex={{ xs: "100", md: "1" }}
        alignItems="center"
        justifyContent={{ md: "space-between" }}
        gap={{ xs: "30px", md: "50px" }}
        pt={{ xs: "120px", md: "0" }}
        sx={styles.showNav(isOpen)}
        pl="0"
        mt="0"
      >
        <CloseIcon onClick={openNav} fontSize="large" sx={styles.close} />

        <Button component="li" sx={styles.linkText} onClick={()=>navigate("/")}>
          Home
        </Button>

        <Button component="li" sx={styles.linkText} onClick={()=>navigate("/marketplace")}>
          Resources
        </Button>

        <Button component="li" sx={styles.linkText} onClick={()=>navigate("/find-courses")}>
          Courses
        </Button>

        {/* <NavProductsDropdown /> */}

        {/* <NavEventDropDown /> */}

        <AppButton
          customStyles={{
            fontWeight: "600",
            borderRadius: "8px",
            padding: "7px 30px",
          }}
          variant="outlined"
          btnText="Login"
          onClick={() => {
            navigate("/login");
          }}
          fullWidth={false}
        />
        <AppButton
          customStyles={{
            fontWeight: "600",
            borderRadius: "8px",
            padding: "7px 30px",
          }}
          btnText="Get Started"
          onClick={() => {
            navigate("/signup");
          }}
          fullWidth={false}
        />
         <Box
              position="relative"
              sx={{ cursor: "pointer" , display: { xs: "none", md: "flex" } }}
            >
              {/* cart notification */}
              <Box
                position="absolute"
                right="-13px"
                top="-15px"
                bgcolor="#ff0000"
                color="white"
                p="3px 7px"
                fontWeight="900"
                borderRadius="30px"
                fontSize="12px"
              >
                {cartItems?.length}
              </Box>

              {/* cart icon */}
              <CartIcon
                onClick={() => {
                  navigate("/cart");
                }}
              />
            </Box>
      </Box>
      
     
    </Box>
  );
};

export default Navbar;

const styles = {
  linkText: {
    fontWeight: "600",
    fontSize: "16px",
    color: "black",
  },
  hamburger: {
    color: "#2E3192",
    display: { xs: "block", md: "none" },
  },
  close: {
    color: "#2E3192",
    display: { xs: "block", md: "none" },
    position: "absolute",
    top: "40px",
    right: "30px",
  },
  showNav: (state) => {
    if (state) {
      return { display: "flex" };
    } else {
      return { display: { xs: "none", md: "flex" } };
    }
  },
};
