export const ForgotPasswordStyles = {
  container: {
    marginTop: "120px",
    height: "70%",
    display: "flex",
    flexDirection: "column",
  },
  headerText: {
    fontSize: 30,
    fontWeight: 700,
    margin: "20px 0 30px",
  },
  headerSubText: {
    fontSize: {xs: "14px",md:"18px"},
    width: "95%",
    color: "#4B4E61",
    marginBottom: "20px",
  },
  createAcc: {
    width: "100%",
    textAlign: "center",
    marginTop: "auto",
  },
  createAccLink: {
    fontWeight: "600",
    color: "#0F27BD",
    cursor: "pointer",
  },
  resendCode: {
    marginBottom: "20px",
  },
  verificationCodeInput: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "80px",
  },
};
