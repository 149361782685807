import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

const AppButton = ({
  variant,
  btnText,
  onClick,
  color,
  fullWidth,
  size,
  isLoading,
  loadingComponent,
  customStyles,
  startIcon,
  endIcon,
  disabled,
  btnType,
  component,
  fileType,
}) => {
  return (
    <Button
      sx={{ ...styles, ...customStyles }}
      color={color}
      variant={variant}
      onClick={isLoading ? ()=>{} : onClick}
      fullWidth={fullWidth}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      type={btnType}
      component={component}
    >
      {isLoading ? loadingComponent : btnText}
      {fileType && <input type={fileType} hidden />}
    </Button>
  );
};

const styles = {};
AppButton.propTypes = {
  variant: PropTypes.string,
  btnText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  customStyles: PropTypes.object,
  btnType: PropTypes.string,
};

AppButton.defaultProps = {
  variant: "contained",
  onClick: () => {},
  btnText: "",
  color: "primary",
  size: "large",
  fullWidth: true,
  loadingComponent: () => <p>Loading...</p>,
  customStyles: {},
  btnType: "button",
};

export default AppButton;
