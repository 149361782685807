import React, { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppInput from "../../inputs/AppInput";
import AppButton from "../../buttons/AppButton";
import { PAGES } from "../../../utils/constant";
import Modal from "../../modal/Modal";
import VerificationModal from "./VerificationModal";

import { ForgotPasswordStyles } from "./ForgotPasswordStyles.styles";

import ForgotPasswordVerification from "./ForgotPasswordVerification";
import { textIsEmail } from "../../../utils/stringFunctions";
import { showSimpleToast } from "../../../utils/alertFunctions";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../redux/auth/actions";

const ForgotPasswordForm = () => {
  const [pageIndex, setPageIndex] = useState(1);

  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isBtnLoading} = useSelector(state => state.authReducer)


  const submitForgotPasswordEmail = () => {
    if(!textIsEmail(email)){
      showSimpleToast("Please enter a valid email", "failed")
      return
    }
    dispatch(forgotPassword({data: {email}, callBack : () => {
      setPageIndex(2);
    }}))
  }

  return (
    <>
      {pageIndex === 1 && (
        <Container sx={ForgotPasswordStyles.container}>
          <Typography sx={ForgotPasswordStyles.headerText}>
            Forgot Password?
          </Typography>
          <Typography sx={ForgotPasswordStyles.headerSubText}>
            Enter the email address you signed up with and we'll send you a code
            to reset your password if it's found in our record.
          </Typography>

          <AppInput
            label="Email Address"
            placeholder="Enter your email address"
            onChange={((e) => setEmail(e.target.value))}
            value={email}
            type="email"
          />

          <AppButton
            onClick={submitForgotPasswordEmail}
            customStyles={{ fontWeight: "bold" }}
            btnText="Send Code"
            isLoading={isBtnLoading}
            loadingComponent="Processing..."
          />

          <Box sx={ForgotPasswordStyles.createAcc}>
            <Typography variant="span">Remembered Password?</Typography>{" "}
            <Typography
              variant="span"
              onClick={() => navigate(PAGES.login)}
              sx={ForgotPasswordStyles.createAccLink}
            >
              Login
            </Typography>
          </Box>
        </Container>
      )}

      {pageIndex === 2 && (
        <ForgotPasswordVerification prevBtn={() => setPageIndex(1)} email={email} />
      )}
    </>
  );
};

export default ForgotPasswordForm;
