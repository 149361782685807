import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet, apiPost } from "../../service/apiService";
import { API_URL } from "../../utils/apiConstants";
import { getUserNotifications } from "../user/action";

export const getSchoolTeachers = createAsyncThunk(
  "school/getSchoolTeachers",
  async (payload) => {
    return apiGet(API_URL.getSchoolTeachers)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const inviteTeacherToSchool = createAsyncThunk(
  "school/inviteTeacherToSchool",
  async (payload) => {
    return apiPost(API_URL.inviteTeacherToSchool, payload.data, null, true)
      .then((res) => {
        payload?.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const respondToSchoolInvite = createAsyncThunk(
  "school/respondToSchoolInvite",
  async (payload, { dispatch }) => {
    return apiPost(API_URL.respondToSchoolInvite, payload.data)
      .then((res) => {
        dispatch(getUserNotifications())
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const listTeachersForCourse = createAsyncThunk(
  "school/listTeachersForCourse",
  async (payload) => {
    return apiGet(`${API_URL.listTeachersForCourse}/${payload}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const addTeachersToCourse = createAsyncThunk(
  "school/addTeachersToCourse",
  async (payload, {dispatch}) => {
    return apiPost(`${API_URL.addTeachersToCourse}/${payload.courseId}`, payload.data)
      .then((res) => {
        dispatch(listTeachersForCourse(payload.courseId));
        payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);