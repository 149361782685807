import React from "react";
import { Box, Typography } from "@mui/material";
import AppButton from "../../buttons/AppButton";
import CheckoutConfirmed from "../../../assets/svg/cart-icons/CheckoutConfirmed.svg";
import { useNavigate } from "react-router-dom";

import Modal from "../../modal/Modal";

const CheckoutComplete = () => {
  const navigate = useNavigate();

  return (
    <Modal showModal={true}>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography fontWeight="600" fontSize="24px" mb="15px">
          Thank You For Your Purchase!
        </Typography>
        <Box
          component="img"
          src={CheckoutConfirmed}
          alt="CheckoutConfirmed"
          mb="15px"
        />
        <Typography fontWeight="400" fontSize="24px">
          Order #123RGR231567Y Confirmed
        </Typography>

        <AppButton
          customStyles={{
            fontWeight: "700",
            fontSize: "16px",
            borderRadius: "8px",
            my: "25px",
          }}
          onClick={() => {
            navigate("/dashboard");
          }}
          btnText="Back to dashboard"
        />
        <Typography fontWeight="400" fontSize="16px" color="#9DA3A1">
          Generate receipt
        </Typography>
      </Box>
    </Modal>
  );
};

export default CheckoutComplete;
