import React from "react";
import Grid from "@mui/material/Grid";

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import AppButton from "../../../buttons/AppButton";
import { getCourseCertificate } from "../../../../redux/user/action";

const  CourseModules = ({ courseId, viewState, expanded, handleModuleDropDown }) => {
  const { courseContent } = useSelector((state) => state.coursesReducer);
  const { isFetchingCert } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToTestPage = (module) => {
    navigate(`/questions/${courseId}/module/${module}`);
  }

  const goToMyCertificate = () => {
    // navigate(`/certificate/course/${courseId}`)
    dispatch(getCourseCertificate({courseId, callBack: ()=>  navigate(`/certificate/course/${courseId}`)}))
  }

  return (
    <Grid item sx={{width: {xs:"100%", lg:"22%"}, p:{xs: "0 10px 80px 10px", lg: "60px 0 0 10px"}}}>
      {courseContent.map((data) => {
        return (
          <Accordion
            key={data.moduleSequence}
            border="2px solid #CFCFCF"
            sx={{ boxShadow: "none" }}
            expanded={expanded === `${data.moduleSequence}${data.moduleTitle}`} onChange={handleModuleDropDown(`${data.moduleSequence}${data.moduleTitle}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontSize="16px" color="#2E3192" fontWeight={viewState.module == data.moduleSequence ? "bold" : "regular"}>
                {data.moduleTitle}
              </Typography>
            </AccordionSummary>
            {data.moduleContent.map((list) => {
              return (
                <AccordionDetails
                  key={list.sequence}
                  width="80%"
                  fontWeight="500"
                  sx={{ display: "flex", justifyContent: "space-between", paddingRight: 3 }}
                >
                  <Typography>{list.title}</Typography>
                  {viewState.module == data.moduleSequence && viewState.page == list.sequence && <CheckCircle />}
                </AccordionDetails>
              );
            })}
            {data.hasTest && <Box sx={{ backgroundColor: "#fff", paddingLeft: "16px", py: "7px", cursor: "pointer" }} onClick={() => goToTestPage(data.moduleSequence)}
              border="1px solid #ececec">
              <Typography fontSize="16px" color="#2E3192"><i>Test</i></Typography>
            </Box>}
          </Accordion>
        );
      })}
      <AppButton btnText="My Certificate" loadingComponent="Fetching..." isLoading={isFetchingCert} customStyles={{ marginTop: "10px" }} onClick={goToMyCertificate} />
    </Grid>
  );
};

export default CourseModules;
