import React, { useEffect } from 'react'
import parse from 'html-react-parser'
import FAQ from '../../components/landing-page/FAQ/FAQ'
import PreFooter from '../../components/landing-page/PreFooter/PreFooter'
import Footer from '../../components/landing-page/Footer/Footer'
import { Box, Button, CircularProgress, Container, Typography } from '@mui/material'
import Navbar from '../../components/landing-page/Navbar/Navbar'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getJobByCode } from '../../redux/jobs/action'
import { LocationOn, RocketLaunch } from '@mui/icons-material'
import { formatAsCurrency } from '../../utils/courses/coursesFunctions'
import { APP_COLOURS } from '../../utils/constant'
import AppButton from '../../components/buttons/AppButton'
import { getJobSalaryDisplay } from '../../utils/dashboard/dashboardFunctions'

export default function JobView() {
    const { code } = useParams();
    const dispatch = useDispatch()

    const { isFetching, jobDetails } = useSelector(state => state.jobReducer)

    useEffect(() => {
        dispatch(getJobByCode({ code }))
    }, [code])

    return (
        <>
            <Container maxWidth="false" disableGutters sx={styles.container}>
                <Navbar />
            </Container>
            {isFetching && <CircularProgress />}

            {jobDetails && jobDetails.code == code && <Box
                padding={{ xs: "50px 24px", md: "50px 70px 155px" }}
                maxWidth="1600px"
                margin="0 auto"
            >
                <Typography variant='h4' textAlign="center" fontWeight="bold" color={APP_COLOURS.primary} marginBottom={3}>Job Details</Typography>
                <Box sx={{display: {xs:"block", lg: "flex"}, flexDirection:"row", justifyContent:"space-between"}} >
                <div>

                <Typography variant='h5' fontWeight="bold">{jobDetails.title}</Typography>
                <Box sx={{ display: "flex", flexDirection: "row", my: 1 }}>
                    <LocationOn />
                    <Typography sx={{ ml: 1 }}>{jobDetails.location}</Typography>
                </Box>
                <Typography>Salary : {getJobSalaryDisplay(jobDetails)}</Typography>
                {/* <Box sx={{display:"flex", flexDirection:"row"}}> */}
                       <Typography variant="body2" fontWeight="bold"  
                sx={{color: jobDetails.isOpen ? "green" : "red" , my:1}}>
                                        {jobDetails.isOpen ? "Job is open" : "Job is closed"}
                                    </Typography>

                                    </div>
                                    <AppButton endIcon={<RocketLaunch />} btnText='Apply for this Job' customStyles={{height: "45px", mb:"20px"}} fullWidth={false} />
                                    </Box>
                {/* </Box> */}
                <Box>
                                <Typography variant="h6" sx={{ fontSize: { xs: "18px", lg: "20px" } }}>
                                    Description
                                </Typography>
                                <Typography sx={{ pt: 1, fontSize: { xs: "14px", lg: "16px" }, whiteSpace: "pre-wrap" }}> {parse(decodeHTML(jobDetails.description))}</Typography>
              

                        </Box>
             
            </Box>}

            <FAQ />
            <PreFooter />
            <Footer />
        </>
    )
}

const styles = {
    container: {
        position: "relative",
        overflow: "hidden",
        background: {
            sm: "#ffffff",
            md: "linear-gradient(292.12deg, #D5D6E9 -35.02%, #EAEAF4 78.96%)",
        },
        padding: { xs: "0", md: "0 70px" },
    },
};
