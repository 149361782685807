export function formatAsCurrency(x, currency) {
  if (!x && x !== 0) x = '';
  x = x.toString().replace(/,/g, '');
  let output = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (output.toString() == 'NaN') return '0';
  return currency ? `${currency}${output}` : output;
}

export function formatFileSize(bytes, decimals = 2) {
  if(!bytes) return "";
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${size} ${sizes[i]}`;
}