import React from "react";
import { Grid } from "@mui/material";
import { APP_COLOURS } from "../../utils/constant";
import logo from "../../assets/svg/logo.svg";
import { useNavigate } from "react-router-dom";

const OnboardingLayout = ({ children, image }) => {
  const navigate = useNavigate();

  return (
    <Grid container>
      <Grid item xs={12} md={6} sx={styles.leftGrid}>
        <img
          src={logo}
          alt="logo"
          onClick={() => {
            navigate("/");
          }}
        />
        {children}
      </Grid>
      <Grid item md={6} sx={styles.rightGrid(image)}></Grid>
    </Grid>
  );
};

export default OnboardingLayout;

const styles = {
  leftGrid: {
    backgroundColor: APP_COLOURS.white,
    padding: {
      xs: "30px 20px",
      md: "40px 10px",
      lg: "50px",
      xl: "50px 100px",
    },
  },
  rightGrid: (img) => {
    return {
      backgroundColor: APP_COLOURS.primary,
      backgroundImage: `url(${img})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "550px 397px",
      height: "100vh",
      position: "sticky",
      top: "0",
      display: { xs: "none", md: "block" },
    };
  },
};
