import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "./Marketplace.module.scss";
import MARKET_PLACE_IMAGE from "../../../assets/img/Marketplace/market-place.jpg";
import MARKET_PLACE_NEW from "../../../assets/img/Marketplace/market-place-new.jpg";
import MARKET_PLACE_UNDERLINE from "../../../assets/img/Marketplace/market-place-underline.jpg"

export default function Marketplace() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/resource-more');
        window.scrollTo(0, 0); // Scroll to the top-left corner of resources landing page
    }
  return (
    <div className={`${styles.marketplace}`}>
            <div className={`${styles.marketplace_wrapper}`}>
                <div className={`${styles.marketplace_left}`}>
                    <div className={styles.marketplace_heading}>
                        Earn Money with our Resource Marketplace
                            <img
                            className={styles.marketplace_underline}
                            src={MARKET_PLACE_UNDERLINE}
                            alt=""
                            />
                    </div>

                    <div className={styles.marketplace_description}>
                        Get Access to millions of resources on Safeticha that get you great opportunities within and outside your organisation. Our resource marketplace helps you make profit and also equips you with what you need to grow.
                    </div>

                    <button type='button' onClick={handleClick} className={styles.marketplace_button}>
                        <div className={styles.button_text}>Learn More</div>
                    </button>

                   

                </div>

                <div className={`${styles.marketplace_right}`}>
                    <div>
                     <img src={MARKET_PLACE_IMAGE} />
                    </div>
                </div>
            </div>

            <div className={`${styles.marketplace_new}`}>
                      <img src={MARKET_PLACE_NEW} />
            </div>
        
    </div>
  )
}
