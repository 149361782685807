import React from "react";
import CoursesDetails from "../../components/dashboard/SharedComponents/CourseDetails";

const DashboardCourseDetails = () => {
  return (
    <>
      <CoursesDetails />
    </>
  );
};

export default DashboardCourseDetails;
