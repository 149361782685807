import React, { useState, useEffect } from "react";
import BottomProfile from "./BottomProfile";
import EditProfile from "./EditProfile";
import Profile from "./Profile";
import UploadDocuments from "./UploadDocuments/UploadDocuments";

import { useDispatch } from "react-redux";
import { clearUploadingState } from "../../../../redux/dashboard/slice";
import { getProfile } from "../../../../redux/dashboard/actions";

const TeacherProfile = () => {
  const [editProfileIndex, setEditProfileIndex] = useState("profile");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearUploadingState());
    dispatch(getProfile());
  }, []);

  const handleProfileIndex = (page) => {
    setEditProfileIndex(page ||"profile");
  };

  const handleEditProfileIndex = () => {
    setEditProfileIndex("editProfile");
  };

  const handleUploadDocumentsIndex = () => {
    setEditProfileIndex("uploadDocuments");
  };

  return (
    <>
      {editProfileIndex === "profile" && (
        <>
          <Profile
            handleUploadDocumentsIndex={handleUploadDocumentsIndex}
            handleEditProfileIndex={handleEditProfileIndex}
          />
          <BottomProfile />
        </>

      )}

      {editProfileIndex === "editProfile" && (
        <EditProfile handleProfileIndex={handleProfileIndex} />
      )}

      {editProfileIndex === "uploadDocuments" && (
        <UploadDocuments handleProfileIndex={handleProfileIndex} />
      )}
    </>
  );
};

export default TeacherProfile;
