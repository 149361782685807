import React, { useEffect } from "react";
import SchoolProfile from "../../components/dashboard/School/SchoolProfile/SchoolProfile";
import TeacherProfile from "../../components/dashboard/Teacher/TeacherProfile/TeacherProfile";
import { getUserData } from "../../utils/auth/authFunctions";

import { useDispatch } from "react-redux";
import { getProfile } from "../../redux/dashboard/actions";

const DashboardProfile = () => {
  const userData = getUserData();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {userData.userType === "school" && <SchoolProfile />}
      {userData.userType === "teacher" && <TeacherProfile />}
    </>
  );
};

export default DashboardProfile;
