import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet, apiPost, apiPut } from "../../service/apiService";
import { API_URL } from "../../utils/apiConstants";
import { getUserProfile } from "../dashboard/actions";
import { showSimpleToast } from "../../utils/alertFunctions";

export const createJob = createAsyncThunk(
  "jobs/createJobs",
  async (payload) => {
    return apiPost(API_URL.createJob, payload.data)
      .then((res) => {
        if (payload.callBack) {
          payload.callBack();
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const updateJob = createAsyncThunk(
  "jobs/updateJob",
  async (payload) => {
    return apiPut(`${API_URL.updateJob}/${payload.id}`, payload.data)
      .then((res) => {
        if (payload.callBack) {
          payload.callBack();
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const listMyCreatedJobs = createAsyncThunk(
  "jobs/myJobs",
  async (payload) => {
    return apiGet(API_URL.listMyCreatedJobs)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getJobDetails = createAsyncThunk(
  "jobs/geJobDetails",
  async (payload) => {
    return apiGet(`${API_URL.job}/${payload.jobId}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getJobByCode = createAsyncThunk(
  "jobs/getJobByCode",
  async (payload) => {
    return apiGet(`${API_URL.getJobByCode}/${payload.code}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const updateJobDetails = createAsyncThunk(
  "jobs/updateJobDetails",
  async (payload) => {
    return apiPut(`${API_URL.job}/${payload.jobId}`, payload.data)
      .then((res) => {
        if (payload.callBack) {
          payload.callBack();
        }
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getAvailableJobs = createAsyncThunk(
  "jobs/getAvailableJobs",
  async (payload) => {
    return apiGet(API_URL.job)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const applyForJob = createAsyncThunk(
  "jobs/applyForJob",
  async (payload, { dispatch }) => {
    return apiPost(`${API_URL.applyForJob}/${payload.jobId}`, {})
      .then((res) => {
        dispatch(getJobDetails(payload))
        if (payload.callBack) {
          payload.callBack();
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const updateApplicationStatus = createAsyncThunk(
  "jobs/updateApplicationStatus",
  async (payload, { dispatch }) => {
    return apiPost(`${API_URL.updateApplicationStatus}/${payload.applicationId}`, payload.statusInfo)
      .then((res) => {
        // dispatch(getJobDetails(payload))
        if (payload.callback) {
          payload.callback(dispatch);
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const sendChatForJobApplication = createAsyncThunk(
  "jobs/sendChatForJobApplication",
  async (payload, { dispatch }) => {
    return apiPost(`${API_URL.sendChatForJobApplication}/${payload.applicationId}`, payload.messageInfo, null, true)
      .then((res) => {
        if (payload.callback) {
          payload.callback(dispatch);
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getChatForJobApplication = createAsyncThunk(
  "jobs/getChatForJobApplication",
  async (payload, { dispatch }) => {
    return apiPost(`${API_URL.getChatForJobApplication}/${payload.applicationId}`, payload.userInfo, null, true)
      .then((res) => {
        if (payload.callback) {
          payload.callback(dispatch);
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getChatForJobApplicationLowKey = createAsyncThunk(
  "jobs/getChatForJobApplicationLowKey",
  async (payload, { dispatch }) => {
    return apiPost(`${API_URL.getChatForJobApplication}/${payload.applicationId}`, payload.userInfo, null, true)
      .then((res) => {
        if (payload.callback) {
          payload.callback(dispatch);
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getJobApplications = createAsyncThunk(
  "jobs/getJobApplications",
  async (payload) => {
    return apiGet(`${API_URL.applyForJob}/${payload.jobId}`, {})
      .then((res) => {
        if (payload.callBack) {
          payload.callBack();
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getJobApplicationDetails = createAsyncThunk(
  "jobs/getJobApplicationDetails",
  async (payload, { dispatch }) => {
    return apiGet(`${API_URL.getjobApplication}/${payload.applicationId}`, {})
      .then((res) => {
        if (payload.callBack) {
          payload.callBack(dispatch);
        }

        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getJobsAppliedFor = createAsyncThunk(
  "jobs/getJobsAppliedFor",
  async (payload) => {
    return apiGet(`${API_URL.applyForJob}/mine`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getUserJobTokens = createAsyncThunk(
  "jobs/getJobTokens",
  async (payload) => {
    return apiGet(API_URL.getJobTokens)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const buyMoreJobTokens = createAsyncThunk(
  "jobs/buyMoreJobTokens",
  async (payload, { dispatch }) => {
    return apiPost(API_URL.buyMoreJobToken, payload)
      .then((res) => {
        if (payload.callBack) {
          payload.callBack();
        }
        if(res.data?.data?.reference == "_"){
          showSimpleToast("Token added successfully", "success");
          // dispatch(getMyCourses());
        }else{
           window.location.href = res.data?.data?.paymenturl;
        }
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

// export const inviteTeacherToSchool = createAsyncThunk(
//   "school/inviteTeacherToSchool",
//   async (payload) => {
//     return apiPost(API_URL.inviteTeacherToSchool, payload.data, null, true)
//       .then((res) => {
//         payload?.callBack();
//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );

// export const respondToSchoolInvite = createAsyncThunk(
//   "school/respondToSchoolInvite",
//   async (payload, { dispatch }) => {
//     return apiPost(API_URL.respondToSchoolInvite, payload.data)
//       .then((res) => {
//         dispatch(getUserNotifications())
//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );

// export const listTeachersForCourse = createAsyncThunk(
//   "school/listTeachersForCourse",
//   async (payload) => {
//     return apiGet(`${API_URL.listTeachersForCourse}/${payload}`)
//       .then((res) => {
//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );

// export const addTeachersToCourse = createAsyncThunk(
//   "school/addTeachersToCourse",
//   async (payload, {dispatch}) => {
//     return apiPost(`${API_URL.addTeachersToCourse}/${payload.courseId}`, payload.data)
//       .then((res) => {
//         dispatch(listTeachersForCourse(payload.courseId));
//         payload.callBack();
//         return res.data;
//       })
//       .catch((error) => {
//         return error;
//       });
//   }
// );