import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { APP_COLOURS, PAGES } from "../../utils/constant";
import logo from "../../assets/svg/logo.svg";
import simpleLogo from "../../assets/svg/SafetichaLogo.svg";

import SettingsIcon from "../../assets/svg/dashboard-icons/SettingsIcon";
import NotificationIcon from "../../assets/svg/dashboard-icons/NotificationIcon";
import CartIcon from "../../assets/svg/dashboard-icons/CartIcon";
import HeartIcon from "../../assets/svg/dashboard-icons/HeartIcon";

import DashboardAvatar from "../../assets/img/DashboardAvatar.png";

import DashboardNavList from "./DashboardNavList";
import AppButton from "../buttons/AppButton";
import AppInput from "../inputs/AppInput";

import NorthIcon from "@mui/icons-material/North";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../redux/auth/slice";
import { clearCart } from "../../redux/cart/slice";
import { clearCourse } from "../../redux/courses/slice";
import { AutoStories, Home, LocalLibrary, People, AssignmentInd, Work, Cloud, Store } from "@mui/icons-material";
import DashboardIcon from "../../assets/svg/dashboard-icons/DashboardIcon";
import CoursesIcon from "../../assets/svg/dashboard-icons/CoursesIcon";
import ProfileIcon from "../../assets/svg/dashboard-icons/ProfileIcon";
import TeacherIcon from "../../assets/svg/dashboard-icons/TeacherIcon";
import MessagesIcon from "../../assets/svg/dashboard-icons/MessagesIcon";
import HelpIcon from "../../assets/svg/dashboard-icons/HelpIcon";
import { showSimpleToast } from "../../utils/alertFunctions";

const DashboardLayout = ({ children }) => {
  const [pageTitle, setPageTitle] = useState("");

  const dispatch = useDispatch();

  const { cartCourses } = useSelector((state) => state.cartReducer);
  const { userNotifications } = useSelector((state) => state.userReducer);
  const { userData } = useSelector((state) => state.authReducer);

  const navigate = useNavigate();
  const location = useLocation();

  const handleListItemClick = (link) => {
    navigate(link);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCart());
    dispatch(clearCourse());
    navigate("/login");
  };

  const handleMyProfile = () => {
    navigate("/profile");
  };

  const handleChangePassword = () => {
    navigate("/profile/change-password");
  };

  const selected = (link) =>{
    if(location.pathname == "/dashboard" && link== "/dashboard"){
      return true
    }else if(location.pathname !== "/dashboard" && link != "/dashboard"){
      return location.pathname.includes(link)
    }
    return false;
   }

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setPageTitle("Home");
    } else if (location.pathname === "/courses") {
      setPageTitle("Courses");
    }else if (location.pathname === "/documents") {
      setPageTitle("My Documents");
    } else if (location.pathname === "/staff") {
      setPageTitle("Staff");
    } else if (location.pathname === "/profile") {
      setPageTitle("Profile");
    } else if (location.pathname === "/resources") {
      setPageTitle("My Store");
    } else if (location.pathname === "/jobs/messages") {
      setPageTitle("Messages");
    } else if (location.pathname === "/help") {
      setPageTitle("Help");
    } else if (location.pathname === "/settings") {
      setPageTitle("Settings");
    } else if (location.pathname === "/cart") {
      setPageTitle("Cart");
    } else if (location.pathname === "/teachers") {
      setPageTitle("Teachers");
    } else if (location.pathname === "profile/change-password") {
      setPageTitle("Change Password");
    } else if (location.pathname === "/notifications") {
      setPageTitle("Notifications");
    } else if (location.pathname.includes("/courses/manage-course")) {
      setPageTitle("Manage Course");
    } else if (location.pathname == "courses/my-courses") {
      setPageTitle("My Courses");
    }else if (location.pathname.includes("/certificate/course")) {
      setPageTitle("Certificate");
    }else if (location.pathname == "/jobs/buy-tokens") {
      setPageTitle("Job Tokens Pricing");
    }else if (location.pathname === "/jobs" ) {
      setPageTitle("Jobs");
    }else if (location.pathname === "/resource/marketplace" ) {
      setPageTitle("Market Place");
    }else if (location.pathname === "/jobs/create" ) {
      setPageTitle("Create Job");
    }else if(location.pathname.includes("jobs/view-application")){
      setPageTitle("View Application")
    }else if (location.pathname.includes("jobs/")) {
      setPageTitle("Job Details");
    }
  }, [location.pathname]);

  return (
    <Grid container>
      {/* nav grid */}
      <Grid item md={2} lg={2} sx={styles.leftGrid}>
        <img
          src={logo}
          alt="logo"
          onClick={() => {
            navigate("/");
          }}
        />

        <Box sx={styles.leftGridNav}>
          <DashboardNavList />

          {/* <ListItemButton
            sx={styles.navButton}
            selected={location.pathname === "/settings"}
            onClick={(event) => handleListItemClick("/settings")}
          >
            <ListItemIcon sx={styles.navIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton> */}

          <Box sx={styles.navUpgrade}>
            <NorthIcon sx={styles.upArrow} />
            <Typography sx={styles.navUpgradeText}>
              SAFETICHA 
            </Typography>
            {/* <AppButton
              customStyles={{ fontWeight: "bold", fontSize: "18px" }}
              btnText="Upgrade"
              onClick={()=> showSimpleToast("You will be able to do this soon ⏱️", "success")}
            /> */}
          </Box>
        </Box>
      </Grid>

      {/* main grid */}
      <Grid item lg={10} sx={styles.rightGrid}>
        <Box sx={styles.topBar}>
          <Box display="flex">
            <Box
              pr="10px"
              component="img"
              src={simpleLogo}
              alt="logo"
              sx={{ width: "35px", display: { xs: "block", lg: "none" } }}
              onClick={() => {
                navigate("/");
              }}

            />
            <Typography sx={styles.pageTitle}>{pageTitle}</Typography>
          </Box>


          <Box sx={{ ...styles.search, display: { xs: "none", md: "none", lg: "block" } }}>
            <AppInput bgColor="white" value="" noBottomPad placeholder="Search..." />
          </Box>

          <Box sx={styles.rightTopBar}>
            <Box
              position="relative"
              display="flex"
              alignItems="flex-end"
              sx={{ cursor: "pointer" }}
            >
              {/* cart notification */}
              <Box
                position="absolute"
                right="-13px"
                top="-15px"
                bgcolor="#ff0000"
                color="white"
                p="3px 7px"
                fontWeight="900"
                borderRadius="30px"
                fontSize="12px"
              >
                {userNotifications.length}
              </Box>

              <NotificationIcon onClick={() => {
                navigate("/notifications");
              }} />
            </Box>


            <Box
              position="relative"
              display="flex"
              alignItems="flex-end"
              sx={{ cursor: "pointer" }}
            >
              {/* cart notification */}
              <Box
                position="absolute"
                right="-13px"
                top="-15px"
                bgcolor="#ff0000"
                color="white"
                p="3px 7px"
                fontWeight="900"
                borderRadius="30px"
                fontSize="12px"
              >
                {cartCourses?.length}
              </Box>

              {/* cart icon */}
              <CartIcon
                onClick={() => {
                  navigate("/cart");
                }}
              />
            </Box>
            <AutoStories sx={{ color: "#9DA6BA", cursor: "pointer", display: { xs: "none", lg: "block" } }} onClick={() => {
              navigate(PAGES.myCourses);
            }} />

            {/* avatar */}
            <Box
              component="img"
              src={userData?.imgUrl ? userData?.imgUrl : DashboardAvatar}
              alt="avatar"
              id="basic-avatar"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ cursor: "pointer", width: 40, height: 40, borderRadius: "50%" }}
            />

            <Menu
              anchorEl={anchorEl}
              id="basic-menu"
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-avatar",
              }}
              sx={{ mt: 1.5 }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleMyProfile();
                  handleClose();
                }}
              >
                My profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate(PAGES.myCourses)
                }}
              >
                My courses
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleChangePassword();
                  handleClose();
                }}
              >
                Change password
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleLogout();
                  handleClose();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        {children}
      </Grid>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { xs: "block", md: "block", lg: "none" } }} elevation={3}>
        <BottomNavigation
          showLabels
          value=""
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
        >
          <BottomNavigationAction onClick={() => navigate("/dashboard")} sx={selected("/dashboard") ? {color:  "#2E3192"}:{}}  label="Home" icon={<Home />} />
          <BottomNavigationAction onClick={() => navigate("/resource/marketplace")} sx={selected("/resource/marketplace") ? {color:  "#2E3192"}:{}} label="Resources" icon={<LocalLibrary  />} />
          {userData.userType == "school" && <BottomNavigationAction sx={selected("/teacher") ? {color:  "#2E3192"}:{}} onClick={() => navigate("/teachers")} label="Teachers" icon={<People />} />}
          <BottomNavigationAction onClick={() => navigate("/jobs")} sx={selected("/jobs") ? {color:  "#2E3192"}:{}}  label="Jobs" icon={<Work />} />
          <BottomNavigationAction onClick={() => navigate("/resources")} sx={selected("/resources") ? {color:  "#2E3192"}:{}}  label="My Store" icon={<Store />} />
          {/* <BottomNavigationAction onClick={() => navigate("/dashboard/profile")} sx={selected("/dashboard/profile") && {color:  "#2E3192"}} label="Profile" icon={<AssignmentInd  />} /> */}
        </BottomNavigation>
      </Paper>
    </Grid>
  );
};

export default DashboardLayout;

const styles = {
  leftGrid: {
    backgroundColor: APP_COLOURS.white,
    height: "100vh",
    padding: "25px",
    position: "sticky",
    top: "0",
    display: { xs: "none", md: "none", lg: "block" }
  },
  leftGridNav: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "93%",
  },
  navButton: {
    color: APP_COLOURS.grey,
    flexGrow: "0",

    ".MuiSvgIcon-root": {
      color: APP_COLOURS.grey,
    },
    "&.Mui-selected": {
      backgroundColor: "primary.main",
      color: "white",
      borderRadius: "10px",

      ".MuiSvgIcon-root": {
        color: "white",
      },
    },
    "&.Mui-selected:hover": {
      backgroundColor: "primary.main",
    },
    "&:hover": {
      backgroundColor: "none",
    },
  },
  navIcon: {
    minWidth: 0,
    marginRight: "10px",
  },
  navUpgrade: {
    textAlign: "center",
  },
  navUpgradeText: {
    color: "primary.main",
    margin: "10px 0",
    fontWeight: 600,
    fontSize: "18px",
  },
  upArrow: {
    color: "white",
    backgroundColor: "primary.main",
    padding: "10px",
    borderRadius: "10px",
    fontSize: 40,
  },
  rightGrid: {
    backgroundColor: APP_COLOURS.lightGrey,
    width:"100%",
    // height: "100vh",
    paddingBottom: { xs: "60px", md: "60px", lg: "0" }
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: { xs: "15px 20px", lg: "15px 40px" }
  },
  pageTitle: {
    fontWeight: "500",
    fontSize: { xs: "20px", lg: "24px" }
  },
  search: {
    width: "40%",
  },
  rightTopBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "30px",
  },
};
