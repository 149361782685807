import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet, apiPost, apiPut } from "../../service/apiService";
import { API_URL } from "../../utils/apiConstants";
import { showSimpleToast } from "../../utils/alertFunctions";

export const getAllCourses = createAsyncThunk(
  "courses/getAllCourses",
  async (payload) => {
    return apiGet(API_URL.getAllCourses)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getMyCourses = createAsyncThunk(
  "courses/getMyCourses",
  async (payload) => {
    return apiGet(API_URL.getMyCourses)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getAllPopularCourses = createAsyncThunk(
  "courses/getAllPopularCourses",
  async (payload) => {
    return apiGet(API_URL.getAllPopularCourses)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getAllCategoryCourses = createAsyncThunk(
  "courses/getAllCategoryCourses",
  async (payload) => {
    return apiGet(API_URL.getAllCategoryCourses + payload)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getPurchaseUrl = createAsyncThunk(
  "courses/getPurchaseUrl",
  async (payload, {dispatch}) => {
    return apiPost(API_URL.getPurchaseUrl, payload.data)
      .then((res) => {
        if(res.data?.data?.reference == "_"){
          showSimpleToast("Course added successfully", "success");
          dispatch(getMyCourses());
        }else{
           window.location.href = res.data?.data?.paymenturl;
        }
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getCourseCategory = createAsyncThunk(
  "courses/getCourseCategory",
  async (payload) => {
    return apiGet(API_URL.getCourseCategory)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getCourse = createAsyncThunk(
  "courses/getCourse",
  async (payload) => {
    return apiGet(API_URL.getCourse + payload)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getCourseContent = createAsyncThunk(
  "courses/getCourseContent",
  async (payload) => {
    return apiGet(API_URL.getCourseContent +'/'+ payload)
      .then((res) => {
        // payload.callBack();
        // console.log('parenteeee :>> ', res.data);
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getCourseProgress = createAsyncThunk(
  "courses/getCourseProgress",
  async (payload) => {
    return apiGet(API_URL.getCourseProgress)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const updateCourseProgress = createAsyncThunk(
  "courses/updateCourseProgress",
  async (payload) => {
    return apiPut(API_URL.updateCourseProgress, payload.data,null,true)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);
