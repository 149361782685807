import React from "react";
import { Stack, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import AppInput from "../../inputs/AppInput";
import AppButton from "../../buttons/AppButton";

const CheckoutStepOne = ({ handleNextPage }) => {
  return (
    <>
      <AppInput
        name="card_number"
        label="Card Number"
        placeholder="1234  5678  9101  1121"
      />

      <Stack direction="row" spacing={2}>
        <AppInput
          name="expiration_date"
          label="Expiration Date"
          placeholder="MM/YY"
        />

        <AppInput name="cvv" label="CVV" placeholder="123" />
      </Stack>

      <FormGroup>
        <FormControlLabel control={<Checkbox />} label="Save card details" />
      </FormGroup>

      <AppButton
        customStyles={{
          fontWeight: "700",
          fontSize: "16px",
          borderRadius: "8px",
          my: "25px",
        }}
        onClick={() => {
          handleNextPage();
        }}
        btnText="Pay ₦3,900"
      />
    </>
  );
};

export default CheckoutStepOne;
