import React from "react";
import { Box, Typography } from "@mui/material";
import img1mobile from "../../../assets/img/WhyChooseUs/WhyChooseImg1a.png";
import img1desktop from "../../../assets/img/WhyChooseUs/WhyChooseImg1b.png";
import img2 from "../../../assets/img/WhyChooseUs/WhyChooseImg2.png";
import checkmark from "../../../assets/svg/WhyChooseUs/checkmark.svg";
import AppButton from "../../buttons/AppButton"
import GooglePlaystore from "../../../assets/svg/WhyChooseUs/GooglePlaystore.svg";
import AppStore from "../../../assets/svg/WhyChooseUs/AppStore.svg";

import classes from "./WhyChooseUs.module.css";
import { useNavigate } from "react-router-dom";

const WhyChooseUs = () => {
  const navigate = useNavigate();
  return (
    <Box
      component="section"
      maxWidth="1600px"
      margin="40px auto 0"
      width={{ xs: "100%", md: "80%" }}
    >
      {/* box 1 */}
      <Box sx={styles.whyChooseUs}>
        {/* image */}
        <Box
          display={{ xs: "none", md: "block" }}
          height="500px"
          width="auto"
          margin={{ xs: "0 auto", md: "0" }}
          position="relative"
        >
          <img src={img1desktop} alt="img1 desktop" className={classes.image} />
        </Box>

        <Box
          display={{ xs: "block", md: "none" }}
          width="90vw"
          margin={{ xs: "0 auto", md: "0" }}
          position="relative"
        >
          <img src={img1mobile} alt="img1 mobile" className={classes.image} />
        </Box>

        {/* content/text */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          gap="10px"
        >
          <Typography
            fontSize="15px"
            color="#2E3192"
            mb={{ xs: "5px", md: "10px" }}
          >
            WHY CHOOSE US
          </Typography>

          <Typography
            fontSize={{ xs: "20px", md: "50px" }}
            width="100%"
            fontWeight="600"
            lineHeight={{ md: "60px" }}
          >
            Get in touch with verified educators and schools.
          </Typography>
          <Typography fontSize="18px" fontWeight="400">
            Find qualified and safety certified educators. Click their
            portfolios to access their legal documents, credentials and
            professional qualifications.
          </Typography>

          <hr style={{ width: "100%" }} />

          <Box sx={styles.checkmarkList}>
            <img src={checkmark} alt="checkmark" height={20} />
            <Typography fontSize="18px">
              Safely recruit teachers and tutors
            </Typography>
          </Box>

          <Box my="4px" sx={styles.checkmarkList}>
            <img src={checkmark} alt="checkmark" height={20} />
            <Typography fontSize="18px">Professional development</Typography>
          </Box>

          <Box sx={styles.checkmarkList}>
            <img src={checkmark} alt="checkmark" height={20} />
            <Typography fontSize="18px">
              Career growth with curated courses
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* box 2 */}
      <Box alignItems="center" sx={styles.whyChooseUs}>
        <Box display="flex" flexDirection="column" gap="15px">
          <Typography
            fontSize="15px"
            color="#2E3192"
            mb={{ xs: "5px", md: "10px" }}
          >
            WHY CHOOSE US
          </Typography>

          <Typography
            fontSize={{ xs: "20px", md: "50px" }}
            width="100%"
            fontWeight="600"
            lineHeight={{ md: "60px" }}
          >
            Get certified with professional courses
          </Typography>
          <Typography fontSize="18px" width="90%">
            Access world class courses that get you great opportunities within
            and outside your organisation.
          </Typography>

          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            gap="10px"
            alignItems={{ xs: "flex-start", md: "center" }}
          >
              <AppButton
              customStyles={{ fontWeight: "600", fontSize: {xs:"16px", md:"20px"}, borderRadius: "20px"  }}
              fullWidth={false}
              btnText="Start your growth journey"  onClick={()=> navigate("/courses")}         
            />
            {/* <Box>
              <img
                src={GooglePlaystore}
                alt="google"
                style={styles.responsive}
              />
            </Box>
            <Box>
              <img src={AppStore} alt="app store" style={styles.responsive} />
            </Box> */}
          </Box>
        </Box>

        <Box
          height={{ md: "100%" }}
          width={{ xs: "100%", md: "auto" }}
          margin={{ xs: "0 auto", md: "0" }}
        >
          <img src={img2} alt="img2" className={classes.image} />
        </Box>
      </Box>
    </Box>
  );
};

export default WhyChooseUs;

const styles = {
  whyChooseUs: {
    display: "flex",
    width: { xs: "90%", md: "100%" },
    flexDirection: { xs: "column", md: "row" },
    margin: "0 auto 100px",
    gap: "72px",
    justifyContent: "space-between",
  },
  checkmarkList: {
    display: "flex",
    gap: "10px",
  },
};
