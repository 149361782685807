import React from "react";
import { Box, Typography, Container, List, ListItem, ListItemIcon, ListItemText, Button } from "@mui/material";
import DrTiti from "../../assets/img/Speakers/Dr_titi.jpg";
import Abimbola from "../../assets/img/Speakers/abimbola.png";
import Olurinola from "../../assets/img/Speakers/olurinola.png";
import DrJumoke from "../../assets/img/Speakers/Dr_Olajumoke.png";
import Isa from "../../assets/img/Speakers/isa.png";
import IfyObidi from "../../assets/img/Speakers/Ify-Obidi-Essien.jpg";
import Ulesson from "../../assets/img/Speakers/Ulesson.png";
import Speaker from "./Speaker";
import { AccessTimeFilled, PinDrop, RocketLaunch, TrendingUp, House } from "@mui/icons-material";
import AppButton from "../buttons/AppButton";

const EventRemain = () => {
    return (
        <>
            <Container sx={{ paddingTop: { xs: "30px", md: "50px" } }}>
                <Typography fontSize={{ xs: "20px", md: "28px" }} textAlign="center" fontWeight="bold" sx={{ marginBottom: "40px", color: "#2E3192" }}>Expected Speakers</Typography>
                <Box sx={styles.speakerBox}>
                    <Speaker image={DrTiti} name="Dr. Titi Olorunyomi" role="Deputy Director of Education, Federal Ministry of Education, Nigeria" />
                    <Speaker image={Abimbola} name="Abimbola Banu-Ogundere" role="Global Education Leadership & Management Expert" />
                    <Speaker image={Olurinola} name="Olurinola Oluwakemi" role="Edtech Consultant / Researcher" />
                </Box>

                <Box sx={styles.speakerBox}>
                    <Speaker image={DrJumoke} name="Dr. Olajumoke Mekiliuwa" role="Provost, Corona College of Education, Lagos" />
                    <Speaker image={Isa} name="Dr. Joy Isa" role="President- Isa School of Education" />
                    <Speaker image={IfyObidi} name="Ify Obidi-Essien" role="Edu-Tech Consultant & Microsoft Global Training Partner" />
                </Box>

                <Box sx={styles.speakerBox}>
                    <Speaker image={Ulesson} name="Iheanyichukwu Akwitti" role="Senior Vice President - uLesson Education" />
                    {/* <Speaker image={MsYemisi} name="Yemisi Ogunlade" role="Principal Consultant, Plumptre Advisory, Nigeria" />
        <Speaker image={IfyObidi} name="IFY OBIDI-ESSIEN" role="Edu-Tech Consultant & Microsoft Global Training Partner" /> */}
                </Box>

                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: { xs: "none", md: "space-around" }, marginY: { xs: "30px", md: "50px" } }}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: { xs: "10px", md: "0" } }}>
                        <PinDrop sx={{ fontSize: { xs: "35px", md: "50px" }, color: "#2E3192" }} />
                        <Box sx={{ marginLeft: "10px" }}>
                            <Typography fontWeight="bold">CIS Hall</Typography>
                            <Typography>Amore Street, off Freedom way, Lekki Phase 1</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: { xs: "10px", md: "0" } }}>
                        <House sx={{ fontSize: { xs: "35px", md: "50px" }, color: "#2E3192" }} />
                        <Box sx={{ marginLeft: "10px" }}>
                            <Typography fontWeight="bold"> 11th of August, 2023</Typography>
                            <Typography> 9am - 4pm (WAT)</Typography>
                        </Box>
                    </Box>

                </Box>
                <Box>

                </Box>
            </Container>
            <Box sx={{ backgroundColor: "#2E3192" }}>
                <Container sx={{ paddingTop: { xs: "30px", md: "80px" }, display: "flex", flexDirection: { xs: "column", md: "row" }, paddingX: { xs: "20px" } }}>
                    <Box sx={{ width: { xs: "100%", md: "40%" } }}>
                        <Typography fontSize={{ xs: "30px", md: "50px", color: "#ffffff" }} fontWeight="bold" >Exhibit or Sell at SSF 2023</Typography>
                        <TrendingUp sx={{ fontSize: "50px", color: "#ffffff" }} />
                    </Box>

                    <Box sx={{ width: { xs: "100%", md: "60%" } }}>
                        <Typography color="#ffffff" fontSize="16px" marginBottom="10px">Showcase your product/service at the largest gathering of school leaders in Lagos, 2023. Safeticha has put together a summer fest hosting over 500 decision makers in the best schools in Nigeria.
                        </Typography>

                        <List>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <RocketLaunch sx={{ color: "#ffffff" }} />
                                </ListItemIcon>
                                <ListItemText sx={{ color: "#ffffff" }} primary="Up to 600 schools leaders in attendance" />
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <RocketLaunch sx={{ color: "#ffffff" }} />
                                </ListItemIcon>
                                <ListItemText sx={{ color: "#ffffff" }} primary="Edtech solutions highlight" />
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <RocketLaunch sx={{ color: "#ffffff" }} />
                                </ListItemIcon>
                                <ListItemText sx={{ color: "#ffffff" }} primary="Direct marketing opportunity" />
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <RocketLaunch sx={{ color: "#ffffff" }} />
                                </ListItemIcon>
                                <ListItemText sx={{ color: "#ffffff" }} primary="Opportunity to address and onboard school leaders" />
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <RocketLaunch sx={{ color: "#ffffff" }} />
                                </ListItemIcon>
                                <ListItemText sx={{ color: "#ffffff" }} primary="Discounted items from education vendors" />
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <RocketLaunch sx={{ color: "#ffffff" }} />
                                </ListItemIcon>
                                <ListItemText sx={{ color: "#ffffff" }} primary="Target market networking" />
                            </ListItem>
                        </List>
                    </Box>
                </Container>
                <Container sx={{ marginTop: "30px", paddingBottom: { xs: "15px", md: "30px" } }}>
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row", justifyContent: "space-around" } }}>
                        <Box sx={styles.exhibitBox}>
                            <Box sx={styles.exhibitTextBox}>
                            <Typography sx={styles.exhibitText}>Exhibit A - ₦950,000</Typography>
                            {/* <Typography sx={styles.exhibitText}>Early bird - ₦700,000</Typography> */}
                            </Box>
                           
                            <Typography sx={styles.exbBooth}>3x3 booth</Typography>
                            <Typography sx={styles.extList}>- QR code to onboard school leaders in event brochure
                            </Typography>
                            <Typography sx={styles.extList}>- Multimedia advert slot</Typography>
                            <Typography sx={styles.extList}>- 5 minute speaking slot to all school leaders</Typography>
                        </Box>

                        <Box sx={styles.exhibitBox}>
                        <Box sx={styles.exhibitTextBox}>
                            <Typography sx={styles.exhibitText}>Exhibit B - ₦700,000</Typography>
                            {/* <Typography sx={styles.exhibitText}>Early bird - ₦500,000</Typography> */}
                            </Box>
                            <Typography sx={styles.exbBooth}>3x2 booth</Typography>
                            <Typography sx={styles.extList}>- QR code to onboard school leaders in event brochure
                            </Typography>
                            <Typography sx={styles.extList}>- Multimedia advert slot</Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row", justifyContent: "space-around" }, marginTop: { xs: "0", md: "30px" } }}>
                        <Box sx={styles.exhibitBox}>
                        <Box sx={styles.exhibitTextBox}>
                            <Typography sx={styles.exhibitText}>Exhibit C - ₦450,000</Typography>
                            {/* <Typography sx={styles.exhibitText}>Early bird - ₦300,000</Typography> */}
                            </Box>
                            <Typography sx={styles.exbBooth}>3x3 booth</Typography>
                        </Box>

                        <Box sx={styles.exhibitBox}>
                        <Box sx={styles.exhibitTextBox}>
                            <Typography sx={styles.exhibitText}>Exhibit D - ₦350,000</Typography>
                            {/* <Typography sx={styles.exhibitText}>Early bird - ₦230,000</Typography> */}
                            </Box>
                            <Typography sx={styles.exbBooth}>3x2 booth</Typography>
                        </Box>

                        <Box sx={styles.exhibitBox}>
                        <Box sx={styles.exhibitTextBox}>
                            <Typography sx={styles.exhibitText}>Exhibit E - ₦270,000</Typography>
                            {/* <Typography sx={styles.exhibitText}>Early bird - ₦180,000</Typography> */}
                            </Box>
                            <Typography sx={styles.exbBooth}>2x2 booth</Typography>
                        </Box>
                    </Box>
                </Container>
                {/* <Typography sx={{...styles.exbBooth, fontWeight:"bold", textAlign: "center"}}>Early birds payments will end July 14th</Typography> */}
                <Box sx={{marginTop: "10px", textAlign: "center", paddingBottom: { xs: "30px", md: "80px" } }}>
                    <a
                        href="https://paystack.com/buy/sssf-exhibit"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <AppButton btnText="Exhibit @ SSF" customStyles={styles.purchaseButton} fullWidth={false} />
                    </a>
                </Box>

            </Box>

            <Box>
                    <Box  sx={{ paddingTop: { xs: "30px", md: "80px" }}}>
                    <Typography fontSize={{ xs: "20px", md: "28px" }} textAlign="center" fontWeight="bold" sx={{ marginBottom: "40px", color: "#2E3192" }}>For Sponship and Partnership</Typography>
                    </Box>

                <Box sx={{ marginTop: "10px", textAlign: "center" }}>
                    <a
                        href="https://forms.gle/rbVPQyTXeEAJ2Fv38"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <AppButton btnText="Become a Sponsor" customStyles={styles.purchaseButton2} fullWidth={false} />
                    </a>
                </Box>

            </Box>

        </>

    );
};

export default EventRemain;

const styles = {
    container: {
        position: "relative",
        overflow: "hidden",
        background: {
            sm: "#ffffff",
            md: "linear-gradient(292.12deg, #D5D6E9 -35.02%, #EAEAF4 78.96%)",
        },
        padding: { xs: "0", md: "0 70px 170px" },
    },
    speakerBox: {
        display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-around",
        marginBottom: { xs: "0", md: "30px" }
    },
    exhibitBox: { width: { xs: "100%", md: "30%" }, border: "solid 2px #ffffff", borderRadius: "19px", paddingBottom: "10px", textAlign: "center", marginBottom: { xs: "20px", md: "0" } },
    exhibitBox2: { width: { xs: "100%", md: "30%" }, border: "solid 2px #2E3192", borderRadius: "19px", paddingBottom: "10px", textAlign: "center", marginBottom: { xs: "20px", md: "0" } },
    exhibitText: {
        color: "#2E3192",
        fontWeight: "bold"
    },
    exhibitTextBox: {
        backgroundColor: "#ffffff",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        padding: "7px",
        textAlign: "center"
    },
    exhibitText2: {
        backgroundColor: "#2E3192",
        color: "#ffffff",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        padding: "7px",
        fontWeight: "bold"
    },
    exbBooth: {
        color: "#ffffff",
        fontStyle: "italic",
        marginTop: "5px"
    },
    extList: {
        color: "#ffffff",
        paddingX: "5px"
    },
    extList2: {
        color: "#2E3192",
        paddingX: "5px",
        marginTop:"8px"
    },
    purchaseButton: {
        color: "#2E3192", backgroundColor: "#fff", '&:hover': {
            backgroundColor: '#fff',
            color: '#3c52b2',
        },
    },
    purchaseButton2: {
        '&:hover': {
            backgroundColor: '#fff',
            color: '#3c52b2',
        },
    }
};
