import React from "react";
import ChangePassword from "../../components/dashboard/SharedComponents/ChangePassword";

const ChangeProfilePassword = () => {
  return (
    <>
      <ChangePassword />
    </>
  );
};

export default ChangeProfilePassword;
