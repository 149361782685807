import React, { useState, useEffect } from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
// import DashboardCard from "./DashboardCard";
import { DashboardCardArray } from "./DashboardCardArray";
import SecureOnline from "../../../../assets/svg/dashboard-icons/SecureOnline.svg";
import TeachersDay from "../../../../assets/img/Monday.png";
import CoursesGrid from "../../SharedComponents/CoursesGrid";
import AppButton from "../../../buttons/AppButton";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllPopularCourses,
  getCourseProgress,
} from "../../../../redux/courses/actions";
import ToDoList from "../../SharedComponents/ToDoList";
import DashboardCard from "../../SharedComponents/DashboardCard";
import { CancelOutlined } from "@mui/icons-material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const TeacherDashboard = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { allPopularCourses, allPopularCoursesLoading, courseProgress } =
    useSelector((state) => state.coursesReducer);
  const [showModal, setShowMOdal] = useState(false);

  // function to get course
  const onOpenCourse = (value) => {
    navigate("/courses/" + value);
  };

  useEffect(() => {
    dispatch(getAllPopularCourses());
    dispatch(getCourseProgress());
    // var teacherDay = localStorage.getItem("_teachersday");
    // console.log('teacherDay :>> ', teacherDay);
    // if(!teacherDay || teacherDay == "false"){
    //   setShowMOdal(true);
    // }
    // eslint-disable-next-line
  }, []);

  const dontShowAgain = () => {
    if (window.confirm("Choose 'Cancel' to enroll later and 'OK' to not show popup again?")) {
      localStorage.setItem("_teachersday", "true")
    }
    setShowMOdal(false)
    // localStorage.setItem("_teachersday", "true")
  }

  return (
    <Box component="section" p="30px">
      <Modal
        open={showModal}
        onClose={dontShowAgain}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          item
          borderRadius="15px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative" sx={style}
        >
          <CancelOutlined onClick={dontShowAgain} sx={{ position: "absolute", top: 15, left: 20, width: "30px", height: "30px" }} />

          <Box component="img" src={TeachersDay} alt="image" style={{ width: "100%", borderRadius: "15px" }} />
          <a
            href="https://forms.gle/9VqbSDc6cMHr7wAP6"
            target="_blank"
            rel="noopener noreferrer" style={{
              position: "absolute",
              bottom: "25px"
            }}
          >
            <AppButton
              customStyles={{
                fontWeight: "600",
                borderRadius: "8px",
                padding: "7px 30px",
                backgroundColor: "white",
                color: "#2D05CE",
                "&:hover": {
                  background: "white"
                }
              }}
              btnText="Enroll Now"
              fullWidth={false}
            />
          </a>
        </Box>
      </Modal>
      {/* dashboard details */}
      <Grid
        container
        gap="10px"
        mb="50px"
      >
        <DashboardCard name="Certificate Earned" value={courseProgress?.certificateEarned} />
        <DashboardCard name="My Courses" value={courseProgress?.myCourses} />
        <DashboardCard name="Course in Progress" value={courseProgress?.courseInProgress} />

        {/* */}
      </Grid>

      {/* most popular and to do list */}
      <Grid xs={12} container gap="20px">
        <Grid sx={{ width: { xs: "100%", md: "66%", lg: "68%" } }}>
          <CoursesGrid
            coursesArray={allPopularCourses}
            gridTitle="Take a Course"
            onOpenCourse={onOpenCourse}
            showPreloader={allPopularCoursesLoading}
            width={{ width: { xs: "100%", md: "48%", lg: "31%" } }}
          />

        </Grid>

        <Grid sx={{ width: { xs: "100%", md: "32%", lg: "30%" } }}>
        <Box padding={0} sx={{ display: { xs: "none", md: "none", lg: "block" } }}>
             <ToDoList/>
          </Box>
          {/* <Grid
            item
            borderRadius="15px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="20px"
            mb="20px"
            position="relative"
          >

            <Box component="img" src={TeachersDay} alt="image" style={{ width: "100%", borderRadius: "15px" }} />
            <a
              href="https://forms.gle/9VqbSDc6cMHr7wAP6"
              target="_blank"
              rel="noopener noreferrer" style={{
                position: "absolute",
                bottom: "25px"
              }}
            >
              <AppButton
                customStyles={{
                  fontWeight: "600",
                  borderRadius: "8px",
                  padding: "7px 30px",
                  backgroundColor: "white",
                  color: "#2D05CE",
                  "&:hover": {
                    background: "white"
                  }
                }}
                btnText="Join Now"
                fullWidth={false}
              />
            </a>
          </Grid> */}

          <Grid
            item
            borderRadius="15px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="20px"
            p="30px 20px"
            mb="20px"
            sx={{
              background: "linear-gradient(180deg, #1E0094 0%, #2D05CE 100%)",
            }}
          >
            <Typography color="#fff" textAlign="center">
              Let's get you started with Safeguarding and Child Protection
            </Typography>

            <Box component="img" src={SecureOnline} alt="image" />

            <AppButton
              customStyles={{
                fontWeight: "600",
                borderRadius: "8px",
                padding: "7px 30px",
                backgroundColor: "white",
                color: "#2D05CE",
                "&:hover": {
                  background: "white"
                }
              }}
              onClick={() => navigate("/courses")}
              btnText="Enroll Now"
              fullWidth={false}
            />
          </Grid>
          <Box margin={0} padding={0} sx={{ display: { xs: "block", md: "block", lg: "none" } }}>
            <ToDoList />
          </Box>

        </Grid >

      </Grid>
    </Box>
  );
};

export default TeacherDashboard;
