import React, { useState, useEffect } from "react";
import { Box, MenuItem, FormControl, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import CoursesGrid from "../SharedComponents/CoursesGrid";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllCourses,
  getCourseCategory,
  getAllCategoryCourses,
} from "../../../redux/courses/actions";
import AppButton from "../../buttons/AppButton";
import { useAuth } from "../../../utils/auth/authFunctions";

const Courses = () => {
  const [valueState, setValueState] = useState("");
  const [categoryState, setCategoryState] = useState("");
  const dispatch = useDispatch();
  const isLoggedIn = useAuth();

  const {
    allCourses,
    allCoursesLoading,
    courseCategory,
    allCategoryCourses,
    allCategoryCoursesLoaded,
  } = useSelector((state) => state.coursesReducer);

  const handleChange = (event) => {
    setValueState(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategoryState(event.target.value);
  };

  const navigate = useNavigate();

  // function to get course
  const onOpenCourse = (value) => {
    if(isLoggedIn){
       navigate("/courses/" + value);
    }else{
      navigate("/buy-course/" + value);
    }
   
  };

  useEffect(() => {
    dispatch(getCourseCategory());
    dispatch(getAllCourses());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (categoryState === "") {
      dispatch(getCourseCategory());
    } else {
      dispatch(getAllCategoryCourses(categoryState));
    }
    // eslint-disable-next-line
  }, [categoryState]);

  return (
    <Box component="section" sx={{padding:{xs:"0",lg:"30px"}}}>
      <Box display="flex" justifyContent="space-between" mb="30px" sx={{marginLeft: {xs: "15px", lg:"0"}, marginRight: {xs: "15px", lg:"0"}}}>
        <Box>
          {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={valueState}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={KeyboardArrowDownIcon}
              sx={{ backgroundColor: "#d5d6e933" }}
            >
              <MenuItem value="">Sort by: Popular</MenuItem>
            </Select>
          </FormControl> */}

          {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={valueState}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={KeyboardArrowDownIcon}
              sx={{ backgroundColor: "#d5d6e933" }}
            >
              <MenuItem value="">Courses</MenuItem>
            </Select>
          </FormControl> */}

          <FormControl>
            <Select
              value={categoryState}
              onChange={handleCategoryChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={KeyboardArrowDownIcon}
              sx={{ backgroundColor: "#d5d6e933" }}
            >
              <MenuItem value="">All Categories</MenuItem>
              {courseCategory.map((category) => {
                return (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        {isLoggedIn && <AppButton
            btnText="My Courses"
            onClick={() => {
              navigate("/courses/my-courses");
            }}
            fullWidth={false}
          />}
      </Box>

      <Box backgroundColor="#d5d6e933" p="30px" borderRadius="15px">
        {/* courses */}
        <CoursesGrid
          coursesArray={categoryState === "" ? allCourses : allCategoryCourses}
          // gridTitle="Courses"
          width={{width: {xs: "100%", md: "48%", lg: isLoggedIn ? "32%" : "23%"}}}
          onOpenCourse={onOpenCourse}
          showPreloader={allCoursesLoading}
        />
      </Box>
    </Box>
  );
};

export default Courses;
