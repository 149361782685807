import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import AppButton from "../../../../buttons/AppButton";
import LeftArrow from "../../../../../assets/svg/LeftArrow.svg";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadDocumentsPage from "./UploadDocumentsPage";

import { useDispatch } from "react-redux";
import { clearUploadingState } from "../../../../../redux/document/slice";

const UploadDocuments = ({ handleProfileIndex }) => {
  const [uploadPageIndex, setUploadPageIndex] = useState(1);
  const [uploadType, setUploadType] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearUploadingState());
  }, []);

  return (
    <Box>
      <Box
        component="img"
        src={LeftArrow}
        alt="left arrow"
        position="relative"
        top="20px"
        left="30px"
        onClick={() => {
          handleProfileIndex();
        }}
      />
      <Box m="10px auto 0" width="100%" borderRadius="12px" sx={{padding: {xs: "30px", md:"70px", lg:"100px"}}}>
        {uploadPageIndex === 1 && (
          <Box
            m="0 auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="20px"
            sx={{width: {xs: "100%", md: "60%", lg:"60%"}}}
          >
            <AppButton
              customStyles={{
                fontWeight: "bold",
                borderRadius: "8px",
              }}
              btnText="CV"
              variant="outlined"
              endIcon={<UploadFileIcon />}
              onClick={() => {
                setUploadPageIndex(2);
                setUploadType("CV");
              }}
            />

            <AppButton
              customStyles={{
                fontWeight: "bold",
                borderRadius: "8px",
              }}
              btnText="POLICE REPORT"
              variant="outlined"
              endIcon={<UploadFileIcon />}
              onClick={() => {
                setUploadPageIndex(2);
                setUploadType("POLICE_REPORT");
              }}
            />

            <AppButton
              customStyles={{
                fontWeight: "bold",
                borderRadius: "8px",
              }}
              btnText="NYSC CERTIFICATE"
              variant="outlined"
              endIcon={<UploadFileIcon />}
              onClick={() => {
                setUploadPageIndex(2);
                setUploadType("NYSC_CERTIFICATE");
              }}
            />

            <AppButton
              customStyles={{
                fontWeight: "bold",
                borderRadius: "8px",
              }}
              btnText="HIGHEST DEGREE"
              variant="outlined"
              endIcon={<UploadFileIcon />}
              onClick={() => {
                setUploadPageIndex(2);
                setUploadType("HIGHEST_DEGREE");
              }}
            />

            <AppButton
              customStyles={{
                fontWeight: "bold",
                borderRadius: "8px",
              }}
              btnText="PROFESSIONAL QUALIFICATION"
              variant="outlined"
              endIcon={<UploadFileIcon />}
              onClick={() => {
                setUploadPageIndex(2);
                setUploadType("PROFESSIONAL_QUALIFICATION");
              }}
            />
          </Box>
        )}

        {uploadPageIndex === 2 && (
          <Box
          sx={{width: {xs: "100%", md: "60%", lg:"60%"}}}
            m="0 auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="20px"
          >
            <UploadDocumentsPage
              handleBackIndex={() => {
                setUploadPageIndex(1);
              }}
              handleProfileIndex={handleProfileIndex}
              uploadType={uploadType}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UploadDocuments;
