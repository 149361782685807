import React, { useEffect, useState } from 'react';
import Navbar from '../../../components/landing-page/Navbar/Navbar';
import CountDown from '../../../components/dashboard/resources/CountDown';
import { Box, Button, CircularProgress, Typography, Grid } from '@mui/material';
import IMAGES from "../../../assets/img/Resources";
import COURSE_IMAGES from "../../../assets/img/Resources/Courses";
import CustomSlider from '../../../components/dashboard/resources/CustomSlider/CustomSlider';
import CustomSelect from '../../../components/dashboard/resources/CustomSelect/CustomSelect';
import CourseCard from '../../../components/dashboard/resources/CourseCard/CourseCard';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMarketPlaceResources } from '../../../redux/resource/action';
import CartIcon from '../../../assets/svg/dashboard-icons/CartIcon';
import { useAuth } from '../../../utils/auth/authFunctions';
import AppInput from '../../../components/inputs/AppInput';
import AppButton from '../../../components/buttons/AppButton';
import { Filter, FilterAlt, Search } from '@mui/icons-material';
import ResourcesSkeleton from './resourcesPlaceholder';
import FilterResourceDrawer from '../../../components/dashboard/resources/filterResources';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { tagList, ageRangesList, SUBJECTS } from "../../../utils/constant";
import { createResource } from "../../../redux/resource/action";


export default function MarketPlace() {
    const { storePath } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useAuth()
    const { isFetchingResources, mResources } = useSelector(state => state.resourceReducer);
    const [searchParams, setSearchParams] = useSearchParams();
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const toggleDrawer = (open) => setOpenDrawer(open);

    const [title, setTitle] = useState(searchParams.get("title"));

    const useDebounce = (value, delay = 500) => {
        const [debouncedValue, setDebouncedValue] = useState(value);
    
        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
    
            return () => clearTimeout(handler);
        }, [value, delay]);
    
        return debouncedValue;
    };

    // Debounced title value
    const debouncedTitle = useDebounce(title, 500);

    useEffect(() => {
        updateUrlWithFilters("title", debouncedTitle);
    }, [debouncedTitle]);

    useEffect(()=>{
        setTitle(searchParams.get('title') || "");
    }, [searchParams])

    useEffect(() => {
        console.log(mResources)
    }, [mResources]);

    

    const updateUrlWithFilters = (key, value) => {
        console.log(value);
        // Merge new filters with existing ones
        const updatedFilters = {
            ...Object.fromEntries(searchParams.entries()),
            [key]: value
        };
        // Remove empty or null values
        Object.keys(updatedFilters).forEach((key) => {
            if (!updatedFilters[key]) delete updatedFilters[key];
        });
        console.log(updatedFilters)
        // Update URL
        setSearchParams(updatedFilters, { replace: true });
    };


    useEffect(() => {
        const filters = {
            ...(searchParams.get('title') &&  {title: searchParams.get('title')}),
            ...(searchParams.get('category') &&  {category: parseInt(searchParams.get('category'))}),
            ...(searchParams.get('subject') &&  {subject: parseInt(searchParams.get('subject'))}),
            ...(searchParams.getAll('ageRanges') &&  {ageRanges: searchParams.getAll('ageRanges')[0]?.split(",") || []}),
            ...(searchParams.getAll('tags') &&  {tags: searchParams.getAll('tags') || []}),
            priceRange: [
                searchParams.get('startPrice') ? parseInt(searchParams.get('startPrice')) : 0,
                ( searchParams.get('endPrice') && (parseInt(searchParams.get('endPrice')) > parseInt(searchParams.get('startPrice'))) )  ? parseInt(searchParams.get('endPrice')) : 100000000000000000000,
            ],
            pageNumber: parseInt(searchParams.get('pageNumber') || "1" ,),
            pageSize: 100,
        }
        console.log(filters);
        dispatch(getAllMarketPlaceResources({
            data: { storePath, ...filters },
        }));
        toggleDrawer(false)
        
    }, [storePath, dispatch, searchParams]);

    const handleLoadMoreResources = () =>{

        if(mResources?.data?.length === mResources.total){
            return;
        }

        const filters = {
            ...(searchParams.get('title') &&  {title: searchParams.get('title')}),
            ...(searchParams.get('category') &&  {category: parseInt(searchParams.get('category'))}),
            ...(searchParams.get('subject') &&  {subject: parseInt(searchParams.get('subject'))}),
            ...(searchParams.getAll('ageRanges') &&  {ageRanges: searchParams.getAll('ageRanges')[0]?.split(",") || []}),
            ...(searchParams.getAll('tags') &&  {tags: searchParams.getAll('tags') || []}),
            priceRange: [
                searchParams.get('startPrice') ? parseInt(searchParams.get('startPrice')) : 0,
                ( searchParams.get('endPrice') && (parseInt(searchParams.get('endPrice')) > parseInt(searchParams.get('startPrice'))) )  ? parseInt(searchParams.get('endPrice')) : 100000000000000000000,
            ],
            pageNumber: parseInt(searchParams.get('pageNumber') || "1" ,),
            pageSize: mResources?.data?.length + 100,
        }
        console.log(filters);
        dispatch(getAllMarketPlaceResources({
            data: { storePath, ...filters },
        }));
    }

    

    return (
        <Box
            sx={{
                // backgroundImage: `url(${IMAGES.RESOURCES_BG})`,
                // backgroundSize: "cover",
                // minHeight: "100vh",
                // position:'relative',
                // overflow:"hidden"
            }}
        >
            {!auth && <Navbar />}


            <Box sx={{
                width: "95%",
                margin: "0 auto",
            }}>

                {/* Top Picks */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: {
                            xs: "center",
                            md: "space-between"
                        },
                        alignItems: "center",
                        margin: "30px auto",
                        padding: "5px 0px 5px 0px",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Sora",
                            fontSize: "24px",
                            fontWeight: "600",
                            lineHeight: "30.24px",
                            color: "#081131",
                            marginRight: {
                                xs: "0",
                                md: "auto"
                            }
                        }}
                    >{storePath ? 'Explore Store' : 'Explore Resources'}</Typography>

                    <FilterResourceDrawer handleFilterChange={updateUrlWithFilters} isFetchingResources={isFetchingResources} openDrawer={openDrawer} toggleDrawer={toggleDrawer} />

                </Box>


                <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "15px", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <AppInput inputStyles={{ minWidth: "40vw" }} placeholder="Search Resources..." bgColor="white" value={title}
                            onChange={(e) => setTitle(e.target.value)} noBottomPad />
                        <AppButton btnText={isFetchingResources ? <CircularProgress size={15} /> : <Search />} fullWidth={false} customStyles={{ marginLeft: "10px" }} /* onClick={onSubmitForm} */ />
                    </Box>
                    {/* <Button sx={{ padding: "10px", fontSize: "16px" }} endIcon={<FilterAlt />}>Filter</Button> */}
                </Box>


                <Box
                    sx={{
                        backgroundColor: "#F3F3F3",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        rowGap: "20px",
                        overflow: "hidden",
                        alignItems: "stretch",
                        padding: "10px 0px 10px 0px",
                    }}
                >

                    {isFetchingResources ? <ResourcesSkeleton /> :

                        (!isFetchingResources && mResources?.data?.length === 0) ? <p style={{ marginLeft: "auto", marginRight: "auto", marginTop: "30px" }}>No Resource found</p> :
                            <Grid container spacing={2}>
                                {
                                    mResources?.data?.map((resource, index) => (
                                        <CourseCard
                                            key={index}
                                            id={resource.id}
                                            title={resource.title}
                                            rating={resource.rating}
                                            price={resource.price || 1000}
                                            img={resource.coverImage}

                                        />
                                    ))
                                }
                            </Grid>
                    }

                </Box>

                {/* <Stack spacing={2} mb={5}>
                    <Pagination count={Math.ceil(mResources.total / mResources.pageSize)} page={parseInt(searchParams.get('pageNumber')) || 1} variant="outlined" shape="rounded" onChange={(event, value) => updateUrlWithFilters("pageNumber", value)}/>
                </Stack> */}

                <Box display="flex" justifyContent="center">
                    <AppButton
                        customStyles={{
                            fontWeight: "600",
                            padding: "7px 30px",
                            height: "50px",
                            width: "180px",
                            borderRadius: "25px",
                            margin: "50px 0px"
                        }}
                        disabled={isFetchingResources || mResources?.data?.length === mResources.total}
                        btnText={ isFetchingResources ? "Loading..." : "Load More"}
                        onClick={handleLoadMoreResources}
                        fullWidth={false}
                    />
                </Box>
            </Box>
        </Box>
    )
}


