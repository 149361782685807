import React from "react";
import { Typography, Box } from "@mui/material";
import ModalImage from "../../../assets/img/ResetCompleteImage.png";
import AppButton from "../../buttons/AppButton";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../utils/constant";
import { useSelector } from "react-redux";

const SignupVerifyModal = ({ handleModal }) => {
  const navigate = useNavigate();
  const { profileData } = useSelector(state => state.dashboardReducer)

  return (
    <Box sx={styles.modal}>
      <Typography
        id="modal-modal-title"
        variant="h4"
        component="h1"
        sx={styles.modalHeader}
      >
        Verification Successful!
      </Typography>
      <img src={ModalImage} alt="modal-img" />
      <Typography sx={styles.modalText}>
        Your email has been verified. You can now proceed to login.
      </Typography>
      <AppButton
        onClick={() => {
          if (profileData) {
            navigate(-1);
          } else {
            navigate(PAGES.login);
          }
        }
        }

        fullWidth={false}
        customStyles={{ fontWeight: "700" }}
        btnText="Proceed"
      />
    </Box>
  );
};

export default SignupVerifyModal;

const styles = {
  modal: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalHeader: {
    fontWeight: "700",
    textAlign: "center",
    color: "success.main",
  },
  modalText: { textAlign: "center", width: "80%" },
};
