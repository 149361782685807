// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px blue dashed;
  width: 100%;
  margin: auto;
}
.btn {
  border: none;
  text-align: center;
  background-color: rgb(218, 216, 216);
  height: 50px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
}
.btn:hover {
  background-color: blue;
  color: aliceblue;
}
.file-list {
  /* border: 3px dotted black; */
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
  padding: 10px 20px;
  margin: 20px 30px;
  /* border: 3px dotted black; */
}
.file-list img {
  height: 100%;
  width: 100px;
  padding-right: 10px;
  object-fit: cover;
}

.file-list-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/Teacher/TeacherProfile/UploadDocuments/UploadDocument.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,oCAAoC;EACpC,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,8BAA8B;EAC9B,wBAAwB;EACxB,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,8BAA8B;AAChC;AACA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".dropzone {\n  text-align: center;\n  padding: 20px;\n  border: 3px blue dashed;\n  width: 100%;\n  margin: auto;\n}\n.btn {\n  border: none;\n  text-align: center;\n  background-color: rgb(218, 216, 216);\n  height: 50px;\n  border-radius: 12px;\n  color: black;\n  font-weight: bold;\n  transition-duration: 0.6s;\n}\n.btn:hover {\n  background-color: blue;\n  color: aliceblue;\n}\n.file-list {\n  /* border: 3px dotted black; */\n  display: flex !important;\n  flex-wrap: wrap;\n  width: auto;\n  padding: 10px 20px;\n  margin: 20px 30px;\n  /* border: 3px dotted black; */\n}\n.file-list img {\n  height: 100%;\n  width: 100px;\n  padding-right: 10px;\n  object-fit: cover;\n}\n\n.file-list-li {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
