import React from 'react';

import IMAGES from "../../../../assets/img/Resources/Courses";
import styles from './Rating.module.css';



const Rating = ({ rating }) => {
  // Calculate the number of full, half, and empty stars
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = 5 - Math.ceil(rating);

  // Array of star elements
  const stars = [];

  // Add full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(<img key={`full-${i}`} src={IMAGES.FULL_STAR} alt="Full star" className={`${styles.rating_icon}`} />);
  }

  // Add half star
  if (hasHalfStar) {
    stars.push(<img key="half" src={IMAGES.HALF_STAR} alt="Half star" className={`${styles.rating_icon}`}/>);
  }

  // Add empty stars
  for (let i = 0; i < emptyStars; i++) {
    stars.push(<img key={`empty-${i}`} src={IMAGES.EMPTY_STAR} alt="Empty star" className={`${styles.rating_icon}`}/>);
  }

  return (
    <div className={`${styles.rating}`}>
      {stars}
    </div>
  );
};

export default Rating;
