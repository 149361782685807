import React from "react";
import { Box, Typography } from "@mui/material";
import backgroundImage from "../../../assets/img/PreFooter/background.png";
import AppButton from "../../buttons/AppButton";
import AbsoluteImage1 from "../../../assets/img/PreFooter/AbsoluteImage1a.png";
import AbsoluteImage2 from "../../../assets/img/PreFooter/AbsoluteImage2a.png";
import AbsoluteImage3 from "../../../assets/img/PreFooter/AbsoluteImage3a.png";
import { useNavigate } from "react-router-dom";

const PreFooter = () => {
  const navigate = useNavigate();
  return (
    <Box backgroundColor="#2E3192">
      <Box maxWidth="1600px" margin="0 auto" sx={styles.preFooter}>
        {/* absolute images */}/
        <Box
          height={{ xs: "150px", md: "auto" }}
          width={{ xs: "150px", md: "auto" }}
          position="absolute"
          top={{ xs: "-30px", md: "-100px" }}
          left="35%"
        >
          <img
            src={AbsoluteImage1}
            alt="AbsoluteImage1"
            style={{ height: "inherit", width: "inherit" }}
          />
        </Box>
        <Box
          height={{ xs: "135px", md: "auto" }}
          width={{ xs: "135px", md: "auto" }}
          position="absolute"
          left={{ xs: "10px", md: "25%" }}
          top={{ xs: "50%", md: "auto" }}
        >
          <img
            src={AbsoluteImage2}
            alt="AbsoluteImage2"
            style={{ height: "inherit", width: "inherit" }}
          />
        </Box>
        <Box
          height={{ xs: "135px", md: "auto" }}
          width={{ xs: "135px", md: "auto" }}
          position="absolute"
          bottom={{ xs: "-50px", md: "-100px" }}
          left={{ xs: "10%", md: "40%" }}
        >
          <img
            src={AbsoluteImage3}
            alt="AbsoluteImage3"
            style={{ height: "inherit", width: "inherit" }}
          />
        </Box>
        <Box sx={styles.box}></Box>
        <Box sx={styles.box2}></Box>
        <Box sx={styles.preFooterBox}>
          <Typography
            fontSize={{ xs: "30px", md: "96px" }}
            color="#ffffff"
            fontWeight="700"
            lineHeight={{ xs: "1.5", md: "1.3" }}
            sx={styles.preFooterText}
          >
            Your Dream <br /> To Know More?
          </Typography>
          <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdd7F1xlT0ykjZ1ZdPlw8YGujETkjS6-wq_egDMaxQyP9sKmA/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
          <AppButton
            fullWidth={false}
            btnText="Partner with us"
            customStyles={{
              backgroundColor: "white",
              color: "#2E3192",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default PreFooter;

const styles = {
  box: {
    height: { xs: "50px", md: "100px" },
    width: { xs: "50px", md: "100px" },
    background: "#CCA349",
    boxShadow: "0px 8px 68px rgba(0, 0, 0, 0.175098)",
    borderRadius: "24px",
    transform: "rotate(45deg)",
    position: "absolute",
    top: { xs: "-5%", md: "-10%" },
    left: { xs: "5%", md: "20%" },
  },
  box2: {
    height: { xs: "65px", md: "100px" },
    width: { xs: "65px", md: "100px" },
    background: "#CCA349",
    boxShadow: "0px 8px 68px rgba(0, 0, 0, 0.175098)",
    borderRadius: "24px",
    transform: "rotate(45deg)",
    position: "absolute",
    bottom: { xs: "-5%", md: "-10%" },
    right: "15%",
  },
  preFooter: {
    position: "relative",
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: { xs: "50px", md: "100px" },
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: { xs: "left", md: "right" },
    height: { xs: "550px", md: "500px" },
  },
  preFooterBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
    position: "relative",
    top: { xs: "-60px", md: "auto" },
    left: { xs: "25px", md: "auto" },
  },
  preFooterText: {
    position: "relative",
    zIndex: "1",
    paddingLeft:{xs:"30px"},

    "&::after": {
      content: '""',
      display: "block",
      height: { xs: "10px", md: "30px" },
      width: "80%",
      backgroundColor: "#CCA349",
      position: "absolute",
      top: "83%",
      zIndex: "-1",
    },
  },
};
