import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/slice";
import dashboardReducer from "./dashboard/slice";
import coursesReducer from "./courses/slice";
import cartReducer from "./cart/slice";
import questionReducer from "./question/slice";
import schoolReducer from "./school/slice";
import userReducer from "./user/slice";
import jobReducer from "./jobs/slice";
import documentReducer from "./document/slice";
import resourceReducer from "./resource/slice";

export default configureStore({
  reducer: {
    authReducer,
    dashboardReducer,
    coursesReducer,
    cartReducer,
    questionReducer,
    schoolReducer,
    userReducer,
    jobReducer,
    documentReducer,
    resourceReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
