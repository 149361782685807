import { Box, Tabs, Tab, Typography, CircularProgress, Button, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomTabPanel from '../../CustomTabPanel'
import JobCards from './JobCard';
import AppButton from '../../buttons/AppButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { buyMoreJobTokens, getUserJobTokens, listMyCreatedJobs } from '../../../redux/jobs/action';
import { APP_COLOURS } from '../../../utils/constant';
import { Help } from '@mui/icons-material';
import AppSelect from '../../inputs/AppSelect';
import { formatAsCurrency } from '../../../utils/numberFunctions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function SchoolJobView() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [tokenValue, setTokenValue] = useState(3);
    const [tokenPrice, setTokenPrice] = useState(4000);
    const { myCreatedJobs, isFetchingJobs, buyingToken, jobTokens } = useSelector(state => state.jobReducer);
    const { userData } = useSelector(state => state.authReducer);

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    useEffect(() => {
        dispatch(listMyCreatedJobs());
        dispatch(getUserJobTokens())
    }, [])

    const handleTokenChange = (e) => {
        console.log('e', e)
        setTokenValue(e.target.value)
        let priceD = tokensData.find(x => x.id == e.target.value);
        setTokenPrice(priceD.price);
    }
    const tokensData = [
        { id: 1, name: "1 Job Token", price: 1500 },
        { id: 3, name: "3 Job Tokens", price: 4000 },
        { id: 5, name: "5 Job Tokens", price: 6500 },
        { id: 10, name: "10 Job Tokens", price: 12000 },
    ]

    const onBuyToken = () =>{
        dispatch(buyMoreJobTokens({
            token: tokenValue,
            amount: tokenPrice,
        }))
    }

    return (

        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Buy More Job Tokens
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ marginTop: "5px", fontSize: 12 }}>
                        Job tokens are used to post jobs. This will also give you access to the recruitment dashboard to manage the recruitment process.
                    </Typography>
                    <AppSelect label="Select tokens to buy" selectArray={tokensData} value={tokenValue}
                        handleInputChange={(e) => handleTokenChange(e)} loadingState={`Price: ₦${formatAsCurrency(tokenPrice)}`} />
                        <AppButton btnText='Buy Tokens' onClick={onBuyToken} isLoading={buyingToken} loadingComponent="Processing" />
                    <AppButton btnText='Cancel' onClick={() => setOpen(false)} customStyles={{marginTop:"10px"}} variant="outline" />
                </Box>
            </Modal>
            <Box sx={{ display: { md: "flex", xs: "block" }, flexDirection: "row", alignItems: "center" }}>

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                    {/* <Help sx={{ marginRight: 1 }} color='primary' /> */}
                    <Typography fontWeight={600} mr={2}>Job Post Tokens Left: <span style={{ color: APP_COLOURS.primary }}>{jobTokens ?jobTokens : '...'}</span>  </Typography>

                    <AppButton btnText='Get More' onClick={() => setOpen(!open)} variant="outlined" fullWidth={false} size="small" />

                </Box>

                <AppButton btnText='List New Job' onClick={() => navigate("/jobs/create")} fullWidth={false} size="small" customStyles={{ marginLeft: "auto", marginTop: { xs: 1, md: 0 } }} />
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Open" {...a11yProps(0)} />
                    <Tab label="Closed" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <>
                    {isFetchingJobs && <CircularProgress />}
                    {/* todo */}
                    {myCreatedJobs.filter(x => x.isOpen).length == 0 && <Box textAlign="center" mt={4}>
                        <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }} > You have no open job</Typography>
                        <AppButton btnText='Create a Job' customStyles={{ mt: "20px" }} onClick={() => navigate("/jobs/create")} fullWidth={false} />
                    </Box>}
                    {myCreatedJobs.filter(x => x.isOpen).map(job => <JobCards key={job.id} job={job} isSchool />)}
                </>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <>
                    {isFetchingJobs && <CircularProgress />}
                    {myCreatedJobs.filter(x => !x.isOpen).length == 0 && <Box textAlign="center" mt={4}>
                        <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }} > You have no closed job</Typography>
                        <AppButton btnText='Create a Job' customStyles={{ mt: "20px" }} onClick={() => navigate("/jobs/create")} fullWidth={false} />
                    </Box>}
                    {myCreatedJobs.filter(x => !x.isOpen).map(job => <JobCards job={job} isSchool />)}
                </>
            </CustomTabPanel>



        </>
    )
}
