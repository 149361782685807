import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../utils/auth/authFunctions";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import { useDispatch, useSelector } from 'react-redux';
import { getUserNotifications } from '../redux/user/action';
import { getUserProfile } from '../redux/dashboard/actions';
import { getProfileData } from '../utils/dashboard/dashboardFunctions';
import { APP_COLOURS, PAGES } from '../utils/constant';
import { showSimpleToast } from '../utils/alertFunctions';
import { Box, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

const PrivateRoute = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search)
  const userType = params.get("usertype");
  const { profileData } = useSelector(state => state.dashboardReducer)
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    dispatch(getUserNotifications());
  }, [dispatch, location.pathname])

  useEffect(() => {
    if(!profileData?.emailVerified){
      setShowAlert(true);
    }else{
      setShowAlert(false);
    }
  }, [navigate, profileData?.emailVerified])

  useEffect(() => {
    if (profileData?.shouldChangePassword) {
      showSimpleToast("Please change your password", "failed");
      navigate(PAGES.changePassword);
    }
  }, [navigate, profileData])

  if (!auth) {
    let redirectUrl = userType?.length > 0 ? `/login?redirect=${location.pathname}&usertype=${userType}` : `/login?redirect=${location.pathname}`
    return <Navigate to={redirectUrl} replace />;
  }


  return (<DashboardLayout>
    {showAlert && <Box component="section" sx={styles.alert}>
      Please verify your email. <span onClick={() => navigate(PAGES.signupVerification)} style={{ textDecoration: "underline", cursor: "pointer" }}>Click here</span>
      <IconButton sx={{ position: "absolute", right: "10px", top: "10px", color: APP_COLOURS.white }} onClick={() => setShowAlert(false)} >
        <Close />
      </IconButton>
    </Box>}
    {children}
  </DashboardLayout>);
};

export default PrivateRoute;

const styles = {
  alert: {
    p: 2,
    background: APP_COLOURS.primary,
    width: { xs: "80%", md: "60%" },
    mx: "auto",
    position: "relative",
    textAlign: "center",
    borderRadius: "15px",
    color: APP_COLOURS.white
  }
}
