import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { Book ,Work} from "@mui/icons-material";

const NavProductsDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    navigate()
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const gotoFindCourse = () =>{
    setAnchorEl(null);
    navigate("/find-courses")
  }

  const gotoFindJob = () =>{
    setAnchorEl(null);
    navigate("/find-jobs")
  }

  return (
    <>
      <Button
        component="li"
        sx={styles.linkText}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Features
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem style={{margin:5}} onClick={gotoFindCourse} > <Book color="#2E3192" style={{marginRight:5,  color:"#2E3192"}} /> Find Courses</MenuItem>
        {/* {/* <MenuItem onClick={handleClose}>Find Teachers</MenuItem> */}
        <MenuItem style={{margin:5}} onClick={gotoFindJob}><Work style={{marginRight:5, color:"#2E3192"}}/> Find Jobs</MenuItem>
      </Menu>
    </>
  );
};

export default NavProductsDropdown;

const styles = {
  linkText: {
    fontWeight: "600",
    fontSize: "16px",
    color: "black",
  },
};
