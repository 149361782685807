import React, { useState, useEffect } from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";

import AppButton from "../../../buttons/AppButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import UserAvatar from "../../../../assets/img/DashboardAvatar.png";

import moment from "moment";
import { useSelector } from "react-redux";
import { CalendarToday, CardMembership, Email, Grade, Phone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Profile = ({ handleUploadDocumentsIndex, handleEditProfileIndex }) => {
  const {profileData} = useSelector(state => state.dashboardReducer)
  const navigate = useNavigate();

  const ProfileData = [
    // {
    //   id: 1,
    //   icon: <DomainIcon />,
    //   text: "Works at Cambridge College",
    // },
    {
      id: 2,
      icon: <Email />,
      text: profileData?.email,
    },
    {
      id: 5,
      icon: <Phone />,
      text: profileData?.phone,
    },
    {
      id: 6,
      icon: <Grade />,
      text: profileData?.specialisation,
    },
    {
      id: 3,
      icon: <CalendarToday />,
      text: `Joined on: ${moment(profileData?.createdAt).format("MMMM DD, yyyy")}`,
    },
    {
      id: 4,
      icon: <CardMembership />,
      text: profileData?.qualification,
    },
  ];

  return (
    <Grid
      container
      gap="25px"
      sx={styles.container}
    >
      <Grid
         item
         gap="20px"
         sx={{display:"flex", flexDirection:"column", alignItems:"center" ,width: {xs:"100%", md:"30%", lg:"25%"}, backgroundColor:"#fff", borderRadius:"10px"}}
         p="20px"
      >
            <Box
               component="img"
               src={profileData?.imgUrl? profileData?.imgUrl :  UserAvatar}
               alt="ProfileImage"
               height="100%"
               width="100%"
               sx={{ objectFit: "cover", width:"150px", height:"150px", borderRadius:"50%" }}
             />

          <AppButton
            customStyles={{
              fontWeight: "bold",
              fontSize: "18px",
              borderRadius: "8px",
            }}
            onClick={() => {
              handleEditProfileIndex();
            }}
            btnText="Edit Profile"
          />
          <AppButton
            customStyles={{
              fontWeight: "bold",
              fontSize: "18px",
              borderRadius: "8px",
            }}
            onClick={() => navigate("/documents")}
            btnText="My Documents"
          />

        {/* <Box backgroundColor="#fff" p="25px" width="100%" borderRadius="10px">
          <Typography fontSize="16px">Complete your profile</Typography>
          <Box
            mt="10px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="15px"
          >
            <Box
              backgroundColor="#EEEEEE"
              borderRadius="30px"
              width="80%"
              height="5px"
            >
              <Box
                backgroundColor="#0FBD3B"
                borderRadius="30px"
                width="80%"
                height="100%"
              ></Box>
            </Box>
            <Typography fontSize="16px" sx={{ color: "#100A37" }}>
              80%
            </Typography>
          </Box>
        </Box> */}
      </Grid>
      <Grid
           item
           sx={{width: {xs:"100%", md:"60%", lg:"70%"}}}
           display="flex"
           flexDirection="column"
           alignItems="center"
           gap="10px"
      >
        <Box backgroundColor="#fff" p="25px" width="100%" borderRadius="10px">
          <Typography fontSize="16px" fontWeight="500" gutterBottom>
            {profileData?.firstName} {profileData?.lastName}
          </Typography>
          <Typography fontSize={14}>{profileData?.category}</Typography>
        </Box>

        <Box
          backgroundColor="#fff"
          flexGrow="1"
          p="30px 25px"
          width="100%"
          borderRadius="10px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="15px"
          >
            <Typography fontSize="16px" fontWeight="500" color="#2E3192">
              About Me
            </Typography>
            {/* <MoreHorizIcon /> */}
          </Box>

          <Typography style={{fontStyle: "italic"}}>
            {profileData?.bio !== null ? profileData?.bio : "No bio"}
          </Typography>

          <Stack spacing={3} mt="20px">
            {ProfileData.map((data) => {
              return (
                <Box
                  key={data.id}
                  display="flex"
                  alignItems="center"
                  gap="10px"
                >
                  {data.icon}
                  <Typography>{data.text}</Typography>
                </Box>
              );
            })}
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Profile;

const styles = {
  container: {
    width: "95%",
    margin: "0 auto",
    background: "#D5D6E933",
    borderRadius: "10px",
    marginTop: "10px",
    padding: "30px",
  },
};
