import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import CoursesGrid from "../../SharedComponents/CoursesGrid";
import Messages from "./Messages";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {  getMyCourses } from "../../../../redux/courses/actions";

const BottomProfile = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { myCourses, myCoursesLoading } = useSelector(
    (state) => state.coursesReducer
  );
  const {userData} = useSelector(state => state.authReducer)

  // function to get course
  const onOpenCourse = (value) => {
    navigate("/courses/manage-course/" + value);
  };

  const viewAllFunc = () => {
    navigate("/courses/my-courses");
  };

  useEffect(() => {
    dispatch(getMyCourses());
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      gap="25px" style={styles.container} sx={{ width: { xs: "100%", md: "66%", lg: "68%" } }}
    >
      <CoursesGrid
        coursesArray={myCourses}
        gridTitle="My Courses"
        width={{width: {xs: "100%", md: "48%", lg: "31%"}}}
        viewAllFunc={viewAllFunc}
        onOpenCourse={onOpenCourse}
        showPreloader={myCoursesLoading}
        personal 
        isSchool
      />

      {/* <Grid
        item
        xs={6}
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        gap="10px"
      >
        <Messages />
      </Grid> */}
    </Grid>
  );
};

export default BottomProfile;

const styles = {
  container: {
    width: "95%",
    margin: "0 auto",
    background: "#D5D6E933",
    borderRadius: "10px",
    marginTop: "20px",
    padding: "30px",
  },
  linkText: {
    fontWeight: "700",
    fontSize: "18px",
    color: "#2E3192",
  },
};
