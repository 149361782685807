import React, { useState, useEffect } from "react";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import AppInput from "../../inputs/AppInput";
import AppButton from "../../buttons/AppButton";
import { APP_COLOURS, PAGES } from "../../../utils/constant";
import GoogleIcon from "../../../assets/svg/GoogleIcon";
import LinkedinIcon from "../../../assets/svg/LinkedinIcon";

import { clearRegisteredUserEmail } from "../../../redux/auth/slice";

import { useDispatch, useSelector } from "react-redux";
import { checkEmail } from "../../../redux/auth/actions";

// modal
import Modal from "../../modal/Modal";
import AccountTypeModal from "./AccountTypeModal";

import SignupForm2 from "./SignupForm2";
import { useNavigate } from "react-router-dom";

const SignupForm = ({forceChangePassword}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isEmailExisting } = useSelector((state) => state.authReducer);

  const [formIndex, setFormIndex] = useState(1);
  const [errorMessage, setErrorMessage] = useState({});

  const [isEmailExistMessage, setIsEmailExistMessage] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [regNoError, setRegNoError] = useState(false);
  const [addressError, setAddressError] = useState(false);

  const [teacherData, setTeacherData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    specialisationId: 0,
    qualificationId: 0,
    categoryId: 0,
  });

  const [schoolData, setSchoolData] = useState({
    email: "",
    phone: "",
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    password: "",
    address: "",
    name: "",
    // regNo: "",
    categories: [],
    schoolType: 0,
  });

  // validation function
  const validateData = (value) => {
    const errors = {};

    if (!value.email) {
      setEmailError(true);
      errors.email = "Email is required";
    } else if (!value.email.includes("@")) {
      setEmailError(true);
      errors.email = "Email must include @";
    } else {
      setEmailError(false);
    }

    if (!value.firstName) {
      setFirstNameError(true);
      errors.firstName = "First name is required";
    } else if (value.firstName.length <= 2) {
      setFirstNameError(true);
      errors.firstName = "First name must be at least 3 letters";
    } else {
      setFirstNameError(false);
    }

    if (!value.lastName) {
      setLastNameError(true);
      errors.lastName = "Last name is required";
    } else if (value.lastName.length <= 2) {
      setLastNameError(true);
      errors.lastName = "Last name must be at least 3 letters";
    } else {
      setLastNameError(false);
    }

    if (!value.name) {
      setNameError(true);
      errors.name = "School Name is required";
    } else if (value.name.length <= 2) {
      setNameError(true);
      errors.name = "School Name must be at least 3 letters";
    } else {
      setNameError(false);
    }

    // if (!value.regNo) {
    //   setRegNoError(true);
    //   errors.regNo = "RC number is required";
    // } else {
    //   setRegNoError(false);
    // }

    if (!value.address) {
      setAddressError(true);
      errors.address = "Location is required";
    } else {
      setAddressError(false);
    }

    if (!value.phone) {
      setPhoneError(true);
      errors.phone = "Phone number is required";
    } else {
      setPhoneError(false);
    }

    return errors;
  };

  const [showModal, setShowModal] = useState(true);
  const [accountType, setAccountType] = useState("");

  const handleModal = () => {
    setShowModal(!showModal);
  };

  // on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (accountType === "teacher") {
      setTeacherData({
        ...teacherData,
        [name]: value,
      });
    } else if (accountType === "school") {
      setSchoolData({
        ...schoolData,
        [name]: value,
      });
    }
  };

  const handleAccountType = (type) => {
    setAccountType(type);
  };

  // to validate data and move to next form
  const nextFormIndex = (e) => {
    e.preventDefault();

    if (accountType === "teacher") {
      // to validate teacher data and move to next form
      setErrorMessage(validateData(teacherData));

      const { email, firstName, lastName, phone } = teacherData;

      if (email !== "" && firstName !== "" && lastName !== "" && phone !== "") {
        if (
          email.includes("@") &&
          firstName.length > 2 &&
          lastName.length > 2
        ) {
          dispatch(checkEmail({ email: email }));
        }
      }
    } else {
      // to validate school data and move to next form
      setErrorMessage(validateData(schoolData));

      const { email, name, regNo, phone, location } = schoolData;

      if (
        email !== "" &&
        name !== "" &&
        // regNo !== "" &&
        phone !== "" &&
        location !== ""
      ) {
        if (email.includes("@") && name.length > 2) {
          dispatch(checkEmail({ email: email }));
        }
      }
    }
  };

  const prevFormIndex = () => {
    setFormIndex(1);
  };

  useEffect(() => {
    dispatch(clearRegisteredUserEmail());
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    if (isEmailExisting !== null) {
      if (!isEmailExisting) {
        setFormIndex(2);
        setEmailError(false);
      } else {
        setEmailError(true);
        setIsEmailExistMessage(
          "This email is registered already, try another email!"
        );
      }
    }
  });

  return (
    <>
      {showModal && (
        <Modal showModal={showModal} handleModal={()=>{
          handleModal();
          navigate(-1);
          }}>
          <AccountTypeModal
            handleAccountType={handleAccountType}
            handleModal={handleModal}
          />
        </Modal>
      )}

      {formIndex === 1 && (
        <Container
          sx={styles.container}
          component="form"
          onSubmit={nextFormIndex}
        >
          <Typography sx={styles.headerText}>Create Your Account</Typography>
          <Typography sx={styles.headerSubText}>
            Fill in the required information to get started!
          </Typography>

          {accountType === "teacher" && (
            <AppInput
              onChange={handleInputChange}
              value={teacherData.firstName}
              name="firstName"
              label="First Name"
              placeholder="Enter your first name"
              error={firstNameError}
              helperText={errorMessage.firstName}
            />
          )}

          {accountType === "teacher" && (
            <AppInput
              onChange={handleInputChange}
              value={teacherData.lastName}
              name="lastName"
              label="Last Name"
              placeholder="Enter your last name"
              error={lastNameError}
              helperText={errorMessage.lastName}
            />
          )}

          {accountType === "school" && (
            <AppInput
              onChange={handleInputChange}
              value={schoolData.name}
              name="name"
              label="Name of school"
              placeholder="Enter the name of your school"
              error={nameError}
              helperText={errorMessage.name}
            />
          )}

          <AppInput
            onChange={handleInputChange}
            value={
              accountType === "teacher" ? teacherData.email : schoolData.email
            }
            name="email"
            label="Email Address"
            placeholder="Enter your email address"
            error={emailError}
            helperText={errorMessage.email || isEmailExistMessage}
          />

          <AppInput
            onChange={handleInputChange}
            value={
              accountType === "teacher" ? teacherData.phone : schoolData.phone
            }
            name="phone"
            label="Phone number"
            placeholder="Enter your phone number"
            error={phoneError}
            helperText={errorMessage.phone}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />

          {/* {accountType === "school" && (
            <AppInput
              onChange={handleInputChange}
              value={schoolData.regNo}
              name="regNo"
              label="RC Number"
              placeholder="Enter your RC Number"
              error={regNoError}
              helperText={errorMessage.regNo}
            />
          )} */}

          {accountType === "school" && (
            <AppInput
              onChange={handleInputChange}
              value={schoolData.address}
              name="address"
              label="Location"
              placeholder="Enter your Location"
              error={addressError}
              helperText={errorMessage.address}
            />
          )}

          <AppButton
            btnType="submit"
            customStyles={{ fontWeight: "bold", margin: "15px 0 30px" }}
            btnText="Next"
          />

<Box sx={styles.createAcc}>
        <Typography variant="span">Already have an account?</Typography>{" "}
        <Typography
          variant="span"
          onClick={() => navigate(PAGES.login)}
          sx={styles.createAccLink}
        >
          Login
        </Typography>
      </Box>

          {/* <Divider>OR</Divider>

          <Box sx={styles.continueDiv}>
            <Box sx={styles.continueBox}>
              <Button
                sx={styles.continueBtn}
                variant="text"
                startIcon={<GoogleIcon />}
              >
                Log in with Google
              </Button>
            </Box>

            <Box sx={styles.continueBox}>
              <Button
                sx={styles.continueBtn}
                variant="text"
                startIcon={<LinkedinIcon />}
              >
                Log in with LinkedIn
              </Button>
            </Box>
          </Box> */}
        </Container>
      )}

      {formIndex === 2 && (
        <SignupForm2
          teacherData={teacherData}
          schoolData={schoolData}
          accountType={accountType}
          setTeacherData={setTeacherData}
          setSchoolData={setSchoolData}
          prevFormIndex={prevFormIndex}
          forceChangePassword={forceChangePassword}
        />
      )}
    </>
  );
};

export default SignupForm;

const styles = {
  container: {},
  headerText: {
    fontSize: 30,
    fontWeight: 700,
    margin: "20px 0",
  },
  headerSubText: {
    width: "75%",
    color: "#4B4E61",
    marginBottom: "20px",
  },
  continueDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "25px",
    gap: "10px",
    fontWeight: "700",
    flexDirection: { xs: "column", md: "row" },
  },
  continueBox: {
    textAlign: "center",
    width: { xs: "100%", md: "auto" },
    border: "2px solid #6877D5",
    borderRadius: "8px",
    padding: "5px",
  },
  continueBtn: { textTransform: "none", fontSize: 16, color: APP_COLOURS.dark },
  createAcc: {
    width: "100%",
    textAlign: "center",
    margin: "30px 0",
  },
  createAccLink: {
    fontWeight: "600",
    color: "#0F27BD",
    cursor: "pointer",
  },
};
