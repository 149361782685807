import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import CourseDisplay from "./CourseDisplay";
import CourseModules from "./CourseModules";
import { useDispatch, useSelector } from "react-redux";
import { getCourseContent, updateCourseProgress } from "../../../../redux/courses/actions";

import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

const WatchCourse = ({courseId}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseContentLoading, courseContent, otherCourseContentDetails } = useSelector((state) => state.coursesReducer);
  const [viewState, setViewState] = useState({ module: 1, page: 1, currentItem: null });
  const [expanded, setExpanded] = React.useState(false);

  const handleModuleDropDown = (panel, forceOpen) => (event, isExpanded) => {
    setExpanded(forceOpen || isExpanded ? panel : false);
  };

  const setCourseViewItem = (module, page, isNext) => {
    let moduleToShow;
    let content;
    let cModule = module;
    let cPage = page;
    if (courseContent.length) {
      moduleToShow = courseContent.find(x => x.moduleSequence == cModule);
      if (moduleToShow) {
        content = moduleToShow.moduleContent.find(x => x.sequence == cPage);
      }

      if (!content) {
        if (isNext) {
          cModule = module + 1;
          moduleToShow = courseContent.find(x => x.moduleSequence == module + 1);
          if (!moduleToShow){
            navigate(`/questions/${courseId}/module/${module}`);
             return;
          }
          cPage = 1;
          content = moduleToShow.moduleContent.find(x => x.sequence == cPage);

        } else if (isNext == false) {
          cModule = module - 1;
          moduleToShow = courseContent.find(x => x.moduleSequence == module - 1);
          if (!moduleToShow) return;
          content = moduleToShow.moduleContent[moduleToShow.moduleContent.length - 1];
          cPage = content?.sequence;
        }
      }

    }
    if (content) {
      setViewState({
        module: cModule,
        page: cPage,
        currentItem: content,
      })
      handleModuleDropDown(`${moduleToShow.moduleSequence}${moduleToShow.moduleTitle}`, true)();
    }
  }

  const goToNextView = () => {
    setCourseViewItem(viewState.module, viewState.page + 1, true)
    dispatch(updateCourseProgress({data: {
      courseId,
      module: viewState.module,
      page: viewState.page
    }}))
  }

  const goToPreviousView = () => {
    setCourseViewItem(viewState.module, viewState.page - 1, false)
  }

  useEffect(() => {
    dispatch(getCourseContent(courseId));
  }, []);

  useEffect(() => {
    let module = otherCourseContentDetails?.progress?.module == 0 ?viewState.module: otherCourseContentDetails?.progress?.module
    let page = otherCourseContentDetails?.progress?.page == 0 ?viewState.page: otherCourseContentDetails?.progress?.page
    setCourseViewItem(module, page)
  }, [courseContent])

  return (
    <>
      {courseContentLoading && <CircularProgress />}
      {!courseContentLoading && (
        <Grid container component="section" gap="10px" >
          <CourseDisplay currentItem={viewState.currentItem} onGoToNext={goToNextView} onGoToPrevious={goToPreviousView} />
          <CourseModules viewState={viewState} courseId={courseId} expanded={expanded} handleModuleDropDown={handleModuleDropDown} />
        </Grid>
      )}
    </>
  );
};

export default WatchCourse;
