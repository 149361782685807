import React from 'react'
import Navbar from '../../../components/landing-page/Navbar/Navbar';
import ResourceDetail from '../../../components/landing-page/Resources/ResourceDetail';

import { Box, Button, Typography } from '@mui/material';
import { useAuth } from '../../../utils/auth/authFunctions';


export default function ResourceDetails() {

  const auth = useAuth()
  return (

    <Box>
        {!auth && <Box padding="0px 35px 0px 15px"><Navbar /></Box>}

         <ResourceDetail />

    </Box>
    


  )
}
