import { Autocomplete, Box, Chip, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import AppButton from "../../components/buttons/AppButton";
import AppInput from "../../components/inputs/AppInput";
import { useNavigate, useParams } from "react-router-dom";
import { createJob, getJobDetails, updateJob } from "../../redux/jobs/action";
import { useDispatch, useSelector } from "react-redux";
import { APP_COLOURS, JOB_ROLES } from "../../utils/constant";
import AppSelect from "../../components/inputs/AppSelect";
import {
    BtnBold,
    BtnItalic,
    BtnUnderline,
    BtnUndo,
    BtnRedo,
    BtnBulletList, BtnLink, BtnNumberedList, BtnStrikeThrough,
    Editor,
    EditorProvider,
    Toolbar,
    BtnStyles
} from 'react-simple-wysiwyg';
import { formatAsCurrency } from "../../utils/numberFunctions";
import { decodeHTML } from "../../utils/stringFunctions";

const AddJob = ({ isEdit }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isSubmitting, jobDetails, isFetching } = useSelector(state => state.jobReducer);
    const [showEmployer, setShowEmployer] = useState(true);
    const [salaryType, setSalaryType] = useState(0)
    const [skills, setSkills] = useState([]);
    const { jobId } = useParams();
    const [form, setForm] = useState({
        title: "",
        description: "",
        salary: "",
        location: "",
        salaryfrom: null,
        salaryto: null
    })
    const [html, setHtml] = useState('');
    const [salaryCurrency, setSalaryCurrency] = useState(1)
    const [salaryText, setSalaryText] = useState(null)

    const onChange = (e) => {
        setHtml(e.target.value);
    }

    useEffect(() => {
        if (jobId && isEdit) {
            dispatch(getJobDetails({ jobId }))
        }
    }, [jobId])

    useEffect(() => {
        if (jobDetails?.id && isEdit) {
            let formCopy = { ...form };
            formCopy.title = jobDetails.title;
            formCopy.salaryfrom = jobDetails.salary;
            formCopy.salary = jobDetails.salary;
            formCopy.salaryto = jobDetails.salaryTo;
            formCopy.location = jobDetails.location
            setHtml(decodeHTML(jobDetails.description))
            setForm(formCopy);
            setSalaryType(jobDetails.salaryType);
            setSalaryCurrency(jobDetails.salaryCurrency == "₦" ? 1 : 2)
        }
    }, [jobDetails])

    const [formError, setFormError] = useState({
        title: null,
        description: null,
        salary: null,
        location: null,
        salaryfrom: null,
        salaryto: null
    })

    const onSubmitForm = () => {
        let formErrorCopy = { ...formError };
        if (form.title.length < 3) {
            formErrorCopy.title = "Please select a valid role"
        } else {
            formErrorCopy.title = null
        }
        if (form.location.length < 5) {
            formErrorCopy.location = "Please enter a valid location"
        } else {
            formErrorCopy.location = null
        }
        if (salaryType == 0) {
            formErrorCopy.salary = "Please specify a salary type"
        }
        let salaryRangeFailed = false;
        if (salaryType == 1) {
            salaryRangeFailed = form.salaryfrom < 5 || form.salaryto < 5 || form.salaryfrom > form.salaryto;
            if (salaryRangeFailed) {
                formErrorCopy.salaryfrom = "Please fill in salary correctly"
            } else {
                formErrorCopy.salaryfrom = null;
            }
        }
        if (formErrorCopy.location || formErrorCopy.title || salaryType == 0) {
            setFormError(formErrorCopy);
            return;
        }
        setFormError(formErrorCopy);
        let payload = {
            ...form,
            salaryType,
            // showEmployer,
            description: html,
            skills,
            salaryCurrency: salaryCurrency == 1 ? "₦" : "$"
        }
        if (salaryType != 1) {
            payload.salaryto = null
        } else if (salaryType > 2) {
            payload.salaryfrom = null
            payload.salaryto = null
        }
        // console.log('form-----', payload)
        if (isEdit) {
            dispatch(updateJob({ data: payload, id:jobDetails.id, callBack: () => navigate(`/jobs/${jobDetails.id}`) }))
        } else {
            dispatch(createJob({ data: payload, callBack: () => navigate("/jobs") }))
        }

    }

    const handleInputChange = (event, isAutoComplete) => {
        let formErrorCopy = { ...formError };
        let formCopy = isAutoComplete ? { ...form, title: event.target.innerText } : { ...form, [event.target.name]: event.target.value };
        let text;
        let currency = salaryCurrency == 1 ? "₦" : "$";
        if (event.target.name == "salaryfrom") {
            text = `${currency}${formatAsCurrency(event.target.value)}`
            if (form.salaryto) {
                text += ` - ${currency}${formatAsCurrency(form.salaryto)}`
            }
        }
        if (event.target.name == "salaryto") {
            text = `${currency}${formatAsCurrency(form.salaryfrom)} - ${currency}${formatAsCurrency(event.target.value)}`
        }
        if (text) setSalaryText(text);
        setForm(formCopy);

        if (formCopy.title.length < 3) {
            formErrorCopy.title = "Please enter a valid role"
        } else {
            formErrorCopy.title = null
        }
        if (form.location.length < 5) {
            formErrorCopy.location = "Please enter a valid location"
        } else {
            formErrorCopy.location = null
        }
        setFormError(formErrorCopy);
    }

    const handleSalaryChange = (event) => {
        setSalaryType(event.target.value)
    }
    const handleSalaryCurrentChange = (event) => {
        console.log('event.target.value', event.target.value)
        setSalaryCurrency(event.target.value)
    }


    return (
        <>
            <Box component="section" sx={{ padding: { xs: "0", lg: "30px" }, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Box backgroundColor="#d5d6e933" p="30px" borderRadius="15px" sx={{ width: "100%", maxWidth: { xs: "95%", lg: "800px" } }}>
                    {isEdit && isFetching && <CircularProgress />}
                    {(!isEdit || (!isFetching && jobDetails?.id)) && <>
                        <Typography fontSize={16} textAlign="center" color="#2E3192">{isEdit ? "Edit you published job" : "Publish a job to a pool of professionals"}</Typography>
                        <Box mt={2}>
                            <Autocomplete
                                disablePortal
                                options={JOB_ROLES}
                                autoSelect
                                disabled={isEdit}
                                defaultValue={isEdit ? jobDetails.title : undefined}
                                onChange={(e) => handleInputChange(e, true)}
                                renderInput={(params) => <AppInput {...params}
                                    label="Job Role"
                                    placeholder="Select Job Role"
                                    value={form.title}
                                    type="text"
                                    name="title"
                                    disabled={isEdit}
                                    error={formError.title != null}
                                    helperText={formError.title} />}
                            />

                            <Typography sx={{ color: APP_COLOURS.lightDark, marginBottom: 1, marginTop: 1 }}>Job Description</Typography>
                            <EditorProvider>
                                <Editor value={html} onChange={onChange} disabled={isEdit}>
                                    <Toolbar>
                                        <BtnUndo />
                                        <BtnRedo />
                                        <BtnBold />
                                        <BtnItalic />
                                        <BtnUnderline />
                                        <BtnBulletList />
                                        <BtnNumberedList />
                                        <BtnStyles />
                                    </Toolbar>
                                </Editor>
                            </EditorProvider>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                sx={{ marginBottom: "-10px", mt: 3 }}
                                options={teacherSkills.map((option) => option)}
                                defaultValue={isEdit ? jobDetails?.skills : []}
                                freeSolo
                                renderTags={(value, getTagProps) => {
                                    setSkills(value)
                                    return value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                }
                                renderInput={(params) => (
                                    <AppInput
                                        {...params}
                                        variant="filled"
                                        label="Job Requirements/Skills"
                                        placeholder="Job Skill (Hit Enter ↵ after each skill to add)"
                                    />

                                )}
                            />
                            {formError.description && <Typography component="em" color="error" mt="15px" fontSize="14px">
                                {formError.description}
                            </Typography>}
                            <AppSelect label="Salary Type" value={salaryType}
                                name="salary"
                                handleInputChange={(e) => handleSalaryChange(e)}
                                selectArray={[
                                    { id: 0, name: "" },
                                    { id: 1, name: "Salary Range" },
                                    { id: 2, name: "Fixed Salary" },
                                    { id: 3, name: "Competitive Salary" },
                                    { id: 4, name: "Non-Disclosed" },
                                ]} errorState={!!formError.salary} />

                            <AppSelect label="Salary Currency" value={salaryCurrency}
                                name="salaryCurrency"
                                handleInputChange={(e) => handleSalaryCurrentChange(e)}
                                selectArray={[
                                    { id: 1, name: "NGN (₦)" },
                                    { id: 2, name: "USD ($)" },
                                ]} loadingState={salaryText} />
                            <Box display="flex" gap={10}>
                                {(salaryType == 1 || salaryType == 2) && <AppInput type="number" value={form.salaryfrom} name="salaryfrom" onChange={handleInputChange} label={salaryType == 1 ? "From" : "Amount"} />}
                                {salaryType == 1 && <AppInput type="number" label="To" value={form.salaryto} name="salaryto" onChange={handleInputChange} />}
                            </Box>
                            <AppInput
                                onChange={handleInputChange}
                                value={form.location}
                                type="text"
                                name="location"
                                label="Job Location"
                                placeholder="LGA, State. Country"
                                error={formError.location != null}
                                helperText={formError.location}
                            />
                            {/* <Box display="flex" gap={10}>
                            <AppInput label="City/LGA" />
                            <AppInput label="State" />
                        </Box>
                        <AppSelect label="Country" value={0}
                            name="nigeria"
                            handleInputChange={(e) => handleSalaryChange(e)}
                            selectArray={[
                                { id: 0, name: "Nigeria" }
                            ]} /> */}

                            {/* <FormControlLabel control={<Checkbox checked={showEmployer} onChange={() => setShowEmployer(!showEmployer)} />} label="Show you as employer on job page" /> */}


                        </Box>
                        <Box sx={{ display: "flex", mb: { xs: "50px", lg: "20px", }, mt: 4, flexDirection: { xs: "column", lg: "row", justifyContent: "center" } }}>
                            <AppButton isLoading={isSubmitting} loadingComponent="Processing..." fullWidth={false} btnText="Save" onClick={onSubmitForm} customStyles={{ mr: { lg: "20px" }, mb: { xs: "20px", lg: 0 } }} />
                            {!isSubmitting && <AppButton fullWidth={false} btnText="Go Back" onClick={() => navigate(-1)} variant="outlined" />}
                        </Box>
                    </>}
                </Box>
            </Box>
        </>
    );
};

export default AddJob;

const teacherSkills = [
    "Classroom Management",
    "Communication Skills",
    "Patience",
    "Adaptability",
    "Creativity",
    "Organization",
    "Time Management",
    "Flexibility",
    "Problem-Solving",
    "Critical Thinking",
    "Empathy",
    "Motivation",
    "Leadership",
    "Teamwork",
    "Technology Integration",
    "Curriculum Development",
    "Lesson Planning",
    "Assessment and Evaluation",
    "Differentiation",
    "Active Listening",
    "Conflict Resolution",
    "Cultural Competence",
    "Emotional Intelligence",
    "Collaboration",
    "Decision-Making",
    "Classroom Technology",
    "Classroom Assessment Techniques",
    "Data Analysis",
    "Instructional Design",
    "Literacy Skills",
    "Numeracy Skills",
    "Classroom Innovation",
    "Parent-Teacher Communication",
    "Positive Reinforcement",
    "Classroom Engagement",
    "Learning Styles",
    "Adaptation to Diverse Learners",
    "Adaptation to Special Needs",
    "Public Speaking",
    "Research Skills",
    "Networking",
    "Flexibility",
    "Classroom Discipline",
    "Conflict Management",
    "Reflective Practice",
    "Resourcefulness",
    "Presentation Skills",
    "Classroom Environment Management",
    "Classroom Adaptation to Remote Learning",
    "Lifelong Learning",
    "Curiosity",
    "Global Awareness",
    "Inclusive Teaching",
    "Interpersonal Skills",
    "Differentiated Instruction",
    "Classroom Motivation",
    "Timeless Enthusiasm",
    "Classroom Safety Awareness",
    "Adapting to New Technologies",
    "Digital Literacy",
    "Mentoring",
    "Innovative Teaching Methods",
    "Assistive Technology",
    "Positive Classroom Culture",
    "Parental Involvement",
    "Behavior Management",
    "Classroom Community Building",
    "Data-Driven Decision Making",
    "Assessment Literacy",
    "Growth Mindset",
    "Flipped Classroom Techniques",
    "Crisis Management",
    "Project-Based Learning",
    "STEM Education",
    "Outdoor Education",
    "Mindfulness Practices",
    "Classroom Democracy",
    "Critical Reflection",
    "Learning Environment Design",
    "Resilience",
    "Learning Resourcefulness",
    "Cross-Curricular Integration",
    "Peer Collaboration",
    "Facilitation Skills",
    "Socratic Questioning",
    "Responsive Teaching",
    "Environmental Awareness",
    "Adaptation to Educational Trends",
    "Formative Assessment",
    "Multicultural Competence",
    "Hybrid Teaching Skills",
    "Inquiry-Based Learning",
    "Data Privacy Awareness",
    "SEL (Social-Emotional Learning) Integration",
    "Instructional Adaptation for Learning Styles",
    "Neurodiversity Understanding",
    "Critical Assessment of Educational Resources",
    "Action Research Skills",
    "Classroom Routine Management",
    "Strategic Use of Classroom Space",
    "Classroom Resourcefulness",
    "Project Management",
    "Cross-Cultural Communication",
    "Microsoft Office",
    "Graphic Design"
];