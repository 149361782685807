import React from "react";
import Courses from "../../components/dashboard/SharedComponents/Courses";

const DashboardCourses = () => {
  return (
    <>
      <Courses />
    </>
  );
};

export default DashboardCourses;
