import React from "react";
import { Box, Typography } from "@mui/material";

const Speaker = ({image, name, role}) => {
  return (
        <Box sx={{display: "flex", flexDirection: "column", alignItems:"center", width: {xs: "90%", md: "30%"}, marginBottom: {xs: "25px", md: "0"}}}>
            <Box component="img" src={image} sx={{width: "200px", height: "200px", borderRadius: "50%"}} />
            <Typography fontWeight="bold" sx={{marginBottom:"2px", marginTop: "7px"}}>{name}</Typography>
            <Typography textAlign="center">{role}</Typography>
        </Box>
  );
};

export default Speaker;
