import React, { useState, useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import "./UploadDocument.css";

import { useDispatch, useSelector } from "react-redux";
import { clearUploadingState } from "../../../../../redux/dashboard/slice";

import AppButton from "../../../../buttons/AppButton";
import { showSimpleToast } from "../../../../../utils/alertFunctions";
import { uploadProfileDocument } from "../../../../../redux/document/action";

const UploadDocumentsPage = ({
  handleBackIndex,
  handleProfileIndex,
  uploadType,
}) => {
  const [base64, setBase64] = useState("");
  const [showUploadBtn, setShowUploadBtn] = useState(false);

  const dispatch = useDispatch();

  const { documentUploadSuccessful, isBtnLoading } = useSelector(
    (state) => state.dashboardReducer
  );

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result;
        setBase64(binaryStr);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const { getRootProps, getInputProps, open, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
    });

  const handleUploadDocument = () => {
    if (base64 !== "") {
      let base64Length = base64.length;
      let fileSize = ((base64Length * (3/4)) - (base64.slice(-2) == "=="? 2:1))/1000;
      if(fileSize >1550){
        showSimpleToast("File cannot be bigger that 1.5MB", "failed")
        return;
      }
      dispatch(
        uploadProfileDocument({
          type: uploadType,
          fileString: base64,
        })
      );
    }
  };

  useEffect(() => {
    if (documentUploadSuccessful) {
      handleProfileIndex();

      setTimeout(() => {
        dispatch(clearUploadingState());
      }, [3000]);
    }
  }, [documentUploadSuccessful]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setShowUploadBtn(true);
    } else {
      setShowUploadBtn(false);
    }
  }, [acceptedFiles]);

  return (
    <>
      <div>
        <div {...getRootProps({ className: "dropzone" })}>
          <input className="input-zone" {...getInputProps()} />
          <div className="text-center">
            {isDragActive ? (
              <p className="dropzone-content">Release to drop the files here</p>
            ) : (
              <p className="dropzone-content">
                Drag and drop <strong>{uploadType}</strong> here to upload
              </p>
            )}
            <button type="button" onClick={open} className="btn">
              Select from device
            </button>
          </div>
        </div>
        <ul>
          {acceptedFiles.map((file) => {
            return (
              <li key={file.path}>
                <p>{file.path}</p>
              </li>
            );
          })}
        </ul>
      </div>

      <Box
        width="100%"
        m="0 auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="20px"
      >
        <AppButton
          customStyles={{
            fontWeight: "bold",
            borderRadius: "8px",
            border: "3px solid",
          }}
          btnText="Cancel"
          variant="outlined"
          onClick={() => {
            handleBackIndex();
          }}
        />

        {showUploadBtn && (
          <AppButton
            customStyles={{
              fontWeight: "bold",
              borderRadius: "8px",
            }}
            btnText={isBtnLoading ? "Uploading file" : "Upload file"}
            disabled={isBtnLoading}
            onClick={() => {
              handleUploadDocument();
            }}
          />
        )}
      </Box>
    </>
  );
};
export default UploadDocumentsPage;
