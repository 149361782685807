import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppInput from "../../inputs/AppInput";
import AppInputPassword from "../../inputs/AppInputPassword";
import AppMultiSelect from "../../inputs/AppMultiSelect";
import AppButton from "../../buttons/AppButton";
import { APP_COLOURS, PAGES } from "../../../utils/constant";
import GoogleIcon from "../../../assets/svg/GoogleIcon";
import LinkedinIcon from "../../../assets/svg/LinkedinIcon";
import LeftArrow from "../../../assets/svg/LeftArrow.svg";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  registerTeacher,
  registerSchool,
  getTeacherCategory,
  getSchoolCategory,
  getQualification,
  getSpecialisation,
} from "../../../redux/auth/actions";
import { clearIsEmailExistingState } from "../../../redux/auth/slice";

import AppSelect from "../../inputs/AppSelect";

const SignupForm2 = ({
  teacherData,
  schoolData,
  accountType,
  setTeacherData,
  setSchoolData,
  prevFormIndex, forceChangePassword
}) => {
  const navigate = useNavigate();

  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const [errorMessage, setErrorMessage] = useState({});

  const [teacherCategoryError, setTeacherCategoryError] = useState(false);
  const [schoolCategoriesError, setSchoolCategoriesError] = useState(false);
  const [schoolTypeError, setSchoolTypeError] = useState(false);
  const [specialisationError, setSpecialisationError] = useState(false);
  const [qualificationError, setQualificationError] = useState(false);
  const [contactNameError, setContactNameError] = useState(false);
  const [contactEmailError, setContactEmailError] = useState(false);
  const [contactPhoneError, setContactPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [checkedError, setCheckedError] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChecked = (e) => {
    setChecked(e.target.checked);
  };

  const dispatch = useDispatch();
  const {
    registeredUserEmail,
    teacherCategory,
    teacherCategoryState,
    schoolCategory,
    schoolCategoryState,
    specialisationState,
    qualificationState,
    qualification,
    specialisation,
    isBtnLoading,
  } = useSelector((state) => state.authReducer);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (accountType === "teacher") {
      setTeacherData({
        ...teacherData,
        [name]: value,
      });
    } else if (accountType === "school") {
      if (name === "categories") {
        const selected = value.pop();
        if (selected) {
          const index = value.findIndex((x) => x.id === selected.id);
          if (index === -1) {
            value.push(selected);
          } else {
            value.splice(index, 1);
          }
        }
      }

      setSchoolData({
        ...schoolData,
        [name]: value,
      });
    }
  };

  const validateData = (value, checked) => {
    const errors = {};

    if (value.categoryId <= 0) {
      setTeacherCategoryError(true);
    } else {
      setTeacherCategoryError(false);
    }

    if (value.specialisationId <= 0) {
      setSpecialisationError(true);
      errors.specialisationId = "Please choose a specialisation";
    } else {
      setSpecialisationError(false);
    }

    if (value.qualificationId <= 0) {
      setQualificationError(true);
      errors.qualificationId = "Please choose a qualification";
    } else {
      setQualificationError(false);
    }

    if (!value.contactName) {
      setContactNameError(true);
      errors.contactName = "Contact name is required";
    } else if (value.contactName.length <= 2) {
      setContactNameError(true);
      errors.contactName = "Contact name must be at least 3 letters";
    } else {
      setContactNameError(false);
    }

    if (!value.contactEmail) {
      setContactEmailError(true);
      errors.contactEmail = "Contact Email is required";
    } else if (!value.contactEmail.includes("@")) {
      setContactEmailError(true);
      errors.contactEmail = "Contact Email must include @";
    } else {
      setContactEmailError(false);
    }

    if (!value.contactPhone) {
      setContactPhoneError(true);
      errors.contactPhone = "Contact Phone number is required";
    // } else if (value.contactPhone.length <= 6) {
    //   setContactPhoneError(true);
    //   errors.contactPhone = "Contact Phone number must be at least 7 numbers";
    } else {
      setContactPhoneError(false);
    }

    if (accountType === "school") {
      if (value.categories.length <= 0) {
        setSchoolCategoriesError(true);
      } else {
        setSchoolCategoriesError(false);
      }

      if (value.schoolType === 0) {
        setSchoolTypeError(true);
      } else {
        setSchoolTypeError(false);
      }
    }

    if (
      passwordRef.current.value === "" ||
      confirmPasswordRef.current.value === ""
    ) {
      setPasswordError(true);
      errors.password = "Password cannot be blank!";
    } else if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      setPasswordError(true);
      errors.password = "Password doesn't match, please check again!";
    } else {
      setPasswordError(false);
    }

    if (!checked) {
      setCheckedError(true);
      errors.checked = "Please check this to proceed";
    } else {
      setCheckedError(false);
    }

    return errors;
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const { categoryId, specialisationId, qualificationId } = teacherData;
    const { contactPhone, contactEmail, contactName, categories, schoolType } =
      schoolData;

    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    if (accountType === "teacher") {
      setErrorMessage(validateData(teacherData, checked));

      if (
        categoryId > 0 &&
        specialisationId > 0 &&
        qualificationId > 0 &&
        password !== "" &&
        password === confirmPassword &&
        checked
      ) {
        dispatch(registerTeacher({ ...teacherData,forceChangePassword, password }));
      }
    } else {
      setErrorMessage(validateData(schoolData, checked));

      if (
        contactPhone !== "" &&
        contactEmail !== "" &&
        contactName !== "" &&
        password !== "" &&
        password === confirmPassword &&
        checked
      ) {
        if (
          contactEmail.includes("@") &&
          contactName.length > 2 &&
          categories.length > 0 &&
          schoolType !== 0
        ) {
          dispatch(registerSchool({ ...schoolData,forceChangePassword, password }));
        }
      }
    }
  };

  useEffect(() => {
    if (registeredUserEmail !== "") {
      navigate(PAGES.dashboard);
    }
    // if (registeredUserEmail !== "") {
    //   navigate(PAGES.signupVerification);
    // }
    // eslint-disable-next-line
  }, [registeredUserEmail]);

  useEffect(() => {
    if (accountType === "teacher") {
      dispatch(getTeacherCategory());
      dispatch(getQualification());
    }

    if (accountType === "school") {
      dispatch(getSchoolCategory());
    }

    dispatch(clearIsEmailExistingState());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (teacherData.categoryId > 0) {
      dispatch(getSpecialisation(teacherData.categoryId));
    }
    // eslint-disable-next-line
  }, [teacherData.categoryId]);

  return (
    <Container
      component="form"
      sx={styles.container}
      onSubmit={handleSubmitForm}
    >
      <Box
        component="img"
        src={LeftArrow}
        alt="left arrow"
        onClick={() => prevFormIndex()}
      />
      <Typography sx={styles.headerText}>Create Your Account</Typography>
      <Typography sx={styles.headerSubText}>
        Fill in the required information to get started!
      </Typography>

      {accountType === "teacher" && (
        <AppSelect
          label="Category"
          value={teacherData.categoryId}
          name="categoryId"
          handleInputChange={handleInputChange}
          selectArray={teacherCategory}
          loadingState={teacherCategoryState}
          errorState={teacherCategoryError}
        />
      )}

      {accountType === "teacher" && (
        <AppSelect
          label="Specialisation"
          value={teacherData.specialisationId}
          name="specialisationId"
          handleInputChange={handleInputChange}
          selectArray={specialisation}
          loadingState={specialisationState}
          errorState={specialisationError}
        />
      )}

      {accountType === "teacher" && (
        <AppSelect
          label="Highest Qualification"
          value={teacherData.qualificationId}
          name="qualificationId"
          handleInputChange={handleInputChange}
          selectArray={qualification}
          loadingState={qualificationState}
          errorState={qualificationError}
        />
      )}

      {accountType === "school" && (
        <AppMultiSelect
          label="School Category"
          value={schoolData.categories}
          name="categories"
          handleInputChange={handleInputChange}
          selectArray={schoolCategory}
          loadingState={schoolCategoryState}
          errorState={schoolCategoriesError}
        />
      )}

      {/* {accountType === "school" && (
        <AppMultiSelect
          label="School Type"
          value={schoolData.schoolType}
          name="schoolType"
          handleInputChange={handleInputChange}
          selectArray={[
            { id: 1, name: "Day" },
            { id: 2, name: "Day & Boarding" },
          ]}
          loadingState="Please select a School Type"
          errorState={schoolTypeError}
        />
      )} */}

      {accountType === "school" && (
        <AppSelect
          label="School Type"
          value={schoolData.schoolType}
          name="schoolType"
          handleInputChange={handleInputChange}
          selectArray={[
            { id: 1, name: "Day" },
            { id: 2, name: "Day & Boarding" },
          ]}
          loadingState="Please select a School Type"
          errorState={schoolTypeError}
        />
      )}

      {accountType === "school" && (
        <AppInput
          onChange={handleInputChange}
          value={schoolData.contactName}
          name="contactName"
          label="Name of Contact Person"
          placeholder="Enter the name of your contact"
          error={contactNameError}
          helperText={errorMessage.contactName}
        />
      )}

      {accountType === "school" && (
        <AppInput
          onChange={handleInputChange}
          value={schoolData.contactEmail}
          name="contactEmail"
          label="Contact Email"
          placeholder="Enter your Contact Email"
          error={contactEmailError}
          helperText={errorMessage.contactEmail}
        />
      )}

      {accountType === "school" && (
        <AppInput
          onChange={handleInputChange}
          value={schoolData.contactPhone}
          name="contactPhone"
          label="Contact Phone Number"
          placeholder="Enter your Contact Phone Number"
          error={contactPhoneError}
          helperText={errorMessage.contactPhone}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
      )}
      <AppInputPassword
        ref={passwordRef}
        label="Password"
        placeholder="Set a 8-character password"
        error={passwordError}
      />

      <AppInputPassword
        ref={confirmPasswordRef}
        label="Confirm Password"
        placeholder="Re-enter your password"
        error={passwordError}
        helperText={errorMessage.password}
      />

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChecked}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="I agree to the company's Terms of Service and Privacy Policy"
        />
      </FormGroup>

      {checkedError && (
        <Typography component="em" color="error" mt="15px" fontSize="14px">
          {errorMessage.checked}
        </Typography>
      )}

      <AppButton
        customStyles={{ fontWeight: "bold", margin: "15px 0 30px" }}
        btnType="submit"
        btnText={isBtnLoading ? "Registering" : "Register"}
        disabled={isBtnLoading}
      />
      {/* <Divider>OR</Divider>
      <Box sx={styles.continueDiv}>
        <Box sx={styles.continueBox}>
          <Button
            sx={styles.continueBtn}
            variant="text"
            startIcon={<GoogleIcon />}
          >
            Log in with Google
          </Button>
        </Box>

        <Box sx={styles.continueBox}>
          <Button
            sx={styles.continueBtn}
            variant="text"
            startIcon={<LinkedinIcon />}
          >
            Log in with LinkedIn
          </Button>
        </Box>
      </Box> */}
    </Container>
  );
};

export default SignupForm2;

const styles = {
  container: {},
  headerText: {
    fontSize: 30,
    fontWeight: 700,
    margin: "20px 0",
  },
  headerSubText: {
    width: "75%",
    color: "#4B4E61",
    marginBottom: "20px",
  },
  continueDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "25px",
    gap: "10px",
    fontWeight: "700",
  },
  continueBox: {
    textAlign: "center",
    width: "45%",
    border: "2px solid #6877D5",
    borderRadius: "8px",
    padding: "5px",
  },
  continueBtn: { textTransform: "none", fontSize: 16, color: APP_COLOURS.dark },
};
