import React, { useEffect } from 'react';
import Navbar from '../../../components/landing-page/Navbar/Navbar';
import CountDown from '../../../components/dashboard/resources/CountDown';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import IMAGES from "../../../assets/img/Resources"
import COURSE_IMAGES from "../../../assets/img/Resources/Courses"
import CustomSlider from '../../../components/dashboard/resources/CustomSlider/CustomSlider';
import CustomSelect from '../../../components/dashboard/resources/CustomSelect/CustomSelect';
import CourseCard from '../../../components/dashboard/resources/CourseCard/CourseCard';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMarketPlaceResources } from '../../../redux/resource/action';
import CartIcon from '../../../assets/svg/dashboard-icons/CartIcon';
import { useAuth } from '../../../utils/auth/authFunctions';

export default function MarketPlace() {
    const { storePath } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useAuth()
    const { isFetchingResources, mResources } = useSelector(state => state.resourceReducer);
    useEffect(() => {
        dispatch(getAllMarketPlaceResources({ data: { storePath } }))
    }, [dispatch, storePath])

    return (
        <Box
            sx={{
                // backgroundImage: `url(${IMAGES.RESOURCES_BG})`,
                // backgroundSize: "cover",
                // minHeight: "100vh",
                // position:'relative',
                // overflow:"hidden"
            }}
        >
            {!auth && <Box padding="0px 35px 0px 15px"><Navbar /></Box>}


            <Box sx={{
                width: "95%",
                margin: "0 auto",
            }}>




                {/* Top Picks */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: {
                            xs: "center",
                            md: "space-between"
                        },
                        alignItems: "center",
                        margin: "30px auto",
                        padding: "5px 30px 5px 30px",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Sora",
                            fontSize: "24px",
                            fontWeight: "600",
                            lineHeight: "30.24px",
                            color: "#081131",
                            marginRight: {
                                xs: "0",
                                md: "auto"
                            }
                        }}
                    >{storePath ? 'Explore Store' : 'Explore Resources'}</Typography>

                </Box>

                {isFetchingResources && <CircularProgress />}
                <Box
                    sx={{
                        backgroundColor: "#F3F3F3",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        rowGap: "20px",
                        overflow: "hidden",
                        alignItems: "stretch",
                        padding: "10px 30px 10px 30px",
                    }}
                >
                    {
                        mResources.map((resource, index) => (

                            <CourseCard
                                key={index}
                                id={resource.id}
                                title={resource.title}
                                rating={resource.rating}
                                price={resource.price || 1000}
                                img={resource.coverImage}

                            />

                        ))
                    }

                    {!isFetchingResources && mResources && mResources.length === 0 && <p style={{ marginLeft: "auto", marginRight: "auto", marginTop: "30px" }}>No Resource Available</p>}
                </Box>
            </Box>




        </Box>
    )
}


