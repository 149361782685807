// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the slider component */

.cenbtYa0hXWvs1IPqIML {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .S56L1nz5MknNGfVZDfzu {
    display: flex;
    overflow: hidden;
    gap: 10px;
    position: relative;
  }

  
  .gZVR3Jk5ujodoWmaVcng {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .i6mq3ojScrbtm15g08SI {
    left: -30px;
  }
  
  .lCiQzXHdtBxUdyeBQZPs {
    right: -30px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/dashboard/resources/CustomSlider/CustomSlider.module.css"],"names":[],"mappings":"AAAA,oCAAoC;;AAEpC;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,SAAS;IACT,kBAAkB;EACpB;;;EAGA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,UAAU;EACZ;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,YAAY;EACd","sourcesContent":["/* Styles for the slider component */\n\n.sliderContainer {\n    display: flex;\n    align-items: center;\n    position: relative;\n  }\n  \n  .cardsContainer {\n    display: flex;\n    overflow: hidden;\n    gap: 10px;\n    position: relative;\n  }\n\n  \n  .sliderArrow {\n    background: none;\n    border: none;\n    font-size: 24px;\n    cursor: pointer;\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    z-index: 1;\n  }\n  \n  .leftArrow {\n    left: -30px;\n  }\n  \n  .rightArrow {\n    right: -30px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderContainer": `cenbtYa0hXWvs1IPqIML`,
	"cardsContainer": `S56L1nz5MknNGfVZDfzu`,
	"sliderArrow": `gZVR3Jk5ujodoWmaVcng`,
	"leftArrow": `i6mq3ojScrbtm15g08SI`,
	"rightArrow": `lCiQzXHdtBxUdyeBQZPs`
};
export default ___CSS_LOADER_EXPORT___;
