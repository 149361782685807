import React from "react";
import Navbar from "../Navbar/Navbar";
import CustomCarousel from "./carousel";
import AppButton from "../../buttons/AppButton";
import HeroImage from "../../../assets/img/HeroSectionImage.png";
import TopRightBg from "../../../assets/svg/Hero/TopRightBg.svg";
import BottomLeftBg from "../../../assets/svg/Hero/BottomLeftBg.svg";
import BottomRightBg from "../../../assets/svg/Hero/BottomRightBg.svg";

const Hero = () => {
  return (
    <>
      <Navbar />
      <CustomCarousel />
    </>
  );
};

export default Hero;

const styles = {
  container: {
    position: "relative",
    overflow: "hidden",
    background: {
      sm: "#ffffff",
    },
    //padding: { xs: "0", md: "0 70px 170px" },
  },
  heroBtns: {
    marginTop: "20px"
  }
};
