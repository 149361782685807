import React from "react";
import { Typography, Box } from "@mui/material";
import ModalImage from "../../../../assets/img/ResetCompleteImage.png";
import AppButton from "../../../buttons/AppButton";
import { useDispatch } from "react-redux";
import { getSchoolTeachers } from "../../../../redux/school/action";

const AddedTeacher = ({ handleModal, onShowTeachers }) => {
  const dispatch = useDispatch();

  return (
    <Box sx={styles.modal}>
      <Typography variant="h4" component="h1" sx={styles.modalHeader}>
        Teacher Invited Successfully!
      </Typography>
      <Box component="img" src={ModalImage} alt="modal-img" />
      <Typography sx={styles.modalText}>
        An invitation mail has been sent to the teacher. We will notify you when there is a response.
      </Typography>
      <AppButton
        fullWidth={false}
        customStyles={{ fontWeight: "700" }}
        btnText="View Teachers"
        onClick={() => {
          handleModal();
          onShowTeachers();
          dispatch(getSchoolTeachers());
        }}
      />
    </Box>
  );
};

export default AddedTeacher;

const styles = {
  modal: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalHeader: {
    textAlign: "center",
    fontWeight: "600",
    color: "success.main",
    fontSize: "25px",
  },
  modalText: { textAlign: "center", width: "80%" },
};
