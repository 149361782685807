import React, { useEffect } from "react";
import { Box } from "@mui/material";

import CoursesGrid from "./CoursesGrid";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getMyCourses } from "../../../redux/courses/actions";

const MyCourses = () => {
  const dispatch = useDispatch();

  const { myCourses, myCoursesLoading } = useSelector(
    (state) => state.coursesReducer
  );
  const {userData} = useSelector(state => state.authReducer)

  const navigate = useNavigate();

  // function to get course
  const onOpenCourse = (value) => {
    if(userData.userType == "teacher"){
      navigate("/courses/my-courses/" + value);
    }else{
      navigate("/courses/manage-course/" + value);
    }
  };

  useEffect(() => {
    dispatch(getMyCourses());
    // eslint-disable-next-line
  }, []);

  return (
    <Box component="section" sx={{padding:{xs:"0",lg:"30px"}}}>
      <Box backgroundColor="#d5d6e933" p="30px" borderRadius="15px">
        {/* courses */}
        <CoursesGrid
          coursesArray={myCourses}
          // gridTitle="My Courses"
          width={{width: {xs: "100%", md: "48%", lg: "32%"}}}
          onOpenCourse={onOpenCourse}
          showPreloader={myCoursesLoading} personal isSchool={userData.userType == "school"}
        />
      </Box>
    </Box>
  );
};

export default MyCourses;
