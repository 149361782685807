import React from "react";
import { Typography, Box } from "@mui/material";
import AppInput from "../../inputs/AppInput";
import AppButton from "../../buttons/AppButton";

const CheckoutStepTwo = ({ handleNextPage }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt="10PX">
      <Typography fontSize="16px" fontWeight="400" mb="15px">
        Enter your 4-digit card pin to confirm this payment
      </Typography>

      <Box
        width="60%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="40px"
        mb="50px"
      >
        <AppInput name="tokenInput1" />
        <AppInput name="tokenInput2" />
        <AppInput name="tokenInput3" />
        <AppInput name="tokenInput4" />
      </Box>

      <AppButton
        customStyles={{
          fontWeight: "700",
          fontSize: "16px",
          borderRadius: "8px",
          my: "25px",
        }}
        onClick={() => {
          handleNextPage();
        }}
        btnText="Confirm Payment"
      />
    </Box>
  );
};

export default CheckoutStepTwo;
