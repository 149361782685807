import React from "react";
import SignupEmailVerification from "../../components/onboarding/Signup/SignupEmailVerification";
import OnboardingLayout from "../../components/onboarding/OnboardingLayout";
import ForgotPasswordImage from "../../assets/img/ForgotPasswordImage.png";

const SignupVerifyEmail = () => {
  return (
    <OnboardingLayout image={ForgotPasswordImage}>
      <SignupEmailVerification />
    </OnboardingLayout>
  );
};

export default SignupVerifyEmail;
