import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import AppInput from "../../../inputs/AppInput";
import AppButton from "../../../buttons/AppButton";

import { useDispatch, useSelector } from "react-redux";
import { getProfile, updateProfileData } from "../../../../redux/dashboard/actions";
import { clearUploadingState } from "../../../../redux/dashboard/slice";

import { getProfileData } from "../../../../utils/dashboard/dashboardFunctions";
import { useNavigate } from "react-router-dom";
import AppSelect from "../../../inputs/AppSelect";
import { getQualification, getSpecialisation, getTeacherCategory } from "../../../../redux/auth/actions";
import { textIsDigits } from "../../../../utils/stringFunctions";
import { showSimpleToast } from "../../../../utils/alertFunctions";

const EditProfileForm = ({ handleProfileIndex }) => {
  const profileData = getProfileData();
  const dispatch = useDispatch()
  const [profileForm, setProfileForm] = useState({
    name: { value: "", error: null },
    email: { value: "", error: null },
    phone: { value: "", error: null },
    location: { value: "", error: null },
    bio: { value: "", error: null },
    currentEmployer: { value: "", error: null },
    categoryId: { value: 0, error: null },
    qualificationId: { value: 0, error: null },
    specialisationId: { value: 0, error: null }

  })
  const [formIsSet, setSetFormIsSet] = useState(false);
  const {
    teacherCategory,
    teacherCategoryState,
    specialisationState,
    qualificationState,
    qualification,
    specialisation,
  } = useSelector((state) => state.authReducer);

  const { isProfileUpdateSuccessful, isBtnLoading } = useSelector(
    (state) => state.dashboardReducer
  );

  useEffect(() => {
    dispatch(getTeacherCategory());
    dispatch(getQualification());

  }, []);

  useEffect(() => {
    if (profileForm.categoryId.value > 0) {
      dispatch(getSpecialisation(profileForm.categoryId.value));
    }
    // eslint-disable-next-line
  }, [profileForm.categoryId.value]);

  const onChangeData = (e) => {
    const { name, value } = e.target;
    const profileFormCopy = { ...profileForm };
    console.log(textIsDigits(value))
    if (name == "phone" && !textIsDigits(value)) {
      return;
    }
    profileFormCopy[name].value = value;
    setProfileForm(profileFormCopy);

  };

  const handleProfileDataEdit = (e) => {
    const isExist = specialisation?.find(x => x.id == profileForm.specialisationId.value)
    if (!isExist) {
      showSimpleToast("Please select your specialisation", "failed")
      return;
    }
    const payload = {
      phone: profileForm.phone.value,
      location: profileForm.location.value,
      bio: profileForm.bio.value,
      currentEmployer: profileForm.currentEmployer.value,
      qualificationId: profileForm.qualificationId.value,
      categoryId: profileForm.categoryId.value,
      specialisationId: profileForm.specialisationId.value,
      email: profileData.email
    }
    dispatch(updateProfileData({ data: payload, callback: ()=>  handleProfileIndex("profile")}));

  };

  useEffect(() => {
    if (isProfileUpdateSuccessful) {
      handleProfileIndex();

      setTimeout(() => {
        dispatch(clearUploadingState());
      }, [3000]);
    }
  }, [isProfileUpdateSuccessful]);

  useEffect(() => {
    if (profileData?.email && !formIsSet) {
      const profileFormCopy = { ...profileForm };
      profileFormCopy.bio.value = profileData.bio;
      profileFormCopy.currentEmployer.value = profileData.currentEmployer;
      profileFormCopy.email.value = profileData.email;
      profileFormCopy.location.value = profileData.location;
      profileFormCopy.name.value = `${profileData.firstName} ${profileData.lastName}`;
      profileFormCopy.phone.value = profileData.phone;
      profileFormCopy.specialisationId.value = profileData.specialisationId;
      profileFormCopy.categoryId.value = profileData.categoryId;
      profileFormCopy.qualificationId.value = profileData.qualificationId;

      setProfileForm(profileFormCopy)
      setSetFormIsSet(true)
    }
  }, [profileData])

  return (
    <>
      <Box>
        <Typography
          fontSize="20px"
          color="#2E3192"
          fontWeight="500"
          gutterBottom
        >
          Personal Information
        </Typography>

        <Box mt="20px">
          <Box display="flex" gap="40px" width="100%">
            <AppInput
              name="name"
              label="Name"
              value={profileForm.name.value}
              inputStyles={{ width: "50%" }}
              disabled={true}
            />

            <AppInput
              name="email"
              label="Email Address"
              value={profileForm.email.value}
              inputStyles={{ width: "50%" }}
              disabled={true}
            />
          </Box>

          <Box display="flex" gap="40px" width="100%">
            <AppInput
              name="phone"
              label="Phone Number"
              value={profileForm.phone.value}
              inputStyles={{ width: "50%" }}
              error={profileForm.phone.error}
              onChange={onChangeData}
            />

            <AppInput
              name="location"
              label="Location"
              placeholder="LGA State, Country"
              inputStyles={{ width: "50%" }}
              value={profileForm.location.value}
              onChange={onChangeData}
            />
          </Box>

          <Box>
            <AppInput
              onChange={onChangeData}
              value={profileForm.bio.value}
              name="bio"
              label="Bio"
              placeholder="Write you bio"
              error={profileForm.bio.error}
              rows={6}
              multiline={true}
            />

            {/* <AppButton
              customStyles={{
                fontWeight: "bold",
                fontSize: "18px",
                borderRadius: "8px",
              }}
              fullWidth={false}
              disabled={isBtnLoading}
              btnText={isBtnLoading ? "Editing Bio" : "Edit Bio"}
              onClick={handleProfileDataEdit}
            /> */}
          </Box>
        </Box>
      </Box>
      {/*  */}
      <Box mt="20px">
        <Typography
          fontSize="20px"
          color="#2E3192"
          fontWeight="500"
          gutterBottom
        >
          Professional Information
        </Typography>

        <Box mt="20px">
          <Box display="flex" gap="40px" width="100%">
            <AppInput
              name="currentEmployer"
              label="Current Employer"
              placeholder="Current Employer"
              inputStyles={{ width: "50%" }}
              value={profileForm.currentEmployer.value}
              onChange={onChangeData}
            />

            <AppSelect
              label="Highest Qualification"
              value={profileForm.qualificationId.value}
              name="qualificationId"
              handleInputChange={onChangeData}
              selectArray={qualification}
              // loadingState={qualificationState}
              errorState={profileForm.qualificationId.error}
              inputStyles={{ width: "50%", marginTop: 0 }}
            />
          </Box>

          <Box display="flex" gap="40px" width="100%">
            <AppSelect
              label="Category"
              value={profileForm.categoryId.value}
              name="categoryId"
              handleInputChange={onChangeData}
              selectArray={teacherCategory}
              // loadingState={teacherCategoryState}
              errorState={profileForm.categoryId.error}
              inputStyles={{ width: "50%", marginTop: 0 }}
            />

            <AppSelect
              label="Specialisation"
              value={profileForm.specialisationId.value}
              name="specialisationId"
              handleInputChange={onChangeData}
              selectArray={specialisation}
              // loadingState={specialisation ? specialisationState : ""}
              errorState={profileForm.specialisationId.error}
              inputStyles={{ width: "50%", marginTop: 0 }}
            />
          </Box>
        </Box>
      </Box>

      <AppButton
        customStyles={{
          fontWeight: "bold",
          fontSize: "18px",
          borderRadius: "8px",
          mt: "24px",
          mb: isBtnLoading ? "40px": "10px",
        }}
        btnText="Save changes"
        onClick={handleProfileDataEdit}
        isLoading={isBtnLoading}
        loadingComponent="Processing..."
      />
      {!isBtnLoading && <AppButton
        customStyles={{
          fontWeight: "bold",
          fontSize: "18px",
          borderRadius: "8px",
          mt: "10px",
          mb: "40px",
          border: "2px solid",
        }}
        variant="outlined"
        btnText="Cancel"
        onClick={() => {
          handleProfileIndex();
        }}
      />}
    </>
  );
};

export default EditProfileForm;
