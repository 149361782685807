import { Box, CircularProgress, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LeftArrow from "../../assets/svg/LeftArrow.svg";
import AppButton from "../../components/buttons/AppButton";
import { getCourseCertificate } from "../../redux/user/action";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ShareCertificateModal from "../../components/modal/ShareCertificateModal";
import axios from 'axios';

const ViewCertificate = () => {
    const { courseId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isFetchingCert, certificate } = useSelector(state => state.userReducer);

    const [isDownloadingCert, setIsDownloadingCert] = useState(false);

    const [showShareModal, setShowShareModal] = useState(false);

    const handleOpenShareModal = () => {
        setShowShareModal(true);
    }

    const handleCloseShareModal = () => {
        setShowShareModal(false);
    }

    useEffect(() => {
        if (!certificate?.url) {
            dispatch(getCourseCertificate({ courseId }))
        }
    }, [courseId])

    // const downloadFile = ( ) => {
    //     let headers = new Headers();

    // headers.append('Content-Type', 'application/json');
    // headers.append('Accept', 'image/png');
    // headers.append('Access-Control-Allow-Origin','localhost:3000');
    //     fetch(certificate?.url, {
    //         mode: 'cors',
    //         credentials: 'include',
    //         method: 'GET',
    //         headers: headers
    //     })
    //       .then(response => response.blob())
    //     .then(blob => {
    //     const url = window.URL.createObjectURL(new Blob([blob]));

    //     const link = document.createElement('a');
    //     link.href = url;
    //     // link.download = `Safeticha_Cert_${certificate.code}`;
    //     link.download = `Safeticha_Cert_${certificate?.code || 'default'}.jpg`;


    //     document.body.appendChild(link);

    //     link.click();

    //     link.parentNode.removeChild(link);
    //     });
    //   };

    const downloadFile = async () => {
        setIsDownloadingCert(true);
        const url = certificate?.url; 
    
        try {
          const response = await axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // Important to get the file as a blob
          });
    
          // Create a URL for the blob object
          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', `Safeticha_Cert_${certificate?.code || 'default'}.jpg`); 
          document.body.appendChild(link);
          link.click();
    
          // Clean up and remove the link
          link.remove();
          window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
          console.error('Error downloading the file:', error);
        } finally {
          setIsDownloadingCert(false);
        }
      };

    return (
        <Box component="section" sx={{ padding: { xs: "10px", lg: "30px" }, position:'relative'}}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                    component="img"
                    src={LeftArrow}
                    alt="left arrow"
                    mr="10px"
                    onClick={() => {
                        navigate(-1);
                    }}
                />
                <Typography sx={{ fontSize: "20px" }}
                    fontWeight="600">{certificate?.courseName}</Typography>
            </Box>
            {isFetchingCert ? <CircularProgress /> : <>
             <Box
                component="img"
                src={certificate?.url}
                alt="Certificate"
                sx={{ objectFit: "cover", marginTop: "30px", width: { xs: "100%", md: "100%", lg: "70%" } }}
            />

            <Box 
                sx={{
                    display:"flex",
                    gap:"10px",
                    marginTop:"20px"
                }}
            >

                <Button
                    disabled={isDownloadingCert}
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        backgroundColor:"#2E3192",
                        color:"white",
                        gap:"10px",
                        width:"130px",
                        cursor:"pointer",
                        opacity: isDownloadingCert ? 0.7 : 1,
                        "&:hover": {
                            backgroundColor: "#2E3192",  
                            boxShadow: "none",           
                            filter: "none",                   
                          }
                    }}
                    onClick={downloadFile}
                >
                    <FileDownloadOutlinedIcon />
                    <Box>Download</Box>
                </Button>

                <Button
                    onClick={handleOpenShareModal}
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        color:"#2E3192",
                        border:"1px solid #2E3192",
                        gap:"10px",
                        width:"110px",
                        cursor:"pointer",
                        "&:hover": {
                            opacity: 0.7
                          }
                    }}
                >
                    <ShareOutlinedIcon />
                    <Box>Share</Box>
                </Button>
            </Box>
            </>}

            
          {
            showShareModal &&  <ShareCertificateModal 
            certificate_url = {certificate.url} handleClose={handleCloseShareModal} 
            shareLink = {`https://www.safeticha.com/certificate/course/${courseId}`}/>
          }
        </Box>
    )
};

export default ViewCertificate;
