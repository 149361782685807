import { Autocomplete, Box, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, LinearProgress, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import AppButton from "../../components/buttons/AppButton";
import AppInput from "../../components/inputs/AppInput";
import { useNavigate, useParams } from "react-router-dom";
import { createJob, getJobDetails, updateJob } from "../../redux/jobs/action";
import { useDispatch, useSelector } from "react-redux";
import { APP_COLOURS, JOB_ROLES, SUBJECTS } from "../../utils/constant";
import AppSelect from "../../components/inputs/AppSelect";
import {
    BtnBold,
    BtnItalic,
    BtnUnderline,
    BtnUndo,
    BtnRedo,
    BtnBulletList, BtnLink, BtnNumberedList, BtnStrikeThrough,
    Editor,
    EditorProvider,
    Toolbar,
    BtnStyles
} from 'react-simple-wysiwyg';
import { formatAsCurrency } from "../../utils/numberFunctions";
import { decodeHTML, textIsDigits } from "../../utils/stringFunctions";
import UploadDocumentsPage from "../../components/dashboard/School/SchoolProfile/UploadDocuments/UploadDocumentsPage";
import { createResource } from "../../redux/resource/action";
import TermsModal from "../../components/modal/TermsAndCondition/TermsModal";
import DocumentUploader from "../../components/document/DocumentUploader";
import axios from "axios";
import { API_URL } from "../../utils/apiConstants";
import { showSimpleToast } from "../../utils/alertFunctions";
import { tagList, ageRangesList } from "../../utils/constant";

const AddResource = ({ isEdit }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isSubmitting, resourceDetails, isFetching, resourceInView } = useSelector(state => state.resourceReducer);
    const [showEmployer, setShowEmployer] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [category, setCategory] = useState(0)
    const [ageRange, setAgeRange] = useState([]);
    const [base64s, setBase64s] = useState([]);
    const [files, setFiles] = useState([]);
    const [coverImagebase64s, setCoverImageBase64s] = useState([]);
    const [coverImagefiles, setCoverImageFiles] = useState([]);
    const [tags, setTags] = useState([]);
    const [subject, setSubject] = useState(-1);
    const { jobId } = useParams();
    const [progress, setProgress] = useState(0);
    const [form, setForm] = useState({
        title: "",
        description: "",
        age: "",
        category: "",
        tags: "",
        subject: "",
        file: null,
        coverImage: null
    })
    const [html, setHtml] = useState('');
    const [checked, setChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false); // State for terms and conditions checkbox


    const [isModalTermsOpen, setTermsModalOpen] = useState(false); // Modal for terms and conditions
    const openTermsModal = () => setTermsModalOpen(true);
    const closeTermsModal = () => setTermsModalOpen(false);


    const handleChecked = (e) => {
        setChecked(e.target.checked);
    };

    const handleTermsChecked = (e) => { // Function to handle terms and conditions checkbox
        setTermsChecked(e.target.checked);
    };

    const onChange = (e) => {
        setHtml(e.target.value);
    }

    useEffect(() => {
        if (jobId && isEdit) {
            dispatch(getJobDetails({ jobId }))
        }
    }, [jobId])

    // useEffect(() => {
    //     if (resourceInView?.id && isEdit) {
    //         let formCopy = { ...form };
    //         formCopy.title = jobDetails.title;
    //         formCopy.salaryfrom = jobDetails.salary;
    //         formCopy.salary = jobDetails.salary;
    //         formCopy.salaryto = jobDetails.salaryTo;
    //         formCopy.location = jobDetails.location
    //         setHtml(decodeHTML(jobDetails.description))
    //         setForm(formCopy);
    //         setSalaryType(jobDetails.salaryType);
    //         setSalaryCurrency(jobDetails.salaryCurrency == "₦" ? 1 : 2)
    //     }
    // }, [jobDetails])

    const [formError, setFormError] = useState({
        title: null,
        price: null,
        description: null,
        ageRange: null,
        tags: null,
        files: null,
        coverImage: null,
        terms: null,
        subject: null,
        category: null
    })

    const errorExists = (arrayOfErrors)=>{
        for (let i = 0; i < arrayOfErrors.length; i++) {
            if (typeof arrayOfErrors[i] === 'string') {
                return true; 
            }
        }
        return false
    }

    const onSubmitForm = () => {
        let payload = {
            title: form.title,
            price: form.price,
            description: html,
            published: checked,
            category: category,
            ageRanges: ageRange.join(','),
            tags: tags.join(','),
            subject: SUBJECTS[subject]?.id,
            fileType: files[0]?.type,
            fileSize: files[0]?.size,
            coverImage: coverImagebase64s[0],
            tnc: termsChecked
        }
        let formErrorCopy = { ...formError };
        if (form.title.length < 3) {
            formErrorCopy.title = "Please enter a title for this resource"
        } else {
            formErrorCopy.title = null
        }
        if (!files.length) {
            formErrorCopy.files = "Please select a file to upload"
        } else {
            formErrorCopy.files = null
        }
        if(payload.description.length < 120){
            formErrorCopy.description = "Not enough characters in description"
        }else{
            formErrorCopy.description = null
        }
        if(!payload.coverImage){
            formErrorCopy.coverImage = "Cover Image is required"
        }else{
            formErrorCopy.coverImage = null
        }
        if(!payload.subject){
            formErrorCopy.subject = "Subject is required"
        }else{
            formErrorCopy.subject = null
        }
        if(!payload.category){
            formErrorCopy.category = "Category is required"
        }else{
            formErrorCopy.category = null
        }
        if(!payload.tags){
            formErrorCopy.tags = "At least one tag is required"
        }else{
            formErrorCopy.tags = null
        }
        if(payload.category === 1 && !payload.ageRanges){
            formErrorCopy.ageRange = "At least one age range is required"
        }else{
            formErrorCopy.ageRange = null
        }

        // Check if terms checkbox is checked
        if (!termsChecked) {
            formErrorCopy.terms = "You must agree to the terms and conditions";
        } else {
            formErrorCopy.terms = null;
        }
        setFormError(formErrorCopy);
        
        let errorStrings = Object.values(formErrorCopy);
        if(errorExists(errorStrings)){
            return;
        }

        // if (isEdit) {
        //     // dispatch(updateJob({ data: payload, id: jobDetails.id, callBack: () => navigate(`/jobs/${jobDetails.id}`) }))
        // } else {
        dispatch(createResource({ data: payload, callBack: (uploadUrl) => uploadResourceDocument(uploadUrl) }))
        

    }

    const uploadResourceDocument = async (uploadUrl) => {
        await handleUpload(uploadUrl)
    }

    const handleUpload = async (uploadUrl) => {

        // const formData = new FormData();
        // formData.append('file', files[]);
        setIsUploading(true)

        try {
            // Upload the file to S3 using the signed URL
            await axios.put(uploadUrl, files[0], {
                headers: {
                    'Content-Type': files[0].type,
                },
                onUploadProgress: (event) => {
                    const percent = Math.round((event.loaded * 100) / event.total);
                    setProgress(percent);
                },
            });

            showSimpleToast("Resource uploaded successfully", "success")
            setTimeout(() => {
                setIsUploading(false)
                setProgress(0);
                navigate("/resources");
            }, 2000);
        } catch (error) {
            setIsUploading(false)
            setProgress(0);
            console.error('Error uploading file:', error);
            alert('Failed to upload resources.');
            //TODO: allow retry
        }
    };

    const handleInputChange = (event, isAutoComplete) => {
        let formErrorCopy = { ...formError };
        let formCopy = { ...form, [event.target.name]: event.target.value }
        setForm(formCopy);

        if (formCopy.title.length < 3) {
            formErrorCopy.title = "Please enter a valid title"
        } else {
            formErrorCopy.title = null
        }
        if (!textIsDigits(formCopy.price)) {
            formErrorCopy.price = "Please enter a valid amount"
        } else {
            formErrorCopy.price = null
        }
        setFormError(formErrorCopy);
    }

    const handleCategory = (event) => {
        setCategory(event.target.value)
    }


    return (
        <>
            {isUploading && <Box width="100vw" top={0} bottom={0} right={0} left={0} height="100vh" position="fixed" textAlign="center" zIndex={1000} bgcolor="#716c6c1f">

                <Box sx={{ backgroundColor: "#fff", p: 2, mt: "30vh" }}>

                    <Typography variant="h5">Uploading document... Please wait</Typography>

                    {progress > 0 && (
                        <Box sx={{ marginTop: 2 }}>
                            <LinearProgress variant="determinate" value={progress} />
                            <Typography variant="body2" sx={{ marginTop: 1 }}>
                                Upload Progress: {progress}%
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>}
            <Box component="section" sx={{ padding: { xs: "0", lg: "30px" }, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Box backgroundColor="#d5d6e933" p="30px" borderRadius="15px" sx={{ width: "100%", maxWidth: { xs: "95%", lg: "800px" } }}>
                    {isEdit && isFetching && <CircularProgress />}
                    {(!isEdit || (!isFetching && resourceDetails?.id)) && <>
                        <Typography fontSize={16} textAlign="center" color="#2E3192">{isEdit ? "Edit your resources" : "Publish your resources and earn"}</Typography>
                        <Box mt={2}>
                            <AppInput
                                onChange={handleInputChange}
                                value={form.title}
                                type="text"
                                name="title"
                                label="Title"
                                placeholder="Name of Resource"
                                error={formError.title != null}
                                helperText={formError.title}
                            />


                            <Typography sx={{ color: APP_COLOURS.lightDark, marginBottom: 1, marginTop: 1 }}>Description</Typography>
                            <EditorProvider>
                                <Editor value={html} onChange={onChange} disabled={isEdit}>
                                    <Toolbar>
                                        <BtnUndo />
                                        <BtnRedo />
                                        <BtnBold />
                                        <BtnItalic />
                                        <BtnUnderline />
                                        <BtnBulletList />
                                        <BtnNumberedList />
                                        <BtnStyles />
                                    </Toolbar>
                                </Editor>
                            </EditorProvider>
                            {formError.description && <Typography component="em" color="error" mt="15px" fontSize="14px">
                                {formError.description}
                            </Typography>}
                            <Box sx={{ height: "20px" }}></Box>
                            <AppInput
                                onChange={handleInputChange}
                                value={form.price}
                                type="text"
                                name="price"
                                label="Price"
                                placeholder="Price"
                                error={formError.price != null}
                                helperText={formError.price || formatAsCurrency(form.price, "₦")}
                            />

                            {/* Resource Category */}
                            <AppSelect label="Category" value={category}
                                name="category"
                                handleInputChange={(e) => handleCategory(e)}
                                selectArray={[
                                    { id: 0, name: "" },
                                    { id: 1, name: "Academic" },
                                    { id: 2, name: "Professional Development" },
                                ]} errorState={!!formError.category} 
                                inputStyles={{marginBottom: 0}}
                            />
                            {<Typography component="div" color="error" fontSize="14px" mb={2}>
                                {formError.category || ""}
                            </Typography>}

                            {/* Age Ranges */}
                            {category === 1 && (
                                <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    sx={{ mt: 3, mb: 0 }}
                                    options={ageRangesList.map((option) => (option === "Staff" ? option : `${option} years`))}
                                    freeSolo
                                    renderTags={(value, getTagProps) => {
                                        setAgeRange(value);
                                        return value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ));
                                    }}
                                    renderInput={(params) => (
                                        <AppInput
                                            {...params}
                                            variant="filled"
                                            label="Age Ranges"
                                            placeholder="Select 2 max."
                                            inputStyles={{ marginBottom: 1}}
                                        />
                                    )}
                                />
                            )}
                            {<Typography component="div" color="error" fontSize="14px" mb={2}>
                                {formError.ageRange || ""}
                            </Typography>}

                            {/* Subject */}

                            <AppSelect label="Subject" value={subject}
                                name="subject"
                                handleInputChange={(e) => setSubject(e.target.value)}
                                selectArray={SUBJECTS} errorState={!!formError.subject} 
                                inputStyles={{marginBottom: 0}}
                            />
                            {<Typography component="div" color="error" fontSize="14px" mb={2}>
                                {formError.subject || ""}
                            </Typography>}

                            {/* Tags */}
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                sx={{ marginBottom: "-17px", mt: 3 }}
                                options={tagList.map((option) => option)}
                                defaultValue={isEdit ? resourceDetails?.skills : []}
                                freeSolo
                                renderTags={(value, getTagProps) => {
                                    setTags(value)
                                    return value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                }
                                renderInput={(params) => (
                                    <AppInput
                                        {...params}
                                        variant="filled"
                                        label="Type"
                                        placeholder=""
                                    />

                                )}
                            />
                            {<Typography component="div" color="error" fontSize="14px" mb={2}>
                                {formError.tags || ""}
                            </Typography>}

                            {/* <UploadDocumentsPage
                                setFiles={setFiles}
                                files={files}
                                setBase64s={setBase64s}
                                base64s={base64s}
                            /> */}
                            {/* {formError.files && <Typography component="em" color="error" mt="15px" fontSize="14px">
                                {formError.files}
                            </Typography>} */}


                            <Typography sx={{ color: APP_COLOURS.lightDark, marginBottom: 1, marginTop: 1 }}>Cover Image</Typography>
                            <UploadDocumentsPage
                                setFiles={setCoverImageFiles}
                                files={coverImagefiles}
                                setBase64s={setCoverImageBase64s}
                                base64s={coverImagebase64s}
                            />
                            {<Typography component="div" color="error" fontSize="14px" mb={3} mt={-4}>
                                {formError.coverImage || ""}
                            </Typography>}

                            <Typography sx={{ color: APP_COLOURS.lightDark, marginTop: 1 }}>Upload resource file</Typography>
                            <DocumentUploader onSetFiles={setFiles} progress={progress} setProgress={setProgress} />
                            {formError.files && <Typography component="em" color="error" mt="10px" fontSize="14px">
                                {formError.files}
                            </Typography>}

                            <FormGroup sx={{ mt: 1 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleChecked}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    }
                                    label="Publish resource to marketplace immediately after upload"
                                />
                            </FormGroup>

                            {/* Terms and Conditions */}
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={termsChecked}
                                            onChange={handleTermsChecked}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    }
                                    label={
                                        <span>
                                            I agree to the terms and conditions as stated{" "}
                                            <span
                                                onClick={openTermsModal}
                                                style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                                            >
                                                here
                                            </span>
                                        </span>
                                    }
                                />
                            </FormGroup>
                            {formError.terms && <Typography component="em" color="error" mt="15px" fontSize="14px">
                                {formError.terms}
                            </Typography>}
                            <TermsModal isOpen={isModalTermsOpen} onClose={closeTermsModal} />


                        </Box>
                        <Box sx={{ display: "flex", mb: { xs: "50px", lg: "20px", }, mt: 4, flexDirection: { xs: "column", lg: "row", justifyContent: "center" } }}>
                            <AppButton isLoading={isSubmitting} loadingComponent="Processing..." fullWidth={false} btnText="Publish" onClick={onSubmitForm} customStyles={{ mr: { lg: "20px" }, mb: { xs: "20px", lg: 0 } }} />
                            {!isSubmitting && <AppButton fullWidth={false} btnText="Go Back" onClick={() => navigate(-1)} variant="outlined" />}
                        </Box>
                    </>}
                </Box>
            </Box>
        </>
    );
};

export default AddResource;



