import React from "react";
import Hero from "./Hero/Hero";
import Partners from "./Partners/Partners";
import OurGoal from "./OurGoal/ourGoal";
import AboutUs from "./AboutUs/AboutUs";
import ResourceMarketplace from "./resourceMarketplace/resourceMarketplace";
import Marketplace from "./Marketplace/Marketplace";
import Services from "./Services/Services";
import WhyChooseUs from "./WhyChooseUs/WhyChooseUs";
import Testimonials from "./Testimonials/Testimonials";
import Numbers from "./Numbers/Numbers";
import FAQ from "./FAQ/FAQ";
import PreFooter from "./PreFooter/PreFooter";
import Footer from "./Footer/Footer";
import EventHero from "./Hero/EventHero";
import EventRemain from "../events/EventRemain";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PartnerWithUs from "./PartnerWithUs/partnerWithUs";

const LandingPage = ({event}) => {
  // const navigate = useNavigate()
  return !!event ? (<>
   <EventHero />
   <EventRemain />
      <Footer />
  </>) : (
    <>
    {/* <Box onClick={() =>navigate("/gift-a-teacher")} sx={{
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'relative', textAlign:"center", bgcolor:"#2E6FB6",
        py:"7px" 
      }}>
       <Typography
       className="scrolling-text"
        sx={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          color:"#fff", fontWeight:"500", fontSize:"16px", cursor:"pointer"
        }}
      >Season Greetings 🎄. Click to Gift-A-Teacher this love season 🎅.</Typography>
    </Box> */}
      <Hero />
      <Partners />
      <OurGoal />
      <ResourceMarketplace />
      <WhyChooseUs />
      <Testimonials />
      {/* <Marketplace /> */}
      {/* <Services /> */}
      
      
      {/* <Numbers /> */}
      <FAQ />
      <PartnerWithUs />
      {/* <PreFooter /> */}
      <Footer />
    </>
  );
};

export default LandingPage;
