// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cWuwnsWrNApRdI7M_qqs {
  height: 600px;
  width: 600px;
}

@media screen and (max-width: 1250px) {
  .cWuwnsWrNApRdI7M_qqs {
    height: 550px;
    width: 550px;
  }
}

@media screen and (max-width: 900px) {
  .cWuwnsWrNApRdI7M_qqs {
    opacity: 0.1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/landing-page/Hero/Hero.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE;IACE,aAAa;IACb,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".heroImage {\n  height: 600px;\n  width: 600px;\n}\n\n@media screen and (max-width: 1250px) {\n  .heroImage {\n    height: 550px;\n    width: 550px;\n  }\n}\n\n@media screen and (max-width: 900px) {\n  .heroImage {\n    opacity: 0.1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroImage": `cWuwnsWrNApRdI7M_qqs`
};
export default ___CSS_LOADER_EXPORT___;
