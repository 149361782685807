import React from "react";
import SchoolHelp from "../../components/dashboard/School/SchoolHelp";
import TeacherHelp from "../../components/dashboard/Teacher/TeacherHelp";
import { getUserData } from "../../utils/auth/authFunctions";

const DashboardHelp = () => {
  const userData = getUserData();

  return (
    <>
      {userData.userType === "school" && <SchoolHelp />}
      {userData.userType === "teacher" && <TeacherHelp />}
    </>
  );
};

export default DashboardHelp;
