import React from "react";
import { Typography, Box } from "@mui/material";

const TeacherSettings = () => {
  return (
    <Box variant="section" sx={styles.container}>
      <Typography variant="h5">Teacher Settings</Typography>
    </Box>
  );
};

export default TeacherSettings;

const styles = {
  container: {
    height: "250px",
    width: "95%",
    margin: "0 auto",
    background: "#D5D6E9",
    display: "grid",
    placeItems: "center",
    borderRadius: "10px",
    marginTop: "10px",
  },
};
