import React from "react";
import { Box, Typography } from "@mui/material";
import img1mobile from "../../../assets/img/WhyChooseUs/why-choose-us-mobile.png";
import img1desktop from "../../../assets/img/WhyChooseUs/why-choose-us-desktop.png";
import img2 from "../../../assets/img/WhyChooseUs/WhyChooseImg2.png";
import checkmark from "../../../assets/svg/WhyChooseUs/checkmark.svg";
import AppButton from "../../buttons/AppButton"
import GooglePlaystore from "../../../assets/svg/WhyChooseUs/GooglePlaystore.svg";
import AppStore from "../../../assets/svg/WhyChooseUs/AppStore.svg";

import classes from "./WhyChooseUs.module.css";
import { useNavigate } from "react-router-dom";

const WhyChooseUs = () => {
  const navigate = useNavigate();
  return (
    <Box
      component="section"
      maxWidth="1600px"
      margin="80px auto 0"
      width={{ xs: "100%", md: "80%" }}
    >
      {/* box 1 */}
      <Box sx={styles.whyChooseUs}>
        {/* image */}
        <Box
          display={{ xs: "none", md: "block" }}
          height={{md: "400px", lg: "500px"}}
          width="auto"
          margin={{ xs: "0 auto", md: "0" }}
          position="relative"
        >
          <img src={img1desktop} alt="img1 desktop" className={classes.image} />
        </Box>

        <Box
          display={{ xs: "block", md: "none" }}
          width="90vw"
          margin={{ xs: "0 auto", md: "0" }}
          position="relative"
        >
          <img src={img1mobile} alt="img1 mobile" className={classes.image} />
        </Box>

        {/* content/text */}
        <Box
          display="flex"
          mt={{}}
          flexDirection="column"
        >
          <Typography
            mt="auto"
            className="font-sora"
            fontSize={{ xs: "14px", md: "18px"}}
            color="rgba(255, 11, 117, 1)"
            mb={{ xs: "10px", lg: "15px"}}
          >
            WHY CHOOSE US
          </Typography>

          <Typography
            fontSize={{ xs: "20px", md: "30px", lg: "50px" }}
            width="100%"
            fontWeight="600"
            lineHeight={{ md: "35px", lg: "60px" }}
            mb={{ xs: "10px", lg: "15px"}}
          >
            Get in touch with qualified educators on our platform
          </Typography>
          <Typography fontSize={{xs: "16px", lg: "18px"}} fontWeight="400">
          Find qualified and safety certified educators. Click their portfolios to access 
          their original police reports, credentials and professional qualifications.
          </Typography>

          <hr style={{ width: "100%", margin: "20px 0px" }} />

          <Box sx={styles.checkmarkList} mb={1}>
            <img src={checkmark} alt="checkmark" height={20} />
            <Typography fontSize={{xs: "14px", lg: "18px"}} className="font-open-sans">
              Safer recruitment
            </Typography>
          </Box>

          <Box my="4px" sx={styles.checkmarkList} mb={1}>
            <img src={checkmark} alt="checkmark" height={20} />
            <Typography fontSize={{xs: "14px", lg: "18px"}} className="font-open-sans">Professional educators are only a few clicks away</Typography>
          </Box>

          <Box sx={styles.checkmarkList}>
            <img src={checkmark} alt="checkmark" height={20} />
            <Typography fontSize={{xs: "14px", lg: "18px"}} className="font-open-sans">
              Our resources help educators grow professionally.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* box 2 */}
      <Box alignItems="center" sx={{...styles.whyChooseUs, flexDirection: {xs: "column-reverse", md: "row"} }}>
        <Box display="flex" flexDirection="column" gap="15px">
          <Typography
            fontSize={{ xs: "14px", md: "18px"}}
            color="rgba(255, 11, 117, 1)"
            mb={{ xs: "5px", md: "10px" }}
          >
            WHY CHOOSE US
          </Typography>

          <Typography
            width="100%"
            fontWeight="600"
            lineHeight={{ md: "35px", lg: "60px" }}
            mb={{ xs: "10px", lg: "15px"}}
            fontSize={{ xs: "20px", md: "30px", lg: "50px" }}
          >
            Get certified with professional courses
          </Typography>
          <Typography fontSize={{xs: "16px", lg: "18px"}} width="90%">
            Access world class courses that get you great opportunities within and outside your organisation.
          </Typography>

          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            gap="10px"
            alignItems={{ xs: "flex-start", md: "center" }}
          >
            <AppButton
              customStyles={{ fontWeight: "600", fontSize: {xs:"16px", md:"18px"}, borderRadius: "20px", padding: "15px 40px", margin: "40px 0px"  }}
              fullWidth={false}
              btnText="Start your growth journey"  onClick={()=> navigate("/courses")} 
                      
            />
            
          </Box>
        </Box>

        <Box
          height={{xs: "auto", md: "400px", lg: "500px"}}
          width={{xs: "90vw", md: "auto"}}
          margin={{ xs: "0 auto", md: "0" }}
          position="relative"
        >
          <img src={img2} alt="img2" className={classes.image} />
        </Box>
      </Box>
    </Box>
  );
};

export default WhyChooseUs;

const styles = {
  whyChooseUs: {
    display: "flex",
    width: { xs: "90%", md: "100%" },
    flexDirection: { xs: "column", md: "row" },
    margin: "0 auto 100px",
    gap: {xs: "20px"},
    justifyContent: "space-between",
  },
  checkmarkList: {
    display: "flex",
    gap: "10px",
  },
};
