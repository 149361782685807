import { Box,Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SchoolJobView from "../../components/dashboard/Jobs/SchoolJobView";
import TeacherJobView from "../../components/dashboard/Jobs/TeacherJobView";

const DashboardJobs = () => {
    const { userData } = useSelector((state) => state.authReducer);
    
    

    return (
        <>
            <Box component="section" sx={{ padding: { xs: "0", lg: "30px" } }}>
                <Box backgroundColor="#d5d6e933" p="30px" borderRadius="15px">

                   {userData.userType == "school" && <SchoolJobView />}
                   {userData.userType == "teacher" && <TeacherJobView />}
                </Box>
            </Box>
        </>
    );
};

export default DashboardJobs;
