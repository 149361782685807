

import React, { Fragment, useState, useEffect} from "react";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import AppButton from "../../../components/buttons/AppButton";
import AppInput from "../../../components/inputs/AppInput";
import AppSelect from "../../../components/inputs/AppSelect";
import { formatAsCurrency } from "../../../utils/numberFunctions";
import { tagList, ageRangesList, SUBJECTS } from "../../../utils/constant";
import { Autocomplete, Box, Chip, Typography } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { textIsDigits } from "../../../utils/stringFunctions";
import { createResource } from "../../../redux/resource/action";
import axios from "axios";
import { showSimpleToast } from "../../../utils/alertFunctions";
import Checkbox from '@mui/material/Checkbox'; 
import FormControlLabel from '@mui/material/FormControlLabel';
import { getAllMarketPlaceResources } from "../../../redux/resource/action";
import { Close } from "@mui/icons-material";


export default function FilterResourceDrawer({ handleFilterChange, isFetchingResources, openDrawer, toggleDrawer}) {
  //const { storePath } = useParams();
  
  const [searchParams, setSearchParams] = useSearchParams();

  

  const navigate = useNavigate();
  //const dispatch = useDispatch();

  const [startPrice, setStartPrice] = useState(searchParams.get("startPrice"));
  const [endPrice, setEndPrice] = useState(searchParams.get("endPrice"));
  
      const useDebounce = (value, delay = 500) => {
          const [debouncedValue, setDebouncedValue] = useState(value);
      
          useEffect(() => {
              const handler = setTimeout(() => {
                  setDebouncedValue(value);
              }, delay);
      
              return () => clearTimeout(handler);
          }, [value, delay]);
      
          return debouncedValue;
      };
  
      // Debounced title value
      const debouncedStartPrice = useDebounce(startPrice, 500);
      const debouncedEndPrice = useDebounce(endPrice, 500);
  
  
  useEffect(() => {
    handleFilterChange("startPrice", debouncedStartPrice);
  }, [debouncedStartPrice]);

  useEffect(() => {
    handleFilterChange("endPrice", debouncedEndPrice);
  }, [debouncedEndPrice]);

  useEffect(()=>{
    setStartPrice(searchParams.get('startPrice') || "");
    setEndPrice(searchParams.get('endPrice') || "");
  }, [searchParams])


  const [selectedAgeRanges, setSelectedAgeRanges] = useState(new Set());

  useEffect(() => {
    const existingRanges = searchParams.get("ageRanges")?.split(",") || [];
    setSelectedAgeRanges(new Set(existingRanges));
  }, [searchParams]);

  const toggleAgeRange = (age) => {
    const updatedRanges = new Set(selectedAgeRanges);
    if (updatedRanges.has(age)) {
      updatedRanges.delete(age);
    } else {
      updatedRanges.add(age);
    }

    const updatedArray = [...updatedRanges];
    setSelectedAgeRanges(updatedRanges);
    handleFilterChange("ageRanges", updatedArray.length ? updatedArray.join(",") : null);
  };

  return (
    <div style={{ display: "inline-block", marginLeft: "auto" }}>
      <Button sx={{border: "1px solid #2E3192"}} onClick={()=>toggleDrawer(true)}>
        {/* <FilterListIcon fontSize="medium" /> */}
        {isFetchingResources ? "Filtering ..." : "Filter Resources"}
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={openDrawer}
        onClose={()=>toggleDrawer(false)}
        onOpen={()=>toggleDrawer(true)}
      >
        <Box
          sx={{ width: 375, padding: "0px 15px" }}
          role="presentation"
        >
          <form>
            <Box component="header" display="flex" alignItems="center" justifyContent="space-between">
              <h3 style={{ padding: "5px 0px" }}>Filter Resources</h3>
              <Button variant="text" onClick={()=>toggleDrawer(false)} ><Close fontSize="medium"/></Button>
            </Box>
            

            <Box component="section" sx={{ padding: { xs: "0"}, display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box sx={{ width: "100%", maxWidth: { xs: "95%", lg: "800px" } }}>

                <Box>

                  {/* Subject */}

                  <AppSelect label="Subject" value={searchParams.get('subject') || 0}
                    name="subject"
                    handleInputChange={(event)=>handleFilterChange("subject", event.target.value)}
                    selectArray={SUBJECTS} 
                    //errorState={!!formError?.subject}
                    inputStyles={{ marginBottom: 0 }}
                  />

                  {/* Resource Category */}
                  <AppSelect label="Category" value={searchParams.get('category') || 0}
                    name="category"
                    handleInputChange={(event)=>handleFilterChange("category", event.target.value)}
                    selectArray={[
                      { id: 0, name: "" },
                      { id: 1, name: "Academic" },
                      { id: 2, name: "Professional Development" },
                    ]} 
                    //errorState={!!formError?.category}
                    inputStyles={{ marginBottom: 0 }}
                  />

                  {/* Tags */}
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    sx={{ marginBottom: "-10px", mt: 3 }}
                    options={tagList.map((option) => option)}
                    defaultValue={[]}
                    freeSolo
                    onChange={(event, newValue) => {
                      handleFilterChange("tags", newValue.join(","));
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <AppInput {...params} variant="filled" label="Type" placeholder="" />
                    )}
                  />

                  <Box sx={{ display: "flex", flexDirection: "column", margin: "15px 0px" }}>
                    <h5 style={{ fontWeight: 400, fontSize: "16px", margin: 0 }}>Age Range</h5>
                    {ageRangesList.map((age) => (
                      <FormControlLabel
                        key={age}
                        sx={{ ml: 1, display: "inline-block", maxWidth: "150px" }}
                        label={age !== "Staff" ? `${age} years` : age}
                        control={
                          <Checkbox
                            checked={selectedAgeRanges.has(age)}
                            onChange={() => toggleAgeRange(age)}
                          />
                        }
                      />
                    ))}
                  </Box>
                  {/* {formError?.ageRanges && <Typography component="em" color="error" mt="15px" fontSize="14px">
                    {formError?.ageRanges}
                  </Typography>} */}

                  
                  <div style={{ display: "flex", alignItems: "center",}}>
                    <AppInput
                      inputStyles={{marginBottom: 0}}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        setStartPrice(value);
                      }}
                      value={startPrice}
                      type="text"
                      name="startPrice"
                      label="Price Range"
                      placeholder="Start price"
                      //error={formError?.startPrice != null/*  || !isNaN(parseFloat(form.startPrice)) */}
                    />

                    <AppInput
                      inputStyles={{marginLeft: "5px", marginTop: "31px", marginBottom: 0}}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        setEndPrice(value);
                      }}
                      value={endPrice}
                      type="text"
                      name="endPrice"
                      placeholder="End price"
                      //error={formError?.endPrice != null/*  || !isNaN(parseFloat(form.endPrice)) */}
                    />
                  </div>
                  {/* {(formError?.startPrice || formError?.endPrice) && <Typography component="em" color="error" mt="15px" fontSize="14px">
                    {formError?.startPrice || formError?.endPrice}
                  </Typography>} */}

                  

                 
                </Box>
                <Box sx={{ display: "flex", mb: { xs: "50px", lg: "20px", }, mt: 4, flexDirection: { xs: "column", lg: "row", justifyContent: "center" } }}>
                  {/* <AppButton isLoading={isFetchingResources} loadingComponent="Processing..." fullWidth={false} btnText="Filter Resources" onClick={onSubmitForm} customStyles={{ mr: { lg: "20px" }, mb: { xs: "20px", lg: 0 } }} /> */}
                  <AppButton fullWidth={false} btnText="Clear Filters" onClick={()=>navigate('/marketplace')} variant="outlined" />
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
