import React from "react";
import { Typography, Box } from "@mui/material";
import ModalImage from "../../../assets/img/ResetCompleteImage.png";
import AppButton from "../../buttons/AppButton";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../../utils/constant";

const ResetPasswordModal = ({ handleModal }) => {
  const navigate = useNavigate();

  return (
    <Box sx={styles.modal}>
      <Typography
        id="modal-modal-title"
        variant="h4"
        component="h1"
        sx={styles.modalHeader}
      >
        Password Reset Complete
      </Typography>
      <img src={ModalImage} alt="modal-img" />
      <Typography sx={styles.modalText}>
        Congratulations! Your password has been reset successfully.
      </Typography>
      <AppButton
        onClick={() => navigate(PAGES.login)}
        fullWidth={false}
        customStyles={{ fontWeight: "700" }}
        btnText="Proceed to Log in"
      />
    </Box>
  );
};

export default ResetPasswordModal;

const styles = {
  modal: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalHeader: { fontWeight: "700", color: "success.main" },
  modalText: { textAlign: "center", width: "80%" },
};
