import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addTeachersToCourse, getSchoolTeachers, listTeachersForCourse } from "../../redux/school/action";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import DashboardCard from "../../components/dashboard/SharedComponents/DashboardCard";
import AppButton from "../../components/buttons/AppButton";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { showSimpleToast } from "../../utils/alertFunctions";
import { CancelRounded } from "@mui/icons-material";

const DashboardManageCourse = () => {
    const { courseId } = useParams();
    const dispatch = useDispatch();
    const { courseTeachersDetails, isLoading, schoolTeachers, isFetchingCouseTeachers } = useSelector(state => state.schoolReducer);
    const [rows, setRows] = useState([]);
    const [courseTeachers, setCourseTeachers] = useState([]);
    const [allTeachers, setAllTeachers] = useState([]);
    const [showAdding, setShowAdding] = useState(false);
    const [selected, setSelected] = useState([]);

    const toggleView = () => {
        if (showAdding) {
            if (selected.length == 0) {
                showSimpleToast("You need to select at least 1 teacher.", "failed")
                return;
            }
            if (window.confirm("Use units available to give these teachers access? This action cannot be reversed.")) {
                dispatch(addTeachersToCourse({
                    data: { teachers: selected },
                    courseId,
                    callBack: () => {
                        setShowAdding(false);
                        setSelected([]);
                    }
                }))
            }
        } else {
            setShowAdding(true)
            setRows([...allTeachers]);
        }
    }

    const teacherNotAddedYet = (teacherId) => {
        const isExist = courseTeachers.find(x => x.id == teacherId);
        return isExist ? false : true
    }

    const updatedSelected = (teacherId, type) => {
        let copySelected = [...selected];
        if (type == "add") {
            copySelected.push(teacherId);
        } else {
            copySelected = selected.filter(x => x != teacherId);
        }
        setSelected(copySelected);
    }

    useEffect(() => {
        dispatch(listTeachersForCourse(courseId))
        if (!schoolTeachers?.length) {
            dispatch(getSchoolTeachers())
        }
    }, [courseId])

    useEffect(() => {
        let copyRows = [];
        if (courseTeachersDetails?.teachersAdded) {
            for (const teacher of courseTeachersDetails?.teachersAdded) {
                copyRows.push({ ...teacher, fullName: teacher.firstName + " " + teacher.lastName, imgUrl: "https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg" })
            }
            setRows(copyRows);
            setCourseTeachers(copyRows);
        }

    }, [courseTeachersDetails?.teachersAdded?.length])

    useEffect(() => {
        let copyRows = [];
        for (const teacher of schoolTeachers) {
            copyRows.push({ ...teacher, fullName: teacher.firstName + " " + teacher.lastName, imgUrl: "https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg" })
        }
        setAllTeachers(copyRows);
    }, [schoolTeachers?.length])

    return (<Box variant="section" sx={styles.container}>
        <Typography
            sx={{ fontSize: { xs: "16px", md: "18px", lg: "20px" } }}
            fontWeight="600"
            color="#5C5C5C"
            pb="20px"
        >
            {courseTeachersDetails.name || "Safe Gaurding"}
        </Typography>
        {!isFetchingCouseTeachers &&
            <Box>
                <Grid  container
        gap="10px"
        mb="30px">
                    <DashboardCard name="Units Purchased" noProgress value={courseTeachersDetails.unitPurchased} customStyles={{ marginRight: "20px" }} />
                    <DashboardCard name="Units Remaining" noProgress value={courseTeachersDetails.unitRemaining} />
                </Grid>

                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Typography fontSize="18px"
                        fontWeight="500">{!showAdding ? 'Educators Learning' : 'School Staff'}</Typography>
                    <Box style={{ display: "flex" }}>
                        <AppButton isLoading={isLoading} loadingComponent="Adding..." btnText={showAdding ? `Add Selected (${selected.length})` : "Add Teachers"} customStyles={{ width: showAdding ? "180px" : "150px", marginLeft: "10px" }} onClick={toggleView} />
                        {showAdding && !isLoading && <>
                            <AppButton btnText="Cancel" variant="outlined" customStyles={{ width: "100px", marginLeft: "10px", display:{xs: "none", md: "block", lg:"block"} }} onClick={() => {
                            setShowAdding(false);
                            setSelected([]);
                            setRows([...courseTeachers])
                        }} />

                        <CancelRounded sx={{display:{ xs: "block", md: "none", lg: "none"}, margin:"10px"}} onClick={() => {
                            setShowAdding(false);
                            setSelected([]);
                            setRows([...courseTeachers])}} />
                        </> }
                    </Box>

                </Box>


<Paper style={styles.tableResponsive}>
<TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>S/N</TableCell>
                                <TableCell align="right">Image</TableCell>
                                <TableCell align="right">Name</TableCell>
                                <TableCell align="right">Email</TableCell>
                                {showAdding && <TableCell align="right">Action</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="right"><Box component="img" sx={{ width: "40px", height: "40px", borderRadius: "50%" }} src={row.imgUrl} /></TableCell>
                                    <TableCell align="right">{row.fullName}</TableCell>
                                    <TableCell align="right">{row.email}</TableCell>
                                    {showAdding && teacherNotAddedYet(row.id) && !isLoading && <TableCell align="right">{selected.includes(row.id) ?
                                        <Typography onClick={() => updatedSelected(row.id, "remove")} color="red" style={styles.select}>Unselect</Typography> :
                                        <Typography onClick={() => updatedSelected(row.id, "add")} color="green" style={styles.select}>Select</Typography>}</TableCell>}
                                    {showAdding && !teacherNotAddedYet(row.id) && <TableCell style={{ fontStyle: "italic" }} align="right">ADDED</TableCell>}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
    </Paper>
                
                {rows.length == 0 && <Typography sx={{ textAlign: "center", margin: "30px" }}>No record found</Typography>}
            </Box>


        }

        {isFetchingCouseTeachers && <CircularProgress style={{ textAlign: "center", margin: 20 }} />}
    </Box>

    );
};


export default DashboardManageCourse;

const styles = {
    container: {
        width: "95%",
        margin: "0 auto",
        background: "#f7f7fb",
        borderRadius: "10px",
        marginTop: "10px",
        border: "1px solid #EFEFEF",
        padding: "20px",
    },
    select: {
        textDecoration: "underline",
        cursor: "pointer"
    },
    tableResponsive: {
        overflowX: "auto",
        marginRight: "auto",
        marginLeft: "auto",
        maxWidth:"90vw",
      }
};