

import React, { useState, useRef, Fragment } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import "../../../index.css"
import { Box, Grid, Typography } from '@mui/material';
import AppButton from '../../buttons/AppButton';
import { useNavigate } from 'react-router-dom';
import HerosectionLines from "../../../assets/svg/Hero/herosection-lines.svg";
import HeroSectionSparks from "../../../assets/svg/Hero/herosection-three-sparks.svg";
import HeroSectionBooks from "../../../assets/svg/Hero/herosection-books.svg";
import Manwithlaptop from "../../../assets/svg/Hero/man-with-laptop.svg";
import Manseatedwithlaptop from "../../../assets/svg/Hero/man-seated-using-laptop.svg";
import AiWithComputer from "../../../assets/svg/Hero/ai-using-computer.svg";
import PinkSplat from "../../../assets/svg/Hero/pink-splat.svg";
import ArrowIndicator from "../../../assets/svg/Hero/arrow-indicator.svg";



export default function CustomCarousel() {
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: false, // Disable default dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: (currentSlide) => {
      // This function will be called after each slide change
      console.log(currentSlide, currentIndex)
      setCurrentIndex(currentSlide);
    },
  };

  const goToSlide = (slideIndex) => {
    console.log(slideIndex)
    sliderRef.current.slickGoTo(slideIndex);
  };

  const Slide1 = () => {
    return (
      <Box component="div" height={{ xs: "600px", lg: "800px" }} position="relative" m={{xs: "10px",  lg: "10px 35px" }} p={{ lg: "50px 90px", }} bgcolor="rgba(143, 214, 246, 1)" borderRadius="20px" display="flex" flexDirection="column" alignItems="start" >
        <Grid container alignItems={{ lg: "center" }} my="auto">
          <Grid item xs={12} lg={7}>
            <Box
              maxWidth={{ xs: "600px", md: "700px"}}
              mx={{xs: "auto", lg: "0px"}}
              sx={{
                padding: 2,
                display: 'flex',
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" fontSize={{ xs: "23px", md: "35px", lg: "45px" }} letterSpacing="-3%" lineHeight={{xs: "36px", md: "55px", lg: "70px"}} fontWeight={600} className='font-sora'>Save planning time by <Box component="img" width={{ xs: "50px", lg: "70px"}} style={{ display: "inline" }}  src={HeroSectionSparks} alt="sparks" /> <br /> exploring our resources made available by Teachers</Typography>

              <img src={HerosectionLines} style={{ padding: "12px 0px", maxWidth: "450px" }} alt="-----divider lines svg ------" />

              <Typography className='font-open-sans' fontSize={{ xs: "16px", md: "20px"}} variant="p">An online platform where you can earn as a teacher by uploading your resources and where you can also buy resources</Typography>


              <Box display="flex" mt="10px" gap={2}>
                <AppButton
                  customStyles={{
                    fontWeight: "600",
                    padding: "7px 30px",
                    height: "50px",
                    borderRadius: "18px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    marginTop: "30px"
                  }}
                  variant="outlined"
                  btnText="Get Resources"
                  onClick={() => {
                    navigate("/marketplace");
                  }}
                  fullWidth={false}
                  sx
                />

                <Box component="img" width={{ xs: "40px", lg:"55px"}} src={ArrowIndicator} alt="arrow indicator"  />
              </Box>
              
            </Box>
          </Grid>
          <Grid display={{ xs: "none", lg: "block"}} item xs={12} lg={5}>
            <Box
              sx={{
                padding: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={HeroSectionBooks} alt="Man pushing a cart of books" />
            </Box>
          </Grid>
        </Grid>
        <Box visibility={{ xs: "hidden", lg: "visible" }} component="img" src={PinkSplat} alt="pink splat" style={{position: "absolute", bottom: "10px", right: "25px"}} />
        <Box visibility={{ xs: "visible", lg: "hidden" }} component="img" src={HeroSectionBooks} alt="Man pushing a cart of books" style={{position: "absolute", bottom: "10px", right: "25px"}} width={{ xs: "180px", sm: "200px", md: "300px"}} />        

        <Box component="div" padding={2} gap={0.8} className="custom-dots" mb={{xs: "auto", lg: "0px"}} width="100%" maxWidth={{ xs: "600px", md: "700px", lg: "900px"}} display="flex" justifyContent="start" mx={{ xs: "auto", lg: "0px"}}>
          {slides.map((_, index) => (
            <button
              key={index}
              className={`${currentIndex === index ? 'activeIndicator' : 'indicator'}`}
              onClick={() => {
                goToSlide(index)
              }}
            >
            </button>
          ))}
        </Box>
      </Box>
    )
  }

  const Slide2 = () => {
    return (
      <Box component="div" height={{ xs: "600px", lg: "800px" }} position="relative" m={{xs: "10px", lg: "10px 35px" }} p={{ lg: "50px 90px", }} bgcolor="rgba(143, 176, 246, 1)" borderRadius="20px" display="flex" flexDirection="column" alignItems="start" >
        <Grid container alignItems={{ lg: "center" }} my="auto">
          <Grid item xs={12} lg={7}>
            <Box
              maxWidth={{ xs: "600px", md: "700px"}}
              mx={{xs: "auto", lg: "0px"}}
              sx={{
                padding: 2,
                display: 'flex',
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" fontSize={{ xs: "23px", md: "35px", lg: "45px" }} letterSpacing="-3%" lineHeight={{xs: "36px", md: "55px", lg: "70px"}} fontWeight={600} className='font-sora'>Safeticha is the best <Box component="img" width={{ xs: "50px", lg: "70px"}} style={{ display: "inline" }}  src={HeroSectionSparks} alt="sparks" /> <br /> platform to easily source for jobs as a Teacher</Typography>

              <img src={HerosectionLines} style={{ padding: "12px 0px", maxWidth: "450px" }} alt="-----divider lines svg ------" />

              <Typography className='font-open-sans' fontSize={{ xs: "16px", md: "20px"}} variant="p">An online platform where you can earn as a teacher by uploading your resources and where you can also buy resources</Typography>


              <Box display="flex" mt="10px" gap={2}>
                <AppButton
                  customStyles={{
                    fontWeight: "600",
                    padding: "7px 30px",
                    height: "50px",
                    borderRadius: "18px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    marginTop: "30px"
                  }}
                  variant="outlined"
                  btnText="Find Jobs"
                  onClick={() => {
                    navigate("/jobs");
                  }}
                  fullWidth={false}
                  sx
                />

                <Box component="img" width={{ xs: "40px", lg:"55px"}} src={ArrowIndicator} alt="arrow indicator"  />
              </Box>
              
            </Box>
          </Grid>
          <Grid display={{ xs: "none", lg: "block"}} item xs={12} lg={5}>
            <Box
              sx={{
                padding: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: "relative",
                bottom: "-40px"
              }}
            >
              <img src={Manwithlaptop} alt="Man standing with a laptop" />
            </Box>
          </Grid>
        </Grid>
        <Box visibility={{ xs: "hidden", lg: "visible" }} component="img" src={PinkSplat} alt="pink splat" style={{position: "absolute", bottom: "10px", right: "25px"}} />
        <Box visibility={{ xs: "visible", lg: "hidden" }} component="img" src={Manwithlaptop} alt="Man standing with a laptop" style={{position: "absolute", bottom: "0px", right: "5px"}} width={{ xs: "210px", sm: "300px"}} />
        
        <Box component="div" padding={2} gap={0.8} className="custom-dots" mb={{xs: "auto", lg: "0px"}} width="100%" maxWidth={{ xs: "600px", md: "700px", lg: "900px"}} display="flex" justifyContent="start" mx={{ xs: "auto", lg: "0px"}} position="relative" top={{lg: "-45px"}}>
          {slides.map((_, index) => (
            <button
              key={index}
              className={`${currentIndex === index ? 'activeIndicator' : 'indicator'}`}
              onClick={() => {
                goToSlide(index)
              }}
            >
            </button>
          ))}
        </Box>
      </Box>
    )
  }


  const Slide3 = () => {
    return (
      <Box component="div" height={{ xs: "600px", lg: "800px" }} position="relative" m={{xs: "10px", lg: "10px 35px" }} p={{ lg: "50px 90px", }} bgcolor="rgba(184, 143, 246, 1)" borderRadius="20px" display="flex" flexDirection="column" alignItems="start" >
        <Grid container alignItems={{ lg: "center" }} my="auto">
          <Grid item xs={12} lg={7}>
            <Box
              maxWidth={{ xs: "600px", md: "700px"}}
              mx={{xs: "auto", lg: "0px"}}
              sx={{
                padding: 2,
                display: 'flex',
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" fontSize={{ xs: "20px", md: "35px", lg: "45px" }} letterSpacing="-3%" lineHeight={{xs: "36px", md: "55px", lg: "70px"}} fontWeight={600} className='font-sora'>Hiring teachers has been <Box component="img" width={{ xs: "50px", lg: "70px"}} style={{ display: "inline" }}  src={HeroSectionSparks} alt="sparks" /> <br /> made easy for schools. All you need to do is enroll with us</Typography>

              <img src={HerosectionLines} style={{ padding: "12px 0px", maxWidth: "450px" }} alt="-----divider lines svg ------" />

              <Typography className='font-open-sans' fontSize={{ xs: "16px", md: "20px"}} variant="p">An online platform where you can earn as a teacher by uploading your resources and where you can also buy resources</Typography>


              <Box display="flex" mt="10px" gap={2}>
                <AppButton
                  customStyles={{
                    fontWeight: "600",
                    padding: "7px 30px",
                    height: "50px",
                    borderRadius: "18px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    marginTop: "30px"
                  }}
                  variant="outlined"
                  btnText="Find Teachers"
                  onClick={() => {
                    navigate("/login");
                  }}
                  fullWidth={false}
                  sx
                />

                <Box component="img" width={{ xs: "40px", lg:"55px"}} src={ArrowIndicator} alt="arrow indicator"  />
              </Box>
              
            </Box>
          </Grid>
          <Grid display={{ xs: "none", lg: "block"}} item xs={12} lg={5}>
            <Box
              sx={{
                padding: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={Manseatedwithlaptop} alt="Man seated using a laptop" />
            </Box>
          </Grid>
        </Grid>
        <Box visibility={{ xs: "hidden", lg: "visible" }} component="img" src={PinkSplat} alt="pink splat" style={{position: "absolute", bottom: "10px", right: "25px"}} />
        <Box visibility={{ xs: "visible", lg: "hidden" }} component="img" src={Manseatedwithlaptop} alt="Man seated using a laptop" style={{position: "absolute", bottom: "0px", right: "25px"}} width={{ xs: "180px", sm: "250px"}} />
        

        <Box component="div" padding={2} gap={0.8} className="custom-dots" mb={{xs: "auto", lg: "0px"}} width="100%" maxWidth={{ xs: "600px", md: "700px", lg: "900px"}} display="flex" justifyContent="start" mx={{ xs: "auto", lg: "0px"}}>
          {slides.map((_, index) => (
            <button
              key={index}
              className={`${currentIndex === index ? 'activeIndicator' : 'indicator'}`}
              onClick={() => {
                goToSlide(index)
              }}
            >
            </button>
          ))}
        </Box>
      </Box>
    )
  }

  const Slide4 = () => {
    return (
      <Box component="div" height={{ xs: "600px", lg: "800px" }} position="relative" m={{ xs: "10px", lg: "10px 35px" }} p={{ lg: "50px 90px", }} bgcolor="rgba(246, 143, 220, 1)" borderRadius="20px" display="flex" flexDirection="column" alignItems="start" >
        <Grid container alignItems={{ lg: "center" }} my="auto">
          <Grid item xs={12} lg={7}>
            <Box
              maxWidth={{ xs: "600px", md: "700px"}}
              mx={{xs: "auto", lg: "0px"}}
              sx={{
                padding: 2,
                display: 'flex',
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" fontSize={{ xs: "20px", md: "35px", lg: "45px" }} letterSpacing="-3%" lineHeight={{xs: "36px", md: "55px", lg: "70px"}} fontWeight={600} className='font-sora'>Ticha&apos;s Ai helps Teachers <Box component="img" width={{ xs: "50px", lg: "70px"}} style={{ display: "inline" }}  src={HeroSectionSparks} alt="sparks" /> <br /> on our Platform with the stress of creating Resources</Typography>

              <img src={HerosectionLines} style={{ padding: "12px 0px", maxWidth: "450px" }} alt="-----divider lines svg ------" />

              <Typography className='font-open-sans' fontSize={{ xs: "16px", md: "20px"}} variant="p">An online platform where you can earn as a teacher by uploading your resources and where you can also buy resources</Typography>


              <Box display="flex" mt="10px" gap={2}>
                <AppButton
                  customStyles={{
                    fontWeight: "600",
                    padding: "7px 30px",
                    height: "50px",
                    borderRadius: "18px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    marginTop: "30px"
                  }}
                  variant="outlined"
                  btnText="Learn More"
                  onClick={() => {
                    navigate("/login");
                  }}
                  fullWidth={false}
                  sx
                />

                <Box component="img" width={{ xs: "40px", lg:"55px"}} src={ArrowIndicator} alt="arrow indicator"  />
              </Box>
              
            </Box>
          </Grid>
          <Grid display={{ xs: "none", lg: "block"}} item xs={12} lg={5}>
            <Box
              sx={{
                padding: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={AiWithComputer} alt="A robot is standing out from a computer screen" />
            </Box>
          </Grid>
        </Grid>
        <Box visibility={{ xs: "hidden", lg: "visible" }} component="img" src={PinkSplat} alt="pink splat" style={{position: "absolute", bottom: "10px", right: "25px"}} />
        <Box visibility={{ xs: "visible", lg: "hidden" }} component="img" src={AiWithComputer} alt="A robot is standing out from a computer screen" style={{position: "absolute", bottom: "0px", right: "25px"}} width={{ xs: "180px", sm: "250px"}} />
        

        <Box component="div" padding={2} gap={0.8} className="custom-dots" mb={{xs: "auto", lg: "0px"}} width="100%" maxWidth={{ xs: "600px", md: "700px", lg: "900px"}} display="flex" justifyContent="start" mx={{ xs: "auto", lg: "0px"}} position="relative" top={{lg: "-15px"}}>
          {slides.map((_, index) => (
            <button
              key={index}
              className={`${currentIndex === index ? 'activeIndicator' : 'indicator'}`}
              onClick={() => {
                goToSlide(index)
              }}
            >
            </button>
          ))}
        </Box>
      </Box>
    )
  }

  

  const slides = [
    <Slide1 />,
    <Slide2 />,
    <Slide3 />,
    <Slide4 />
  ];


  return (
    <div>
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => <Fragment key={index}>{slide}</Fragment>)}
      </Slider>
    </div>
      
  );
}

