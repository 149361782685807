import React from "react";
import LandingPage from "../../components/landing-page/LandingPage";

 const Home = () => {
  return (
    <>
      <LandingPage />
    </>
  );
};

export default Home;