import React from "react";
import { APP_COLOURS } from "../../utils/constant";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { getUserData } from "../../utils/auth/authFunctions";
import JobIcon from "../../assets/svg/dashboard-icons/JobIcon";
import { AssignmentInd, Cloud, Home, LocalLibrary, People, Store, Work } from "@mui/icons-material";

const NavLists = [
  {
    id: 0,
    navText: "Dashboard",
    icon: <Home color="#9DA6BA" />,
    link: "/dashboard",
  },
  {
    id: 1,
    navText: "Teachers",
    icon: <People />,
    link: "/teachers",
  },
  {
    id: 2,
    navText: "Courses",
    icon: <LocalLibrary />,
    link: "/courses",
  },
  {
    id: 3,
    navText: "Jobs",
    icon:  <Work /> ,
    link: "/jobs",
  },
  {
    id: 4,
    navText: "My Store",
    icon:  <Store /> ,
    link: "/resources",
  },
  {
    id: 5,
    navText: "Resources",
    icon:  <Cloud /> ,
    link: "/resource/marketplace",
  },
  {
    
    id: 6,
    navText: "Profile",
    icon: <AssignmentInd />,
    link: "/profile",
  },
  // {
  //   id: 4,
  //   navText: "Help",
  //   icon: <HelpIcon />,
  //   link: "/help",
  // },
  // {
  //   id: 5,
  //   navText: "Messages",
  //   icon: <MessagesIcon />,
  //   link: "/messages",
  // },
];



const DashboardNavList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const category = useSelector((state) => state.authReducer.category);
  const userData = getUserData();

  const handleListItemClick = (event, link) => {
    navigate(link);
  };
  const selected = (list) =>{
    if(location.pathname == "/dashboard" && list.link== "/dashboard"){
      return true
    }else if(location.pathname !== "/dashboard" && list.link != "/dashboard"){
      return location.pathname.includes(list.link)
    }
    return false;
   }

  return (
    <List component="nav">
      {NavLists.map((list) => {
        if (userData.userType !== "school") {
          if (list.navText === "Teachers") {
            return "";
          }
        }

        return (
          <ListItemButton
            key={list.id}
            selected={selected(list)}
            onClick={(event) => handleListItemClick(event, list.link)}
            sx={styles.navButton}
          >
            <ListItemIcon sx={styles.navIcon}>{list.icon}</ListItemIcon>
            <ListItemText primary={list.navText} />
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default DashboardNavList;

const styles = {
  navButton: {
    color: APP_COLOURS.grey,
    ".MuiSvgIcon-root": {
      color: APP_COLOURS.grey,
    },
    "&.Mui-selected": {
      backgroundColor: "primary.main",
      color: "white",
      borderRadius: "10px",

      ".MuiSvgIcon-root": {
        color: "white",
      },
    },
    "&.Mui-selected:hover": {
      backgroundColor: "primary.main",
    },
    "&:hover": {
      backgroundColor: "none",
    },
  },

  navIcon: {
    minWidth: 0,
    marginRight: "10px",
  },
};
