import React from "react";
import ForgotPasswordForm from "../../components/onboarding/Login/ForgotPasswordForm";
import OnboardingLayout from "../../components/onboarding/OnboardingLayout";
import ForgotPasswordImage from "../../assets/img/ForgotPasswordImage.png";
import Dummy from "../../components/dummy";

const ForgotPassword = () => {
  return (
    <>
    {/* <Dummy /> */}
    <OnboardingLayout image={ForgotPasswordImage}>
      <ForgotPasswordForm />
    </OnboardingLayout>
    </>
    
  );
};

export default ForgotPassword;
