

import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import "../../../index.css";
import ResourcesIcon from "../../../assets/svg/our-goals/resources-icon.svg";
import FindJobsIcon from "../../../assets/svg/our-goals/find-jobs-icon.svg";
import FindTeachersIcon from "../../../assets/svg/our-goals/teacher-icon.svg";
import AiIcon from "../../../assets/svg/our-goals/ai-icon.svg";


const OurGoal = () => {
  return (
    <Box padding={{xs: "30px 0px", md: "50px 0px" }} width="100%" display="flex" flexDirection="column" gap={2} alignItems="center">

      <Typography variant="h4" className="font-sora" color="rgba(255, 11, 117, 1)" fontSize={{ xs: "14px", md: "18px"}} align="center" fontWeight={400}>
        OUR GOAL
      </Typography>

      <Typography variant="h5" maxWidth={{xs: "700px", lg: "700px"}} className="font-sora" color="#000000" fontSize={{ xs: "25px", md: "35px", lg: "50px"}} lineHeight={{md: "40px", lg: "60px"}} align="center" fontWeight={600} px="20px">
        Strengthening Africa’s educational system
      </Typography>

      <Typography variant="p" maxWidth="800px" className="font-open-sans" color="rgba(75, 78, 97, 1)" fontSize={{ xs: "14px", md: "18px"}} align="center" fontWeight={400} px="20px">
        Resolving the critical challenges faced by Africa's teacher training and recruitment systems.
      </Typography>


      <Grid container padding={{sm: "0px 70px"}} spacing={2} marginTop={{ xs: "15px", md: "40px"}}>
        <Grid item xs={12} sm={6} md={3} display="flex" >
          <Box component="div" border="1px solid rgba(171, 200, 210, 1)" maxWidth="330px" margin="auto" padding="25px" borderRadius="20px">
            <Box component="img" src={ResourcesIcon} />
            <Typography fontSize={{xs: "18px", sm: "20px"}} fontWeight={700} mt={2} className="font-sora" color="rgba(16, 10, 55, 1)">Resources</Typography>

            <Typography my={2} className="font-open-sans" fontSize="14px" color="rgba(75, 78, 97, 1)">Resources are available on safeticha for everyone’s use. you can purchase them immediately.</Typography>
            <Box component="a" href="/resource-more" aria-disabled={true} fontWeight={700} color="rgba(46, 49, 146, 1)" style={{textDecoration: "none"}} >Read More</Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3} display="flex">
          <Box component="div" border="1px solid rgba(171, 200, 210, 1)" maxWidth="330px" margin="auto" padding="25px" borderRadius="20px">
            <Box component="img" src={FindJobsIcon} />
            <Typography fontSize={{xs: "16px", sm: "20px"}} fontWeight={700} mt={2} className="font-sora" color="rgba(16, 10, 55, 1)">Finding Jobs</Typography>

            <Typography my={2} className="font-open-sans" fontSize="14px" color="rgba(75, 78, 97, 1)">You can easily apply for jobs on Safeticha’s platform by browsing the jobs available and then applying</Typography>
            <Box component="a" href="/resource-more" aria-disabled={true} fontWeight={700} color="rgba(46, 49, 146, 1)" style={{textDecoration: "none"}}>Read More</Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3} display="flex">
          <Box component="div" border="1px solid rgba(171, 200, 210, 1)" maxWidth="330px" margin="auto" padding="25px" borderRadius="20px">
            <Box component="img" src={FindTeachersIcon} />
            <Typography fontSize={{xs: "16px", sm: "20px"}} fontWeight={700} mt={2} className="font-sora" color="rgba(16, 10, 55, 1)">Finding Teachers</Typography>

            <Typography my={2} className="font-open-sans" fontSize="14px" color="rgba(75, 78, 97, 1)">Recruiting managers can now find professionally trained educators with just the click of a button</Typography>
            <Box component="a" href="/resource-more" aria-disabled={true} fontWeight={700} color="rgba(46, 49, 146, 1)" style={{textDecoration: "none"}}>Read More</Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={3} display="flex">
          <Box component="div" border="1px solid rgba(171, 200, 210, 1)" maxWidth="330px" margin="auto" padding="25px" borderRadius="20px">
            <Box component="img" src={AiIcon} />
            <Typography fontSize={{xs: "16px", sm: "20px"}} fontWeight={700} mt={2} className="font-sora" color="rgba(16, 10, 55, 1)">AI assistant</Typography>

            <Typography my={2} className="font-open-sans" fontSize="14px" color="rgba(75, 78, 97, 1)">Our Ai assistant can help teachers create resources by just supplying few details </Typography>
            <Box component="a" href="/resource-more" aria-disabled={true} fontWeight={700} color="rgba(46, 49, 146, 1)" style={{textDecoration: "none"}}>Read More</Box>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};

export default OurGoal;

