import React, { useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import AppButton from "../../../buttons/AppButton";
import AppInput from "../../../inputs/AppInput";
import AddedTeacher from "./AddedTeacher";
import Modal from "../../../modal/Modal";
import { textIsEmail } from "../../../../utils/stringFunctions";
import { showSimpleToast } from "../../../../utils/alertFunctions";
import { useDispatch, useSelector } from "react-redux";
import { inviteTeacherToSchool } from "../../../../redux/school/action";

const AddTeacher = ({ onShowTeachers }) => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const { isLoading} = useSelector(state => state.schoolReducer)

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const setEmailTyped = (e) => {
    setEmail(e.target.value);
  }

  const onInviteTeacher = () => {
    if(textIsEmail(email)){
      dispatch(inviteTeacherToSchool({data: {email}, callBack: handleModal}))
    }else{
      showSimpleToast("Please provide a valid email", "failed")
    }
  }

  return (
    <>
      {showModal && (
        <Modal showModal={showModal} handleModal={handleModal} disableOutsideClick >
          <AddedTeacher
            handleModal={handleModal}
            onShowTeachers={onShowTeachers}
          />
        </Modal>
      )}

      <Box
        m="10px auto 0"
        width="95%"
        backgroundColor="white"
        borderRadius="12px"
        sx={{padding:{xs:"20px", md: "40px", lg:"100px"}}}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        

        <Box sx={{width: {xs: "100%", md: "60%", lg:"60%"}}}>
        <Typography mb="13px" textAlign="center" fontWeight="700" fontSize="25px">
          Invite Teacher
        </Typography>

        <Typography mb="30px" textAlign="center"  fontSize="18px">
          Provide the email of the teacher you want to add so they can be invited. They will have to accept your invite.
        </Typography>
          {/* <AppInput
            name="teacherName"
            label="Name of Teacher"
            placeholder="Enter the name of your teacher"
          /> */}
          <AppInput
          onChange={setEmailTyped}
          value={email}
            name="teacherEmail"
            label="Email Address"
            placeholder="Teacher's email"
          />
          {/* <AppInput
            name="teacherSpecialization"
            label="Specialization"
            placeholder="Enter your teacher's specialization"
          /> */}
        </Box>
{isLoading && <CircularProgress sx={{textAlign:"center"}} /> }
        {!isLoading && <Box sx={{width: {xs: "100%", md: "60%", lg:"60%"}}}>
          <AppButton
            customStyles={{
              fontWeight: "bold",
              borderRadius: "8px",
            }}
            btnText="Add Teacher"
            onClick={onInviteTeacher}
          />
            <AppButton
            customStyles={{
              fontWeight: "bold",
              borderRadius: "8px",
              marginTop:"20px"
            }}
            variant="outlined"
            btnText="Go Back"
            onClick={onShowTeachers}
          />
        </Box>}
      </Box>
    </>
  );
};

export default AddTeacher;
