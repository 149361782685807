// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.glyjoQBKbSvtptyueW0p{
    display: flex;
}

.DpdLAfxmhWGJa0FOu_U4{
    width: 20px;
    height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/landing-page/Resources/Rating/Rating.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["\n.rating{\n    display: flex;\n}\n\n.rating_icon{\n    width: 20px;\n    height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rating": `glyjoQBKbSvtptyueW0p`,
	"rating_icon": `DpdLAfxmhWGJa0FOu_U4`
};
export default ___CSS_LOADER_EXPORT___;
