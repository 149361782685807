import { Cancel } from '@mui/icons-material';
import { Box, Button, Modal } from '@mui/material'
import React from 'react'
import useWindowDimensions from '../../utils/windowsDimension';



export default function ViewFileModal({ showDocument, onClose, file }) {
    const { height } = useWindowDimensions();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { md: "60%", xs: "95%" },
        bgcolor: "background.paper",
        border: "1px solid #ececec",
        boxShadow: 24,
        p: 1,
        minHeight: `${height * 0.85}px`
    };

    return (
        <Modal
            open={showDocument}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{ mb: 2 }}
                    startIcon={<Cancel />}
                >
                    Close
                </Button>
                {file?.length && (
                    <div>
                        <iframe title='File Viewer'
                            src={file}
                            width="100%"
                            height={height * 0.8}
                        />
                    </div>
                )}
            </Box>
        </Modal>
    )
}
