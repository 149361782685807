import React from "react";
import { Box, Typography } from "@mui/material";
import OpenZeppelin from "../../../assets/img/PartnersLogos/OpenZeppelin.png";
import Oracle from "../../../assets/img/PartnersLogos/Oracle.png";
import ShareBanta from "../../../assets/img/PartnersLogos/sharebanta.png";
import Samsung from "../../../assets/img/PartnersLogos/Samsung.png";
import MDSchool from "../../../assets/img/PartnersLogos/mdschool.jpeg";
import PineField from "../../../assets/img/PartnersLogos/pfield.jpg";
import Vilac from "../../../assets/img/PartnersLogos/vilac.png";
import "../../../index.css"

const PartnersLogo = [
  {
    id: 1,
    logo: MDSchool,
  },
  {
    id: 2,
    logo: PineField,
  },
  {
    id: 3,
    logo: Vilac,
  },
  {
    id: 4,
    logo: ShareBanta,
  },
];

const Partners = () => {
  return (
    <Box padding={{xs: "30px 0px", md: "60px 0px"}} width="100%">
      <Typography variant="h5" align="center" fontWeight="bold" color="rgba(170, 170, 170, 1)">
        Our world class partners:
      </Typography>

      <Box
        width="90%"
        maxWidth="1600px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin="30px auto 0"
        overflow="auto"
        gap="30px"
        sx={styles.noScrollbar}
      >
        {PartnersLogo.map((partner) => {
          return (
            <img
              key={partner.id}
              src={partner.logo}
              alt="partner"
              style={{ display: "inline-block", width: "100px" }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Partners;

const styles = {
  noScrollbar: {
    "&::-webkit-scrollbar": {
      width: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: "30px",
      // border: "1px solid white",
    },
    width: { md: "65%", xs: "90%" },
  },
};
