import React, { useEffect } from "react";
import SchoolDashboard from "../../components/dashboard/School/SchoolDashboard/SchoolDashboard";
import TeacherDashboard from "../../components/dashboard/Teacher/TeacherDashboard/TeacherDashboard";
import { getUserData } from "../../utils/auth/authFunctions";

import { useDispatch } from "react-redux";
import { getProfile } from "../../redux/dashboard/actions";

const DashboardHome = () => {
  const userData = getUserData();
console.log('userData------', userData)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {userData.userType === "school" && <SchoolDashboard />}
      {userData.userType === "teacher" && <TeacherDashboard />}
    </>
  );
};

export default DashboardHome;
