import { Box, Button, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppButton from "../../components/buttons/AppButton";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getJobDetails, updateJobDetails } from "../../redux/jobs/action";
import { formatAsCurrency } from "../../utils/courses/coursesFunctions";
import SchoolJobDetails from "../../components/dashboard/Jobs/SchoolJobDetails";
import TeacherJobDetails from "../../components/dashboard/Jobs/TeacherJobDetails";
import PageContainer from "../../container/PageContainer";

const JobDetails = () => {
    const [value, setValue] = useState(0);
    const { jobId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { jobDetails, isFetching, isUpdatingJob } = useSelector(state => state.jobReducer)
    const { userData } = useSelector(state => state.authReducer)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(getJobDetails({ jobId }))
    }, [jobId])

  

 


    return (
        <>
            <PageContainer>
                    <Button onClick={() => navigate(-1)}> Go Back</Button>
{userData.userType == "school" && <SchoolJobDetails jobDetails={jobDetails} /> } 
{userData.userType == "teacher" && <TeacherJobDetails jobDetails={jobDetails} /> } 
                   
            </PageContainer>
        </>
    );
};

export default JobDetails;
