

import * as React from 'react';
import { Grid, Card, Skeleton } from '@mui/material';

export default function ResourcesSkeleton() {
  const placeholders = Array.from({ length: 12 }); // Render 12 placeholders
  return (
    <Grid container spacing={2}>
      {placeholders.map((_, index) => (
        <Grid item xs={6} md={4} lg={3} xl={2} style={{ marginBottom: "20px" }}>
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="rectangular" height={200} sx={{ borderRadius: "20px" }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ marginBottom: "25px" }} />

          <div style={{ display: "flex", alignItems: "center" }}>
            <Skeleton variant="text" width={50} sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} style={{ marginLeft: "auto" }} />
          </div>
        </Grid>
      ))}
    </Grid>


  );
}
