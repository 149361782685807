import React from "react";
import { Box, Typography, Container } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import AppButton from "../../buttons/AppButton";
import HeroImage from "../../../assets/img/HeroSectionImage.png";
import TopRightBg from "../../../assets/svg/Hero/TopRightBg.svg";
import BottomLeftBg from "../../../assets/svg/Hero/BottomLeftBg.svg";
import BottomRightBg from "../../../assets/svg/Hero/BottomRightBg.svg";

import classes from "./Hero.module.css";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="false" disableGutters sx={styles.container}>
      <Navbar />

      <Box
        display={{ xs: "none", md: "block" }}
        position="absolute"
        right="0"
        top="20%"
      >
        <img src={TopRightBg} alt="bg" />
      </Box>

      <Box
        display={{ xs: "none", md: "block" }}
        position="absolute"
        right="0"
        bottom="-15%"
      >
        <img src={BottomRightBg} alt="bg" />
      </Box>

      <Box
        display={{ xs: "none", md: "block" }}
        position="absolute"
        left="0"
        bottom="15%"
      >
        <img src={BottomLeftBg} alt="bg" />
      </Box>

      <Box
        maxWidth="1600px"
        margin="0 auto"
        component="main"
        marginTop="30px"
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box
          width={{ xs: "100%", md: "50%" }}
          display="flex"
          flexDirection="column"
          gap="20px"
          alignItems={{ xs: "center", md: "flex-start" }}
          position={{ xs: "absolute", md: "relative" }}
          zIndex={{ xs: "50", md: "0" }}
          top={{ xs: "28vh", md: "5vh" }}
        >
          <Typography
            width={{ xs: "95%", md: "100%" }}
            textAlign={{ xs: "center", md: "start" }}
            component="h2"
            fontSize={{ xs: "28px", md: "57px" }}
            fontWeight="600"
            marginTop="30px"
            lineHeight="1.3"
            zIndex="1"
          >
            Be a part of Africa's largest professional community for educators
            and schools
          </Typography>

          <Typography
            width={{ xs: "95%", md: "80%" }}
            fontSize={{ xs: "18px", md: "20px" }}
            textAlign={{ xs: "center", md: "start" }}
          >
            An online platform to help schools safely recruit and professionally
            develop educators .
          </Typography>
          {/* <AppButton btnText="Hire an Educator" /> */}
          <Box
          display="flex"
            flexDirection={{xs: "column", md: "row"}}
            gap="25px"
            sx={styles.heroBtns}
          >
            <AppButton
              customStyles={{ fontWeight: "600", fontSize: {xs:"16px", md:"20px"}, borderRadius: "20px"  }}
              fullWidth={false}
              btnText="Hire an Educator"  onClick={()=> navigate("/jobs?usertype=school")}         
            />
            <AppButton
              customStyles={{ fontWeight: "600" , fontSize: {xs:"16px", md:"20px"}, borderRadius: "20px" }}
              fullWidth={false}
              btnText="Get a Job" onClick={()=> navigate("/jobs?usertype=teacher")}
            />
          </Box>
        </Box>

        <img src={HeroImage} alt="hero img" className={classes.heroImage} />
      </Box>
    </Container>
  );
};

export default Hero;

const styles = {
  container: {
    position: "relative",
    overflow: "hidden",
    background: {
      sm: "#ffffff",
      md: "linear-gradient(292.12deg, #D5D6E9 -35.02%, #EAEAF4 78.96%)",
    },
    padding: { xs: "0", md: "0 70px 170px" },
  },
  heroBtns: {
    marginTop: "20px"
  }
};
