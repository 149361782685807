import * as React from "react";

const CartIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.72575 0.87868L3.43286 5.17157H1.00019C0.391639 5.17157 -0.0758235 5.71056 0.0102384 6.31299L1.47922 16.5958C1.69035 18.0738 2.95611 19.1716 4.44907 19.1716H15.2453C16.7382 19.1716 18.004 18.0738 18.2151 16.5958L19.6841 6.31299C19.7702 5.71056 19.3027 5.17157 18.6942 5.17157H16.2613L11.9684 0.878679C10.7968 -0.292894 8.89733 -0.292892 7.72575 0.87868ZM10.5542 2.29289C10.1637 1.90237 9.53049 1.90237 9.13997 2.29289L6.26129 5.17157L13.4329 5.17157L10.5542 2.29289ZM6.84717 10.1716C6.84717 9.61928 6.39945 9.17157 5.84717 9.17157C5.29488 9.17157 4.84717 9.61928 4.84717 10.1716V14.1716C4.84717 14.7239 5.29488 15.1716 5.84717 15.1716C6.39945 15.1716 6.84717 14.7239 6.84717 14.1716V10.1716ZM9.84717 9.17157C10.3995 9.17157 10.8472 9.61928 10.8472 10.1716V14.1716C10.8472 14.7239 10.3995 15.1716 9.84717 15.1716C9.29488 15.1716 8.84717 14.7239 8.84717 14.1716V10.1716C8.84717 9.61928 9.29488 9.17157 9.84717 9.17157ZM14.8472 10.1716C14.8472 9.61928 14.3995 9.17157 13.8472 9.17157C13.2949 9.17157 12.8472 9.61928 12.8472 10.1716V14.1716C12.8472 14.7239 13.2949 15.1716 13.8472 15.1716C14.3995 15.1716 14.8472 14.7239 14.8472 14.1716V10.1716Z"
      fill="#9DA6BA"
    />
  </svg>
);

export default CartIcon;
