// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Gi9uhT1ry4ooP7UkSBCu{position:fixed;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,.7);display:flex;justify-content:center;align-items:center;backdrop-filter:blur(5px);z-index:1000}.B0g0vphBdiu_dqcPrw8m{background:#fff;border-radius:10px;width:80%;max-width:600px;padding:20px;max-height:80vh;overflow-y:auto;box-shadow:0 4px 8px rgba(0,0,0,.1);position:relative}.JGvJd44EBQq9vKD7lokR{position:absolute;top:15px;right:15px;background:none;border:none;font-size:24px;cursor:pointer}.YF7M3iLkKN_hwyUX2P0e{margin-top:20px;line-height:1.6;color:#333}.YF7M3iLkKN_hwyUX2P0e h2,.YF7M3iLkKN_hwyUX2P0e h3{color:#0056b3;margin-bottom:10px}.YF7M3iLkKN_hwyUX2P0e p{margin-bottom:15px}.YF7M3iLkKN_hwyUX2P0e ul li{list-style-type:disc !important;margin-left:20px}.gRgOKO3g9ijyqnN0JvtM{display:flex;justify-content:center;padding:20px 0}.eixNKMBqqe8NnmXYYz_B{background-color:#0056b3;color:#fff;border:none;padding:10px 20px;font-size:16px;border-radius:5px;cursor:pointer;transition:background-color .3s ease}.eixNKMBqqe8NnmXYYz_B:hover{background-color:#003e82}`, "",{"version":3,"sources":["webpack://./src/components/modal/TermsAndCondition/TermsModal.module.scss"],"names":[],"mappings":"AACA,sBACI,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,yBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,yBAAA,CACA,YAAA,CAIF,sBACE,eAAA,CACA,kBAAA,CACA,SAAA,CACA,eAAA,CACA,YAAA,CACA,eAAA,CACA,eAAA,CACA,mCAAA,CACA,iBAAA,CAIF,sBACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,eAAA,CACA,WAAA,CACA,cAAA,CACA,cAAA,CAIF,sBACE,eAAA,CACA,eAAA,CACA,UAAA,CAEA,kDACI,aAAA,CACA,kBAAA,CAGF,wBACE,kBAAA,CAGF,4BACE,+BAAA,CACA,gBAAA,CAKR,sBACI,YAAA,CACA,sBAAA,CACA,cAAA,CAGF,sBACE,wBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,cAAA,CACA,oCAAA,CAEA,4BACE,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `Gi9uhT1ry4ooP7UkSBCu`,
	"modal": `B0g0vphBdiu_dqcPrw8m`,
	"closeButton": `JGvJd44EBQq9vKD7lokR`,
	"content": `YF7M3iLkKN_hwyUX2P0e`,
	"footer": `gRgOKO3g9ijyqnN0JvtM`,
	"footerCloseButton": `eixNKMBqqe8NnmXYYz_B`
};
export default ___CSS_LOADER_EXPORT___;
