import React from "react";
import { Typography, MenuItem, FormControl, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const AppSelect = ({
  label,
  value,
  name,
  handleInputChange,
  selectArray,
  loadingState,
  errorState, inputStyles ={},
  marginVertical = 2
}) => {
  return (
    <FormControl sx={{ my: marginVertical, ...inputStyles }} fullWidth>
      <Typography mb="8px">{label}</Typography>
      <Select
        value={value}
        onChange={handleInputChange}
        name={name}
        error={!!errorState}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={KeyboardArrowDownIcon}
      >
        {selectArray.map((item) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
      <Typography
        component="em"
        mt="5px"
        fontSize="14px"
        sx={{ color: errorState ? "red" : "inherit" }}
      >
        {loadingState}
      </Typography>
    </FormControl>
  );
};

export default AppSelect;
