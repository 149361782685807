import React, { useState } from 'react'
import CustomTabPanel, { a11yProps } from '../../CustomTabPanel'
import { Box, Button, CircularProgress, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import {  useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getJobApplications } from '../../../redux/jobs/action';
import JobDetails from './Tabs/JobDetails';
import { getStatusText } from '../../../utils/stringFunctions';
import { Edit } from '@mui/icons-material';

export default function SchoolJobDetails({ jobDetails }) {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const { jobId } = useParams();
    const dispatch = useDispatch();
    const {fetchingApplications, applications } = useSelector(state => state.jobReducer);
    const {userData } = useSelector(state => state.authReducer);

    const handleChange = (event, newValue) => {
        console.log("i ran", newValue, event)
        if (newValue == 1) {
            dispatch(getJobApplications({ jobId }))
        }
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Details" {...a11yProps(0)} />
                    <Tab label="Applications" {...a11yProps(1)} />
                </Tabs>
                {jobDetails?.userId == userData.id &&<Button onClick={() => navigate(`/jobs/edit/${jobDetails.id}`)}  startIcon={<Edit />}>
                    Edit Job
                </Button>}
            </Box>
            <CustomTabPanel value={value} index={0}>
                <JobDetails jobDetails={jobDetails} jobId={jobId} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <>
                    {fetchingApplications && <CircularProgress />}
                    {applications.length == 0 && !fetchingApplications && <Box textAlign="center" mt={4}>
                        <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }} >No application for this job yet.</Typography>
                    </Box>}
                    {applications.length > 0 && <Box>
                        <Paper style={styles.tableResponsive}>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S/N</TableCell>
                                            <TableCell align="right">Image</TableCell>
                                            <TableCell align="right">Name</TableCell>
                                            <TableCell align="right">Code</TableCell>
                                            <TableCell align="right">Status</TableCell>
                                            <TableCell align="right">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {applications.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell align="right"><Box component="img" sx={{ width: "40px", height: "40px", borderRadius: "50%" }} src={row.imgUrl} /></TableCell>
                                                <TableCell align="right">{`${row.user.firstName} ${row.user.lastName}`}</TableCell>
                                                <TableCell align="right">{row.applicationCode}</TableCell>
                                                <TableCell align="right">{getStatusText(row.status)}</TableCell>
                                                <TableCell align="right"><Button onClick={() => navigate(`/jobs/view-application/${row.id}`)}  sx={{ textDecorationLine: "underline" }}  >View Application</Button></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>}
                </>
            </CustomTabPanel>

        </>
    )
}

const styles = {
    tableResponsive: {
        overflowX: "auto",
        marginRight: "auto",
        marginLeft: "auto",
        maxWidth: "90vw",
    }
}