import React from "react";
import { Typography, Box } from "@mui/material";
import AppButton from "../../buttons/AppButton";
import { Cancel } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const AccountTypeModal = ({ handleAccountType, handleModal }) => {
  const navigate = useNavigate()
  return (
    <Box sx={styles.modal}>
      <div style={{position:"absolute" , top: 20,right:20 , cursor:"pointer"}} onClick={()=>{
        handleModal();
        navigate(-1);
        }}>
        <Cancel />
      </div>
      <Typography
        id="modal-modal-title"
        fontSize="28px"
        fontWeight="600"
        textAlign="center"
      >
        New to Safeticha? <br /> Register to get started.
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: "column", md: "row" }}
        gap="5px"
        width="100%"
      >
        <AppButton
          onClick={() => {
            handleAccountType("teacher");
            handleModal();
          }}
          fullWidth={false}
          customStyles={{
            fontWeight: "600",
            fontSize: "18px",
            padding: "7px 20px",
          }}
          btnText="Get started as a Teacher"
        />

        <AppButton
          onClick={() => {
            handleAccountType("school");
            handleModal();
          }}
          fullWidth={false}
          customStyles={{
            fontWeight: "600",
            fontSize: "18px",
            padding: "7px 20px",
          }}
          btnText="Get started as a School"
        />
      </Box>
    </Box>
  );
};

export default AccountTypeModal;

const styles = {
  modal: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    alignItems: "center",
    justifyContent: "space-between",
    my: 3,
  },
};
