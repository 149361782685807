import React from "react";
import MyCourses from "../../components/dashboard/SharedComponents/MyCourses";

const DashboardMyCourses = () => {
  return (
    <>
      <MyCourses />
    </>
  );
};

export default DashboardMyCourses;
