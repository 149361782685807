import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(newObject, value, theme) {
  const isPresent = value.find((x) => x.id == newObject.id);

  return {
    fontWeight: isPresent
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
    backgroundColor: isPresent ? "#ececec" : "",
  };
}

export default function AppMultiSelect({
  label,
  value,
  name,
  handleInputChange,
  selectArray,
  loadingState,
  errorState,
}) {
  const theme = useTheme();

  return (
    <div>
      <FormControl sx={{ mb: 2 }} fullWidth>
        <Typography mb="8px">{label}</Typography>
        <Select
          multiple
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={KeyboardArrowDownIcon}
          name={name}
          value={value}
          error={errorState}
          onChange={handleInputChange}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value.id} label={value.name} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {selectArray.map((name) => (
            <MenuItem
              key={name.id}
              value={{ id: name.id, name: name.name }}
              style={getStyles({ id: name.id, name: name.name }, value, theme)}
            >
              {name.name}
            </MenuItem>
          ))}
        </Select>
        <Typography
          component="em"
          mt="5px"
          fontSize="14px"
          sx={{ color: errorState ? "red" : "inherit" }}
        >
          {loadingState}
        </Typography>
      </FormControl>
    </div>
  );
}
