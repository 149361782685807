import React from 'react';
import styles from './EarnMoney.module.scss';
import EARN_IMAGE from "../../../../../assets/img/Resources/LandingPage/earn_money_img.png";
import DESIGN_1 from "../../../../../assets/img/Resources/LandingPage/design_1.png";
import DESIGN_2 from "../../../../../assets/img/Resources/LandingPage/design_2.png";

export default function EarnMoney() {
  return (
    <div className={`${styles.earn_money}`}>


    <div className={`${styles.earn_money_wrapper}`}>
        <div className={`${styles.earn_money_left}`}>
          <div className={styles.earn_heading}>
            Earn Money with our Resource Marketplace
          </div>

          <div className={styles.earn_description}>
            Get Access to millions of resources on Safeticha that get you great opportunities within and outside your organisation. Our resource marketplace helps you make profit and also equips you with what you need to grow.
          </div>


        </div>

        <div className={`${styles.earn_money_right}`}>
            <div>
                <img src={EARN_IMAGE} alt="" />
            </div>
        </div>
        <img className={`${styles.design_1}`} src={DESIGN_1} alt="" />
        <img className={`${styles.design_2}`} src={DESIGN_2} alt="" />

    </div>

    


    </div>
  )
}
