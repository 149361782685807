import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import AppInputPassword from "../../inputs/AppInputPassword";
import AppButton from "../../buttons/AppButton";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../redux/dashboard/actions";
import { clearUploadingState } from "../../../redux/dashboard/slice";

const ChangePassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);

  const [errorMessage, setErrorMessage] = useState({});

  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();

  const { isPasswordChanged, isBtnLoading } = useSelector(
    (state) => state.dashboardReducer
  );

  const validateData = (value, validationType) => {
    const errors = {};

    if (oldPasswordRef.current.value === "") {
      setOldPasswordError(true);
      errors.oldPassword = "Input cannot be blank!";
    } else {
      setOldPasswordError(false);
    }

    if (
      newPasswordRef.current.value === "" ||
      confirmPasswordRef.current.value === ""
    ) {
      setNewPasswordError(true);
      errors.newPassword = "Input cannot be blank!";
    } else if (
      newPasswordRef.current.value !== confirmPasswordRef.current.value
    ) {
      setNewPasswordError(true);
      errors.newPassword = "Password doesn't match, please check again!";
    } else {
      setNewPasswordError(false);
    }

    return errors;
  };

  const handleChangePassword = () => {
    const oldPassword = oldPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    setErrorMessage(validateData({}, "passwordData"));

    if (
      oldPassword !== "" &&
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword === confirmPassword
    ) {
      dispatch(changePassword({ oldPassword, newPassword, confirmPassword }));
    }
  };

  useEffect(() => {
    if (isPasswordChanged) {
      oldPasswordRef.current.value = "";
      newPasswordRef.current.value = "";
      confirmPasswordRef.current.value = "";

      navigate("/dashboard");

      setTimeout(() => {
        dispatch(clearUploadingState());
      }, [3000]);
    }
  }, [isPasswordChanged]);

  return (
    <Box p="40px">
      {/* change password */}
      <Box mt="20px">
        <Typography
          fontSize="30px"
          color="#2E3192"
          fontWeight="500"
          gutterBottom
          textAlign="center"
        >
          Change Password
        </Typography>

        <Box mt="20px">
          <Box display="flex" flexDirection="column" width="100%">
            <AppInputPassword
              label="Old Password"
              placeholder="Enter your old password"
              ref={oldPasswordRef}
              error={oldPasswordError}
              helperText={errorMessage.oldPassword}
            />

            <AppInputPassword
              label="New Password"
              placeholder="Enter your new password"
              ref={newPasswordRef}
              error={newPasswordError}
            />

            <AppInputPassword
              label="Confirm New Password"
              placeholder="Confirm your new password"
              ref={confirmPasswordRef}
              error={newPasswordError}
              helperText={errorMessage.newPassword}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" gap="10px" justifyContent="flex-end" mb="10px">
        <AppButton
          customStyles={{
            fontWeight: "600",
            borderRadius: "8px",
            padding: "7px 15px",
            border: "2px solid",
          }}
          variant="outlined"
          btnText="Cancel"
          fullWidth={false}
          onClick={() => {
            navigate("/dashboard");
          }}
        />

        <AppButton
          customStyles={{
            fontWeight: "600",
            borderRadius: "8px",
            padding: "7px 15px",
          }}
          btnText={isBtnLoading ? "Updating Password" : "Update Password"}
          disabled={isBtnLoading}
          fullWidth={false}
          onClick={() => {
            handleChangePassword();
          }}
        />
      </Box>
    </Box>
  );
};

export default ChangePassword;
