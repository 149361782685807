import React, { useEffect, useState } from "react";
import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getModuleQUestions, submitModuleQuestions } from "../../redux/question/action";
import AppButton from "../../components/buttons/AppButton";
import { ArrowBack } from "@mui/icons-material";
import { showSimpleToast } from "../../utils/alertFunctions";

const ModuleQuestion = () => {
    const dispatch = useDispatch();
    const { courseId, module } = useParams();

    const { questionData, isLoadingQuestions, submiting, submissionData } = useSelector(
        (state) => state.questionReducer
    );
    const [answers, setAnswers] = useState({});
    const [scoreTrack, setScoreTrack] = useState(null);

    const navigate = useNavigate();

    const handleSelectAnswer = (event, questionId) => {
        console.log("radio", event.target.value);
        let answerCopy = { ...answers };
        answerCopy[questionId] = event.target.value;
        setAnswers(answerCopy);
    }

    const submitQuestionForMarking = () => {
        if (submissionData.percentageScore && submissionData.attemptNo < questionData.maxAttempt) {
            setScoreTrack(null)
            setAnswers({})
            dispatch(getModuleQUestions({ moduleSequence: module, courseId }));
        } else {
            const data = { courseId, moduleSequence: module };
            let answersPicked = [];
            for (const key in answers) {
                answersPicked.push({ questionId: parseInt(key), picked: answers[key] })
            }
            data.answers = answersPicked;
            if (data.answers.length != questionData.questions.length) {
                showSimpleToast("Please answer all questions before submitting.", "failed");
                return;
            }
            dispatch(submitModuleQuestions({ data }))
        }
    }

    const getScoreText = () => {
        let correctCount = (submissionData.userScoreSheet.filter(x => x.isCorrect)).length;
        return `${correctCount} out of ${submissionData.userScoreSheet.length} is correct`;
    }

    useEffect(() => {
        if (submissionData.percentageScore) {
            let scoreTrackObj = {};
            for (const score of submissionData.userScoreSheet) {
                scoreTrackObj[score.questionId] = score
            }
            setScoreTrack(scoreTrackObj)
        }
    }, [submissionData.percentageScore]);

    useEffect(() => {
        setScoreTrack(null)
        setAnswers({})
        dispatch(getModuleQUestions({ moduleSequence: module, courseId }));
        // eslint-disable-next-line
    }, []);

    return (
        <Box component="section" sx={{p:{xs: "16px", lg: "30px"}}}>
            <Box display="flex" gap="14px" sx={{ marginBottom: "20px" }} >
                <ArrowBack
                    sx={{ color: "#2E3192" }}
                    onClick={() => {
                        navigate(-1);
                    }}
                />
                <Typography fontWeight="600" fontSize="20px">
                    {/* TODO use course name here */}
                    TEST - Module {module}
                </Typography>
            </Box>
            <Box backgroundColor="#fff" p="30px" borderRadius="15px">
                {!isLoadingQuestions && questionData.maxAttempt > questionData.userAttempt && <Typography sx={{ textAlign: "center", fontSize: "16px", fontWeight: "500", marginBottom: "20px" }}>{`${questionData.userAttempt + 1} of ${questionData.maxAttempt} Attempt${questionData.maxAttempt > 1 ? 's.' : '.'}`}</Typography>}
                {!isLoadingQuestions && questionData.userAttempt >= questionData.maxAttempt && <Typography sx={{ textAlign: "center", fontSize: "16px", fontWeight: "500", marginBottom: "20px" }}>{`Test has been taken ${questionData.maxAttempt} time${questionData.maxAttempt > 1 ? 's.' : '.'}`} No more attempts</Typography>}
                {isLoadingQuestions && <CircularProgress />}
                {!isLoadingQuestions && questionData?.questions?.length > 0 && questionData.maxAttempt > questionData.userAttempt &&
                    <Box>
                        {questionData.questions.map((question, index) =>
                            <Box key={index} sx={{ marginBottom: "20px", position: "relative" }}>
                                {submissionData.percentageScore && <Typography color={scoreTrack && scoreTrack[question.id].isCorrect ? "green" : "red"} style={{ position: "absolute", right: "10px", bottom: "10px", fontWeight: "600" }}>{scoreTrack && scoreTrack[question.id].isCorrect ? 'CORRECT' : 'FAILED'}</Typography>}
                                <Typography>{`${index + 1}. ${question.question}`}</Typography>
                                <FormControl>
                                    <RadioGroup aria-label={`question${index}`} name={`question${index}`} value={answers[question.id]} onChange={(event) => handleSelectAnswer(event, question.id)}>
                                        {question.options.map((option, i) => <Box key={i}>
                                            <FormControlLabel value={option} control={<Radio />} label={option} />

                                        </Box>)}
                                    </RadioGroup>
                                </FormControl>
                            </Box>)}
                        {submissionData.percentageScore && <Box>
                            <Typography color="primary" variant="h5">Score: {submissionData.percentageScore}%</Typography>
                            <Typography color="primary" variant="h5">{getScoreText()}</Typography>

                        </Box>}
                        <Box sx={{ display: {xs: "block", lg: "flex"}, flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "40px" }}>
                            <Typography sx={{ textAlign: "center", fontSize: "18px", fontWeight: "500", marginBottom: {xs: "10px", lg: "0"} }}>{`Attempt ${questionData.userAttempt + 1}`}</Typography>
                            {!submissionData.percentageScore && <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <AppButton variant="outlined" btnText="Back" customStyles={{ marginRight: "20px" }} isLoading={submiting} onClick={() => navigate(-1)} />
                                <AppButton btnText="Submit" isLoading={submiting} onClick={submitQuestionForMarking} loadingComponent="Grading..." />
                            </Box>}
                            {submissionData.percentageScore && <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <AppButton variant="outlined" btnText={submissionData.attemptNo < questionData.maxAttempt ?"Back": "Continue Course"} customStyles={{ marginRight: "20px" }} isLoading={submiting} onClick={() => navigate(-1)} />
                                {submissionData.attemptNo < questionData.maxAttempt && <AppButton btnText="Retry" onClick={submitQuestionForMarking} />}
                            </Box>}
                        </Box>
                    </Box>
                    }


{!isLoadingQuestions && questionData?.questions?.length == 0 && questionData.hasQuestions && questionData.testIsDone &&
                    <Box>
                        {questionData.percentageScores.map((score,i)=>
                            <Box sx={{marginBottom:"20px"}} key={i}>
                                 <Typography variant="h6">Attempt {score.attemptNo}</Typography>
                                <Typography color="primary" variant="h5">Score: {score.score}%</Typography>
                            </Box>
                        )}

<AppButton btnText="Continue Course" customStyles={{ marginRight: "20px" , width: "250px"}} isLoading={submiting} onClick={() => navigate(-1)} />
                    </Box>}



              

            </Box>
        </Box>
    );
};

export default ModuleQuestion;
