import React, { useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import RadioSelect from "./RadioSelect";
import CheckoutRightGrid from "./CheckoutRightGrid";
import CheckoutStepOne from "./CheckoutStepOne";
import CheckoutStepTwo from "./CheckoutStepTwo";

import LeftArrow from "../../../assets/svg/LeftArrow.svg";
import CheckoutComplete from "./CheckoutComplete";

const Checkout = () => {
  const [paymentPageIndex, setPaymentPageIndex] = useState(1);

  const handleNextPage = () => {
    setPaymentPageIndex(paymentPageIndex + 1);
  };

  const handlePrevPage = () => {
    setPaymentPageIndex(paymentPageIndex - 1);
  };

  return (
    <>
      {paymentPageIndex === 3 && <CheckoutComplete />}
      {paymentPageIndex !== 3 && (
        <Grid container>
          <Grid
            item
            md={6}
            p="100px 80px 0px 150px"
            backgroundColor="#fff"
            height="100vh"
          >
            {paymentPageIndex === 2 && (
              <Box
                component="img"
                src={LeftArrow}
                alt="LeftArrow"
                position="absolute"
                top="50px"
                onClick={handlePrevPage}
                cursor="pointer"
              />
            )}
            <Typography
              fontSize="20px"
              fontWeight="600"
              pb="10px"
              borderBottom="1px solid #acacac"
              mb="20px"
            >
              Payment
            </Typography>

            <Typography fontSize="16px" fontWeight="600">
              Pay with:
            </Typography>
            <RadioSelect />

            <Box my="15px" />

            {paymentPageIndex === 1 && (
              <CheckoutStepOne handleNextPage={handleNextPage} />
            )}
            {paymentPageIndex === 2 && (
              <CheckoutStepTwo handleNextPage={handleNextPage} />
            )}

            <Typography fontSize="14px" color="#acacac">
              Your personal data will be used to process your order, support
              your experience throughout this website, and for other purposes
              described in our privacy policy.
            </Typography>
          </Grid>

          <CheckoutRightGrid />
        </Grid>
      )}
    </>
  );
};

export default Checkout;
