import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillInstagram } from "react-icons/ai";
import { FaTwitter, FaLinkedin } from "react-icons/fa";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function ShareCertificateModal({ handleClose, certificate_url, shareLink }) {
    const iconSize = 40;
    const shareText = "Check out this amazing certificate I got on Safeticha!";

      // Single handleShare method to manage all platforms
  const handleShare = (platform) => {
    let shareUrl = "";
    const encodedText = encodeURIComponent(shareText);
    const encodedUrl = encodeURIComponent(certificate_url);

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodedText}%20${encodedUrl}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
        break;
      case "instagram":
        // Instagram does not allow web sharing, alerting for now
        alert("Instagram sharing is available via the app.");
        return; // No need to open a link for Instagram
      default:
        return;
    }

    // Open the share URL in a new tab
    window.open(shareUrl, "_blank");
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    toast.success("Link copied to clipboard!");
  };


  return (
       <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.5)", 
                backdropFilter: "blur(5px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 999, 
            }}
            onClick={handleClose}
       >
             <Box
                sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:"25px",
                    backgroundColor:"white",
                    borderRadius:"10px",
                    padding:"30px 30px",
                    width:{
                        xs:"250px",
                        sm:"500px",
                    },
                }}
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
        >

            <Box>
                <Typography sx={{fontWeight:"bold"}}>Share</Typography>
                <Typography>Show your friends what they can learn on Safeticha</Typography>
            </Box>

            {/* ICONS Box */}
            <Box
                sx={{
                    display:"flex",
                    justifyContent:"center",
                    gap:"20px"
                }}
            >
                <IoLogoWhatsapp
                    color="#2E3192"
                    size={iconSize}
                    onClick={() => handleShare("whatsapp")}
                    style={{ cursor: "pointer" }}
                    />
                <AiFillInstagram
                color="#2E3192"
                size={iconSize}
                onClick={() => handleShare("instagram")}
                style={{ cursor: "pointer" }}
                />
                <FaTwitter
                color="#2E3192"
                size={iconSize}
                onClick={() => handleShare("twitter")}
                style={{ cursor: "pointer" }}
                />
                <FaLinkedin
                color="#2E3192"
                size={iconSize}
                onClick={() => handleShare("linkedin")}
                style={{ cursor: "pointer" }}
                />
            </Box>

            {/* Shareable Link Box */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#f9f9f9",
                padding: "10px 15px",
                borderRadius: "5px",
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {shareLink}
              </Typography>
              <IconButton onClick={handleCopyLink}>
                <ContentCopyIcon />
              </IconButton>
            </Box>

            {/* Close Button */}
            <Box sx={{ display: "flex", justifyContent: "center"}}>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  backgroundColor: "#f0f0f0",
                  color: "#333",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                Close
              </Button>
            </Box>

           </Box>
           <ToastContainer 
           
           />

       </Box>

  )
}
