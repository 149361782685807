import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import AppButton from "../../../../buttons/AppButton";
import UploadDocumentsPage from "./UploadDocumentsPage";
import { DOCUMENT_TYPE } from "../../../../../utils/constant";
import AppSelect from "../../../../inputs/AppSelect";
import { getUserData } from "../../../../../utils/auth/authFunctions";
import AppInput from "../../../../inputs/AppInput";
import { useDispatch, useSelector } from "react-redux";
import { showSimpleToast } from "../../../../../utils/alertFunctions";
import { clearUploadingState } from "../../../../../redux/document/slice";
import { uploadProfileDocument } from "../../../../../redux/document/action";

const UploadDocuments = ({ onSuccess }) => {
  const userData = getUserData();
  const dispatch = useDispatch();
  const [base64s, setBase64s] = useState([]);
  const [files, setFiles] = useState([]);
  const [doctype, setDocType] = useState()
  const [form, setForm] = useState({
    name: "",
    remarks: "",
  })
  const [formError, setFormError] = useState({
    name: "",
    remarks: "",
  })
  const handleDoctypeChange = (e) => {
    setDocType(e.target.value)
  }

  const { documentUploadSuccessful, isBtnLoading } = useSelector(
    (state) => state.documentReducer
  );

  useEffect(() => {
    if (documentUploadSuccessful) {
      setTimeout(() => {
        dispatch(clearUploadingState());
        onSuccess();
      }, [2000]);
    }
  }, [dispatch, documentUploadSuccessful, onSuccess]);

  const handleUploadDocument = () => {
    if(files.length == 0){
      showSimpleToast("Please select a file to upload", "failed")
      return;
    }
    if(!doctype){
      showSimpleToast("Please select a document type", "failed")
      return;
    }
    console.log('base64s', files[0])
    if (base64s.length === 1) {
      let base64Length = base64s[0].length;
      let fileSize = ((base64Length * (3 / 4)) - (base64s[0].slice(-2) == "==" ? 2 : 1)) / 1000;
      if (fileSize > 1550) {
        showSimpleToast("File cannot be bigger that 1.5MB", "failed")
        return;
      }
      dispatch(
        uploadProfileDocument({
          type: doctype,
          fileString: base64s[0],
          name: files[0].path,
          remarks: form.remarks,
          size: files[0].size
        })
      );
    } else {
      showSimpleToast("You can only upload one document at a time", "failed")
    }
  };

  const handleInputChange = (event) =>{
    const formErrorCopy = {...formError};
    const formCopy = {...form};
    formCopy[event.target.name] = event.target.value;
    setForm(formCopy)
  }

  return (
    <Grid sx={{ marginLeft: "auto", marginRight: "auto", maxWidth: "600px" }} >
      <Typography variant='h6' textAlign="center" my="15px">Add Document</Typography>
      <UploadDocumentsPage
        setFiles={setFiles}
        files={files}
        setBase64s={setBase64s}
        base64s={base64s}
      />
      <Box >
        <AppSelect label="Document Type"
          name="type"
          handleInputChange={(e) => handleDoctypeChange(e)}
          selectArray={userData.userType == "teacher" ? [
            { id: 0, name: "" },
            {  name: "Curriculum Vitae", id: DOCUMENT_TYPE.cv },
            {  name: "Police Report", id: DOCUMENT_TYPE.policeReport },
            {  name: "NYSC Certificate", id: DOCUMENT_TYPE.nysc },
            {  name: "Highest Degree", id: DOCUMENT_TYPE.highestDegree },
            {  name: "Professional Qualification", id: DOCUMENT_TYPE.profQualification },
            {  name: "Others", id: DOCUMENT_TYPE.others },
          ] : [
            { id: 0, name: "" },
            { id: 1, name: "CAC Certificate", type: DOCUMENT_TYPE.cac },
            { id: 2, name: "Others", type: DOCUMENT_TYPE.others },]} errorState={!!formError.type} />
        <AppInput
          name="name"
          label="Document Name"
          placeholder=""
          error={formError.name != null}
          helperText={formError.name}
          onChange={handleInputChange}
          disabled
          value={files[0]?.path}
        />
        <AppInput
          name="remarks"
          label="Remarks (Optional)"
          placeholder=""
          onChange={handleInputChange}
        />
        <AppButton btnText='Upload' isLoading={isBtnLoading} loadingComponent="Uploading... Please wait." onClick={handleUploadDocument} btnType="contained" customStyles={{ marginBottom: "20px" }} />
      </Box>
    </Grid>


  );
};

export default UploadDocuments;
