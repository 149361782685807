import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";

import AppButton from "../../buttons/AppButton";

import AppInput from "../../inputs/AppInput";

import { useDispatch, useSelector } from "react-redux";
import { formatAsCurrency } from "../../../utils/courses/coursesFunctions";
import { removeCourseFromCart, setCourseCart } from "../../../redux/cart/slice";
import { getPurchaseUrl } from "../../../redux/courses/actions";

const FilledCartResponsive = () => {
  const dispatch = useDispatch();

  const { cartCourses } = useSelector((state) => state.cartReducer);
  const { userData } = useSelector((state) => state.authReducer);
  const { isLoading } = useSelector((state) => state.coursesReducer);
  const [totalSum, setTotalSum] = useState(0);
  console.log('cartCoursesfilled', cartCourses)

  useEffect(() => {
    let num = 0;

    for (let x of cartCourses) {
      num += x.price * x.units;
    }

    setTotalSum(num);
  }, [cartCourses]);

  const onBuyCourses = () => {

    let cart = cartCourses.map(course => { return { id: course?.id, qty: course.units } });
    dispatch(getPurchaseUrl({data: { cart }}))
  }

  const setCourseUnits = (courseId, type) => {
    let copyCourses = JSON.parse(JSON.stringify(cartCourses));;
    let courseIndex = copyCourses.findIndex(x => x.id == courseId);
    if (courseIndex > -1) {
      if (type == "add") {
        copyCourses[courseIndex].units++;
      } else if (type == "minus" && copyCourses[courseIndex].units != 1) {
        copyCourses[courseIndex].units--;
      }
      dispatch(setCourseCart(copyCourses))
    }
  }

  const removeItemFromCart = (course) =>{
    if(window.confirm(`Are sure you want to remove "${course.name}" from cart?`)){
        dispatch(removeCourseFromCart(course.id))
    }
  }

  return (
    <Box sx={{display: {xs:"block",lg:"none"}}}>

        <Grid container display="flex">
          {cartCourses.map((course) => {
            return (
              <Grid
                key={course.id}
                item
                display="flex"
                justifyContent="space-between"
                gap="15px"
                borderTop="2px solid #E5E5E5"
                p="20px 20px"
                width="100%"
              >
                <Box display="flex">
<Box
                  component="img"
                  src={course.coverImage}
                  alt="CoursePreview"
                  height="7vh"
                  width="20vw"
                  mr="20px"
                />

                <Box>
                  <Typography fontSize="14px" gutterBottom>
                    {course.name}
                  </Typography>


                  <Typography fontSize="12px" color="#2E3192" gutterBottom>
                    {course.category}
                  </Typography>

                  {userData.userType == "school" && <Box display="flex" alignItems="center" fontSize="12px" gap="10px">
                    Units:
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-evenly"
                      borderRadius="10px"
                      border="1px solid #E5E5E5"
                      py="5px"
                      height="35px"
                    >
                      <Typography onClick={() => setCourseUnits(course.id, "minus")} style={{ cursor: "pointer", padding: "0px 10px" }} fontWeight="700">-</Typography>
                      <Typography fontSize="12px">{course.units}</Typography>
                      <Typography onClick={() => setCourseUnits(course.id, "add")} style={{ cursor: "pointer", padding: "0px 10px" }} fontWeight="700">+</Typography>
                    </Box>
                  </Box>}
                </Box>
                </Box>

                
                <Box>
                  <Typography fontSize="14px" color="primary" fontWeight="600">₦{formatAsCurrency(course.price * course.units)}</Typography>
                  <Typography fontSize="10px" style={{fontStyle: "italic",textAlign: "right"}}>
                    ₦{formatAsCurrency(course.price)} x {course.units}
                  </Typography>
                  <Typography onClick={()=> removeItemFromCart(course)} color="#D41111" style={{fontSize:"12px", textDecoration: "underline", cursor:"pointer", textAlign: "right", marginTop:"1px",padding: "10px 0"}}>Remove</Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        <Grid item m="10px 20px">
          <Typography fontSize="14px" fontWeight="400" textAlign="right" gutterBottom>
            Total:
          </Typography>
          <Typography textAlign="right"  fontSize="18px" fontWeight="600" mb="16px">
            ₦{formatAsCurrency(totalSum)}
          </Typography>

          <AppButton
            customStyles={{
              fontWeight: "bold",
              fontSize: "18px",
              borderRadius: "8px",
            }}
            btnText="Make Payment"
            onClick={onBuyCourses}
            isLoading={isLoading}
            loadingComponent="Processing..."
          />

          <hr
            style={{
              height: "2px",
              backgroundColor: "#EFEFEF",
              border: "none",
              margin: "16px 0",
            }}
          />

     
        </Grid>
    </Box>
  );
};

export default FilledCartResponsive;
