import { createSlice } from "@reduxjs/toolkit";
import { getUserDocuments, uploadProfileDocument } from "./action";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        documentUploadSuccessful: [],
        isFetching: false,
        documents: [],
        userDocuments: [],
        isBtnLoading: false
    },
    reducers: {
        clearUploadingState: (state) => {
            state.documentUploadSuccessful = false;
            state.imageUploadSuccessful = false;
            state.isProfileUpdateSuccessful = false;
            state.isPasswordChanged = false;
          },
    },
    extraReducers: {
        // ---------GET USER DOCUMENTS   ---------/
        [getUserDocuments.pending]: (state) => {
            state.isFetching = true;
        },
        [getUserDocuments.fulfilled]: (state, { payload, meta }) => {
            if (payload.status === "success") {
                var params = meta.arg;
                console.log('params----', params)
                if(params.userId){
                    state.userDocuments = payload.data;
                }else{
                    state.documents = payload.data;
                }
            }
            state.isFetching = false;
        },
        [getUserDocuments.rejected]: (state, load) => {
            state.isFetching = false
        },
        // ---------UPLOAD PROFILE DOCUMENT---------/
        [uploadProfileDocument.pending]: (state) => {
            state.isBtnLoading = true;
        },
        [uploadProfileDocument.fulfilled]: (state, { payload }) => {
            if (payload.status === "success") {
                state.documentUploadSuccessful = true;
                state.isBtnLoading = false;
            } else {
                state.isBtnLoading = false;
            }
        },
        [uploadProfileDocument.rejected]: (state, load) => {
            state.isBtnLoading = false;
        },
    },
});

export const { clearUploadingState} = userSlice.actions;

export default userSlice.reducer;
