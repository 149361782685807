import React from "react";
import { Box, Typography, Grid } from "@mui/material";

import linkedinIcon from "../../../assets/svg/footer-icons/linkedin.svg";
import messengerIcon from "../../../assets/svg/footer-icons/messenger.svg";
import twitterIcon from "../../../assets/svg/footer-icons/twitter.svg";
import twooIcon from "../../../assets/svg/footer-icons/twoo.svg";

import AppInput from "../../inputs/AppInput";
import AppButton from "../../buttons/AppButton";
import { Link } from "react-router-dom";
import { Instagram, LinkedIn, Mail, Twitter } from "@mui/icons-material";
import { APP_COLOURS } from "../../../utils/constant";

const Footer = () => {
  return (
    <Box
      component="footer"
      padding="160px 0 50px"
      width={{xs: "80%", md: "90%", lg: "80%"}}
      maxWidth="1600px"
      margin="0 auto"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Grid
        container
        width="100%"
        gap={{ xs: 1, lg: 3 }}
        rowSpacing={{ xs: 5, md: 0 }}
        pb="30px"
        mb="30px"
        borderBottom="2px solid #E5E5EA"
        flexWrap={{ md: "nowrap" }}
      >
        {/* grid 1 */}
        <Grid
          item
          xs={12}
          sm={3}
          lg={2}
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <Typography fontSize="18px" fontWeight="700">
            Safeticha
          </Typography>
          <Typography fontSize="12px" fontWeight="400" color="#757095">
            Safeticha is a safe recruitment and professional development
            platform for educators and schools.
          </Typography>

          <Box display="flex" alignItems="center" gap="10px">
            <a
              href="https://www.linkedin.com/company/safeticha/"
              target="_blank"
              rel="noopener noreferrer"
            >
               <LinkedIn color={APP_COLOURS.primary} />
            </a>
            <a
              href="https://www.instagram.com/safeticha/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram color={APP_COLOURS.primary} />
            </a>
            <a
              href="https://www.twitter.com/safeticha/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter color={APP_COLOURS.primary} />
            </a>

            <a
              href="mailto:info@safeticha.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Mail color={APP_COLOURS.primary} />
            </a>
            {/* <img src={messengerIcon} alt="logo" />
            <img src={twitterIcon} alt="logo" />
            <img src={twooIcon} alt="logo" /> */}
          </Box>
        </Grid>

        {/* grid 2 */}
        <Grid item 
          xs={12}
          sm={3}
          lg={2}>
          <Typography fontSize="18px" fontWeight="700" marginBottom="15px">
            About Us
          </Typography>

          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              fontSize="12px"
              fontWeight="400"
              component="li"
            >
              About Us
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight="400"
              component="li"
            >
              Careers
            </Typography>
            <Typography fontSize="12px" fontWeight="400" component="li">
              Pricing
            </Typography>
          </Box>
        </Grid>

        {/* grid 3*/}
        <Grid item 
        xs={12}
        sm={3}
        lg={2}>
          <Typography fontSize="18px" fontWeight="700" marginBottom="15px">
            Educators
          </Typography>

          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              fontSize="12px"
              fontWeight="400"
              component="li"
            >
              Find a job
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight="400"
              component="li"
            >
              Work with us
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight="400"
              component="li"
            >
              Book consultation
            </Typography>
          </Box>
        </Grid>

        {/* grid 4 */}
        <Grid item 
        xs={12}
        sm={3}
        lg={2}>
          <Typography fontSize="18px" fontWeight="700" marginBottom="15px">
            Schools
          </Typography>

          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              fontSize="12px"
              fontWeight="400"
              component="li"
            >
              Staff audit
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight="400"
              component="li"
            >
              Book consultation
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight="400"
              component="li"
            >
              Recruitment solution
            </Typography>
          </Box>
        </Grid>

        {/* grid 5 */}
        <Grid
          item
          xs={12}
          lg={4}
          display="flex"
          flexDirection="column"
          gap={3}
        >
          <Typography fontSize="18px" fontWeight="700">
            Join Our Newsletter
          </Typography>

          <Box display="flex" width="100%">
            <AppInput
              noBottomPad
              customStyles={{ borderRadius: "0px" }}
              bgColor="#eeeeff"
              placeholder="Your email address"
              disabled
            />
            <AppButton
              customStyles={{ borderRadius: "0" }}
              fullWidth={false}
              btnText="Subscribe"
            />
          </Box>

          {/* <Typography fontSize="12px" color="#757095">
            * Will send you weekly updates for your better finance management.
          </Typography> */}
        </Grid>
      </Grid>

      <Typography
        fontSize={{ xs: "12px", md: "16px" }}
        color="#181433"
        width="100%"
        align="center"
      >
        Copyright @ Safeticha {new Date().getFullYear()}. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
