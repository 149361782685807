import React from "react";
import { Typography, Box, Grid } from "@mui/material";
// import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CoursesCompletedIcon from "../../../assets/svg/dashboard-icons/CoursesCompletedIcon.svg";

const DashboardCard = ({
  id,
  image,
  numbers,
  cardText,
  progressNumber,
  progressColor,
  name,noProgress,
  value,customStyles, icon
}) => {
  return (
    <Grid
      item
      py="30px"
      backgroundColor="#ffffff"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="20px"
      borderRadius="16px"
      sx={{width: { xs: "100%", md: "49%", lg: "32.5%" }}}
    >
      <Box width="80%" display="flex" alignItems="center" gap="20px">
        {icon ? icon :<Box
          component="img"
          src={CoursesCompletedIcon}
          alt="icon"
          height="70px"
        />}

        <Box>
          <Typography fontSize="26px" fontWeight="600" gutterBottom>
            {value}
          </Typography>
          <Typography>{name} </Typography>
        </Box>
      </Box>
      {/* progress and text */}
   {!noProgress &&   <Box
        width="80%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap="15px"
      >
        <Box
          backgroundColor="#F5F5F5"
          borderRadius="30px"
          width="100%"
          height="5px"
        >
          <Box
            backgroundColor="#ff0000"
            borderRadius="30px"
            width="100%"
            height="100%"
          ></Box>
        </Box>
        {/* <Typography
            fontSize="18px"
            fontWeight="600"
            sx={{ color: "#100A37" }}
          >
            {progressNumber}%
          </Typography> */}
      </Box>}
    </Grid>
  );
};

export default DashboardCard;
