// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the card component */

.DMSQwxit_tVdkQgoYRgo {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    gap: 10px;
    flex: 0 1 calc(20% - 10px);
    transition: all 1.3s;
    cursor: pointer;
  }


@media (max-width: 899px) {
  .DMSQwxit_tVdkQgoYRgo {
    flex: 0 1 calc(33.33% - 10px); 
  }
}

@media (max-width: 599px) {
  .DMSQwxit_tVdkQgoYRgo {
    flex: 0 1 calc(50% - 10px); 
  }
}

@media (max-width: 450px) {
  .DMSQwxit_tVdkQgoYRgo {
    flex: 0 1 calc(100% - 10px); 
  }
}
  
  .FSrr8TSI6NXeaStdgMVJ {
    width: 100%;
    height: 180px;
    border-radius: 8px;
  }
  
  .ukqBrHytucgbaDxRN19h {
    /* margin-top: 10px; */
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .ukqBrHytucgbaDxRN19h h3 {
    font-family: Sora;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    color: #100A37;
  }
  
  .MtWrgTJAxV179DkXeB83{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
  }

  .ruTv54wTVTGx7khHruyI{
    font-family: Sora;
    font-size: 14px;
    font-weight: 500;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/dashboard/resources/CourseCard/CourseCard.module.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,0BAA0B;IAC1B,oBAAoB;IACpB,eAAe;EACjB;;;AAGF;EACE;IACE,6BAA6B;EAC/B;AACF;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE;IACE,2BAA2B;EAC7B;AACF;;EAEE;IACE,WAAW;IACX,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;EAC/B;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,gBAAgB;EAClB","sourcesContent":["/* Styles for the card component */\n\n.cardContainer {\n    display: flex;\n    flex-direction: column;\n    background-color: #fff;\n    border-radius: 8px;\n    padding: 15px;\n    gap: 10px;\n    flex: 0 1 calc(20% - 10px);\n    transition: all 1.3s;\n    cursor: pointer;\n  }\n\n\n@media (max-width: 899px) {\n  .cardContainer {\n    flex: 0 1 calc(33.33% - 10px); \n  }\n}\n\n@media (max-width: 599px) {\n  .cardContainer {\n    flex: 0 1 calc(50% - 10px); \n  }\n}\n\n@media (max-width: 450px) {\n  .cardContainer {\n    flex: 0 1 calc(100% - 10px); \n  }\n}\n  \n  .cardImage {\n    width: 100%;\n    height: 180px;\n    border-radius: 8px;\n  }\n  \n  .cardContent {\n    /* margin-top: 10px; */\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n  \n  .cardContent h3 {\n    font-family: Sora;\n    font-size: 15px;\n    font-weight: 600;\n    line-height: 22px;\n    color: #100A37;\n  }\n  \n  .rating_section{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content:space-between;\n  }\n\n  .timeRated{\n    font-family: Sora;\n    font-size: 14px;\n    font-weight: 500;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `DMSQwxit_tVdkQgoYRgo`,
	"cardImage": `FSrr8TSI6NXeaStdgMVJ`,
	"cardContent": `ukqBrHytucgbaDxRN19h`,
	"rating_section": `MtWrgTJAxV179DkXeB83`,
	"timeRated": `ruTv54wTVTGx7khHruyI`
};
export default ___CSS_LOADER_EXPORT___;
