import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import SecureOnline from "../../../../assets/svg/dashboard-icons/SecureOnline.svg";

import Leaderboard from "./Leaderboard";
import Teachers from "./Teachers";
import Messages from "./Messages";

import { useNavigate } from "react-router-dom";

// import DashboardCard from "./DashboardCard";
import { DashboardCardArray } from "./DashboardCardArray";

import CoursesGrid from "../../SharedComponents/CoursesGrid";

import AppButton from "../../../buttons/AppButton";

import { useDispatch, useSelector } from "react-redux";
import { getAllPopularCourses, getCourseProgress } from "../../../../redux/courses/actions";
import ToDoList from "../../SharedComponents/ToDoList";
import DashboardCard from "../../SharedComponents/DashboardCard";

const SchoolDashboard = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { allPopularCourses, allPopularCoursesLoaded, courseProgress } = useSelector(
    (state) => state.coursesReducer
  );

  const onOpenCourse = (value) => {
    navigate("/courses/" + value);
  };

  useEffect(() => {
    dispatch(getAllPopularCourses());
    dispatch(getCourseProgress());
    // eslint-disable-next-line
  }, []);

  return (
    <Box component="section" p="30px">
      {/* dashboard details */}
      <Grid
        container
        gap="10px"
        mb="50px"
      >
        <DashboardCard name="Teachers" value={courseProgress?.myTeachers} />
        <DashboardCard name="Course Purchased" value={courseProgress?.myCourses} />
        <DashboardCard name="Completed Courses" value={courseProgress?.coursesCompleted} />


      </Grid>

      {/* leader board and to do list */}
      {/* <Grid container wrap="nowrap" gap="20px">
   
        <Leaderboard />
      </Grid> */}
      <Grid xs={12} item container gap="20px">
        <Grid sx={{ width: { xs: "100%", md: "66%", lg: "68%" } }}>
          <CoursesGrid
            coursesArray={allPopularCourses}
            gridTitle="Buy a Course"
            width={{ width: { xs: "100%", md: "48%", lg: "31%" } }}
            onOpenCourse={onOpenCourse}
            showPreloader={allPopularCoursesLoaded}
          />
        </Grid>

        <Grid sx={{ width: { xs: "100%", md: "32%", lg: "30%" } }}>
        <ToDoList />
          <Grid
            item
            borderRadius="15px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="20px"
            p="30px 20px"
            mb="20px"
            sx={{
              background: "linear-gradient(180deg, #1E0094 0%, #2D05CE 100%)",
            }}
          >
            <Typography color="#fff" textAlign="center">
              Let's get started with Safeguarding and Child Protection
            </Typography>

            <Box component="img" src={SecureOnline} alt="image" />

            <AppButton
              customStyles={{
                fontWeight: "600",
                borderRadius: "8px",
                padding: "7px 30px",
                backgroundColor: "white",
                color: "#2D05CE"
              }}
              onClick={() => navigate("/courses")}
              btnText="Get Started"
              fullWidth={false}
            />
          </Grid>
      
        </Grid >
      </Grid>
      {/* <Grid container mt="20px" wrap="nowrap" gap="20px">
        <Teachers />
        <Messages />
      </Grid> */}
    </Box>
  );
};

export default SchoolDashboard;
