import React, { useEffect, useState } from 'react';
import IMAGES from "../../../assets/img/Resources/Courses"
import Rating from './Rating/Rating';


import parse from 'html-react-parser';
import styles from './ResourceDetail.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getResourceById, purchaseResource } from '../../../redux/resource/action';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { decodeHTML, removeAllSpaces, textIsEmail } from '../../../utils/stringFunctions';
import { formatAsCurrency } from '../../../utils/numberFunctions';
import AppButton from '../../buttons/AppButton';
import { APP_COLOURS } from '../../../utils/constant';
import { addCourseToCart } from '../../../redux/cart/slice';
import AppInput from '../../inputs/AppInput';
import { showSimpleToast } from '../../../utils/alertFunctions';

import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';


export default function ResourceDetail() {
    const { resourceId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cartCourses } = useSelector((state) => state.cartReducer);
    const { profileData } = useSelector(state => state.dashboardReducer)
    const { isLoading, resourceInview, isSubmitting } = useSelector(state => state.resourceReducer);
    const [email, setEmail] = useState("");
    const [isConfirmed, setIsConfirmed] = useState(false); // state for confirm checkbox

    useEffect(() => {
        dispatch(getResourceById({ id: resourceId }))
    }, [])

    useEffect(() => {
        if (profileData && profileData.email) {
            setEmail(profileData.email)
        }
    }, [profileData])

    const onBuyCart = () => {

        // let cart = cartCourses.map(item => { return { id: item?.id, qty:item.type =="resource"? 1: item.units, type:item.type } });
        if (!textIsEmail(email)) {
            showSimpleToast("Please provide your email", "failed")
            return
        }
        const payload = {
            id: resourceId,
            email: removeAllSpaces(email),
            price: resourceInview.price
        }
        dispatch(purchaseResource({ data: payload }))
    }

    const handleAddItem = () => {
        dispatch(
            addCourseToCart({
                id: resourceInview.id,
                name: resourceInview.title,
                // coverImage: course.coverImage,
                // contentInfo: course.contentInfo,
                // category: course.courseCategory.name,
                price: resourceInview.price,
                units: 1
            })
        );
    };

    return (
        <div className={`${styles.resources}`}>

            <div className={`${styles.resource_wrapper}`}>

                <div className={`${styles.resource_main}`}>
                    <div>Resource Details</div>
                    {isLoading && <CircularProgress />}
                    {resourceInview && <div className={`${styles.resource_inner_main}`}>
                        {/* Description */}
                        <div className={`${styles.resource_desc}`}>
                            <div className={`${styles.resource_title}`}>
                                {resourceInview.title}
                            </div>
                            <div className={`${styles.resource_details}`}>
                                <Typography sx={{ pt: 1, fontSize: { xs: "14px", lg: "16px" }, whiteSpace: "pre-wrap" }}> {parse(decodeHTML(resourceInview.description))}</Typography>
                            </div>
                            <div className={`${styles.resource_age_ranges}`}>
                                <span style={{ marginRight: "10px" }}>Age Ranges:</span>
                                {resourceInview.ageRanges.length && resourceInview.ageRanges.split(',').map((x, i) => <span key={i} style={{ marginRight: "10px" }}> {x}</span>)}
                            </div>
                            <div onClick={() => navigate(-1)} style={{ color: APP_COLOURS.primary, cursor: "pointer", padding: "10px" }}>Go Back</div>

                        </div>

                        {/* Purchase */}
                        <div className={`${styles.resource_buy}`}>
                            <div className={`${styles.resource_img}`}>
                                <img style={{ width: "100%", height: "200px" }} src={resourceInview.coverImage} alt="resource" />
                            </div>
                            <div className={`${styles.resource_price_section}`}>
                                <span className={`${styles.resource_price}`}>{formatAsCurrency(resourceInview.price, "₦")}</span>
                                <Rating rating={5} />
                                {/* <span className={`${styles.resource_rating_amt}`}>(45)</span> */}
                            </div>
                            <Box mt={2}>
                                <AppInput placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Box>
                            <FormGroup  sx={{marginTop:'-20px', marginBottom:"8px"}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isConfirmed}
                                            onChange={() => setIsConfirmed(!isConfirmed)}
                                            inputProps={{ "aria-label": "controlled" }}
                                          
                                        />
                                    }
                                    label={
                                        <Typography sx={{ fontSize: '12px' }}>
                                            I confirm that Safeticha can use the email provided to send me the resource purchased
                                        </Typography>
                                    }
                                />
                            </FormGroup>
                            <AppButton btnText='Buy Now' fullWidth={true}
                            
                            customStyles={{
                                marginTop: '7px',
                                opacity: !isConfirmed ? 0.7 : 1,
                                cursor: !isConfirmed ? 'not-allowed' : 'pointer', 
                            }}
                             onClick={onBuyCart} isLoading={isSubmitting} 
                             loadingComponent="Processing..." 
                                disabled={!isConfirmed} // Disable if checkbox is not checked
                            />
                            {/* <AppButton btnText='Add to cart' variant="outlined" onClick={(handleAddItem)}  /> */}

                        </div>
                    </div>}

                </div>

            </div>

        </div>
    )
}
