import React from "react";
import LandingPage from "../../components/landing-page/LandingPage";
import { useLocation } from "react-router-dom";

 const SummerFest = () => {
    const location = useLocation();
  return (
    <>
      <LandingPage event={location.pathname == "/summer-fest"} />
    </>
  );
};

export default SummerFest;