import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppInput from "../../inputs/AppInput";
import AppInputPassword from "../../inputs/AppInputPassword";
import AppButton from "../../buttons/AppButton";
import { APP_COLOURS, PAGES } from "../../../utils/constant";
import GoogleIcon from "../../../assets/svg/GoogleIcon";
import LinkedinIcon from "../../../assets/svg/LinkedinIcon";

import {
  clearRegisteredUserEmail,
  clearUnverifiedUserState,
} from "../../../redux/auth/slice";
import { useDispatch, useSelector } from "react-redux";

import { loginUser } from "../../../redux/auth/actions";
import { getUnregisteredEmail } from "../../../redux/auth/slice";

const LoginForm = () => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const [errorMessage, setErrorMessage] = useState({});
  const [loginPageText, setLoginPageText] = useState("Recruit Safely | Get Hired | Professional Development");

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { isUserLoggedIn, isBtnLoading, redirectUnverifiedUser } = useSelector(
    (state) => state.authReducer
  );

  const handleLoginChange = (e) => {
    const { name, value } = e.target;

    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const validateData = (value) => {
    const errors = {};

    if (!value.email) {
      setEmailError(true);
      errors.email = "Email is required";
    } else if (!value.email.includes("@")) {
      setEmailError(true);
      errors.email = "Email must include @";
    } else {
      setEmailError(false);
    }

    if (value.password === "") {
      setPasswordError(true);
      errors.password = "Password is required";
    } else {
      setPasswordError(false);
    }

    return errors;
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setErrorMessage(validateData(loginData));

    const { email, password } = loginData;

    if (email !== "" && password !== "") {
      if (email.includes("@")) {
        dispatch(loginUser(loginData));
      }
    }
  };

  useEffect(() => {
    const userType = searchParams.get("usertype");
    if(userType){
      setLoginPageText(userType == "school" ? "Recruit safely and professionally develop educators" : "Get hired fast and grow your career!")
    }
    if (isUserLoggedIn) {
      const redirectpath = searchParams.get("redirect");
      if(redirectpath){
        navigate(redirectpath); 
      }else{
        navigate(PAGES.dashboard);
      }
      
    }
  }, [isUserLoggedIn]);

  // useEffect(() => {
  //   if (redirectUnverifiedUser) {
  //     navigate(PAGES.emailVerification);
  //     dispatch(getUnregisteredEmail(loginData.email));
  //     dispatch(clearUnverifiedUserState());
  //   }
  // }, [redirectUnverifiedUser]);

  useEffect(() => {
    dispatch(clearRegisteredUserEmail());
  }, []);

  return (
    <Container sx={styles.container} component="form" onSubmit={handleLogin}>
      <Typography sx={styles.headerText}>
        Log in to Your Safeticha Account!
      </Typography>
      <Typography sx={styles.headerSubText}>
        {loginPageText}
      </Typography>

      <AppInput
        onChange={handleLoginChange}
        value={loginData.email}
        type="email"
        name="email"
        label="Email Address"
        placeholder="Enter your email address"
        error={emailError}
        helperText={errorMessage.email}
      />

      <AppInputPassword
        onChange={handleLoginChange}
        value={loginData.password}
        name="password"
        label="Password"
        placeholder="Enter your password"
        error={passwordError}
        helperText={errorMessage.password}
      />

      <Box sx={styles.forgotPassword}>
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label="Remember me" />
        </FormGroup>
        <Typography
          variant="span"
          onClick={() => navigate(PAGES.forgotPassword)}
          sx={styles.createAccLink}
        >
          Forgot password
        </Typography>
      </Box>

      <AppButton
        btnType="submit"
        customStyles={{ fontWeight: "bold" }}
        btnText={isBtnLoading ? "Logging in" : "Login"}
        disabled={isBtnLoading}
      />

      <Box sx={styles.createAcc}>
        <Typography variant="span">Don't have an account?</Typography>{" "}
        <Typography
          variant="span"
          onClick={() => navigate(PAGES.signup)}
          sx={styles.createAccLink}
        >
          Create account
        </Typography>
      </Box>

      {/* <Divider>OR</Divider> */}

      {/* <Box sx={styles.continueDiv}>
        <Box sx={styles.continueBox}>
          <Button
            sx={styles.continueBtn}
            variant="text"
            startIcon={<GoogleIcon />}
          >
            Log in with Google
          </Button>
        </Box>

        <Box sx={styles.continueBox}>
          <Button
            sx={styles.continueBtn}
            variant="text"
            startIcon={<LinkedinIcon />}
          >
            Log in with LinkedIn
          </Button>
        </Box>
      </Box> */}
    </Container>
  );
};

export default LoginForm;

const styles = {
  container: {},
  headerText: {
    fontSize: 30,
    fontWeight: 700,
    margin: "20px 0",
  },
  headerSubText: {
    width: "75%",
    color: "#4B4E61",
    marginBottom: "20px",
  },
  forgotPassword: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  createAcc: {
    width: "100%",
    textAlign: "center",
    margin: "30px 0",
  },
  createAccLink: {
    fontWeight: "600",
    color: "#0F27BD",
    cursor: "pointer",
  },
  continueDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "25px",
    gap: "10px",
    fontWeight: "700",
  },
  continueBox: {
    textAlign: "center",
    width: "45%",
    border: "2px solid #6877D5",
    borderRadius: "8px",
    padding: "5px",
  },
  continueBtn: { textTransform: "none", fontSize: 16, color: APP_COLOURS.dark },
};
