import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import LeaderboardImage from "../../../../assets/img/Dashboard/School/LeaderboardImage.png";
import EditIcon from "../../../../assets/svg/dashboard-icons/School/EditIcon.svg";
import TrashIcon from "../../../../assets/svg/dashboard-icons/School/TrashIcon.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AppButton from "../../../buttons/AppButton";

import AddTeacher from "./AddTeacher";
import { useDispatch, useSelector } from "react-redux";
import { getSchoolTeachers } from "../../../../redux/school/action";

const SchoolTeachers = () => {
  const [teachersPageIndex, setTeachersPageIndex] = useState(1);
  const [rows, setRows] = useState([])
  const dispatch = useDispatch();
  const { isFetching, schoolTeachers } = useSelector(state => state.schoolReducer)

  useEffect(() => {
    dispatch(getSchoolTeachers())
  }, [])
  useEffect(() => {
    let copyRows = [];
    for (const teacher of schoolTeachers) {
      copyRows.push({ ...teacher, fullName: teacher.firstName + " " + teacher.lastName, imgUrl: "https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg" })

    }
    setRows(copyRows);
  }, [schoolTeachers.length])
  const handlePageIndex = (pageIndex) => {
    setTeachersPageIndex(pageIndex);
  };

  return (
    <>
      {teachersPageIndex === 1 && (
        <Box
          m="10px auto 0"
          width="95%"
          backgroundColor="white"
          borderRadius="12px"
          p="20px"
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>

            <AppButton
              onClick={() => {
                handlePageIndex(2);
              }}
              customStyles={{
                fontWeight: "bold",
                fontSize: "18px",
                borderRadius: "8px",
                padding: "7px",
              }}
              fullWidth={false}
              btnText="Add new Teacher"
            />
          </Box>

          <Paper style={styles.tableResponsive}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>S/N</TableCell>
                    <TableCell align="right">Image</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="right"><Box component="img" sx={{ width: "40px", height: "40px", borderRadius: "50%" }} src={row.imgUrl} /></TableCell>
                      <TableCell align="right">{row.fullName}</TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right"><Box component="img" src={TrashIcon} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>




          {isFetching && <CircularProgress style={{ textAlign: "center", margin: "30px" }} />}
          {!isFetching && rows.length == 0 && <Typography sx={{ textAlign: "center", margin: "30px" }}>No record found</Typography>}



        </Box>
      )}

      {teachersPageIndex === 2 && (
        <AddTeacher onShowTeachers={() => handlePageIndex(1)} />
      )}
    </>
  );
};

export default SchoolTeachers;

const styles = {
  tableResponsive: {
    overflowX: "auto",
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: "90vw",
  }
}
