
import React from 'react'
import styles from './Steps.module.scss'; 
import GREEN_TWO from "../../../../../assets/img/Resources/LandingPage/green_two.jpg";
import PINK_THREE from "../../../../../assets/img/Resources/LandingPage/pink_three.jpg";
import YELLOW_ONE from "../../../../../assets/img/Resources/LandingPage/yellow_one.jpg";
import RESOURCE_REGISTER from "../../../../../assets/img/Resources/LandingPage/resource-register.jpg";
import RESOURCE_UPLOAD from "../../../../../assets/img/Resources/LandingPage/resource-upload.jpg";

export default function Steps() {
  return (
    <div className={`${styles.steps}`}>
        <div className={`${styles.steps_wrapper}`}>

          <div className={styles.steps_heading}>
              <div className={styles.steps_title}>
                Steps to Making Money on Marketplace
              </div>
          </div>


          {/* Step 1 */}

          <div className={`${styles.steps_list}`}>
              <div className={`${styles.step_img}`}>
                <img src={RESOURCE_REGISTER} alt="green_two" />
              </div>

              <div className={`${styles.step_details}`}>
                  <div className={`${styles.step_details_count}`}><img src={YELLOW_ONE} alt="" /></div>
                  <div className={styles.step_details_heading}>
                    Register on Safeticha
                  </div>

                  <div className={styles.step_details_description}>
                  Sign up on behalf of yourself (as a teacher) or on behalf of your school by clicking Sign Up and filling in the required information. Already have an account? Simply login using your email and password.
                  </div>


              </div>

          </div>

          {/* Step 2 */}
          <div className={`${styles.steps_list}`}>
              <div className={`${styles.step_img}`} style={{order:1}}>
                <img src={RESOURCE_UPLOAD} alt="UPLOAD RESOURCE" />
              </div>

              <div className={`${styles.step_details}`}>
                  <div className={`${styles.step_details_count}`}><img src={GREEN_TWO} alt="" /></div>
                  <div className={styles.step_details_heading}>
                    Upload Your Resource
                  </div>

                  <div className={styles.step_details_description}>
                   Once you are logged in, click on My Store &gt; Add Resource and you are on your way
                  </div>


              </div>

          </div>

          {/* Step 3 */}
          <div className={`${styles.steps_list}`}>
              <div className={`${styles.step_details}`}>
                  <div className={`${styles.step_details_count}`}><img src={PINK_THREE} alt="" /></div>
                  <div className={styles.step_details_heading}>
                   Set Price and Earn Money 
                  </div>

                  <div className={styles.step_details_description}>
                     Here is a very important tip! Make sure your resource is not overpriced. Set a price that can get you the most customers and publish your course
                  </div>


              </div>

          </div>



        </div>

    

    </div>
  )
}
