import React, {useState} from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowDown';
const AccordionData = [
  {
    id: 1,
    question: "Do I have to be a teacher to create a profile?",
    answer:
      "No, you don’t have to be a teaching staff. This platform I open to everyone in the education field. This includes sports coaches, administrative staff, lab technicians and so on. Just pick the appropriate option.",
  },
  {
    id: 2,
    question: " How do I get verified?",
    answer:
      "To get verified, you must have submitted all the required documents and completed our safety trainings which are compulsory. To get verified as a school, your staff must have completed the compulsory safety courses.",
  },
  {
    id: 3,
    question: " Are contact details made public?",
    answer:
      "No. For safety reasons, only schools on paid subscription can send direct messages to teachers and view complete teacher portfolios. However, ratings for schools and educators are available to the general public.",
  },
  {
    id: 4,
    question: "Can I boost my profile?",
    answer:
      "Yes, educators and schools with the most badges are automatically boosted. These badges come from completing courses on the platform. You can also feature on the home page for a fee. ",
  },
  {
    id: 5,
    question: "Will I get a certificate after completing a course?",
    answer:
      "Yes, educators and schools with the most badges are automatically boosted. These badges come from completing courses on the platform. You can also feature on the home page for a fee. ",
  },
];

const FAQ = () => {

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box
      padding={{ xs: "70px 20px", md: "70px 70px 100px" }}
      maxWidth="1600px"
      margin="0 auto"
    >
      <Typography
        fontSize={{ xs: "30px", md: "66px" }}
        fontWeight="700"
        lineHeight="1.3"
      >
        Frequently <br /> asked questions
      </Typography>
      <Box marginTop="20px" display="flex" flexDirection="column" gap="10px">
        {/* accordion */}
        {AccordionData.map((data, index) => {
          return (
            <div key={data.id} style={{ border: "1px solid rgba(207, 207, 207, 1)", borderRadius: "10px", overflow: "clip"}}>
              <Accordion
               expanded={expanded === `panel${index+1}`} onChange={handleChange(`panel${index+1}`)}
               sx={{boxShadow: "none"}}
            >
              <AccordionSummary
                expandIcon={expanded === `panel${index+1}` ? <KeyboardArrowUpIcon /> : <AddIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" fontWeight="bold" fontSize={{ xs: "14px", sm: "18px"}} py={1}>
                  {data.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography width={{xs: "100%", md: "80%"}} fontSize={{ xs: "14px", sm: "16px"}}>{data.answer}</Typography>
              </AccordionDetails>
            </Accordion>
            </div>
            
          );
        })}
      </Box>
    </Box>
  );
};

export default FAQ;
