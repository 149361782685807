import React from "react";
import { Box, Typography } from "@mui/material";
import PeopleIcon from "../../../assets/svg/services-icons/PeopleIcon.svg";
import SafetyIcon from "../../../assets/svg/services-icons/SafetyIcon.svg";
import ProfessionalIcon from "../../../assets/svg/services-icons/ProfessionalIcon.svg";

const ServicesData = [
  {
    id: 1,
    heading: "Easier Recruitment",
    icon: PeopleIcon,
    description:
      "Recruiting managers can now find professionally trained educators with just the click of a button",
  },
  {
    id: 2,
    heading: "Safer Recruitment",
    icon: SafetyIcon,
    description:
      "Safeticha helps schools safely recruit teachers by thorough verification, detailed background checks and continuous safety training.",
  },
  {
    id: 3,
    heading: "Professional development",
    icon: ProfessionalIcon,
    description:
      "Schools and teachers all over Africa can develop themselves professionally through our well researched and credible resources.",
  },
];

const Services = () => {
  return (
    <Box sx={styles.serviceBox}>
      {ServicesData.map((service) => {
        return (
          <Box key={service.id} sx={styles.serviceDiv}>
            <img src={service.icon} alt={service.heading} />
            <Typography fontSize="24px" fontWeight="700">
              {service.heading}
            </Typography>
            <Typography fontSize="16px"> {service.description} </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default Services;

const styles = {
  serviceBox: {
    margin: "0 auto 100px",
    width: { xs: "90%", md: "80%" },
    maxWidth: "1600px",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  serviceDiv: {
    width: { xs: "100%", md: "370px" },
    height: { xs: "auto", md: "320px" },
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ABC8D2",
    padding: "30px 20px",
    borderRadius: "10px",
    alignItems: "flex-start",
    gap: "15px",
  },
};
