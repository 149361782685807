import React from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import CourseVideoIcon from "../../../assets/svg/dashboard-icons/CourseVideoIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { formatAsCurrency } from "../../../utils/courses/coursesFunctions";
import AppButton from "../../buttons/AppButton";
import { useNavigate } from "react-router-dom";

const CoursesGrid = ({
  coursesArray,
  gridTitle,
  gridSize,
  width={width: {xs: "100%", md: "50%", lg: "33%"}},
  onOpenCourse,
  viewAll,
  viewAllFunc,
  customStyles,
  showPreloader,
  personal,isSchool
}) => {
  const navigate = useNavigate()
  return (
    <Grid item xs={gridSize} sx={{ ...customStyles }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography mb="17px" fontWeight="700" fontSize="20px">
          {gridTitle}
        </Typography>

        {viewAll && (
          <Button
            sx={styles.linkText}
            onClick={() => {
              viewAllFunc();
            }}
          >
            VIEW ALL
          </Button>
        )}
      </Box>

      {showPreloader && <CircularProgress />}

      {coursesArray.length > 0 && !showPreloader && <Grid container gap="20px">
        {coursesArray.map((card) => {
          return (
            <Grid
              key={card.id}
              item
              backgroundColor="#ffffff"
              p="10px"
              pb="15px"
              borderRadius="15px"
              position="relative"
              sx={{ cursor: "pointer",...width }}
              onClick={() => onOpenCourse(card.id)}
            >
              <Box
                fontWeight="600"
                borderRadius="10px"
                backgroundColor="#ffffff99"
                p="5px"
                position="absolute"
                top="7%"
                left="7%"
              >
                {card.courseCategory}
              </Box>

              <Box
                component="img"
                src={card.coverImage}
                alt="most popular"
                width="100%"
                height="150px"
              />

              <Typography
                mt="10px"
                mb="15px"
                gutterBottom
                fontSize="18px"
                fontWeight="600"
              >
                {card.name}
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" gap="10px" alignItems="center">
                  <Box
                    component="img"
                    src={CourseVideoIcon}
                    alt="CourseVideoIcon"
                  />
                  <Typography>{card.contentInfo}</Typography>
                </Box>
                {!personal && <Typography color="#CCA349" fontWeight="600">
                  {card.price == 0 ? 'Free' : `₦${formatAsCurrency(card.price)}` }
                </Typography>}
                {personal && !isSchool && <Typography color="#CCA349" >
                  {card.progress != "NaN" ? card.progress : 0}% Progress
                </Typography>}

                {personal && isSchool && <Typography color="#CCA349" >
                  {card.remainingUnits} Unit{card?.remainingUnits > 1 ? 's': ''}
                </Typography>}
              </Box>
            </Grid>
          );
        })}
      </Grid>}
      {coursesArray.length == 0 && personal && !showPreloader &&<div style={styles.noContent}>
        <Typography variant="h5">You don't have any course yet</Typography>  
        <AppButton
            customStyles={{
              fontSize: "16px",
              fontWeight: "500",
              borderRadius: "8px",
              width:"200px",
              marginTop: "40px",
            }}
            btnText="Go To Courses"
            onClick={() => {
              navigate("/courses");
            }}
            fullWidth={false}
          />
      </div> }
    </Grid>
  );
};

export default CoursesGrid;

const styles = {
  linkText: {
    fontWeight: "700",
    fontSize: "18px",
    color: "#2E3192",
  },
  noContent: {
    width: "100%",
    marginTop:"20%",
    display:"flex",
    flexDirection: "column",
    alignItems:"center"
  }
};
