import { createSlice } from "@reduxjs/toolkit";
import {
  applyForJob,
  createJob,
  getAvailableJobs,
  getChatForJobApplication,
  getChatForJobApplicationLowKey,
  getJobApplicationDetails,
  getJobApplications,
  getJobByCode,
  getJobDetails,
  getJobsAppliedFor,
  listMyCreatedJobs,
  sendChatForJobApplication,
  updateApplicationStatus,
  updateJob,
  updateJobDetails,
  buyMoreJobTokens,
  getUserJobTokens
} from "./action";

export const jobsSlice = createSlice({
  name: "jobs",
  initialState: {
    isLoading: false,
    isFetchingJobs: false,
    isFetching: false,
    isSubmitting: false,
    myCreatedJobs: [],
    availableJobs: [],
    applications: [],
    fetchingApplications: false,
    appliedJobs: [],
    isFetchingAppliedJobs: false,
    jobDetails: null,
    isUpdatingJob: false,
    isApplying: false,
    application: null,
    fetchingMessages: false,
    sendingChat: false,
    jobChats: [],
    buyingToken: false,
    jobTokens: null
  },
  reducers: {},
  extraReducers: {
    // ---------CREATE JOB ---------/
    [createJob.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createJob.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.schoolTeachers = payload.data;
      }
      state.isSubmitting = false;
    },
    [createJob.rejected]: (state) => {
      state.isSubmitting = false;
    },
    // ---------GET MY  JOB ---------/
    [listMyCreatedJobs.pending]: (state) => {
      state.isFetchingJobs = true;
    },
    [listMyCreatedJobs.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.myCreatedJobs = payload.data;
      }
      state.isFetchingJobs = false;
    },
    [listMyCreatedJobs.rejected]: (state) => {
      state.isFetchingJobs = false;
    },
    // ---------GET  JOB DETAILS---------/
    [getJobDetails.pending]: (state) => {
      state.isFetching = true;
      state.jobDetails = null;
    },
    [getJobDetails.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.jobDetails = payload.data;
      }
      state.isFetching = false;
    },
    [getJobDetails.rejected]: (state) => {
      state.isFetching = false;
    },
    // ---------GET  JOB APPLICATION DETAILS---------/
    [getJobApplicationDetails.pending]: (state) => {
      state.isFetching = true;
      state.application = null;
    },
    [getJobApplicationDetails.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.application = payload.data;
      }
      state.isFetching = false;
    },
    [getJobApplicationDetails.rejected]: (state) => {
      state.isFetching = false;
    },
    // GET JOB BY CODE--------/
    [getJobByCode.pending]: (state) => {
      state.isFetching = true;
    },
    [getJobByCode.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.jobDetails = payload.data;
      }
      state.isFetching = false;
    },
    [getJobByCode.rejected]: (state) => {
      state.isFetching = false;
    },
    // ---------Update JOBD DETAILS---------/
    [updateJobDetails.pending]: (state) => {
      state.isUpdatingJob = true;
    },
    [updateJobDetails.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.jobDetails = { ...state.jobDetails, ...payload.data };
      }
      state.isUpdatingJob = false;
    },
    [updateJobDetails.rejected]: (state) => {
      state.isUpdatingJob = false;
    },
      // ---------Update JOB---------/
      [updateJob.pending]: (state) => {
        state.isSubmitting = true;
      },
      [updateJob.fulfilled]: (state, { payload }) => {
        if (payload.status === "success") {
          
        }
        state.isSubmitting = false;
      },
      [updateJob.rejected]: (state) => {
        state.isSubmitting = false;
      },
    // ---------Update APPLICATION STATUS---------/
    [updateApplicationStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [updateApplicationStatus.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
      }
      state.isLoading = false;
    },
    [updateApplicationStatus.rejected]: (state) => {
      state.isLoading = false;
    },
    // ---------GET AVAILABLE JOBS---------/
    [getAvailableJobs.pending]: (state) => {
      state.isFetchingJobs = true;
    },
    [getAvailableJobs.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.availableJobs = Array.isArray(payload.data)
          ? payload.data.reverse()
          : [];
      }
      state.isFetchingJobs = false;
    },
    [getAvailableJobs.rejected]: (state) => {
      state.isFetchingJobs = false;
    },
    // APPLY FOR JOB--------/
    [applyForJob.pending]: (state) => {
      state.isApplying = true;
    },
    [applyForJob.fulfilled]: (state, { payload }) => {
      state.isApplying = false;
    },
    [applyForJob.rejected]: (state) => {
      state.isApplying = false;
    },
    // GET JOB APPLICATION--------/
    [getJobApplications.pending]: (state) => {
      state.fetchingApplications = true;
    },
    [getJobApplications.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.applications = payload.data;
      }
      state.fetchingApplications = false;
    },
    [getJobApplications.rejected]: (state) => {
      state.fetchingApplications = false;
    },
    // GET JOB APPLICATION--------/
    [getJobsAppliedFor.pending]: (state) => {
      state.isFetchingAppliedJobs = true;
    },
    [getJobsAppliedFor.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.appliedJobs = payload.data;
      }
      state.isFetchingAppliedJobs = false;
    },
    [getJobsAppliedFor.rejected]: (state) => {
      state.isFetchingAppliedJobs = false;
    },
    // SEND JOB APPLICATION CHAT--------/
    [sendChatForJobApplication.pending]: (state) => {
      state.sendingChat = true;
    },
    [sendChatForJobApplication.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.jobChats.push({...payload.data, createdAt: new Date().toISOString()});
      }
      state.sendingChat = false;
    },
    [sendChatForJobApplication.rejected]: (state) => {
      state.sendingChat = false;
    },
    // GET JOB APPLICATION CHAT--------/
    [getChatForJobApplication.pending]: (state) => {
      state.fetchingMessages = true;
      state.jobChats = [];
    },
    [getChatForJobApplication.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.jobChats = payload.data;
      }
      state.fetchingMessages = false;
    },
    [getChatForJobApplication.rejected]: (state) => {
      state.fetchingMessages = false;
    },
    // GET JOB APPLICATION CHAT LOW KEY--------/
    [getChatForJobApplicationLowKey.pending]: (state) => {
      state.fetchingMessages = true;
    },
    [getChatForJobApplicationLowKey.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.jobChats = payload.data;
      }
      state.fetchingMessages = false;
    },
    [getChatForJobApplicationLowKey.rejected]: (state) => {
      state.fetchingMessages = false;
    },
      // GET BUY MORE TOKEN URL--------/
      [buyMoreJobTokens.pending]: (state) => {
        state.buyingToken = true;
      },
      [buyMoreJobTokens.fulfilled]: (state, { payload }) => {
        state.buyingToken = false;
      },
      [buyMoreJobTokens.rejected]: (state) => {
        state.buyingToken = false;
      },
      // GET JOB TOKENS--------/
    [getUserJobTokens.pending]: (state) => {
      // state.isFetchingAppliedJobs = true;
    },
    [getUserJobTokens.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.jobTokens = payload.data.tokens;
      }
      // state.isFetchingAppliedJobs = false;
    },
    [getUserJobTokens.rejected]: (state) => {
      // state.isFetchingAppliedJobs = false;
    },
  },
});

export const { } = jobsSlice.actions;

export default jobsSlice.reducer;
