import React from "react";
import { Box, Typography } from "@mui/material";
import QuoteIcon from "../../../assets/svg/aboutus-icons/QuoteIcon.svg";
import PersonIcon from "../../../assets/svg/testimonials/PersonIcon.svg";
import TopRightBg from "../../../assets/svg/testimonials/TopRightBg.svg";

const TestimonialsArray = [
  {
    id: 1,
    name: "Pinefield School Teacher",
    quote:
      "This has opened our eyes to the basic measures that need to be taken by school authorities to ensure that appropriate measures are taken to keep children safe.",
  },
  {
    id: 2,
    name: "Vilac School Admin",
    quote:
      "Every school owner should incorporate safeguarding training provided by this platform into their staff development program",
  },
  {
    id: 3,
    name: "Pinefield School Teacher",
    quote:
      "This has opened our eyes to the basic measures that need to be taken by school authorities to ensure that appropriate measures are taken to keep children safe.",
  }
  /* {
    id: 4,
    name: "MD School Teacher",
    quote:
      "I have learnt how to better relate with my learners through these trainings",
  }, */
];

const Testimonials = () => {
  return (
    <Box backgroundColor="#D5D6E9" p={{ xs: "50px 25px", lg: "80px" }}>
      <Box position="relative" maxWidth="1600px" margin="0 auto">
        <Box
          position="absolute"
          top={{ xs: "5px", md: "12%" }}
          left={{ xs: "auto" }}
          right={{ xs: "15%" }}
          height="auto"
          width={{ xs: "70px", md: "auto" }}
        >
          <img
            src={TopRightBg}
            alt="TopRightBg"
            style={{ height: "inherit", width: "inherit" }}
          />
        </Box>

        <Typography
          fontSize={{ xs: "25px", md: "55px" }}
          fontWeight="bold"
          color="#100A37"
          gutterBottom
        >
          Testimonials
        </Typography>
        <Typography
          color="rgba(75, 78, 97, 1)"
          fontSize="18px"
          fontWeight="500"
          mb={{ xs: "10px", md: "100px" }}
        >
          See what educators and schools are saying about Safeticha
        </Typography>

        <Box sx={styles.quoteBox}>
          {TestimonialsArray.map((quote) => {
            return (
              <Box
                key={quote.id}
                position="relative"
                backgroundColor="white"
                display="flex"
                padding="20px"
                height="auto"
                gap="10px"
                borderRadius="10px"
                width={{ xs: "100%", md: "33%", lg: "30%" }}
              >
                <Box position="absolute" top="-20px" left="10%">
                  <img src={QuoteIcon} alt="quote" height="32px" />
                </Box>

                <img src={PersonIcon} alt="person" width="100px" />

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Typography color="#100A37" fontSize="18px" fontWeight="600">
                    {quote.name}
                  </Typography>
                  <Typography fontSize={{xs: "12px", lg: "14px"}} fontWeight="400" color="rgba(75, 78, 97, 1)">
                    {quote.quote}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonials;

const styles = {
  quoteBox: {
    marginTop: "50px",
    display: "flex",
    alignItems: "start",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-between",
    rowGap: "30px",
    flexWrap: "wrap",
  },
};
