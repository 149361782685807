
import EASY_TO_UNDERSTAND_HISTORY from './easy_to_understand_history.jpg';
import INDICES_LOGARITHM from './indices_logarithm.jpg';
import LIT_IN_ENG from './lit_in_eng.jpg';
import LITERATURE from './literature.jpg';
import THE_SUN from './the_sun.jpg';
import FULL_STAR from './full_star1.png';
import HALF_STAR from './half_empty1.png';
import EMPTY_STAR from './empty_star.png';
import NEXT_ICON from './next_icon.png';
import PREV_ICON from './prev_icon.png';

export default {EASY_TO_UNDERSTAND_HISTORY, INDICES_LOGARITHM, LIT_IN_ENG, LITERATURE, THE_SUN, FULL_STAR, HALF_STAR, EMPTY_STAR, NEXT_ICON, PREV_ICON};

