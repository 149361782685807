import React from "react";
import SchoolTeachers from "../../components/dashboard/School/SchoolTeachers/SchoolTeachers";

const DashboardTeachers = () => {
  return (
    <>
      <SchoolTeachers />
    </>
  );
};

export default DashboardTeachers;
