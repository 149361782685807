import { RemoveRedEye, Delete, ArrowBack, UploadFile, Folder } from '@mui/icons-material'
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import UploadDocuments from '../../components/dashboard/School/SchoolProfile/UploadDocuments/UploadDocuments';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDocuments } from '../../redux/document/action';
import AppButton from '../../components/buttons/AppButton';
import { useNavigate } from 'react-router-dom';
import { formatFileSize } from '../../utils/numberFunctions';
import { DOCUMENT_TYPE } from '../../utils/constant';
import ViewFileModal from '../../components/modal/ViewFileModal';

export default function Documents() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentView, setCurrentView] = useState(1);
    const [openFile, setOpenFile] = useState(false);
    const [fileInView, setFileInView] = useState();
    const { documents } = useSelector(state => state.documentReducer)
    const onDeleteDocument = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure you want to delete this document?")) {
            console.log("first")
        }
    }
    useEffect(() => {
        dispatch(getUserDocuments({}))
    }, [])
    const getDocumentTypeDisplay = (type) => {
        switch (type) {
            case DOCUMENT_TYPE.cv:
                return "Curriculum Vitae";
            case DOCUMENT_TYPE.cac:
                return "CAC";
            case DOCUMENT_TYPE.highestDegree:
                return "Highest Degree";
            case DOCUMENT_TYPE.nysc:
                return "NYSC Certificate";
            case DOCUMENT_TYPE.policeReport:
                return "Police Report";
            case DOCUMENT_TYPE.profQualification:
                return "Professional Cert";
            default:
                return "Others";
        }
    }
    return (
        <>
        <ViewFileModal showDocument={openFile} onClose={()=> {
            setOpenFile(false)
            setFileInView(null)}} file={fileInView}/>
            <Box
                m="10px auto 0"
                borderRadius="12px"
                p="20px"
            >
                <Box
                    m="10px auto 0"
                    backgroundColor="white"
                    borderRadius="12px"
                    p="20px"
                >
                    <Box sx={{ display: "flex", flexDirection: "row", mb: "15px" }}>
                        <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)}>Back</Button>
                        {currentView == 1 ?
                            <Button startIcon={<UploadFile />} sx={{ ml: "auto", display: "flex" }} variant="contained" onClick={() => setCurrentView(2)}>Add Document</Button> :
                            <Button startIcon={<Folder />} sx={{ ml: "auto", display: "flex" }} variant="contained" onClick={() => setCurrentView(1)}>View Documents</Button>}
                    </Box>

                    {currentView == 1 && <Paper style={styles.tableResponsive}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>S/N</TableCell>
                                        <TableCell >Document</TableCell>
                                        <TableCell align="right">Size</TableCell>
                                        <TableCell align="right">Type</TableCell>
                                        <TableCell align="right">Created</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {documents.map((document, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell >{document.name}</TableCell>
                                            <TableCell align="right">{formatFileSize(document.size)}</TableCell>
                                            <TableCell align="right">{getDocumentTypeDisplay(document.type)}</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={()=>{
                                                    setFileInView(document.fileUrl);
                                                    setOpenFile(true)
                                                }}>
                                                    <RemoveRedEye color='primary' />
                                                </IconButton>
                                                <IconButton onClick={onDeleteDocument}>
                                                    <Delete color='error' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        {documents.length == 0 && <Box textAlign="center" my={4}>
                            <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }} > You have no document</Typography>
                            <AppButton btnText='Add Document' customStyles={{ mt: "20px" }} onClick={() => setCurrentView(2)} fullWidth={false} />
                        </Box>}
                    </Paper>}

                    {currentView === 2 && <UploadDocuments onSuccess={() => {
                        setCurrentView(1);
                        dispatch(getUserDocuments({}))
                    }
                    } />}

                </Box>
            </Box>
        </>

    )
}

const styles = {
    tableResponsive: {
        overflowX: "auto",
        marginRight: "auto",
        marginLeft: "auto",
        maxWidth: "90vw",
    },
    icon: {
        width: { xs: "30px", md: "45px", lg: "50px" },
        height: { xs: "30px", md: "45px", lg: "50px" },
    }
}