import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UploadFile } from "@mui/icons-material";
import IncomeImage from "../../../assets/img/income.png";
import Educator from "../../../assets/img/education.png";

const ToDoList = () => {
  const tasks = [
    { id: 0, name: "CV" },
    { id: 1, name: "Police Report" },
    { id: 2, name: "NYSC" },
    { id: 3, name: "Highest Degree" },
    { id: 4, name: "Professional Qualification" },
  ];

  const { profileData } = useSelector((state) => state.dashboardReducer);

  const navigate = useNavigate();
  const handleClickTodo = () => {
    navigate("/resources");
  };

  return (
    <Grid item mb="20px">
      <Typography mb="17px" fontWeight="700" fontSize="20px">
        Resources
        {/* Grow your profile */}
      </Typography>
      <Box
        sx={{ backgroundColor: "#ffffff", cursor: 'pointer', '&:hover': { backgroundColor: '#d6d7f2' } }}
        width="100%"
        borderRadius="12px"
        p="20px"
        display="flex"
        flexDirection="column"
        gap="50px"
      >
        <Box
          display="flex"
          gap="20px"
          alignItems="center"
          onClick={handleClickTodo}
        >
          {/* <UploadFile color="primary" /> */}
          <Box>

            <Box component="img" src={IncomeImage} height={50} />
            {/* <Box component="img" src={Educator}  height={40}  /> */}
          </Box>

          <Box>
            <Typography fontSize="16px" fontWeight="600" mb="7px">
              Upload  •  Grow  •  Earn
              {/* Professional Development  •  Grow   */}
            </Typography>

            <Box display="flex" gap="10px">
              <Typography fontSize={12}>Upload your documents to build a better profile or for commercial purposes and earn on your sales. </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ToDoList;
