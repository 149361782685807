import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiPost, apiGet } from "../../service/apiService";
import { API_URL } from "../../utils/apiConstants";

export const registerTeacher = createAsyncThunk(
  "auth/registerTeacher",
  async (payload) => {
    return apiPost(API_URL.registerTeacher, payload)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const registerSchool = createAsyncThunk(
  "auth/registerSchool",
  async (payload) => {
    return apiPost(API_URL.registerSchool, payload)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const verifyUserEmail = createAsyncThunk(
  "auth/verifyUserEmail",
  async (payload) => {
    return apiPost(API_URL.verify, payload)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const loginUser = createAsyncThunk("auth/loginUser", async (payload) => {
  return apiPost(API_URL.login, payload)
    .then((res) => {
      // payload.callBack();
      return res.data;
    })
    .catch((error) => {
      return error;
    });
});

export const sendVerificationMail = createAsyncThunk("auth/sendVerificationEmail", async (payload) => {
  return apiPost(API_URL.sendVerificationMail, payload)
    .then((res) => {
      // payload.callBack();
      return res.data;
    })
    .catch((error) => {
      return error;
    });
});

export const checkEmail = createAsyncThunk(
  "auth/checkEmail",
  async (payload) => {
    return apiPost(API_URL.checkEmail, payload, null, true)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getTeacherCategory = createAsyncThunk(
  "auth/getTeacherCategory",
  async (payload) => {
    return apiGet(API_URL.teacherCategories, payload)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getSchoolCategory = createAsyncThunk(
  "auth/getSchoolCategory",
  async (payload) => {
    return apiGet(API_URL.schoolCategories, payload)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getQualification = createAsyncThunk(
  "auth/getQualification",
  async (payload) => {
    return apiGet(API_URL.qualification, payload)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const getSpecialisation = createAsyncThunk(
  "auth/getSpecialisation",
  async (payload) => {
    return apiGet(API_URL.specialisation + payload)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (payload) => {
    return apiPost(API_URL.forgotPassword, payload.data)
      .then((res) => {
        payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);


export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (payload) => {
    return apiPost(API_URL.resetPassword, payload.data)
      .then((res) => {
        payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);
