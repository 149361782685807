import React from "react";
import { Typography, Box } from "@mui/material";

import EmptyCart from "./EmptyCart";
import FilledCart from "./FilledCart";

import { useSelector } from "react-redux";
import FilledCartResponsive from "./FilledCartResponsive";

const Cart = (props) => {
  const { cartCourses } = useSelector((state) => state.cartReducer);

  return (
    <Box variant="section" sx={styles.container}>

      {cartCourses.length > 0 ? <><FilledCart /><FilledCartResponsive /></> : <EmptyCart {...props} />}
    </Box>
  );
};

export default Cart;

const styles = {
  container: {
    width: {xs: "100%",md: "95", lg: "95%"},
    margin: "0 auto",
    background: "#f7f7fb",
    borderRadius: "10px",
    marginTop: "10px",
    border: "1px solid #EFEFEF",
  },
};
