import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCourses,
  getAllPopularCourses,
  getAllCategoryCourses,
  getCourseCategory,
  getCourse,
  getCourseContent,
  getCourseProgress,
  getMyCourses,
  getPurchaseUrl,
  updateCourseProgress,
} from "./actions";

export const coursesSlice = createSlice({
  name: "courses",
  initialState: {
    unauthorised: false,
    allCourses: [],
    myCourses: [],
    allCoursesLoading: false,
    myCoursesLoading: false,
    allPopularCourses: [],
    allPopularCoursesLoading: false,
    allCategoryCourses: [],
    allCategoryCoursesLoaded: false,
    courseCategory: [],
    courseCategoryLoaded: false,
    course: null,
    courseLoaded: false,
    courseContent: [],
    otherCourseContentDetails:{},
    courseContentLoading: false,
    courseProgress: null,
    courseProgressLoaded: false,
    isLoading: false,
  },
  reducers: {
    clearCourse: (state) => {
      state.course = null;
    },
    clearUnauthorisation: (state) => {
      state.unauthorised = false;
    },
  },
  extraReducers: {
    // ---------GET ALL COURSES ---------/
    [getAllCourses.pending]: (state) => {
      state.allCoursesLoading = true; 
    },
    [getAllCourses.fulfilled]: (state, { payload }) => {
      if (payload?.status === "success") {
        state.allCourses = payload.data;
        state.allCoursesLoaded = true;
      } else if (payload?.response?.status === 401) {
        state.unauthorised = true;
      }
      state.allCoursesLoading = false;
    },
    [getAllCourses.rejected]: (state, load) => {
      state.allCoursesLoading = false
    },
    // ---------GET MY COURSES ---------/
    [getMyCourses.pending]: (state) => {
      state.myCoursesLoading = true
    },
    [getMyCourses.fulfilled]: (state, { payload }) => {
      if (payload?.status === "success") {
        state.myCourses = payload.data;
        state.myCoursesLoaded = true;
      } else if (payload.response?.status === 401) {
        state.unauthorised = true;
      }
      state.myCoursesLoading = false
    },
    [getMyCourses.rejected]: (state, load) => {
      state.myCoursesLoading = false
    },
    // ---------GET ALL POPULAR COURSES ---------/
    [getAllPopularCourses.pending]: (state) => {
      state.allPopularCoursesLoading = true;
     },
    [getAllPopularCourses.fulfilled]: (state, { payload }) => {
      if (payload?.status === "success") {
        state.allPopularCourses = payload.data;
      } else if (payload.response?.status === 401) {
        state.unauthorised = true;
      }

      state.allPopularCoursesLoading = false;
    },
    [getAllPopularCourses.rejected]: (state, load) => {
      state.allPopularCoursesLoading = false
     },

    // ---------GET ALL CATEGORY COURSES ---------/
    [getAllCategoryCourses.pending]: (state) => {
      state.allCategoryCoursesLoaded = false;
    },
    [getAllCategoryCourses.fulfilled]: (state, { payload }) => {
      if (payload?.status === "success") {
        state.allCategoryCourses = payload.data;
        state.allCategoryCoursesLoaded = true;
      } else if (payload.response?.status === 401) {
        state.unauthorised = true;
      }
    },
    [getAllCategoryCourses.rejected]: (state, load) => { },

    // ---------GET COURSE CATEGORY ---------/
    [getCourseCategory.pending]: (state) => { },
    [getCourseCategory.fulfilled]: (state, { payload }) => {
      if (payload?.status === "success") {
        state.courseCategory = payload.data;
        state.courseCategoryLoaded = true;
      } else if (payload?.response?.status === 401) {
        state.unauthorised = true;
      }
    },
    [getCourseCategory.rejected]: (state, load) => { },

    // ---------GET COURSE ---------/
    [getCourse.pending]: (state) => {
      state.courseLoaded = false;
    },
    [getCourse.fulfilled]: (state, { payload }) => {
      if (payload?.status === "success") {
        state.course = payload.data;
        state.courseLoaded = true;
      } else if (payload.response?.status === 401) {
        state.unauthorised = true;
      }
    },
    [getCourse.rejected]: (state, load) => { },

    // ---------GET COURSE CONTENT ---------/
    [getCourseContent.pending]: (state) => {
      state.courseContentLoading = true;
    },
    [getCourseContent.fulfilled]: (state, { payload }) => {
      if (payload?.status === "success") {
        //sort
        let parent = [...payload.data.contents]
        parent.sort((a, b) => a.moduleSequence - b.moduleSequence);
        for (let childItem of parent) {
          childItem.moduleContent.sort((a, b) => a.sequence - b.sequence);
        }
        state.courseContent = parent;
        state.otherCourseContentDetails = {
          name: payload.data.name,
          progress: payload.data.progress
        }
      } else if (payload.response?.status === 401) {
        state.unauthorised = true;
      }
      state.courseContentLoading = false;
      state.false = true;
    },
    [getCourseContent.rejected]: (state, load) => {

      state.courseContentLoading = false;
     },

    // ---------GET COURSE PROGRESS ---------/
    [getCourseProgress.pending]: (state) => {
      state.courseProgressLoaded = false;
    },
    [getCourseProgress.fulfilled]: (state, { payload }) => {
      if (payload?.status === "success") {
        // console.log(payload.data);
        state.courseProgress = payload.data;
        state.courseProgressLoaded = true;
      } else if (payload.response?.status === 401) {
        state.unauthorised = true;
      }
    },
    [getCourseProgress.rejected]: (state, load) => { },
    // ---------GET PURCHASE URL ---------/
    [getPurchaseUrl.pending]: (state) => {
      state.isLoading = true;
    },
    [getPurchaseUrl.fulfilled]: (state, { payload }) => {
      if (payload?.status === "success") {
      
      } else if (payload.response?.status === 401) {
        state.unauthorised = true;
      }
      state.isLoading = false;
    },
    [getPurchaseUrl.rejected]: (state, load) => { 
      state.isLoading = false;
    },
      // ---------UPDATE COURSE PROGRESS ---------/
      [updateCourseProgress.pending]: (state) => {
      },
      [updateCourseProgress.fulfilled]: (state, { payload }) => {
      },
      [updateCourseProgress.rejected]: (state, load) => { 
      },
  },
});

export const { clearCourse, clearUnauthorisation } = coursesSlice.actions;

export default coursesSlice.reducer;
