import React from 'react';
import CountDown from '../../components/dashboard/resources/CountDown';
import { Box } from '@mui/material';

export default function ComingSoon() {
  return (
    <Box
      sx={{
        marginTop: '120px',
      }}
    >
        
        <CountDown 
            launchDate="2024-09-15T00:00:00"
            headerText="We are launching soon!"
        />
        
    </Box>
  )
}
