import React from "react";
import styles from "./TermsModal.module.scss";

const TermsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) onClose();
  };

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <h2>Terms and Conditions for Selling on Safeticha</h2>
        <div className={styles.content}>
          <h3>1. Eligibility</h3>
          <p>
            To be eligible to sell resources on our platform you must:
            <ul>
              <li>Be a registered teacher or an educational professional with the requisite qualifications and expertise.</li>
              <li>Have the legal right to sell the resources you create and represent that you are the original author or owner.</li>
              <li>Be at least 18 years old or older and possess the legal capacity to enter into binding agreements.</li>
            </ul>
            By registering for an account and selling resources, you confirm that you meet all of these criteria.
          </p>

          <h3>2. Resource Ownership & Intellectual Property</h3>
          <p>
            <strong>Ownership:</strong> You affirm that you are the sole author, creator, and owner of all rights, titles, and interests in and to the resources you upload for sale. This includes, but is not limited to, the text, graphics, videos, activities, and other content provided in the resource.
          </p>
          <p>
            <strong>Right to Sell:</strong> You guarantee that the resources you sell do not infringe upon any third-party intellectual property rights, including but not limited to copyrights, trademarks, and patents. You must ensure that you have the necessary rights, permissions, or licenses to distribute any content within your resources that you do not own (e.g., images, clipart, or texts obtained from other sources).
          </p>
          <p>
            <strong>Representation and Warranty:</strong> By uploading a resource, you represent and warrant that:
            <ul>
              <li>The resource is entirely your own original work.</li>
              <li>It does not infringe upon the intellectual property, privacy, or publicity rights of any third party.</li>
              <li>You have not misappropriated anyone else’s work and you have the appropriate rights to commercialize any external content used.</li>
            </ul>
          </p>

          <h3>3. Prohibited Content</h3>
          <p>
            You may not upload or sell any resources that:
            <ul>
              <li>Violate intellectual property rights, including unauthorized or unlicensed use of copyrighted material.</li>
              <li>Are illegal, harmful, discriminatory, offensive, or inappropriate for the intended educational audience.</li>
              <li>Contain sensitive information, including the personal information of children or any confidential data.</li>
              <li>Promote violence, hatred, or illegal activities.</li>
            </ul>
            We reserve the right to review, suspend, or remove any resource that violates these terms or is deemed inappropriate for sale on our platform.
          </p>

          <h3>4. Commission and Payment Terms</h3>
          <p>
            <strong>Commission Fee:</strong> Our platform will charge a 20% commission on the sale price of each resource sold. This means that 80% of the sale proceeds will be paid to you.
          </p>
          <p>
            <strong>Payments:</strong> Payments will be made to you on a monthly basis after deducting the platform’s commission and any applicable taxes. Payment will be processed using the payment details provided in your account.
          </p>
          <p>
            <strong>Pricing:</strong> You are responsible for setting the price of your resources, but we reserve the right to establish pricing guidelines to ensure consistency and fairness across the platform.
          </p>

          <h3>5. Copyright Infringement and Plagiarism</h3>
          <p>
            <strong>Liability Disclaimer:</strong> We are not responsible for any instances of copyright infringement or plagiarism by sellers using our platform. The responsibility to ensure that the resource complies with intellectual property laws rests solely with the seller.
          </p>
          <p>
            <strong>Third-Party Claims:</strong> If a third party asserts that your resource infringes upon their intellectual property rights, you agree to fully cooperate with us in resolving the matter. You also agree to indemnify and hold us harmless from any claims, damages, or legal expenses arising from such infringement claims.
          </p>
          <p>
            <strong>Reporting Violations:</strong> Any user may report suspected plagiarism or IP violations. We take such reports seriously and will take action as necessary, including removing the offending material and suspending or terminating accounts of those found in violation.
          </p>

          <h3>6. Termination of Account</h3>
          <p>
            We reserve the right to terminate or suspend your account and remove your resources from the platform if:
            <ul>
              <li>You breach any of these terms and conditions.</li>
              <li>We receive repeated and verified complaints about the quality or originality of your resources.</li>
              <li>You engage in illegal or unethical conduct or provide false information to us or buyers.</li>
            </ul>
            You may also choose to close your account at any time, but doing so will not relieve you of any outstanding obligations regarding sales made prior to the account closure.
          </p>

          <h3>7. Limitation of Liability</h3>
          <p>
            <strong>General Limitation:</strong> Our total liability to you for any claim, whether in contract, tort, or otherwise, arising out of your use of the platform or your sales activities will be limited to the total amount of commissions you have earned in the 12 months preceding the claim.
          </p>
          <p>
            <strong>No Liability for Indirect Damages:</strong> Under no circumstances will we be liable for any indirect, incidental, special, or consequential damages, including lost profits, loss of data, or interruption of business arising out of or in connection with the use of our platform.
          </p>

          <h3>8. Indemnification</h3>
          <p>
            You agree to indemnify, defend, and hold us harmless from any and all claims, damages, liabilities, and expenses (including legal fees) arising out of or related to your breach of these terms or any unauthorized use of the platform.
          </p>

          <h3>9. Changes to Terms</h3>
          <p>
            We reserve the right to modify these terms and conditions at any time. Changes will be effective once posted on the platform, and your continued use of the platform constitutes acceptance of the revised terms.
          </p>

          <p>By uploading and selling resources on Safeticha, you agree to comply with all of the terms outlined above. If you have any questions about these terms, please contact us at safeticha@gmail.com.</p>

          <div className={styles.footer}>
            <button className={styles.footerCloseButton} onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
