import React, { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppButton from "../../buttons/AppButton";
import { PAGES } from "../../../utils/constant";
import { ForgotPasswordStyles } from "./ForgotPasswordStyles.styles";

import VerificationInput from "react-verification-input";
import classes from "../Signup/VerificationInput.module.css";
import AppInput from "../../inputs/AppInput";
import AppInputPassword from "../../inputs/AppInputPassword";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../../redux/auth/actions";
import { showSimpleToast } from "../../../utils/alertFunctions";

const ForgotPasswordVerification = ({email}) => {
  const [tokenInput, setTokenInput] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch()
  

  const navigate = useNavigate();
  const {forgotPasswordEmail, isBtnLoading} = useSelector(state => state.authReducer)

  const onResetPassword = () =>{
    if(tokenInput.length !== 4){
      showSimpleToast("Please input the token sent to you","failed");
      return
    }
    const data = {
      email: forgotPasswordEmail,
      newPassword: password,
      token:tokenInput
    }
    dispatch(resetPassword({data, callBack: ()=>{
      setTimeout(() => {
        navigate(PAGES.login)
      }, 2000);
    }}))
  }

  return (
    <>
      <Container sx={ForgotPasswordStyles.container}>
        <Typography sx={ForgotPasswordStyles.headerText}>
          Reset Password
        </Typography>
        <Typography sx={ForgotPasswordStyles.headerSubText}>
          Enter the 4 digit code sent to your email address to reset your
          password
        </Typography>

        <AppInputPassword
        onChange={(e)=> setPassword(e.target.value)}
        value={password}
        name="password"
        label="New Password"
        placeholder="Enter your new password"
      />
      <Typography></Typography>

        <Typography>Verification code</Typography>

        <VerificationInput
          classNames={{
            container: classes.container,
            character: classes.character,
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
          value={tokenInput}
          length={4}
          validChars="0-9"
          inputProps={{ type: "tel" }}
          placeholder=""
          onChange={setTokenInput}
        />

        {/* <Box sx={ForgotPasswordStyles.resendCode}>
          <Typography variant="span">Didn't get a code?</Typography>{" "}
          <Typography
            variant="span"
            onClick={() => {
              alert("Email sent, check again!");
            }}
            sx={ForgotPasswordStyles.createAccLink}
          >
            Resend
          </Typography>
        </Box> */}

        <AppButton
          onClick={onResetPassword}
          customStyles={{ fontWeight: "bold" }}
          btnText="Reset Password"
          loadingComponent="Processing..."
          isLoading={isBtnLoading}
        />

        <Box sx={ForgotPasswordStyles.createAcc}>
          <Typography variant="span">Remembered Password?</Typography>{" "}
          <Typography
            variant="span"
            onClick={() => navigate(PAGES.login)}
            sx={ForgotPasswordStyles.createAccLink}
          >
            Login
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default ForgotPasswordVerification;
