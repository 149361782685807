import React, { useState, useEffect, useRef } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppButton from "../../buttons/AppButton";
import { PAGES } from "../../../utils/constant";
import Modal from "../../modal/Modal";
import SignupVerifyModal from "./SignupVerifyModal";

import { ForgotPasswordStyles } from "../Login/ForgotPasswordStyles.styles";

import { useDispatch, useSelector } from "react-redux";
import { sendVerificationMail, verifyUserEmail } from "../../../redux/auth/actions";

import { clearRegisteredUserEmail } from "../../../redux/auth/slice";

import VerificationInput from "react-verification-input";
import classes from "./VerificationInput.module.css";

const SignupEmailVerification = () => {
  const [showModal, setShowModal] = useState(false);

  const { profileData } = useSelector(state => state.dashboardReducer)

  const dispatch = useDispatch();
  const { registeredUserEmail, isBtnLoading, isUserVerified } = useSelector(
    (state) => state.authReducer
  );
  const sentVerificationEmail = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      if (profileData && !sentVerificationEmail.current) {
        sentVerificationEmail.current = true;
        dispatch(sendVerificationMail({ email: profileData.email, id: profileData.id }))
      }
    }, 1000);
    
  }, [dispatch, profileData])

  const navigate = useNavigate();

  const [tokenInput, setTokenInput] = useState("");

  const handleVerifyEmail = () => {
    dispatch(
      verifyUserEmail({
        email: registeredUserEmail || profileData.email,
        token: tokenInput,
      })
    );
  };

  const onResendCode = () => {
    //TODO
  }

  useEffect(() => {
    if (isUserVerified) {
      setShowModal(!showModal);
      dispatch(clearRegisteredUserEmail());
    }
  }, [isUserVerified]);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {showModal && (
        <Modal showModal={showModal} handleModal={handleModal}>
          <SignupVerifyModal handleModal={handleModal} />
        </Modal>
      )}
      <Container sx={ForgotPasswordStyles.container}>
        <Typography sx={ForgotPasswordStyles.headerText}>
          Verify Your Email
        </Typography>
        <Typography sx={ForgotPasswordStyles.headerSubText}>
          We sent a 4-digit code to {registeredUserEmail}. Code expires in 10
          minutes
        </Typography>

        <Typography>Verification code</Typography>

        <VerificationInput
          classNames={{
            container: classes.container,
            character: classes.character,
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
          value={tokenInput}
          length={4}
          validChars="0-9"
          inputProps={{ type: "tel" }}
          placeholder=""
          onChange={setTokenInput}
        />

        <Box sx={ForgotPasswordStyles.resendCode}>
          <Typography variant="span">Didn't get a code?</Typography>{" "}
          <Typography
            variant="span"
            onClick={onResendCode}
            sx={ForgotPasswordStyles.createAccLink}
          >
            Resend
          </Typography>
        </Box>

        <AppButton
          onClick={() => {
            handleVerifyEmail();
          }}
          customStyles={{ fontWeight: "bold" }}
          btnText={isBtnLoading ? "Verifying" : "Verify"}
          disabled={isBtnLoading}
        />
        {profileData && <AppButton
          onClick={() => {
            navigate(-1);
          }}
          customStyles={{ fontWeight: "bold",marginTop: "20px" }}
          btnText="Go Back"
          variant="outlined"
        />}

        {!profileData && <Box sx={ForgotPasswordStyles.createAcc}>
          <Typography variant="span">Already have an account?</Typography>{" "}
          <Typography
            variant="span"
            onClick={() => navigate(PAGES.login)}
            sx={ForgotPasswordStyles.createAccLink}
          >
            Login
          </Typography>
        </Box>}
      </Container>
    </>
  );
};

export default SignupEmailVerification;
