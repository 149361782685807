import React from "react";
import SignupForm from "../../components/onboarding/Signup/SignupForm";
import OnboardingLayout from "../../components/onboarding/OnboardingLayout";
import OnboardingImage from "../../assets/img/OnboardingImage.png";
import Dummy from "../../components/dummy";

const Signup = ({forceChangePassword}) => {
  return (
    <>
    {/* <Dummy /> */}
        <OnboardingLayout image={OnboardingImage}>
      <SignupForm forceChangePassword={forceChangePassword} />
    </OnboardingLayout>
    </>

  );
};

export default Signup;
