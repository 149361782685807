import React, { useEffect, useState } from 'react'
import CustomTabPanel from '../../CustomTabPanel';
import { Box, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import JobCards from './JobCard';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableJobs, getJobsAppliedFor } from '../../../redux/jobs/action';

export default function TeacherJobView() {
    const dispatch = useDispatch()
    const handleChange = (event, newValue) => {
        if(newValue == 1){
            dispatch(getJobsAppliedFor())
        }
        setValue(newValue);
    };
    const { availableJobs,isFetchingAppliedJobs, appliedJobs, isFetchingJobs } = useSelector(state => state.jobReducer)
    const [value, setValue] = useState(0);
    const a11yProps =(index) =>{
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    console.log('appliedJobs :>> ', appliedJobs);

    useEffect(()=>{
        dispatch(getAvailableJobs())
    }, [])
  return (
   <>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                       <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Find Jobs" {...a11yProps(0)} />
                            <Tab label="Applied Jobs" {...a11yProps(1)} />
                                           
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <>
                        {isFetchingJobs && <CircularProgress />}
                        {availableJobs.length == 0 && <Box textAlign="center" mt={4}>
                        <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }} > No matching jobs at the moment.</Typography>
                    </Box>}
                       {availableJobs?.map(job => <JobCards job={job} />)}
                        </>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                    <>
                    {isFetchingAppliedJobs && <CircularProgress />}
                    {appliedJobs.length == 0 && <Box textAlign="center" mt={4}>
                        <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }} > You haven't applied for any job</Typography>
                    </Box>}
                    {appliedJobs.map(data => <JobCards job={{...data.vacancy,status:data.status}} isApplied />)}
                        </>
                    </CustomTabPanel>

   </>
  )
}
