

import React, { useState, useRef, Fragment, useEffect } from 'react';
import { Box, Typography, Grid } from "@mui/material";
import "../../../index.css";
import ResourcesIcon from "../../../assets/svg/our-goals/resources-icon.svg";
import FindJobsIcon from "../../../assets/svg/our-goals/find-jobs-icon.svg";
import FindTeachersIcon from "../../../assets/svg/our-goals/teacher-icon.svg";
import AiIcon from "../../../assets/svg/our-goals/ai-icon.svg";
import BookIcon from "../../../assets/svg/resource-marketplace/book-icon.svg"
import { tagList } from "../../../utils/constant";
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import "../../../index.css";


const ResourceMarketplace = () => {

  function truncateText(text, maxLength = 18) {
    if (typeof text !== 'string') {
      return ''; // Or handle non-string input as needed
    }
  
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) ;
    }
  }

  const sliderRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = [];
    const bgColors = ["rgba(255, 229, 244, 1)", "rgba(255, 232, 210, 1)", "rgba(229, 251, 255, 1)", "rgba(204, 229, 255, 1)"]
    for (let index = 0; index < tagList.length; index += 4) {
      const slideItems = tagList.slice(index, index + 4);
      slides.push(slideItems);
      console.log(slideItems)
    }

    const settings = {
      dots: false, // Disable default dots
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 5000,
      afterChange: (currentSlide) => {
        // This function will be called after each slide change
        //console.log(currentSlide, currentIndex)
        setCurrentIndex(currentSlide);
      },
    };
  
    const goToSlide = (slideIndex) => {
      console.log(slideIndex)
      sliderRef.current.slickGoTo(slideIndex);
    };

    
      

  return (
    <Box bgcolor="rgba(48, 64, 89, 1)" padding={{xs: "30px 0px", md: "70px 0px" }} width="100%" display="flex" flexDirection="column" gap={2} alignItems="center">

      <Typography variant="h4" className="font-sora" color="rgba(255, 11, 117, 1)" fontSize={{ xs: "14px", md: "18px"}} align="center" fontWeight={400}>
        RESOURCE MARKETPLACE
      </Typography>

      <Typography variant="h5" maxWidth="600px" className="font-sora" color="#ffffff" fontSize={{ xs: "25px", md: "35px", lg: "50px"}} lineHeight={{md: "40px", lg: "60px"}} align="center" fontWeight={600} px="20px">
      Browse through our popular Resources
      </Typography>

      <Typography variant="p" maxWidth="800px" className="font-open-sans" color="#ffffff" fontSize={{ xs: "14px", md: "18px"}} align="center" fontWeight={400} px="20px">
        Over 1000 resources made by teachers on our platform for everyone’s use
      </Typography>



      <Slider ref={sliderRef} {...settings} style={{ width: "100%" }}>

        {slides.map((slide, index) => (
          <div key={index}>
            <Grid container padding={{ xs: "0px 10px", sm: "0px 70px" }}  spacing={{xs: 1, sm:2}} marginTop={{ xs: "15px", md: "40px" }}>
              {slide.map((item, itemIndex) => (
                <Grid key={itemIndex} item xs={6} md={3}>
                  <Box component="div" maxWidth="300px" margin="auto" padding={{ xs: "7px 5px", sm: "10px 7px", lg: "25px 20px 15px" }} width="100%" borderRadius="20px" display="flex" flexDirection="column" bgcolor={bgColors[itemIndex]} alignItems="center">
                    <Box component="div" width="100px" height="100px" borderRadius="50px" bgcolor="rgba(69, 107, 163, 1)" display="flex" alignItems="center" justifyContent="center">
                      <Box component="img" src={BookIcon} width="40px" />
                    </Box>

                    <Box bgcolor="white" display="flex" flexDirection="column" alignItems="center" width="100%" mt={3} borderRadius="15px" padding={{xs: 1, sm: 2, md:1, lg: 3}}>
                      <Typography textTransform="capitalize" fontSize={{ xs: "14px", sm: "20px", md: "16px", lg: "20px" }} fontWeight={700} mt={1} className="font-sora" color="rgba(16, 10, 55, 1)">{truncateText(item)}</Typography>

                      {/* <Typography className="font-open-sans" fontSize="12px" color="rgba(170, 170, 170, 1)" my={1}>243 resources</Typography> */}

                      <Box component="a" href={`/marketplace?type=${item.toLocaleLowerCase()}`} style={{textDecoration: "none"}} mt={2} fontWeight={600} fontSize={{xs: "10px", sm: "14px", md: "10px", lg: "14px"}} color="rgba(0, 0, 0, 1)" border="1px solid rgba(170, 170, 170, 1)" px={3} py={1.5} borderRadius="12px">Explore Category</Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </Slider>

      <Box display="flex" alignItems="center" justifyContent="center" gap={3} mt={2}>
        <button onClick={()=>goToSlide(currentIndex - 1)} style={{ border: "1px solid #ffffff", width: "40px", height: "40px", borderRadius: "20px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "transparent"}}><ArrowBackIos style={{marginLeft: "7px"}} sx={{color: "#ffffff"}} /></button>
        <button onClick={()=>goToSlide(currentIndex + 1)} style={{ border: "1px solid #ffffff", width: "40px", height: "40px", borderRadius: "20px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "transparent"}}><ArrowForwardIos style={{marginLeft: "4px"}} sx={{color: "#ffffff"}} /></button>
      </Box>


      <Box component="a" href='/marketplace' fontWeight={600} fontSize="14px" color="rgba(0, 0, 0, 1)" bgcolor="#ffffff" border="1px solid rgba(170, 170, 170, 1)" px={5} py={2} borderRadius="25px" mt={4} style={{textDecoration: "none"}}>View all resources</Box>
      
    </Box>
  );
};

export default ResourceMarketplace;

