import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const RadioSelect = () => {
  return (
    <FormControl>
      {/* <FormLabel id="demo-row-radio-buttons-group-label">Pay with:</FormLabel> */}
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        defaultValue="card"
      >
        <FormControlLabel value="card" control={<Radio />} label="Card" />
        <FormControlLabel value="bank" control={<Radio />} label="Bank" />
        <FormControlLabel
          value="transfer"
          control={<Radio />}
          label="Transfer"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioSelect;
