import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const BasicModal = ({ showModal, handleModal, children, disableOutsideClick }) => {
  const checkBeforeClose =(event, reason) =>{
    if (disableOutsideClick &&reason && reason == "backdropClick") {
      return;
    }
    handleModal()
  }
  return (
    <Modal
      open={showModal}
      onClose={checkBeforeClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={styles.modal}
    >
      <Box sx={styles.children}>{children}</Box>
    </Modal>
  );
};

export default BasicModal;

const styles = {
  modal: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  children: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90vw", sm: 500 },
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 3,
  },
};
