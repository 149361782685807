import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationIcon from "../../assets/svg/dashboard-icons/NotificationIcon";
import AppButton from "../../components/buttons/AppButton";
import { respondToSchoolInvite } from "../../redux/school/action";
import EmptyCart from "../../components/dashboard/Cart/EmptyCart";
import { useNavigate } from "react-router-dom";
import { getUserNotifications } from "../../redux/user/action";

const DashboardNotifications = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {userNotifications, isFetching, isLoading} = useSelector(state => state.userReducer);
    const [submiting, setSubmiting] = useState(null)
  const { userData } = useSelector(state => state.authReducer)

    useEffect(() =>{
      dispatch(getUserNotifications());
    },[])

    const onAcceptRequest = (schoolId, index) =>{
        if(window.confirm("Accept your invitation to join the school")){
            setSubmiting(index)
            dispatch(respondToSchoolInvite({data: {schoolId, accepted:true}}));
        }
    }

    const onRejectRequest = (schoolId, index) =>{
         if(window.confirm("Are you sure you want to reject this invitation?")){
            setSubmiting(index)
            dispatch(respondToSchoolInvite({data: {schoolId, accepted:false}}));
        }
    }
  return (
    <Box variant="section" sx={styles.container}>
    <Typography
      fontSize="20px"
      fontWeight="600"
      color="#5C5C5C"
      pt="20px"
      pl="20px"
      pb="5px"
    >
      Notifications
    </Typography>
    {!isFetching && userNotifications.length== 0 && <EmptyCart text="You do not have any notification at the moment." btnAction={() => navigate("/dashboard")} btnText="Go back" />}
    {!isFetching && userNotifications.length > 0 && 
    userNotifications.map((notification, index) => <Box style={styles.notificationBox} key={index}>
        <NotificationIcon />
        <Box style={{marginLeft: 20}}>
        <Typography >{notification.description}</Typography>
        {notification.notificationType == "invite" && <Box style={{display:"flex", marginTop:"5px"}}>
            {!isLoading && submiting != index && 
            <>
             <AppButton onClick={()=> onAcceptRequest(notification.schoolId, index)} btnText="Accept" customStyles={{marginRight: "10px", width: "150px"}} />
            <AppButton  onClick={()=> onRejectRequest(notification.schoolId, index)}  btnText="Reject" variant="outlined" customStyles={{width: "150px"}} />
            </>
            }
            {isLoading && submiting == index && <CircularProgress />}
        </Box>}
        {notification.notificationType == "jobMessage" && <Box style={{display:"flex", marginTop:"5px"}}>
            {!isLoading && submiting != index && 
            <>
             <AppButton onClick={()=> navigate(userData.userType == "school" ? `/jobs/view-application/${notification?.applicationId}?tab=1`: `/jobs/${notification?.jobId}?tab=1`)} btnText="View Message" fullWidth={false} customStyles={{marginRight: "10px"}} />
            </>
            }
            {isLoading && submiting == index && <CircularProgress />}
        </Box>}
        </Box>
       
    </Box>)
    }

    {isFetching && <CircularProgress style={{textAlign:"center", margin:20}} />}
  </Box>
  );
};

export default DashboardNotifications;

const styles = {
    container: {
      width: "95%",
      margin: "0 auto",
      background: "#f7f7fb",
      borderRadius: "10px",
      marginTop: "10px",
      border: "1px solid #EFEFEF",
    },
    notificationBox:{
        margin: "20px 30px",
        display: "flex",
        alignItems: 'center',
    }
  };
  
