import React, { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppInput from "../../inputs/AppInput";
import AppButton from "../../buttons/AppButton";
import { PAGES } from "../../../utils/constant";
import Modal from "../../modal/Modal";
import ResetPasswordModal from "./ResetPasswordModal";

import { ForgotPasswordStyles } from "./ForgotPasswordStyles.styles";

const ResetPassword = () => {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {showModal && (
        <Modal showModal={showModal} handleModal={handleModal}>
          <ResetPasswordModal handleModal={handleModal} />
        </Modal>
      )}
      <Container sx={styles.container}>
        <Typography sx={ForgotPasswordStyles.headerText}>
          Reset Password
        </Typography>
        <Typography sx={ForgotPasswordStyles.headerSubText}>
          Enter the code sent to the provided email address
        </Typography>

        <AppInput
          label="New Password"
          placeholder="Set a 8-character password"
        />
        <AppInput
          label="Confirm Password"
          placeholder="Enter the password again"
        />

        <AppButton
          onClick={handleModal}
          customStyles={{ fontWeight: "bold" }}
          btnText="Reset Password"
        />

        <Box sx={ForgotPasswordStyles.createAcc}>
          <Typography variant="span">Already have an account?</Typography>{" "}
          <Typography
            variant="span"
            onClick={() => navigate(PAGES.login)}
            sx={ForgotPasswordStyles.createAccLink}
          >
            Login
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default ResetPassword;

const styles = {
  container: {
    ...ForgotPasswordStyles.container,
    marginTop: "80px",
    height: "80%",
  },
};
