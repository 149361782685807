import React from "react";
import WatchCourse from "../../components/dashboard/SharedComponents/WatchCourse/WatchCourse";
import { useParams } from "react-router-dom";

const DashboardWatchCourse = () => {
  const {courseId} = useParams()
  return <WatchCourse courseId={courseId}  />;
};

export default DashboardWatchCourse;
