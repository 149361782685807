import { createSlice } from "@reduxjs/toolkit";
import { getModuleQUestions, submitModuleQuestions } from "./action";

export const questionSlice = createSlice({
  name: "question",
  initialState: {
    questionData: {},
    isLoadingQuestions: false,
    submiting: false,
    submissionData: {}
  },
  reducers:{
    clearSubmissionData: (state) => {
        state.submissionData = {};
      },
  },
  extraReducers: {
    // ---------GET MODULE  QUESTIONS ---------/
    [getModuleQUestions.pending]: (state) => {
        state.submissionData = {};
      state.isLoadingQuestions = true;
    },
    [getModuleQUestions.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        console.log('payload.data :>> ', payload.data);
        state.questionData = payload.data;
      }
      state.isLoadingQuestions = false;
    },
    [getModuleQUestions.rejected]: (state, load) => {
      state.isLoadingQuestions = false
    },
 // ---------SUBMIT MODULE  QUESTIONS ---------/
 [submitModuleQuestions.pending]: (state) => {
    state.submiting = true;
  },
  [submitModuleQuestions.fulfilled]: (state, { payload }) => {
    if (payload.status === "success") {
      console.log('payload.dataSUBMIT :>> ', payload.data);
      state.submissionData = payload.data;
    }
    state.submiting = false;
  },
  [submitModuleQuestions.rejected]: (state, load) => {
    state.submiting = false
  },
  },
});

export const { clearSubmissionData } = questionSlice.actions;

export default questionSlice.reducer;
