import React, {useState, useEffect} from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function CountDown({ launchDate, headerText, subHeaderText }) {
    const navigate = useNavigate();

    const [timeLeft, setTimeLeft] = useState({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      });
    
      useEffect(() => {
        const calculateTimeLeft = () => {
          const now = new Date();
          const difference = new Date(launchDate) - now;
    
          if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);
    
            setTimeLeft({
              days: String(days).padStart(2, '0'),
              hours: String(hours).padStart(2, '0'),
              minutes: String(minutes).padStart(2, '0'),
              seconds: String(seconds).padStart(2, '0'),
            });
          } else {
            setTimeLeft({
              days: '00',
              hours: '00',
              minutes: '00',
              seconds: '00',
            });
          }
        };
    
        const timer = setInterval(calculateTimeLeft, 1000);
    
        return () => clearInterval(timer);
      }, [launchDate]);


  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      
    >
      <Typography sx={{ fontSize: { xs:"30px", sm:"48px" } }} fontWeight="600" textAlign="center">
        {headerText}
      </Typography>

      <Typography sx={{ fontSize: { xs:"14px" } }} fontWeight="400" mb={2} textAlign="center">
        {subHeaderText}
      </Typography>
         
         
        <Grid container backgroundColor = "white" borderRadius={2} gap= "10px" justifyContent="center" mb={2}
            sx={{
                    boxShadow: "0px 1px 1px 0px #00000040",
                    padding: "20px 20px 20px 20px",
                    borderRadius: "14px",
                    width: {
                       xs: "300px",
                       sm: "600px"
                      },
                      "@media (max-width: 300px)": {
                        width: "250px", // width for screens smaller than xs (360px)
                      },
                    }}
        >
            
                <Grid item>
                    <Box display="flex" justifyContent="center" gap="10px">
                        <Box textAlign="center">    
                            <Typography fontWeight="600" color = 'black'
                                sx={{ fontSize: { xs:"32px", sm:"64px" } }}
                            >
                                {timeLeft.days}
                            </Typography>
                            
                            <Typography component="div" sx={{
                                fontSize: { xs:"18px", sm:"24px", mt: "-8px"  }
                            }}
                                
                                > Days </Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item>
                 <Box> 
                    <Typography  sx={{ fontSize: { xs:"32px", sm:"64px" } }} component="span" fontWeight="600">:</Typography>
                 </Box>
                </Grid>

                <Grid item>
                    <Box display="flex" justifyContent="center" gap="10px">
                        <Box textAlign="center">    
                            <Typography sx={{ fontSize: { xs:"32px", sm:"64px" } }} fontWeight="600" color = 'black'>
                                {timeLeft.hours}
                            </Typography>
                            <Typography component="div" sx={{
                                fontSize: { xs:"18px", sm:"24px", mt: "-8px"  }
                            }}> Hours </Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item>
                 <Box> 
                    <Typography  sx={{ fontSize: { xs:"32px", sm:"64px" } }} component="span" fontWeight="600">:</Typography>
                 </Box>
                </Grid>

                <Grid item>
                    <Box display="flex" justifyContent="center" gap="10px">
                        <Box textAlign="center">    
                            <Typography sx={{ fontSize: { xs:"32px", sm:"64px" } }} fontWeight="600" color = 'black'>
                                {timeLeft.minutes}
                            </Typography>
                            <Typography component="div" sx={{
                                fontSize: { xs:"18px", sm:"24px", mt: "-8px"  }
                            }}> Minutes </Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item>
                 <Box> 
                    <Typography  sx={{ fontSize: { xs:"32px", sm:"64px" } }} component="span" fontWeight="600">:</Typography>
                 </Box>
                </Grid>

                <Grid item>
                    <Box display="flex" justifyContent="center" gap="10px">
                        <Box textAlign="center">    
                            <Typography sx={{ fontSize: { xs:"32px", sm:"64px" } }}fontWeight="600" color = '#2E3192'>
                                {timeLeft.seconds}
                            </Typography>
                            <Typography component="div" sx={{
                                fontSize: { xs:"18px", sm:"24px", mt: "-8px"  }
                            }}> Seconds </Typography>
                        </Box>
                    </Box>
                </Grid>
        </Grid>

        <Button variant="contained" 
            sx={{
                padding:{
                    xs:"10px 70px",
                    sm:"10px 110px"
                },
                fontSize:{
                    xs:"18px",
                    sm:"24px"
                },
            }}
            onClick={() => window.open("https://forms.gle/21NjpV46iWYRQjow7", "_blank")}
        >
            I'm interested!
        </Button>
        

    </Box>
  )
}


