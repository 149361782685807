import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import AppInput from "../../inputs/AppInput";
import AppButton from "../../buttons/AppButton";

const CheckoutRightGrid = () => {  
  return (
    <Grid
      item
      md={6}
      backgroundColor="#F9FAFA"
      height="100vh"
      p="100px 150px 0px 80px"
    >
      <Typography
        fontSize="20px"
        fontWeight="600"
        pb="10px"
        borderBottom="1px solid #acacac"
        mb="20px"
      >
        Order Summary
      </Typography>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb="30px"
        borderBottom="1px solid #acacac"
      >
        <Box display="flex" alignItems="center" gap="30px">
          <Box
            height="70px"
            width="70px"
            borderRadius="10px"
            backgroundColor="#00000033"
          ></Box>
          <Box>
            <Typography fontSize="18px" fontWeight="400">
              Taxmax Leather Bag
            </Typography>
            <Typography fontSize="16px" fontWeight="400" color="#acacac">
              Brown Vegan Leather
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography fontSize="18px" fontWeight="400">
            $49.80
          </Typography>
          <Typography fontSize="16px" fontWeight="400" color="#acacac">
            Qty: 2
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        gap="30px"
        width="100%"
        mt="20px"
        pb="25px"
        borderBottom="1px solid #acacac"
      >
        <Box flexGrow="2">
          <AppInput
            noBottomPad
            bgColor="white"
            placeholder="Gift or gift code"
          />
        </Box>

        <AppButton
          customStyles={{
            fontWeight: "600",
            borderRadius: "8px",
          }}
          btnText="Apply"
          disabled={true}
          fullWidth={false}
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt="15px"
      >
        <Typography fontSize="16px">Subtotal</Typography>
        <Typography fontSize="16px">$49.80</Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt="15px"
        pb="25px"
        borderBottom="1px solid #acacac"
      >
        <Typography fontSize="16px">Shipping</Typography>
        <Typography fontSize="16px">$7.24</Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt="15px"
      >
        <Box>
          <Typography fontSize="18px" fontWeight="400">
            Total:
          </Typography>
          <Typography fontSize="16px" fontWeight="400" color="#acacac">
            Including $2.24 in taxes
          </Typography>
        </Box>

        <Typography fontSize="25px" fontWeight="600">
          ₦3,900
        </Typography>
      </Box>
    </Grid>
  );
};

export default CheckoutRightGrid;
