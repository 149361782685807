import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: { main: "#2E3192" },
    success: { main: "#0FBD3B" },
  },
  typography: {
    fontFamily: ["Sora", "Open Sans", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
  },
  // breakpoints: {
  //   values: {
  //     mobile: 0,
  //     tablet: 768,
  //     laptop: 1024,
  //     desktop: 1200
  //   }
  // }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
