import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet, apiPost } from "../../service/apiService";
import { API_URL } from "../../utils/apiConstants";

export const getUserDocuments = createAsyncThunk(
  "document/getUserDocuments",
  async (payload) => {
    const url2call = payload.userId ? `${API_URL.getUserDocuments}/${payload.userId}` :API_URL.getUserDocuments;
    return apiGet(url2call)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);


export const uploadProfileDocument = createAsyncThunk(
    "dashboard/uploadProfileDocument",
    async (payload) => {
      return apiPost(API_URL.uploadProfileDocument, payload)
        .then((res) => {
          // payload.callBack();
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    }
  );
