// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NTgvrL3077x0JfACFnKX {
    height: inherit;
    width: inherit
}`, "",{"version":3,"sources":["webpack://./src/components/landing-page/WhyChooseUs/WhyChooseUs.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf;AACJ","sourcesContent":[".image {\n    height: inherit;\n    width: inherit\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `NTgvrL3077x0JfACFnKX`
};
export default ___CSS_LOADER_EXPORT___;
