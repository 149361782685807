import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import "../../../index.css";
import logo from "../../../assets/svg/logo.svg";
import SafetichaLogo from "../../../assets/svg/SafetichaLogo.svg";
import AppButton from "../../buttons/AppButton";
import { useNavigate } from "react-router-dom";

import NavProductsDropdown from "./NavProductsDropdown";
import NavEventDropDown from "./NavEventDropDown";
import CartIcon from "../../../assets/svg/dashboard-icons/CartIcon";
import { useSelector } from "react-redux";
import { KeyboardArrowDown } from "@mui/icons-material";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { cartCourses } = useSelector((state) => state.cartReducer);

  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    if (cartCourses.length) {
      const items = cartCourses.filter(x => x.type == "resource");
      setCartItems(items);
    }

  }, [cartCourses])

  const openNav = () => {
    setIsOpen(!isOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      maxWidth="100%"
      component="nav"
      display="flex"
      position="sticky"
      top="0px"
      zIndex={200}
      backgroundColor="#ffffff"
      alignItems="center"
      px={{ xs: "30px", md: "50px" }}
      py={{ xs: "15px", md: "10px" }}
      boxShadow="0px 0px 5px 0px rgba(0,0,0,0.75)"
    >
      <Box display={{ xs: "none", lg: "block" }}>
        <img src={logo} style={{ cursor: "pointer", marginLeft: "-25px"}} height={70} alt="logo" onClick={() => navigate("/")} />
      </Box>

      <Box display={{ xs: "block", lg: "none" }} zIndex="105" >
        <img src={SafetichaLogo} style={{ cursor: "pointer" }} height={45} alt="logo" onClick={() => navigate("/")} />
      </Box>

      <Box
        position="relative"
        sx={{ cursor: "pointer", display: { xs: "flex", lg: "none" }, marginLeft: "auto", marginRight: "30px" }}
      >
      </Box>

      <MenuIcon onClick={openNav} fontSize="large" sx={styles.hamburger} />

      <Box
        component="ul"
        flexDirection={{ xs: "column", md: "row" }}
        backgroundColor={{ xs: "white", md: "inherit" }}
        position={{ xs: "fixed", md: "relative" }}
        height={{ xs: "100%", md: "auto" }}
        width={{ xs: "100%", md: "auto" }}
        top={{ xs: "0", md: "auto" }}
        left={{ xs: "0", md: "auto" }}
        zIndex={{ xs: "100", md: "1" }}
        alignItems="center"
        justifyContent={{ md: "space-between" }}
        gap={{ xs: "30px", lg: "50px" }}
        pt={{ xs: "120px", md: "0" }}
        sx={styles.showNav(isOpen)}
        pl="0"
        mt="0"
        mb="0"
        ml="auto"
      >
        <CloseIcon onClick={openNav} fontSize="large" sx={styles.close} />

        <Button component="li" sx={styles.linkText} className="font-open-sans" onClick={() => navigate("/")}>
          Home
        </Button>

        {/* <div>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{color: "#000000", fontSize: "16px", fontWeight: 400}}
          >
            Services <KeyboardArrowDown fontSize="small" />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem sx={{ minWidth: "150px"}} onClick={handleClose}>Service 1</MenuItem>
            <MenuItem sx={{ minWidth: "150px"}} onClick={handleClose}>Service 2</MenuItem>
            <MenuItem sx={{ minWidth: "150px"}} onClick={handleClose}>Service 3</MenuItem>
          </Menu>
        </div> */}

        <Button component="li" sx={styles.linkText} className="font-open-sans" onClick={() => navigate("/marketplace")}>
          Resources
        </Button>

        {/* <Button component="li" sx={styles.linkText} className="font-open-sans" onClick={() => navigate("/marketplace")}>
          About
        </Button> */}


        <Box component="div" display="flex" flexDirection={{ xs: "column", md: "row"}} gap="20px">
          <AppButton
            customStyles={{
              fontWeight: "600",
              padding: "7px 30px",
              height: "50px",
              width: "180px",
              borderRadius: "25px",
            }}
            variant="outlined"
            btnText="Login"
            onClick={() => {
              navigate("/login");
            }}
            fullWidth={false}
            sx
          />
          <AppButton
            customStyles={{
              fontWeight: "600",
              padding: "7px 30px",
              height: "50px",
              width: "180px",
              borderRadius: "25px",
            }}
            
            btnText="Get Started"
            onClick={() => {
              navigate("/signup");
            }}
            fullWidth={false}
          />
        </Box>

      </Box>



    </Box>
  );
};

export default Navbar;

const styles = {
  linkText: {
    fontSize: "16px",
    color: "black",
  },
  hamburger: {
    color: "#2E3192",
    display: { xs: "block", lg: "none" },
  },
  close: {
    color: "#2E3192",
    display: { xs: "block", lg: "none" },
    position: "absolute",
    top: "20px",
    right: "30px",
  },
  showNav: (state) => {
    if (state) {
      return { display: "flex" };
    } else {
      return { display: { xs: "none", lg: "flex" } };
    }
  },
};
