import React from "react";

import Checkout from "../../components/dashboard/Checkout/Checkout";

const CartCheckout = () => {
  return (
    <>
      <Checkout />
    </>
  );
};

export default CartCheckout;
