import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AppInput from "../../../inputs/AppInput";
import AppButton from "../../../buttons/AppButton";

import { useDispatch, useSelector } from "react-redux";
import { updateProfileData } from "../../../../redux/dashboard/actions";
import { clearUploadingState } from "../../../../redux/dashboard/slice";

import { getProfileData } from "../../../../utils/dashboard/dashboardFunctions";
import { textIsDigits } from "../../../../utils/stringFunctions";

const EditProfileForm = ({ handleProfileIndex }) => {
  const profileData = getProfileData();
  const dispatch = useDispatch();

  const [profileForm, setProfileForm] = useState({
    name: {value: "", error: null},
    email: {value: "", error: null},
    phone: {value: "", error: null},
    address: {value: "", error: null},
    bio: {value: "", error: null},
    contactName: {value: "", error: null},
    contactEmail:{value: "", error: null},
contactPhone: {value: "", error: null}
  });

  const [formIsSet, setSetFormIsSet] = useState(false);

  const { isProfileUpdateSuccessful, isBtnLoading } = useSelector(
    (state) => state.dashboardReducer
  );

  const onChangeData = (e) => {
    const { name, value } = e.target;
    const profileFormCopy = { ...profileForm };
    if ((name == "phone" || name == "contactPhone" ) && !textIsDigits(value)) {
      return;
    }
    profileFormCopy[name].value = value;
    setProfileForm(profileFormCopy);

  };

  const handleProfileDataEdit = (e) => {
    const payload = {
      name: profileForm.name.value,
      phone: profileForm.phone.value,
      address: profileForm.address.value,
      bio: profileForm.bio.value,
      contactEmail: profileForm.contactEmail.value,
      contactName: profileForm.contactName.value,
      contactPhone: profileForm.contactPhone.value,
      email: profileData.email
    }
      dispatch(updateProfileData({data: payload, callback: ()=>  handleProfileIndex("profile")}));
    
  };

  useEffect(() => {
    if (isProfileUpdateSuccessful) {
      handleProfileIndex();

      setTimeout(() => {
        dispatch(clearUploadingState());
      }, [3000]);
    }
  }, [isProfileUpdateSuccessful]);

  useEffect(() => {
    if (profileData?.email && !formIsSet) {
      const profileFormCopy = { ...profileForm };
      profileFormCopy.bio.value = profileData.bio;
      profileFormCopy.contactEmail.value = profileData.contactEmail;
      profileFormCopy.email.value = profileData.email;
      profileFormCopy.address.value = profileData.address;
      profileFormCopy.name.value = profileData.name;
      profileFormCopy.phone.value = profileData.phone;
      profileFormCopy.contactName.value = profileData.contactName;
      profileFormCopy.contactPhone.value = profileData.contactPhone;

      setProfileForm(profileFormCopy)
      setSetFormIsSet(true)
    }
  }, [profileData])

  return (
    <>
      <Box>
        <Typography
          fontSize="20px"
          color="#2E3192"
          fontWeight="500"
          gutterBottom
        >
          Personal Information
        </Typography>

        <Box mt="20px">
          <Box display="flex" gap="40px" width="100%">
            <AppInput
              name="name"
              label="Name"
              value={profileForm.name.value}
              inputStyles={{ width: "50%" }}
              disabled={true}
            />

            <AppInput
              name="email"
              label="Email Address"
              value={profileForm.email.value}
              inputStyles={{ width: "50%" }}
              disabled={true}
            />
          </Box>

          <Box display="flex" gap="40px" width="100%">
            <AppInput
             onChange={onChangeData}
              name="phone"
              label="Phone Number"
              value={profileForm.phone.value}
              inputStyles={{ width: "50%" }}
            />

            <AppInput
             onChange={onChangeData}
              name="address"
              label="Address"
              value={profileForm.address.value}
              inputStyles={{ width: "50%" }}
            />
          </Box>

          <Box>
            <AppInput
               onChange={onChangeData}
              value={profileForm.bio.value}
              name="bio"
              label="Bio"
              placeholder="Bio"
              rows={6}
              multiline={true}
              error={profileForm.bio.error}
            />

            {/* <AppButton
              customStyles={{
                fontWeight: "bold",
                fontSize: "18px",
                borderRadius: "8px",
              }}
              fullWidth={false}
              disabled={isBtnLoading}
              btnText={isBtnLoading ? "Editing Bio" : "Edit Bio"}
              onClick={handleProfileDataEdit}
            /> */}
          </Box>
        </Box>
      </Box>
      {/*  */}
      <Box mt="20px">
        <Typography
          fontSize="20px"
          color="#2E3192"
          fontWeight="500"
          gutterBottom
        >
          Contact Person Information
        </Typography>

        <Box mt="20px">
          <Box display="flex" gap="40px" width="100%">
            <AppInput
               onChange={onChangeData}
              name="contactName"
              label="Name"
              value={profileForm.contactName.value}
              inputStyles={{ width: "50%" }}
            />

            <AppInput
               onChange={onChangeData}
              name="contactEmail"
              label="Email"
              value={profileForm.contactEmail.value}
              inputStyles={{ width: "50%" }}
            />
          </Box>

          <Box display="flex" gap="40px" width="100%">
            <AppInput
               onChange={onChangeData}
              name="contactPhone"
              label="Phone Number"
              value={profileForm.contactPhone.value}
              inputStyles={{ width: "50%" }}
            />
          </Box>
        </Box>

        <AppButton
          customStyles={{
            fontWeight: "bold",
            fontSize: "18px",
            borderRadius: "8px",
            mt: "24px",
            mb: isBtnLoading ? "40px": "10px",
          }}
          btnText="Save changes"
          onClick={handleProfileDataEdit}
          isLoading={isBtnLoading}
        loadingComponent="Processing..."
        />
       {!isBtnLoading && <AppButton
        customStyles={{
          fontWeight: "bold",
          fontSize: "18px",
          borderRadius: "8px",
          mt: "10px",
          mb: "40px",
          border: "2px solid",
        }}
        variant="outlined"
        btnText="Cancel"
        onClick={() => {
          handleProfileIndex();
        }}
      />}
      </Box>
    </>
  );
};

export default EditProfileForm;
