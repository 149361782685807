import React from "react";
import SchoolSettings from "../../components/dashboard/School/SchoolSettings";
import TeacherSettings from "../../components/dashboard/Teacher/TeacherSettings";
import { getUserData } from "../../utils/auth/authFunctions";

const DashboardSettings = () => {
  const userData = getUserData();

  return (
    <>
      {userData.userType === "school" && <SchoolSettings />}
      {userData.userType === "teacher" && <TeacherSettings />}
    </>
  );
};

export default DashboardSettings;
