import React from "react";
import Hero from "./Hero/Hero";
import Partners from "./Partners/Partners";
import AboutUs from "./AboutUs/AboutUs";
import Marketplace from "./Marketplace/Marketplace";
import Services from "./Services/Services";
import WhyChooseUs from "./WhyChooseUs/WhyChooseUs";
import Testimonials from "./Testimonials/Testimonials";
import Numbers from "./Numbers/Numbers";
import FAQ from "./FAQ/FAQ";
import PreFooter from "./PreFooter/PreFooter";
import Footer from "./Footer/Footer";
import EventHero from "./Hero/EventHero";
import EventRemain from "../events/EventRemain";

const LandingPage = ({event}) => {
  return !!event ? (<>
   <EventHero />
   <EventRemain />
      <Footer />
  </>) : (
    <>
     <Hero />
      <Partners />
      <AboutUs />
      <Marketplace />
      <Services />
      <WhyChooseUs />
      <Testimonials />
      {/* <Numbers /> */}
      <FAQ />
      <PreFooter />
      <Footer />
    </>
  );
};

export default LandingPage;
