// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WuhikN4_CdI2Dqi_zPHe{padding:0px 35px 0px 15px}.Mo0eMp8zlXpNptIkZpzS{margin-top:-75px}`, "",{"version":3,"sources":["webpack://./src/pages/website/Resources/ResourcesLandingPage/ResourcesLandingPage.module.scss"],"names":[],"mappings":"AAMA,sBACI,yBAAA,CAEJ,sBACI,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `WuhikN4_CdI2Dqi_zPHe`,
	"footer_wrapper": `Mo0eMp8zlXpNptIkZpzS`
};
export default ___CSS_LOADER_EXPORT___;
