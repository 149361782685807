import React from "react";
import LoginEmailVerification from "../../components/onboarding/Login/LoginEmailVerification";
import OnboardingLayout from "../../components/onboarding/OnboardingLayout";
import ForgotPasswordImage from "../../assets/img/ForgotPasswordImage.png";

const LoginVerifyEmail = () => {
  return (
    <OnboardingLayout image={ForgotPasswordImage}>
      <LoginEmailVerification />
    </OnboardingLayout>
  );
};

export default LoginVerifyEmail;
