import { createSlice } from "@reduxjs/toolkit";
import {createResource, editResource, deleteResource, getAllMarketPlaceResources, getAllMyResources, getMyResourceBalances, getResourceById, purchaseResource
} from "./action";

export const resourceSlice = createSlice({
  name: "resource",
  initialState: {
    isSubmitting: false,
    isLoading: false,
    isFetchingResources: false,
    fetchingBalances: false,
    resourceDetails: null,
    resources: [],
    mResources: [],
    resourceInview: null,
    balances:{ totalWithdrawals:0, totalEarnings: 0 }
  },
  reducers: {},
  extraReducers: {
    // ---------CREATE RESOURCE ---------/
    [createResource.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createResource.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
      }
      state.isSubmitting = false;
    },
    [createResource.rejected]: (state) => {
      state.isSubmitting = false;
    },

    // ---------EDIT RESOURCE ---------/
    [editResource.pending]: (state) => {
      state.isSubmitting = true;
    },
    [editResource.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        // Update the resource list if necessary, or any other state updates
        const index = state.resources.findIndex(
          (resource) => resource.id === payload.data.id
        );
        if (index !== -1) {
          state.resources[index] = payload.data;
        }
      }
      state.isSubmitting = false;
    },
    [editResource.rejected]: (state) => {
      state.isSubmitting = false;
    },


     // ---------DELETE RESOURCE ---------/
     [deleteResource.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteResource.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        // Remove the deleted resource from the resources list
        state.resources = state.resources.filter(
          (resource) => resource.id !== payload.data.id
        );
      }
      state.isSubmitting = false;
    },
    [deleteResource.rejected]: (state) => {
      state.isSubmitting = false;
    },

    // ---------GET ALL RESOURCE ---------/
    [getAllMyResources.pending]: (state) => {
      state.isFetchingResources = true;
    },
    [getAllMyResources.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.resources = payload.data;
      }
      state.isFetchingResources = false;
    },
    [getAllMyResources.rejected]: (state) => {
      state.isFetchingResources = false;
    },

    // ---------GET BALANCES ---------/
    [getMyResourceBalances.pending]: (state) => {
      state.fetchingBalances = true;
    },
    [getMyResourceBalances.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.balances = payload.data;
      }
      state.fetchingBalances = false;
    },
    [getMyResourceBalances.rejected]: (state) => {
      state.fetchingBalances = false;
    },
    // ---------GET BALANCES ---------/
    [getAllMarketPlaceResources.pending]: (state) => {
      state.isFetchingResources = true;
    },
    [getAllMarketPlaceResources.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.mResources = payload.data;
      }
      state.isFetchingResources = false;
    },
    [getAllMarketPlaceResources.rejected]: (state) => {
      state.isFetchingResources = false;
    },
    // ---------GET RESOURCE BY ID ---------/
    [getResourceById.pending]: (state) => {
      state.isLoading = true;
    },
    [getResourceById.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.resourceInview = payload.data;
      }
      state.isLoading = false;
    },
    [getResourceById.rejected]: (state) => {
      state.isLoading = false;
    },
     // ---------GET PURCHASE URL ---------/
     [purchaseResource.pending]: (state) => {
      state.isSubmitting = true;
    },
    [purchaseResource.fulfilled]: (state, { payload }) => {
      if (payload?.status === "success") {
      
      } else if (payload.response?.status === 401) {
        state.unauthorised = true;
      }
      state.isSubmitting = false;
    },
    [purchaseResource.rejected]: (state, load) => { 
      state.isSubmitting = false;
    },
  },
});

export const { } = resourceSlice.actions;

export default resourceSlice.reducer;
