import { formatAsCurrency } from "../numberFunctions";

export const getProfileData = () => {
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  return profileData;
};

export const getJobSalaryDisplay = (job) => {
  let salary = "";
  switch (job.salaryType) {
      case 1:
          salary = `${job.salaryCurrency}${formatAsCurrency(job.salary)}  - ${job.salaryCurrency}${formatAsCurrency(job.salaryTo)}`
          break;
      case 2:
          salary = `${job.salaryCurrency}${formatAsCurrency(job.salary)}`
          break;
      case 3:
          salary = `Competitive Salary. Pays in ${job.salaryCurrency}`
          break;
      case 4:
          salary = `Non-Disclosed. Pays in ${job.salaryCurrency}`
          break;

      default:
          salary = "Info not available"
          break;
  }
  return salary;
}
