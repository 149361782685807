import React from "react";
import Cart from "../../components/dashboard/Cart/Cart";

const DashboardCart = () => {
  return (
    <>
      <Cart />
    </>
  );
};

export default DashboardCart;
