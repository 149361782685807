import React from 'react';
import styles from './CourseCard.module.css'; 
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import Rating from '../../../landing-page/Resources/Rating/Rating';
import { formatAsCurrency } from '../../../../utils/numberFunctions';
import AltResource from '../../../../assets/img/education.png'
import {useNavigate } from 'react-router-dom';
import { Link, Grid } from '@mui/material';

// Custom card component that receives props for displaying individual course information
const CourseCard = ({ id ,title, rating, price, img }) => {
  const navigate = useNavigate()

  function clipTextWithEllipsisByChar(text, charLimit = 40) {
    if (typeof text !== 'string') {
      return ""; // Handle invalid input
    }
  
    if (text.length <= charLimit) {
      return text; // No clipping needed
    }
  
    const clippedText = text.substring(0, charLimit) + '...';
    return clippedText;
  }

  const imageStyle = {
    backgroundSize: 'cover', // Optional: Adjust as needed
    backgroundRepeat: 'no-repeat', // Optional: Adjust as needed
    width: "100%", 
    height: "170px",
    borderRadius: "20px",
    margin: 0,
    padding: 0,
    objectFit: 'cover'
  };

  return (
    <Grid item xs={6} md={4} lg={3} xl={2} style={{ marginBottom: "20px" }}>
      <div  className={styles.cardContainer} onClick={() => navigate(`/marketplace/${id}`)}>
        <figure style={{  ...imageStyle, backgroundImage: `url("${img || AltResource}")`}}></figure>
        <div className={styles.cardContent}>
          <a title={title} style={{ display: "block", height: "44px", overflowY: "clip", textDecoration: 'none', color: 'inherit' }} href={`/marketplace/${id}`}><h3 style={{ textTransform: "capitalize", margin: 0, padding: 0}}>{ clipTextWithEllipsisByChar(title)}</h3></a>
          <div className={`${styles.rating_section}`}>
            <div className={`${styles.timeRated}`}>{formatAsCurrency(price, "₦")}</div>
            <Rating rating={5} />
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default CourseCard;
