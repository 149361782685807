import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import AppButton from "../../buttons/AppButton";

import BulletpointSvg from "../../../assets/svg/dashboard-icons/Teacher/BulletpointSvg.svg";

import {
  addCourseToCart,
  removeCourseFromCart,
} from "../../../redux/cart/slice";
import { checkCourseExists } from "../../../redux/cart/slice";

import { getCourse, getMyCourses, getPurchaseUrl } from "../../../redux/courses/actions";

import { formatAsCurrency } from "../../../utils/courses/coursesFunctions";
import AppInput from "../../inputs/AppInput";
import { useAuth } from "../../../utils/auth/authFunctions";

const CourseDetails = () => {
  const [courseDetails, setCourseDetails] = useState({});
  const [courseUnits, setCourseUnits] = useState(1);
  const isLoggedIn = useAuth();

  const { course, courseLoaded, isLoading, myCourses } = useSelector((state) => state.coursesReducer);
  const { courseExists } = useSelector((state) => state.cartReducer);
  const { userData } = useSelector((state) => state.authReducer);
  const [alreadyPurchased, setAlreadyPurchased] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const setCourseCartUnits = (type) => {
    if (type == "add") {
      setCourseUnits(courseUnits + 1);
    } else if (type == "minus" && courseUnits != 1) {
      setCourseUnits(courseUnits - 1);
    }
  }

  // function to add course to cart
  const handleAddCourse = () => {
    dispatch(
      addCourseToCart({
        id: course.id,
        name: course.name,
        coverImage: course.coverImage,
        contentInfo: course.contentInfo,
        category: course.courseCategory.name,
        price: course.price,
        units: courseUnits
      })
    );
  };

  // function to remove course from cart
  const handleRemoveCourse = () => {
    dispatch(removeCourseFromCart(course.id));
  };

  // function to go back to courses from breadcrumb
  const handleBackClick = () => {
    navigate("/courses");
  };

  // function to go home
  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  const onPressBuyNow = () => {
    if(isLoggedIn){
      let data = { cart: [{ id: course?.id, qty: courseUnits }] };
      dispatch(getPurchaseUrl({ data }))
    }else{
      navigate(`/login?redirect=/courses/${params.courseId}`)
    }
  }

  // use effect to get course details
  useEffect(() => {
    dispatch(getCourse(params.courseId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(!myCourses?.length){
      dispatch(getMyCourses())
    }else{
      let isExist = userData?.userType == "teacher" ? myCourses?.find(x => x.id == params.courseId) : false;
      setAlreadyPurchased(isExist);
    }
    // eslint-disable-next-line
  }, [myCourses.length]);

  // use effect to check if course exists to determine what button to show
  useEffect(() => {
    dispatch(checkCourseExists(courseDetails.id));
  });

  // use effect to to display course
  useEffect(() => {
    if (course !== null) {
      setCourseDetails(course);
    }
  }, [course]);

  // for breadcrumb
  const breadcrumbs = [
    <Typography
      underline="hover"
      key="1"
      color="inherit"
      onClick={handleHomeClick}
      sx={{ cursor: "pointer" }}
    >
      Home
    </Typography>,
    <Typography
      underline="hover"
      key="2"
      color="inherit"
      onClick={handleBackClick}
      sx={{ cursor: "pointer" }}
    >
      Courses
    </Typography>,
  ];

  // for course details list
  const courseHighlight = [
    { id: 1, text: "2 hours+ of video course" },
    { id: 2, text: "60 useful resources" },
    { id: 3, text: "40 tutorial article" },
    { id: 4, text: "Unlimited access" },
    { id: 5, text: "English language" },
    { id: 6, text: "Certificate of completion" },
  ];

  return (
    <Box component="section" sx={{ padding: { xs: "0", lg: "30px" } }}>
      <Box backgroundColor="#d5d6e933" sx={{ padding: { xs: "10px 0 0 0", lg: "30px" } }} borderRadius="15px">
       {isLoggedIn && <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ margin: { xs: "10px 10px 20px 30px", lg: "0 0 30px" } }}
        >
          {breadcrumbs}
          {courseLoaded && (
            <Typography key="3" color="text.primary">
              {courseDetails.name}
            </Typography>
          )}
        </Breadcrumbs>}

        {!courseLoaded && <CircularProgress />}
        {courseLoaded && (
          <Grid
            container
            backgroundColor="#ffffff"
            p="30px"
            borderRadius="10px"
          >
            <Grid item sx={{ width: { xs: "100%", lg: "50%" } }}>
            <Box sx={{display: {xs: "block", md:"block", lg:"none"}}}>
                  <Box
                    component="img"
                    src={courseDetails.coverImage}
                    alt="CoursePreview"
                    width="100%"
                    height="auto"
                  />
                </Box>
                {!alreadyPurchased && <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  sx={{display: {xs: "flex", md:"flex", lg:"none"}}}
                  >
                    <Typography sx={{fontSize:{xs: "18px", lg:"20px"}}} fontWeight="600">
                      ₦{formatAsCurrency(courseDetails.price)}
                    </Typography>

                    <Box>
                      <StarOutlinedIcon
                        sx={{ height: "15px", color: "#FFC90C" }}
                      />
                      <StarOutlinedIcon
                        sx={{ height: "15px", color: "#FFC90C" }}
                      />
                      <StarOutlinedIcon
                        sx={{ height: "15px", color: "#FFC90C" }}
                      />
                      <StarOutlinedIcon
                        sx={{ height: "15px", color: "#FFC90C" }}
                      />
                      <StarOutlinedIcon
                        sx={{ height: "15px", color: "#FFC90C" }}
                      />
                    </Box>
                  </Box>}
              <Typography sx={{fontSize:{xs: "21px", lg:"24px", fontWeight: "500"}}} gutterBottom>
                {courseDetails.name}
              </Typography>
              <Typography sx={{fontSize:{xs: "14px", lg:"16px"}}}>
                {courseDetails.description}
              </Typography>

              <Box display="flex" gap="7px" alignItems="center" my="20px">
                {/* <StarOutlinedIcon sx={{ color: "#FFC90C" }} />
                <Typography mr="20px">4.0/5.0</Typography> */}

                <PlayCircleFilledOutlinedIcon sx={{ color: "#2E3192" }} />
                {/* <Typography mr="20px">12x Lessons</Typography> */}
                <Typography mr="20px">{courseDetails.contentInfo}</Typography>

                {/* <AccessTimeIcon sx={{ color: "#4B4E61" }} />
                <Typography mr="20px">2h 30m</Typography> */}
              </Box>

              {/* <Typography fontSize="18px" gutterBottom>
                What is included?
              </Typography> */}

              {/* <Box display="flex" flexDirection="column" gap="15px">
                {courseHighlight.map((list) => {
                  return (
                    <Box
                      key={list.id}
                      display="flex"
                      alignItems="center"
                      gap="15px"
                    >
                      <Box
                        component="img"
                        src={BulletpointSvg}
                        alt="bullet point"
                      />
                      <Typography fontSize="16px">{list.text}</Typography>
                    </Box>
                  );
                })}
              </Box> */}

              {/* <AppButton
                customStyles={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  borderRadius: "8px",
                  padding: "7px 120px",
                  mt: "24px",
                }}
                btnText="Save"
                fullWidth={false}
              /> */}
            </Grid>
            <Grid item sx={{ width: { xs: "100%", lg: "50%" } }}>
              <Box sx={{ width: { xs: "100%", lg: "80%" } }} mx="auto">
                <Box position="relative" sx={{display: {xs: "none", md:"none", lg:"block"}}}>
                  <Box
                    component="img"
                    src={courseDetails.coverImage}
                    alt="CoursePreview"
                    width="100%"
                    height="auto"
                  />
                </Box>

                {/* course options */}
                <Box
                  p="17px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                  alignItems="center"
                  gap="20px"
                >
                   {!alreadyPurchased && <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  sx={{display: {xs: "none", md:"none", lg:"flex"}}}
                  >
                  <Typography fontSize="20px" fontWeight="600">
                  {courseDetails.price == 0 ? 'Free' : `₦${formatAsCurrency(courseDetails.price)}` }
                    </Typography>

                    <Box>
                      <StarOutlinedIcon
                        sx={{ height: "15px", color: "#FFC90C" }}
                      />
                      <StarOutlinedIcon
                        sx={{ height: "15px", color: "#FFC90C" }}
                      />
                      <StarOutlinedIcon
                        sx={{ height: "15px", color: "#FFC90C" }}
                      />
                      <StarOutlinedIcon
                        sx={{ height: "15px", color: "#FFC90C" }}
                      />
                      <StarOutlinedIcon
                        sx={{ height: "15px", color: "#FFC90C" }}
                      />
                    </Box>
                  </Box>}
                  {userData?.userType == "school" && <Box display="flex" alignItems="center" gap="10px">
                    Units:
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-evenly"
                      borderRadius="10px"
                      border="1px solid #E5E5E5"
                      gap="10px"
                      py="10px"
                    >
                      <Typography onClick={() => setCourseCartUnits("minus")} style={{ cursor: "pointer", padding: "0px 10px" }} fontWeight="700">-</Typography>
                      <Typography>{courseUnits}</Typography>
                      <Typography onClick={() => setCourseCartUnits("add")} style={{ cursor: "pointer", padding: "0px 10px" }} fontWeight="700">+</Typography>
                    </Box>
                  </Box>}
                 {alreadyPurchased ? <AppButton
                    customStyles={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      borderRadius: "8px",
                    }}
                    btnText="Continue Course"
                    onClick={()=> navigate("/courses/my-courses/" + params.courseId)}
                  /> : <>
                  <AppButton
                    customStyles={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      borderRadius: "8px",
                    }}
                    btnText={courseDetails.price == 0 ? "Add to my courses" : "Buy now"}
                    onClick={onPressBuyNow}
                    isLoading={isLoading}
                    loadingComponent="Processing..."
                  />



                  {/* add to cart button to show if course doesn't exist */}
                  {!courseExists && isLoggedIn && (
                    <AppButton
                      customStyles={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        borderRadius: "8px",
                        border: "2px solid",
                      }}
                      variant="outlined"
                      btnText="Add to cart"
                      onClick={handleAddCourse}
                    />
                  )}

                  {/* remove from cart button to show if course exists */}
                  {courseExists && isLoggedIn && (
                    <AppButton
                      customStyles={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        borderRadius: "8px",
                        border: "2px solid",
                      }}
                      variant="outlined"
                      btnText="Remove from cart"
                      onClick={handleRemoveCourse}
                    />
                  )}
                  </>}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default CourseDetails;
