import React, { useState, useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import AppButton from "../../buttons/AppButton";
import { PAGES } from "../../../utils/constant";

import { ForgotPasswordStyles } from "./ForgotPasswordStyles.styles";

import { useDispatch, useSelector } from "react-redux";
import { clearRegisteredUserEmail } from "../../../redux/auth/slice";
import { verifyUserEmail } from "../../../redux/auth/actions";

import VerificationInput from "react-verification-input";
import classes from "../Signup/VerificationInput.module.css";

import LeftArrow from "../../../assets/svg/LeftArrow.svg";

const LoginEmailVerification = ({ prevBtn, verificationData }) => {
  const dispatch = useDispatch();

  const [tokenInput, setTokenInput] = useState("");

  const { isBtnLoading, unverifiedUserEmail, isUserVerified } = useSelector(
    (state) => state.authReducer
  );

  const navigate = useNavigate();

  const handleVerifyEmail = () => {
    dispatch(
      verifyUserEmail({
        email: unverifiedUserEmail,
        token: tokenInput,
      })
    );
  };

  useEffect(() => {
    if (isUserVerified) {
      navigate(PAGES.login);
      dispatch(clearRegisteredUserEmail());
    }
  }, [isUserVerified]);

  return (
    <>
      {/* <Box
        component="img"
        src={LeftArrow}
        alt="left arrow"
        position="absolute"
        top="120px"
        left="30px"
        onClick={() => prevBtn()}
      /> */}
      <Container sx={ForgotPasswordStyles.container}>
        <Typography sx={ForgotPasswordStyles.headerText}>
          Email Address Verification
        </Typography>
        <Typography sx={ForgotPasswordStyles.headerSubText}>
          Enter the code that was sent to your email: {unverifiedUserEmail} to
          verify your account.
        </Typography>

        <Typography>Verification code</Typography>

        <VerificationInput
          classNames={{
            container: classes.container,
            character: classes.character,
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
          value={tokenInput}
          length={4}
          validChars="0-9"
          inputProps={{ type: "tel" }}
          placeholder=""
          onChange={setTokenInput}
        />

        <Box sx={ForgotPasswordStyles.resendCode}>
          <Typography variant="span">Didn't get a code?</Typography>{" "}
          <Typography
            variant="span"
            onClick={() => {
              alert("Email sent, check again!");
            }}
            sx={ForgotPasswordStyles.createAccLink}
          >
            Resend
          </Typography>
        </Box>

        <AppButton
          onClick={() => {
            handleVerifyEmail();
          }}
          customStyles={{ fontWeight: "bold" }}
          btnText={isBtnLoading ? "Verifying" : "Verify"}
          disabled={isBtnLoading}
        />

        <Box mt="10px" sx={ForgotPasswordStyles.createAcc}>
          <Typography variant="span">Already verified?</Typography>{" "}
          <Typography
            variant="span"
            onClick={() => navigate(PAGES.login)}
            sx={ForgotPasswordStyles.createAccLink}
          >
            Login
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default LoginEmailVerification;
