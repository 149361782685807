import React from "react";
import SchoolMessages from "../../components/dashboard/School/SchoolMessages";
import TeacherMessages from "../../components/dashboard/Teacher/TeacherMessages";
import { getUserData } from "../../utils/auth/authFunctions";

const DashboardMessages = () => {
  const userData = getUserData();

  return (
    <>
      {userData.userType === "school" && <SchoolMessages />}
      {userData.userType === "teacher" && <TeacherMessages />}
    </>
  );
};

export default DashboardMessages;
