import { Grid } from "@mui/material";
import React from "react";
import Navbar from "../../components/landing-page/Navbar/Navbar";
import DashboardCourses from "../dashboard/DashboardCourses";

const Courses = () => {
  return (
    <Grid sx={{px: {xs: "0px", md: "70px"}}}>
         <Navbar />
<div style={{height:"20px"}}></div>
         <DashboardCourses />
    </Grid>
  );
};

export default Courses;
