import React, { useEffect, useRef, useState } from 'react'
import CustomTabPanel, { a11yProps } from '../../CustomTabPanel'
import parse from 'html-react-parser';
import { Box, Button, Chip, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { applyForJob, getChatForJobApplication, getChatForJobApplicationLowKey, sendChatForJobApplication, updateJobDetails } from '../../../redux/jobs/action';
import AppButton from '../../buttons/AppButton';
import { RocketLaunch, Send } from '@mui/icons-material';
import { getJobSalaryDisplay } from '../../../utils/dashboard/dashboardFunctions';
import { decodeHTML, getStatusKey, getStatusText, useQueryParam } from '../../../utils/stringFunctions';
import { getLocalPrettyDate } from '../../../utils/dateFunctions';
import AppInput from '../../inputs/AppInput';
import { markMessageAsRead } from '../../../redux/user/action';
import { MESSAGE_PURPOSE_TYPE } from '../../../utils/constant';

export default function TeacherJobDetails({ jobDetails }) {
    const [value, setValue] = useState(0);
    const { jobId } = useParams();
    const dispatch = useDispatch();
    const { isFetching, isApplying, jobChats, fetchingMessages, sendingChat } = useSelector(state => state.jobReducer)
    const { userData } = useSelector(state => state.authReducer);
    const [statusMessages, setStatusMessages] = useState({});
    const [chat, setChat] = useState("");
    const tab = useQueryParam("tab");
    const containerRef = useRef();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(getChatForJobApplicationLowKey({
            applicationId: jobDetails?.application?.id,
            userInfo: {
                user1: jobDetails?.application?.userId,
                user2: jobDetails.userId
            }
        }))
    };
    const onApplyForJob = () => {
        if (window.confirm("Are you sure?")) {
            dispatch(applyForJob({ jobId }));
        }
    }

    const onChangeMessage = (e) => {
        setChat(e.target.value);
    };

    const refreshChat = () => {
        dispatch(getChatForJobApplicationLowKey({
            applicationId: jobDetails?.application?.id,
            userInfo: {
                user1: jobDetails?.application?.userId,
                user2: jobDetails.userId
            }
        }))
    }


    const onSendChat = () => {
        if (chat?.length == 0) {
            return;
        }
        const payload = {
            applicationId: jobDetails?.application.id,
            messageInfo: {
                to: jobDetails.userId,
                from: userData.id,
                message: chat,
            },
            callback: () => setChat("")
        }
        dispatch(sendChatForJobApplication(payload));
    }
    //   useEffect(() =>{
    // const refresher = setInterval(() => {
    //     dispatch(getChatForJobApplicationLowKey({
    //         applicationId:jobDetails?.application?.id,
    //         userInfo: {
    //           user1: jobDetails?.application?.userId,
    //           user2: jobDetails.userId
    //         }
    //       }))
    // }, 15000);

    // return () => clearInterval(refresher);
    //   },[])
    useEffect(() => {
        if (jobDetails?.application) {
            setStatusMessages(JSON.parse(jobDetails?.application.remarks));
            if (tab && parseInt(tab) == 1) setValue(parseInt(tab))
            dispatch(getChatForJobApplication({
                applicationId: jobDetails?.application?.id,
                userInfo: {
                    user1: jobDetails?.application?.userId,
                    user2: jobDetails.userId
                }
            }))
        }

    }, [jobDetails?.application])

    useEffect(() => {
        scrollToBottom();
    }, [value]);

    useEffect(() => {
        if (value == 1 && jobChats?.length > 0) {
          if (jobChats[jobChats?.length - 1].isRead == false && jobChats[jobChats?.length - 1].to == userData.id) {
            dispatch(markMessageAsRead({
              purpose: MESSAGE_PURPOSE_TYPE.jobApplication,
              purposeId: jobDetails?.application?.id
            }))
          }
        }
      }, [jobChats])

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current?.scrollHeight;
        }

    };

    const getStatusLabel = (status) => {
        if (status) {
            if (status == 1) return "Acceptance";
            if (status == 2) return "Rejection";
            if (status == 3) return "Completion";
            if (status == 4) return "Closing";
        }
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Details" {...a11yProps(0)} />
                    {jobDetails?.hasApplied && <Tab label="Chat" {...a11yProps(1)} />}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <>
                    {isFetching && <CircularProgress />}
                    {!!jobDetails && <><Box component="section" sx={{ padding: { xs: "0", lg: "0" } }}>
                        <Box backgroundColor="#d5d6e933" p="30px" borderRadius="15px 15px 0 0">
                            <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, justifyContent: "space-between" }}>
                                <div>
                                    <Typography variant="h6" sx={{ fontSize: { xs: "18px", lg: "20px" } }}>
                                        {jobDetails.title}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5, fontSize: "14px" }} color="text.secondary">
                                        {jobDetails.location}
                                    </Typography>
                                    {userData.userType == "teacher" && <Typography variant="body2" sx={{ mb: { md: 0, xs: 1 } }}>
                                        Application Status: {jobDetails?.application?.status ? getStatusText(jobDetails?.application?.status) : "Not Applied"}
                                    </Typography>}
                                </div>
                                <div>
                                    <Typography variant="body2">
                                        Salary : {getJobSalaryDisplay(jobDetails)}
                                    </Typography>

                                    {userData.userType == "school" && <Typography variant="body2" mt={1} color={jobDetails.isOpen ? "green" : "red"}>
                                        Job is {jobDetails.isOpen ? "opened" : "closed"}
                                    </Typography>}
                                    <AppButton size="small" disabled={jobDetails.hasApplied} endIcon={<RocketLaunch />} onClick={onApplyForJob} customStyles={{ mt: "10px" }} btnText={jobDetails.hasApplied ? "Applied" : isApplying ? "Applying" : "Apply for Job"} fullWidth={false} />
                                </div>

                            </Box>




                        </Box>
                        {jobDetails?.application?.status != 0 && Object.keys(statusMessages).length > 0 &&
                            statusMessages[getStatusKey(jobDetails?.application?.status)] && (
                                <Box
                                    pr="30px" pl="30px" pt="30px"
                                    borderRadius={2}
                                >
                                    <Typography sx={{ mb: 1 }} variant="h6">
                                        {getStatusLabel(jobDetails?.application?.status)} Message
                                        <span style={{ fontStyle: "italic", fontSize: 12 }}>
                                            (This message is from the recruiter)
                                        </span>
                                    </Typography>
                                    <Typography>
                                        {parse(
                                            decodeHTML(
                                                statusMessages[getStatusKey(jobDetails?.application?.status)]
                                            )
                                        )}
                                    </Typography>
                                </Box>
                            )}
                    </Box>
                        <Box component="section" sx={{ padding: { xs: "0", lg: "" }, mb: { xs: 4, lg: 1 } }}>
                            <Box backgroundColor="#ffffff" p="30px" borderRadius="0 0 15px 15px">
                                <Typography variant="h6" sx={{ fontSize: { xs: "18px", lg: "20px" } }}>
                                    Job Description
                                </Typography>
                                <Typography sx={{ pt: 1, fontSize: { xs: "14px", lg: "16px" }, whiteSpace: "pre-wrap" }}> {parse(decodeHTML(jobDetails.description))}</Typography>
                            </Box>

                        </Box>
                        <Box component="section" sx={{ padding: { xs: "0", lg: "" }, mb: { xs: 4, lg: 1 } }}>
                            <Box backgroundColor="#ffffff" p="30px" pt={0} borderRadius="0 0 15px 15px">
                                <Typography variant="h6" sx={{ fontSize: { xs: "18px", lg: "20px" } }}>
                                    Job Skills/Requirements
                                </Typography>
                                <Box mt={1}>
                                    {jobDetails.skills && jobDetails.skills.map(skill => <Chip variant="outlined" label={skill} sx={{ mr: 1, mb: 1 }} />)}
                                </Box>
                            </Box>
                        </Box>
                    </>}

                </>
            </CustomTabPanel>
            {jobDetails?.hasApplied && <CustomTabPanel value={value} index={1}>
                {jobDetails?.application?.status == 0 && (
                    <Box textAlign="center" mt={4}>
                        <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }}>
                            Recruiter needs to accept your application to start a chat.
                        </Typography>
                    </Box>
                )}
                {jobDetails?.application?.status == 2 && (
                    <Box textAlign="center" mt={4}>
                        <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }}>
                            This application was rejected and closed.
                        </Typography>
                    </Box>
                )}
                {jobDetails?.application?.status != 2 && jobDetails?.application?.status != 0 && (
                    <>
                        <Box sx={{ position: "relative", }}>
                            <div ref={containerRef} style={{ height: "50vh", display: "flex", flexDirection: "column", overflowY: "auto" }}>
                                {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                }}
              > */}
                                {jobChats.length == 0 && <Box textAlign="center" mt={4}>
                                    <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }}>
                                        No chats yet.
                                    </Typography>
                                </Box>}
                                {jobChats.map((chat, index) => userData.id == chat.from ?


                                    <Box
                                        sx={{
                                            maxWidth: "85%",
                                            marginLeft: "auto",
                                            display: "inline-block",
                                            mb: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                backgroundColor: "#cfd0f0",
                                                p: 1,
                                                borderRadius: "10px",
                                            }}
                                        >
                                            {chat.message}
                                        </Typography>
                                        <Typography
                                            fontSize={10}
                                            sx={{ width: "100%", textAlign: "right" }}
                                        >
                                            {getLocalPrettyDate(chat.createdAt)}
                                        </Typography>
                                    </Box> : <Box
                                        sx={{
                                            maxWidth: "85%",
                                            display: "inline-block",
                                            marginRight: "auto",
                                            mb: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                backgroundColor: "#ececec",
                                                p: 1,
                                                borderRadius: "10px",
                                            }}
                                        >
                                            {chat.message}
                                        </Typography>
                                        <Typography
                                            fontSize={10}
                                            sx={{ width: "100%", textAlign: "right" }}
                                        >
                                            {getLocalPrettyDate(chat.createdAt)}
                                        </Typography>
                                    </Box>
                                )}

                            </div>
                            <Box sx={{ alignSelf: "flex-end" }}>
                                {fetchingMessages && <Typography fontStyle="italic" >Fetching Messages...</Typography>}
                                <AppInput
                                    multiline
                                    minRows={1}
                                    maxRows={3}
                                    value={chat}
                                    onChange={onChangeMessage}
                                />
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 2 }}>
                                    <Button onClick={refreshChat}>Refresh</Button>
                                    <AppButton btnText="Send" endIcon={<Send />} size="small" customStyles={{
                                        padding: "7px 30px",
                                    }} fullWidth={false} isLoading={sendingChat} loadingComponent="Sending..." onClick={onSendChat} />
                                </Box>

                            </Box>
                        </Box>
                    </>
                )}
            </CustomTabPanel>}

        </>
    )
}
