import React, {  } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import AppButton from "../../../buttons/AppButton";

import { useNavigate } from "react-router-dom";

import Iframe from "react-iframe";
import { useSelector } from "react-redux";

const CourseDisplay = ({currentItem, onGoToNext, onGoToPrevious}) => {
  const navigate = useNavigate();
  const { otherCourseContentDetails } = useSelector((state) => state.coursesReducer);

  const displayCurrentContent = (requestedForDisplay) =>{
    if (requestedForDisplay?.type === "image") {
      return (
        <Box
          key={requestedForDisplay.sequence}
          component="img"
          src={requestedForDisplay.content}
          alt="image"
          width="100%"
          height="100%"
          sx={{ objectFit: "contain" }}
        />
      );
    }else if (requestedForDisplay?.type === "video") {
      const toDisplay  = requestedForDisplay?.content.replace('/watch?v=','/embed/' )
      return (
        <Iframe
          url={`${toDisplay}?autoplay=1&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&origin=http://youtubeembedcode.com`}
          width="100%"
          height="100%"
          id=""
          className=""
          display="block"
          position="relative"
        />
      );
    }else{
      return <></>
    }
  }
  return (
    <>
      <Grid item sx={{width: {xs:"100%", lg:"72%"}, p:{xs: "30px 10px", lg: "20px 0 20px 30px"}}}>
        {/* title */}
        <Box display="flex" gap="14px" sx={{marginBottom:"20px"}}>
          <ArrowBack
            sx={{ color: "#2E3192" }}
            onClick={() => {
              navigate("/courses/my-courses");
            }}
          />
          <Typography sx={{fontSize: {xs: "16px", lg:"20px", fontWeight:"600"}}}  >
            {otherCourseContentDetails.name}
          </Typography>
        </Box>

        {/* display */}
        <Box
          my="20px"
          border="1px #ececec solid"
          backgroundColor="#AAAAAA"
          sx={{height: {xs: "250px",lg: "60vh"}}}
        >
          {displayCurrentContent(currentItem)}
        </Box>

        <Box mt="15px" display="flex" gap="10px">
          <AppButton
            btnText="Previous"
            customStyles={{
              backgroundColor: "#fff",
              fontSize: "18px",
              fontWeight: "600",
            }}
            variant="outlined"
            fullWidth={false}
            onClick={onGoToPrevious}
          />
          <AppButton
            btnText="Next"
            customStyles={{
              fontSize: "18px",
              fontWeight: "600",
            }}
            fullWidth={false}
            onClick={onGoToNext}
          />
        </Box>
      </Grid>
    </>
  );
};

export default CourseDisplay;
