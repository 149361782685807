import { Box, Button, Card, CardActions, CardContent, Typography, colors } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatAsCurrency } from "../../../utils/courses/coursesFunctions";
import { getJobSalaryDisplay } from "../../../utils/dashboard/dashboardFunctions";
import { getStatusText } from "../../../utils/stringFunctions";

const JobCards = ({ job, isApplied, isSchool }) => {
    const navigate = useNavigate();

    return (
        <Card sx={{ width: "100%", marginBottom: { xs: "10px", lg: "20px" } }}>
            <CardContent sx={{ pb: 0 }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, justifyContent: "space-between" }}>
                    <div>
                        <Typography variant="h6" sx={{ fontSize: { xs: "16px", lg: "18px" } }}>
                            {job.title}
                        </Typography>
                        <Typography sx={{ mb: 1.5, fontSize: "12px" }} color="text.secondary">
                            {job.location}
                        </Typography>
                    </div>
                    <Box>
                        <Typography variant="body2">
                            Salary : {getJobSalaryDisplay(job)}
                        </Typography>
                        {isSchool && <Typography variant="body2" sx={{ textAlign: { xs: "left", lg: "right" } }} color={job.isOpen ? "green" : "red"}>
                            {job.isOpen ? "OPEN" : "CLOSED"}
                        </Typography>}
                    </Box>

                </Box>
            </CardContent>
            <CardActions sx={{ pt: { xs: 1, lg: 0 } }}>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    {isApplied && <Button onClick={() => navigate(`/jobs/` + job.id)}>View Application</Button>}
                    {!isApplied && <Button onClick={() => navigate(`/jobs/` + job.id)}>View Details</Button>}

                    {isApplied && <Typography color={job.status ==2 || job.status ==4 ? "error" :"primary"}>{getStatusText(job.status)}</Typography>}
                </Box>
            </CardActions>
        </Card>
    );
};

export default JobCards;
