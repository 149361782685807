import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet, apiPost } from "../../service/apiService";
import { API_URL } from "../../utils/apiConstants";

export const getModuleQUestions = createAsyncThunk(
  "question/getModuleQUestions",
  async (payload) => {
    return apiGet(`${API_URL.getModuleQuestions}`, payload)
      .then((res) => {
        // payload.callBack();
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const submitModuleQuestions = createAsyncThunk(
    "question/submitModuleQuestions",
    async (payload) => {
      return apiPost(API_URL.submitModuleQuestions, payload.data)
        .then((res) => {
          // payload.callBack();
          return res.data;
        })
        .catch((error) => {
          return error;
        });
    }
  );