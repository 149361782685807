import { CopyAllSharp } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, Typography } from '@mui/material';
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { showSimpleToast } from '../../../../utils/alertFunctions';
import parse from 'html-react-parser';
import { API_URL } from '../../../../utils/apiConstants';
import AppButton from '../../../buttons/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { formatAsCurrency } from '../../../../utils/courses/coursesFunctions';
import { updateJobDetails } from '../../../../redux/jobs/action';
import { getJobSalaryDisplay } from '../../../../utils/dashboard/dashboardFunctions';
import { decodeHTML } from '../../../../utils/stringFunctions';

export const JobDetails = ({ jobDetails, jobId }) => {
    const dispatch = useDispatch();
    const { isFetching, isUpdatingJob, } = useSelector(state => state.jobReducer)

    const onChangeJobStatus = () => {
        if (window.confirm(`Are you sure you want to ${!jobDetails.isOpen ? "open" : "close"} this job?`)) {
            dispatch(updateJobDetails({ jobId, data: { isOpen: !jobDetails.isOpen } }));
        }
    }

    return (
        <>
            {isFetching && <CircularProgress />}
            {!!jobDetails && <><Box component="section" sx={{ padding: { xs: "0", lg: "0" } }}>
                <Box backgroundColor="#d5d6e933" p="30px" borderRadius="15px 15px 0 0">
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, justifyContent: "space-between" }}>
                        <div>
                            <Typography variant="h6" sx={{ fontSize: { xs: "18px", lg: "20px" } }}>
                                {jobDetails.title}
                            </Typography>
                            <Typography sx={{ mb: 1.5, fontSize: "14px" }} color="text.secondary">
                                {jobDetails.location}
                            </Typography>
                            <CopyToClipboard text={`${API_URL.client_url}/jobs/view/${jobDetails.code}`} onCopy={() => showSimpleToast("Job URL Copied!!", "success")} >
                                <Button endIcon={<CopyAllSharp />} sx={{ display: { lg: "flex", xs: "none" } }} >Copy Job URL</Button>
                            </CopyToClipboard>
                        </div>
                        <div>
                            <Typography variant="body2">
                                Salary : {getJobSalaryDisplay(jobDetails)}
                            </Typography>
                            <Typography variant="body2" color={jobDetails.isOpen ? "green" : "red"}>
                                Job is {jobDetails.isOpen ? "opened" : "closed"}
                            </Typography>
                            <AppButton size="small"
                                isLoading={isUpdatingJob}
                                loadingComponent="Processing..." onClick={onChangeJobStatus}
                                customStyles={{ mt: "10px", backgroundColor: jobDetails.isOpen ? "darkred" : "darkgreen" }}
                                btnText={jobDetails.isOpen ? "Close for Applications" : "Open for Applications"}
                                fullWidth={false} />
                            <CopyToClipboard text={`${API_URL.client_url}/jobs/view/${jobDetails.code}`} onCopy={() => showSimpleToast("Job URL Copied!!", "success")} >
                                <Button endIcon={<CopyAllSharp />} sx={{ mt: 2, display: { lg: "none", xs: "flex" } }} >Copy Job URL</Button>
                            </CopyToClipboard>
                        </div>
                    </Box>
                </Box>
            </Box>
                <Box component="section" sx={{ padding: { xs: "0", lg: "" }, mb: { xs: 4, lg: 1 } }}>
                    <Box backgroundColor="#ffffff" p="30px" pb={0} borderRadius="0 0 15px 15px">
                        <Typography variant="h6" sx={{ fontSize: { xs: "18px", lg: "20px" } }}>
                            Description
                        </Typography>
                        <Typography sx={{ pt: 1, fontSize: { xs: "14px", lg: "16px" }, whiteSpace: "pre-wrap" }}> {parse(decodeHTML(jobDetails.description))}</Typography>
                    </Box>

                </Box>
                <Box component="section" sx={{ padding: { xs: "0", lg: "" }, mb: { xs: 4, lg: 1 } }}>
                    <Box backgroundColor="#ffffff" p="30px" borderRadius="0 0 15px 15px">
                        <Typography variant="h6" sx={{ fontSize: { xs: "18px", lg: "20px" } }}>
                            Job Skills/Requirements
                        </Typography>
                        <Box mt={1}>
                            {jobDetails.skills && jobDetails.skills.map((skill, index) => <Chip key={index} variant="outlined" label={skill} sx={{ mr: 1, mb: 1 }} />)}
                        </Box>
                    </Box>
                </Box>
            </>}</>
    )
}

export default JobDetails;