import * as React from "react";

const NotificationIcon = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={21}
  height={20}
  fill="none"
  {...props}
>
  <path
    fill="#9DA6BA"
    fillRule="evenodd"
    d="M2.255 15h16.806a1.42 1.42 0 0 0 1.014-2.414l-.985-1.002a2 2 0 0 1-.572-1.402V7.825a7.825 7.825 0 1 0-15.65.025l.008 2.308a2 2 0 0 1-.597 1.432l-1.012.996A1.41 1.41 0 0 0 2.255 15Zm10.927 2c.651 0 1.127.625.777 1.174A3.907 3.907 0 0 1 10.663 20a3.907 3.907 0 0 1-3.297-1.826c-.35-.55.126-1.174.777-1.174h5.039Z"
    clipRule="evenodd"
  />
</svg>
);

export default NotificationIcon;
