import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import EditProfileBg from "../../../../assets/img/Dashboard/Teacher/EditProfileBg.png";
import CameraIcon from "../../../../assets/svg/dashboard-icons/CameraIcon.svg";
import EditProfileForm from "./EditProfileForm";
import UserAvatar from "../../../../assets/img/DashboardAvatar.png";

import LeftArrow from "../../../../assets/svg/LeftArrow.svg";
import { getProfileData } from "../../../../utils/dashboard/dashboardFunctions";

import { useDispatch, useSelector } from "react-redux";
import {
  uploadProfileImage,
  getProfile,
} from "../../../../redux/dashboard/actions";
import { setUserProfileData } from "../../../../redux/dashboard/slice";

const EditProfile = ({ handleProfileIndex }) => {
  // const [profile, setProfile] = useState({});

  const { profileData } = useSelector(
    (state) => state.dashboardReducer
  );

  const [imageValue, setImageValue] = useState(null);
  const [pickedImage, setPickedImage] = useState(null);
  const [imageError, setImageError] = useState("");

  const dispatch = useDispatch();

  const uploadImage = async (e) => {
    const file = e.target.files[0];
  if(file){
  const fileSize = Math.round(file.size / 1024);
  if (fileSize > 1024) {
    setImageError("File too big, must be < 1mb");
  } else {
    setImageError("");
    const base64 = await convertBase64(file);
    setImageValue(base64);
    setPickedImage(true)
    // console.log('base64 :>> ', base64);

    dispatch(uploadProfileImage({data: { imgUrl: base64 }, callBack: ()=> {
      setImageValue(null)
      setPickedImage(null)}}));
  }
}
   
  };

  const convertBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        // var image = new Image();
        // image.src = e.target.result;
        // image.onload = function () {
        //   if (image.height > 700 || image.width > 700) {
        //     setImageError("Image size too big");
        //   } else {
        //     setImageError("");
        resolve(reader.result);
        //   }
        // };
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    dispatch(getProfile());
    // let myProfile = getProfileData();
    // if(!myProfile){
    //   dispatch(getProfile());
    // }
  }, []);

  return (
    <Box sx={styles.container}>
      <Box
        component="img"
        src={LeftArrow}
        alt="left arrow"
        mb="10px"
        onClick={() => {
          handleProfileIndex();
        }}
      />
      <Box>
        <Box
          component="img"
          src={EditProfileBg}
          alt="edit profile bg"
          width="100%"
        />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          position="relative"
          bottom="30px"
          gap="20px"
          height="0"
        >
          <Box height="150px" width="150px" position="relative">
            <Box
              variant="contained"
              component="label"
              position="absolute"
              bottom="0px"
              right="0px"
              sx={{ cursor: "pointer" }}
            >
              <Box component="img" src={CameraIcon} alt="camera" />
              <input
                onChange={(e) => uploadImage(e)}
                type="file"
                name="image"
                id="image"
                hidden
                accept="image/*"
              />
            </Box>

           {!!imageValue && pickedImage && <Box
              component="img"
              src={imageValue}
              alt="ProfileImage"
              height="100%"
              width="100%"
              sx={{ objectFit: "cover" }}
              borderRadius="50%"
            />}
             { !!profileData?.imgUrl && !pickedImage && <Box
              component="img"
              src={profileData?.imgUrl}
              alt="ProfileImage"
              height="100%"
              width="100%"
              sx={{ objectFit: "cover" }}
              borderRadius="50%"
            />}
             { !profileData?.imgUrl && !imageValue &&  <Box
              component="img"
              src={UserAvatar}
              alt="ProfileImage"
              height="100%"
              width="100%"
              sx={{ objectFit: "cover" }}
              borderRadius="50%"
            />}
          </Box>

          <Box display="flex" flexGrow="1" justifyContent="space-between">
            <Box>
              <Typography fontSize="20px" fontWeight="500" gutterBottom>
                {profileData?.firstName} {profileData?.lastName}
              </Typography>
              <Typography>{profileData?.category}</Typography>
            </Box>

            {/* <Typography sx={styles.linkText}>Delete account</Typography> */}
          </Box>
        </Box>
      </Box>

      <EditProfileForm
        handleProfileIndex={handleProfileIndex}
      />
    </Box>
  );
};

export default EditProfile;

const styles = {
  container: {
    width: "95%",
    margin: "0 auto",
    borderRadius: "10px",
    mt: "10px",
  },
  linkText: {
    fontWeight: "700",
    fontSize: "18px",
    color: "#D41111",
  },
};
