import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";

import AppButton from "../../buttons/AppButton";

import AppInput from "../../inputs/AppInput";

import { useDispatch, useSelector } from "react-redux";
import { formatAsCurrency } from "../../../utils/courses/coursesFunctions";
import { removeCourseFromCart, setCourseCart } from "../../../redux/cart/slice";
import { getPurchaseUrl } from "../../../redux/courses/actions";

const FilledCart = () => {
  const dispatch = useDispatch();

  const { cartCourses } = useSelector((state) => state.cartReducer);
  const { userData } = useSelector((state) => state.authReducer);
  const { isLoading } = useSelector((state) => state.coursesReducer);
  const [totalSum, setTotalSum] = useState(0);
console.log('cartCourses', cartCourses)
  useEffect(() => {
    let num = 0;

    for (let x of cartCourses) {
      num += x.price * x.units;
    }

    setTotalSum(num);
  }, [cartCourses]);

  const onBuyCourses = () => {

    let cart = cartCourses.map(course => { return { id: course?.id, qty: course.units } });
    dispatch(getPurchaseUrl({data: { cart }}))
  }

  const setCourseUnits = (courseId, type) => {
    let copyCourses = JSON.parse(JSON.stringify(cartCourses));;
    let courseIndex = copyCourses.findIndex(x => x.id == courseId);
    if (courseIndex > -1) {
      if (type == "add") {
        copyCourses[courseIndex].units++;
      } else if (type == "minus" && copyCourses[courseIndex].units != 1) {
        copyCourses[courseIndex].units--;
      }
      dispatch(setCourseCart(copyCourses))
    }
  }

  const removeItemFromCart = (course) =>{
    if(window.confirm(`Are sure you want to remove "${course.name}" from cart?`)){
        dispatch(removeCourseFromCart(course.id))
    }
  }

  return (
    <Box sx={{display: {xs:"none",lg:"block"}}}>

      <Grid container gap="60px" flexWrap="nowrap">
        <Grid container columns={7} flexDirection="column" flexWrap="nowrap">
          {cartCourses.map((course) => {
            return (
              <Grid
                key={course.id}
                item
                xs={8}
                display="flex"
                justifyContent="space-between"
                gap="15px"
                borderTop="2px solid #E5E5E5"
                p="30px 20px"
              >
                <Box display="flex">
<Box
                  component="img"
                  src={course.coverImage}
                  alt="CoursePreview"
                  height="180px"
                  width="250px"
                  mr="20px"
                />

                <Box>
                  <Typography fontSize="20px" gutterBottom>
                    {course.name}
                  </Typography>

                  <Box display="flex" gap="7px" alignItems="center" my="20px">
                    {/* <StarOutlinedIcon sx={{ color: "#FFC90C" }} />
                    <Typography mr="15px">4.0/5.0</Typography> */}

                    <PlayCircleFilledOutlinedIcon sx={{ color: "#2E3192" }} />
                    <Typography mr="15px"> {course.contentInfo}</Typography>

                    {/* <AccessTimeIcon sx={{ color: "#4B4E61" }} />
                    <Typography>2h 30m</Typography> */}
                  </Box>

                  <Typography fontSize="18px" color="#2E3192" gutterBottom>
                    {course.category}
                  </Typography>

                  {userData.userType == "school" && <Box display="flex" alignItems="center" gap="10px">
                    Units:
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-evenly"
                      borderRadius="10px"
                      border="1px solid #E5E5E5"
                      gap="10px"
                      py="10px"
                    >
                      <Typography onClick={() => setCourseUnits(course.id, "minus")} style={{ cursor: "pointer", padding: "0px 10px" }} fontWeight="700">-</Typography>
                      <Typography>{course.units}</Typography>
                      <Typography onClick={() => setCourseUnits(course.id, "add")} style={{ cursor: "pointer", padding: "0px 10px" }} fontWeight="700">+</Typography>
                    </Box>
                  </Box>}
                </Box>
                </Box>

                
                <Box>
                  <Typography fontSize="18px" color="primary" fontWeight="600">₦{formatAsCurrency(course.price * course.units)}</Typography>
                  <Typography fontSize="12px" style={{fontStyle: "italic",textAlign: "right"}}>
                    ₦{formatAsCurrency(course.price)} x {course.units}
                  </Typography>
                  <Typography onClick={()=> removeItemFromCart(course)} color="#D41111" style={{fontSize:"12px", textDecoration: "underline", cursor:"pointer", textAlign: "right", marginTop:"20px",padding: "10px 0"}}>Remove</Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        <Grid item xs={4} pt="30px" pr="10px">
          <Typography fontSize="16px" fontWeight="400" gutterBottom>
            Total:
          </Typography>
          <Typography fontSize="22px" fontWeight="600" mb="16px">
            ₦{formatAsCurrency(totalSum)}
          </Typography>

          <AppButton
            customStyles={{
              fontWeight: "bold",
              fontSize: "18px",
              borderRadius: "8px",
            }}
            btnText="Make Payment"
            onClick={onBuyCourses}
            isLoading={isLoading}
            loadingComponent="Processing..."
          />

          <hr
            style={{
              height: "2px",
              backgroundColor: "#EFEFEF",
              border: "none",
              margin: "16px 0",
            }}
          />

          <Box
            display="flex"
            width="100%"
            borderRadius="10px"
            border="2px solid #2E3192"
            overflow="hidden"
          >
            <AppInput
              noBottomPad
              disableOutline={true}
              customStyles={{ flexGrow: "2" }}
              placeholder="Enter Coupon"
            />
            <AppButton
              customStyles={{
                flexGrow: "1",
                fontSize: "16px",
                fontWeight: "600",
              }}
              btnText="Apply"
              fullWidth={false}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilledCart;
