import React, { Fragment, useEffect, useRef, useState } from "react";
import PageContainer from "../../../container/PageContainer";
import { useDispatch, useSelector } from "react-redux";
import CustomTabPanel, { a11yProps } from "../../CustomTabPanel";
import parse from "html-react-parser";
import {
  getChatForJobApplication,
  getChatForJobApplicationLowKey,
  getJobApplicationDetails,
  sendChatForJobApplication,
  updateApplicationStatus,
} from "../../../redux/jobs/action";
import { useNavigate, useParams } from "react-router-dom";
import UserAvatar from "../../../assets/img/DashboardAvatar.png";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { APP_COLOURS, DOCUMENT_TYPE, MESSAGE_PURPOSE_TYPE } from "../../../utils/constant";
import {
  AttachFileSharp,
  Cancel,
  CancelOutlined,
  Chat,
  CheckBox,
  Close,
  Rocket,
  Send,
} from "@mui/icons-material";
import {
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnUndo,
  BtnRedo,
  BtnBulletList,
  BtnLink,
  BtnNumberedList,
  Editor,
  EditorProvider,
  Toolbar,
  BtnStyles,
} from "react-simple-wysiwyg";
import { markMessageAsRead } from "../../../redux/user/action";
import AppInput from "../../inputs/AppInput";
import AppButton from "../../buttons/AppButton";
import {
  decodeHTML,
  getStatusKey,
  getStatusText,
  getStatusTextColor,
  useQueryParam,
} from "../../../utils/stringFunctions";
import { getLocalPrettyDate } from "../../../utils/dateFunctions";
import { getUserDocuments } from "../../../redux/document/action";
import ViewFileModal from "../../modal/ViewFileModal";



function ViewApplication() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [showDocument, setShowDocument] = useState(false);
  const [showRejectForm, setShowRejectForm] = useState(null);
  const [chat, setChat] = useState("");
  const tab = useQueryParam("tab");
  const { userData } = useSelector(state => state.authReducer)

  const onSendChat = () => {
    if (chat?.length == 0) {
      return;
    }
    const payload = {
      applicationId,
      messageInfo: {
        to: application.user.id,
        from: userData.id,
        message: chat,
      },
      callback: () => setChat("")
    }
    dispatch(sendChatForJobApplication(payload));
  }

  const { application, isFetching, isLoading, fetchingMessages, sendingChat, jobChats } = useSelector(
    (state) => state.jobReducer
  );

  const { userDocuments } = useSelector((state) => state.documentReducer);
  const [html, setHtml] = useState("");
  const [statusMessages, setStatusMessages] = useState({});
  const containerRef = useRef();

  useEffect(() => {
    scrollToBottom();
  }, [value]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current?.scrollHeight;
    }

  };

  const onChange = (e) => {
    setHtml(e.target.value);
  };
  const onChangeMessage = (e) => {
    setChat(e.target.value);
  };

  useEffect(() => {
    dispatch(getJobApplicationDetails({ applicationId }));
  }, [applicationId, dispatch]);

  useEffect(() => {
    if (value == 1 && jobChats?.length > 0) {
      if (jobChats[jobChats?.length - 1].isRead == false && jobChats[jobChats?.length - 1].to == userData.id) {
        dispatch(markMessageAsRead({
          purpose: MESSAGE_PURPOSE_TYPE.jobApplication,
          purposeId: applicationId
        }))
      }
    }
  }, [jobChats])

  useEffect(() => {
    // if (application?.user?.id) {
    //   dispatch(getUserDocuments({ userId: application.user.id }));
    // }
    if (tab && parseInt(tab) == 1) setValue(parseInt(tab))
  }, [tab]);

  useEffect(() => {
    if (application?.job.remarks) {
      setStatusMessages(JSON.parse(application?.job.remarks));
      dispatch(getChatForJobApplication({
        applicationId,
        userInfo: {
          user1: application.job.userId,
          user2: application.job.vacancy.userId
        }
      }))
    }
  }, [application?.job]);
  const refreshChat = () => {
    dispatch(getChatForJobApplicationLowKey({
      applicationId,
      userInfo: {
        user1: application.job.userId,
        user2: application.job.vacancy.userId
      }
    }))
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(getChatForJobApplicationLowKey({
      applicationId,
      userInfo: {
        user1: application.job.userId,
        user2: application.job.vacancy.userId
      }
    }))
  };

  const onStatusUpdated = () => {
    let payload = {
      statusInfo: {
        status: getStatusValue(showRejectForm),
        message: html,
      },
      applicationId,
      callback: (dispatch) => {
        dispatch(getJobApplicationDetails({ applicationId }));
        setHtml(null);
        setShowRejectForm(false);
      },
    };
    dispatch(updateApplicationStatus(payload));
  };

  const getStatusLabel = (status) => {
    if (status) {
      if (status == 1) return "Acceptance";
      if (status == 2) return "Rejection";
      if (status == 3) return "Completion";
      if (status == 4) return "Closing";
    }
    if (showRejectForm == "reject") return "Rejection";
    if (showRejectForm == "close") return "Closing";
    if (showRejectForm == "accept") return "Acceptance";
    if (showRejectForm == "complete") return "Completion";
  };

  const getButtonLabel = () => {
    if (showRejectForm == "reject") return "Reject";
    if (showRejectForm == "close") return "Close";
    if (showRejectForm == "accept") return "Accept";
    if (showRejectForm == "complete") return "Complete";
  };


  const getStatusValue = (status) => {
    if (status) {
      if (status == "accept") return 1;
      if (status == "reject") return 2;
      if (status == "complete") return 3;
      if (status == "close") return 4;
    }
  };

  const getFileDisplay =(file) =>{
    switch (file.type) {
      case DOCUMENT_TYPE.cv:
          return "Curriculum Vitae";
      case DOCUMENT_TYPE.cac:
          return "CAC";
      case DOCUMENT_TYPE.highestDegree:
          return "Highest Degree";
      case DOCUMENT_TYPE.nysc:
          return "NYSC Certificate";
      case DOCUMENT_TYPE.policeReport:
          return "Police Report";
      case DOCUMENT_TYPE.profQualification:
          return "Professional Cert";
      default:
          return file.name;
  }
  }

  return (
    <PageContainer>
      <ViewFileModal file={showDocument} onClose={() => setShowDocument(false)} showDocument={showDocument} key="file" />
      <Box sx={{ mb: 2, borderColor: "divider" }}>
        <Button onClick={() => navigate(-1)}> Go Back</Button>
        {!isFetching && application && (
          <Typography mb={1} mt={2} variant="h6">
            Role: {application?.job.vacancy?.title}
          </Typography>
        )}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Applicant" {...a11yProps(0)} />
          <Tab label="Chats" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {isFetching && <CircularProgress />}
        {!isFetching && application && (
          <Fragment>
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Personal Information</Typography>
              <Typography
                fontWeight={600}
                color={getStatusTextColor(application?.job?.status)}
              >
                {getStatusText(application?.job?.status)}
              </Typography>
            </Box>

            <Box sx={{ display: { md: "flex", sx: "block" } }}>
              <Box
                component="img"
                src={
                  application?.user?.imgUrl
                    ? application?.user?.imgUrl
                    : UserAvatar
                }
                alt="ProfileImage"
                height="100%"
                width="100%"
                sx={{
                  objectFit: "cover",
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                  marginBottom: { xs: 3 },
                }}
              />

              <Box sx={{ marginLeft: { md: 5 } }}>
                <div style={styles.text}>
                  <Typography fontSize={10} color={APP_COLOURS.primary}>
                    Name
                  </Typography>
                  <Typography mb={1}>
                    {`${application?.user.firstName} ${application?.user.lastName}`}
                  </Typography>
                </div>
                <div style={styles.text}>
                  <Typography fontSize={10} color={APP_COLOURS.primary}>
                    Email:
                  </Typography>
                  <Typography mb={1}> {application?.user.email}</Typography>
                </div>
                <div style={styles.text}>
                  <Typography fontSize={10} color={APP_COLOURS.primary}>
                    Contact Number:
                  </Typography>
                  <Typography mb={1}> {application?.user.phone}</Typography>
                </div>
              </Box>
              <Box sx={{ marginLeft: { md: 5 } }}>
                <div style={styles.text}>
                  <Typography fontSize={10} color={APP_COLOURS.primary}>
                    Qualification:
                  </Typography>
                  <Typography mb={1}>
                    {application?.user.qualification}
                  </Typography>
                </div>
                {application?.user?.location && <div style={styles.text}>
                  <Typography fontSize={10} color={APP_COLOURS.primary}>
                    Location:
                  </Typography>
                  <Typography mb={1}>
                    {application?.user.location}
                  </Typography>
                </div>}
                {application?.user?.bio && (
                  <div style={styles.text}>
                    <Typography fontSize={10} color={APP_COLOURS.primary}>
                      Bio:
                    </Typography>
                    <Typography mb={1}> {application?.user.bio}</Typography>
                  </div>
                )}
              </Box>
            </Box>
            <Box mb={3}>
              <Typography variant="h6" mb={2}>
                Documents
              </Typography>
              {!application?.documents?.length && (
                  <Typography fontStyle="italic">No record found</Typography>
                )}

                {application?.documents?.length && application.documents.map(doc => <Button
                  variant="outlined"
                  onClick={() => setShowDocument(doc.fileUrl)}
                  sx={{ mr: 1, mb: { md: 0, xs: 1 } }}
                  startIcon={<AttachFileSharp />}
                >
                  {getFileDisplay(doc)}
                </Button>)}
             
            </Box>
            <Box mb={5}>
              <Typography variant="h6" mb={2}>
                Application Status
              </Typography>
              {application?.job?.status == 0 && !showRejectForm && (
                <Fragment>
                  <Typography>Are you interested in this applicant?</Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Button
                      startIcon={<Rocket />}
                      onClick={() => setShowRejectForm("accept")}
                    >
                      Accept
                    </Button>
                    <Button
                      color="error"
                      startIcon={<CancelOutlined />}
                      onClick={() => setShowRejectForm("reject")}
                    >
                      Reject
                    </Button>
                  </Box>
                </Fragment>
              )}
              {application?.job?.status == 1 && !showRejectForm && (
                <Fragment>
                  <Typography>You accepted this application</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      mb: 5,
                    }}
                  >
                    <Button
                      startIcon={<Chat />}
                      onClick={() => handleChange(null, 1)}
                    >
                      Chat Applicant
                    </Button>
                    <Button
                      color="success"
                      startIcon={<CheckBox />}
                      onClick={() => setShowRejectForm("complete")}
                    >
                      Complete
                    </Button>
                    <Button
                      color="error"
                      startIcon={<Close />}
                      onClick={() => setShowRejectForm("close")}
                    >
                      Close
                    </Button>
                  </Box>
                </Fragment>
              )}
              {application?.job?.status == 2 && !showRejectForm && (
                <Fragment>
                  <Typography fontWeight={500} color="error">
                    You rejected this application
                  </Typography>
                </Fragment>
              )}
              {application?.job?.status == 3 && !showRejectForm && (
                <Fragment>
                  <Typography fontWeight={500} color="success">
                    This Application has been completed
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      mb: 2,
                    }}
                  >
                    <Button
                      startIcon={<Chat />}
                      onClick={() => handleChange(null, 1)}
                    >
                      Chat Applicant
                    </Button>
                  </Box>
                </Fragment>
              )}
              {application?.job?.status == 4 && !showRejectForm && (
                <Fragment>
                  <Typography fontWeight={500} color="error">
                    This application has been closed by you
                  </Typography>
                </Fragment>
              )}
              {showRejectForm && (
                <>
                  <Typography sx={{ mb: 1 }}>
                    {getStatusLabel()} Message
                    <span style={{ fontStyle: "italic", fontSize: 12 }}>
                      (Optional. This will be sent to the applicant)
                    </span>
                  </Typography>
                  <EditorProvider>
                    <Editor value={html} onChange={onChange}>
                      <Toolbar>
                        <BtnUndo />
                        <BtnRedo />
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnLink />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <BtnStyles />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                  <AppButton
                    btnText={`${getButtonLabel()} Application`}
                    customStyles={{ marginBottom: "15px", marginTop: "10px" }}
                    onClick={onStatusUpdated}
                    isLoading={isLoading}
                    loadingComponent="Processing..."
                  />
                  {!isLoading && (
                    <AppButton
                      btnText="Cancel"
                      variant="outlined"
                      customStyles={{ marginBottom: "50px" }}
                      onClick={() => {
                        setHtml(null);
                        setShowRejectForm(null);
                      }}
                    />
                  )}
                </>
              )}
              {application?.job?.status != 0 &&
                statusMessages[getStatusKey(application?.job?.status)] && (
                  <Box
                    bgcolor={
                      application?.job?.status == 2 ? "#ffefea" : "#d5d6e933"
                    }
                    p={1}
                    borderRadius={2}
                  >
                    <Typography fontWeight={500} sx={{ mb: 1 }}>
                      {getStatusLabel(application?.job?.status)} Message
                      <span style={{ fontStyle: "italic", fontSize: 12 }}>
                        (This was sent to the applicant)
                      </span>
                    </Typography>
                    <Typography>
                      {parse(
                        decodeHTML(
                          statusMessages[getStatusKey(application?.job?.status)]
                        )
                      )}
                    </Typography>
                  </Box>
                )}
            </Box>
          </Fragment>
        )}
        <Box mb={5}></Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {application?.job?.status == 0 && (
          <Box textAlign="center" mt={4}>
            <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }}>
              You need accept this application to start a chat with the person.
            </Typography>
          </Box>
        )}
        {application?.job?.status == 2 && (
          <Box textAlign="center" mt={4}>
            <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }}>
              This application was rejected and closed.
            </Typography>
          </Box>
        )}
        {application?.job?.status != 2 && application?.job?.status != 0 && (
          <>
            <Box sx={{ position: "relative", }}>
              <div ref={containerRef} style={{ height: "50vh", display: "flex", flexDirection: "column", overflowY: "auto" }}>
                {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                }}
              > */}
                {jobChats?.length == 0 && <Box textAlign="center" mt={4}>
                  <Typography sx={{ fontSize: { xs: "14px", lg: "18px" } }}>
                    No chats yet.
                  </Typography>
                </Box>}
                {jobChats.map((chat, index) => userData.id == chat.from ?


                  <Box
                    sx={{
                      maxWidth: "85%",
                      marginLeft: "auto",
                      display: "inline-block",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        backgroundColor: "#cfd0f0",
                        p: 1,
                        borderRadius: "10px",
                      }}
                    >
                      {chat.message}
                    </Typography>
                    <Typography
                      fontSize={10}
                      sx={{ width: "100%", textAlign: "right" }}
                    >
                      {getLocalPrettyDate(chat.createdAt)}
                    </Typography>
                  </Box> : <Box
                    sx={{
                      maxWidth: "85%",
                      display: "inline-block",
                      marginRight: "auto",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        backgroundColor: "#ececec",
                        p: 1,
                        borderRadius: "10px",
                      }}
                    >
                      {chat.message}
                    </Typography>
                    <Typography
                      fontSize={10}
                      sx={{ width: "100%", textAlign: "right" }}
                    >
                      {getLocalPrettyDate(chat.createdAt)}
                    </Typography>
                  </Box>
                )}

              </div>
              <Box sx={{ alignSelf: "flex-end" }}>
                {fetchingMessages && <Typography fontStyle="italic" >Fetching Messages...</Typography>}
                <AppInput
                  multiline
                  minRows={1}
                  maxRows={3}
                  value={chat}
                  onChange={onChangeMessage}
                />
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 2 }}>
                  <Button onClick={refreshChat}>Refresh</Button>
                  <AppButton btnText="Send" endIcon={<Send />} size="small" customStyles={{
                    padding: "7px 30px",
                  }} fullWidth={false} isLoading={sendingChat} loadingComponent="Sending..." onClick={onSendChat} />
                </Box>

              </Box>
            </Box>
          </>
        )}
      </CustomTabPanel>
    </PageContainer>
  );
}

const styles = {
  text: {},
};

export default ViewApplication;
