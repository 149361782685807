import { createSlice } from "@reduxjs/toolkit";
import { addTeachersToCourse, getModuleQUestions, getSchoolTeachers, inviteTeacherToSchool, listTeachersForCourse, respondToSchoolInvite, submitModuleQuestions } from "./action";

export const schoolSlice = createSlice({
  name: "school",
  initialState: {
    schoolTeachers: [],
    courseTeachersDetails: {},
    isFetchingCouseTeachers: false,
    isLoading: false
  },
  reducers: {
  },
  extraReducers: {
    // ---------GET SCHOOL TEACHERS ---------/
    [getSchoolTeachers.pending]: (state) => {
      state.isFetching = true;
    },
    [getSchoolTeachers.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.schoolTeachers = payload.data;
      }
      state.isFetching = false;
    },
    [getSchoolTeachers.rejected]: (state, load) => {
      state.isFetching = false
    },
    // ---------INIVTE TEAVHER TO SCHOOL ---------/
    [inviteTeacherToSchool.pending]: (state) => {
      state.isLoading = true;
    },
    [inviteTeacherToSchool.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [inviteTeacherToSchool.rejected]: (state, load) => {
      state.isLoading = false
    },
    // ---------INIVTE RESPONSE TO SCHOOL ---------/
    [respondToSchoolInvite.pending]: (state) => {
      state.isLoading = true;
    },
    [respondToSchoolInvite.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [respondToSchoolInvite.rejected]: (state, load) => {
      state.isLoading = false
    },
    // ---------LIST TEACHERS FOR COURSE ---------/
    [listTeachersForCourse.pending]: (state) => {
      state.isFetchingCouseTeachers = true;
    },
    [listTeachersForCourse.fulfilled]: (state, { payload }) => {
      if (payload.status === "success") {
        state.courseTeachersDetails = payload.data;
      }
      state.isFetchingCouseTeachers = false;
    },
    [listTeachersForCourse.rejected]: (state, load) => {
      state.isFetchingCouseTeachers = false
    },
      // ---------ADD TEACHERS FOR COURSE ---------/
      [addTeachersToCourse.pending]: (state) => {
        state.isLoading = true;
      },
      [addTeachersToCourse.fulfilled]: (state, { payload }) => {
        state.isLoading = false;
      },
      [addTeachersToCourse.rejected]: (state, load) => {
        state.isLoading = false
      },
  },
});

export const { } = schoolSlice.actions;

export default schoolSlice.reducer;
