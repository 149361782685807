import React, { useState } from 'react';
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  TextField,
} from '@mui/material';
import axios from 'axios';

const DocumentUploader = ({onSetFiles, progress, setProgress}) => {
  const [file, setFile] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState(null);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      onSetFiles(event.target.files);
      setUploadedUrl(null);
      setProgress(0);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('http://localhost:5000/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (event) => {
          const percent = Math.round((event.loaded * 100) / event.total);
          setProgress(percent);
        },
      });

      setUploadedUrl(response.data.url);
      alert('File uploaded successfully!');
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file.');
    }
  };

  return (
    <Box sx={{ width: "100%", textAlign: 'center'}}>

      <TextField
        type="file"
        inputProps={{ accept: '*' }}
        onChange={handleFileChange}
        fullWidth
        margin="normal"
      />

      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        fullWidth
        disabled={!file}
        sx={{ marginBottom: 2 }}
      >
        Upload
      </Button> */}

      {progress > 0 && (
        <Box sx={{ marginTop: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            Upload Progress: {progress}%
          </Typography>
        </Box>
      )}

      {uploadedUrl && (
        <Typography variant="body2" color="success.main" sx={{ marginTop: 2 }}>
          File Uploaded Successfully: <a href={uploadedUrl}>{uploadedUrl}</a>
        </Typography>
      )}
    </Box>
  );
};

export default DocumentUploader;