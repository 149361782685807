import { Box } from '@mui/material'
import React from 'react'

function PageContainer(props) {
    return (
        <Box
            m="10px auto 0"
            width="95%"
            backgroundColor="white"
            borderRadius="12px"
            p="20px"
        >
            {props.children}
        </Box>
    )
}

export default PageContainer