import React from "react";
import { Box, Typography, Container } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import AppButton from "../../buttons/AppButton";
import HeroImage from "../../../assets/img/EventHero.png";
import TopRightBg from "../../../assets/svg/Hero/TopRightBg.svg";
import BottomLeftBg from "../../../assets/svg/Hero/BottomLeftBg.svg";
import BottomRightBg from "../../../assets/svg/Hero/BottomRightBg.svg";
import LongSummer from "../../../assets/img/summer2023.png";

import classes from "./Hero.module.css";

const EventHero = () => {
  return (
    <Container maxWidth="false" disableGutters sx={styles.container}>
      <Navbar />

      <Box
        display={{ xs: "none", md: "block" }}
        position="absolute"
        right="0"
        top="20%"
      >
        <img src={TopRightBg} alt="bg" />
      </Box>

      <Box
        display={{ xs: "none", md: "block" }}
        position="absolute"
        right="0"
        bottom="-15%"
      >
        <img src={BottomRightBg} alt="bg" />
      </Box>

      <Box
        display={{ xs: "none", md: "block" }}
        position="absolute"
        left="0"
        bottom="15%"
      >
        <img src={BottomLeftBg} alt="bg" />
      </Box>

      <Box
        maxWidth="1600px"
        margin="0 auto"
        component="main"
        marginTop="30px"
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box
          width={{ xs: "100%", md: "50%" }}
          display="flex"
          flexDirection="column"
          gap="20px"
          alignItems={{ xs: "center", md: "flex-start" }}
          position={{ xs: "absolute", md: "relative" }}
          zIndex={{ xs: "50", md: "0" }}
          top={{ xs: "30vh", md: "15vh" }}
        >
         <Box component="img" src={LongSummer} maxWidth="95%" height={{xs:"30px", md: "50px"}}>
         </Box>
         

          <Typography
            width={{ xs: "95%", md: "100%" }}
            textAlign={{ xs: "center", md: "start" }}
            component="h2"
            fontSize={{ xs: "12px", md: "16px" }}
            sx={{border: "#2E3192 solid 1px", width:"100px", textAlign: "center", color:"#2E3192", padding: "5px", borderRadius: "10px" }}
            fontWeight="600"
            marginTop="10px"
            lineHeight="1.3"
            zIndex="1"
          >
            Theme: 
          </Typography>
          <Typography
            width={{ xs: "95%", md: "100%" }}
            textAlign={{ xs: "center", md: "start" }}
            component="h2"
            fontSize={{ xs: "28px", md: "57px" }}
            fontWeight="600"
            lineHeight="1.3"
            zIndex="1"
          >
            Leading Innovation for Futuristic Education
          </Typography>

          {/* <Typography
            width={{ xs: "95%", md: "80%" }}
            fontSize={{ xs: "18px", md: "20px" }}
            textAlign={{ xs: "center", md: "start" }}
          >
          
          </Typography> */}
          <Box
            display={{ xs: "flex", md: "flex" }}
            flexDirection="column"
            gap="25px"
            sx={styles.heroBtns}
          >
             <a
              href="https://forms.gle/UrgpQwegvtEPAxCz5"
              target="_blank"
              rel="noopener noreferrer"
            >
            <AppButton
              customStyles={{ fontWeight: "600" }}
              fullWidth={false}
              btnText="Register for Event"
            />
            </a>
            {/* <AppButton
              customStyles={{ fontWeight: "600" }}
              fullWidth={false}
              btnText="For Schools" */}
            {/* /> */}
          </Box>
        </Box>

        <img src={HeroImage} alt="hero img" className={classes.heroImage} />
      </Box>
    </Container>
  );
};

export default EventHero;

const styles = {
  container: {
    position: "relative",
    overflow: "hidden",
    background: {
      sm: "#ffffff",
      md: "linear-gradient(292.12deg, #D5D6E9 -35.02%, #EAEAF4 78.96%)",
    },
    padding: { xs: "0", md: "0 70px 170px" },
  },
};
