import React from "react";
import LoginForm from "../../components/onboarding/Login/LoginForm";
import OnboardingLayout from "../../components/onboarding/OnboardingLayout";
import OnboardingImage from "../../assets/img/OnboardingImage.png";
import Dummy from "../../components/dummy";

const Login = () => {
  return (
    <>
    {/* <Dummy /> */}
    <OnboardingLayout image={OnboardingImage}>
      <LoginForm /> </OnboardingLayout>
    </>
    
   
  );
};

export default Login;
