import { createSlice } from "@reduxjs/toolkit";
import {
  registerTeacher,
  registerSchool,
  verifyUserEmail,
  loginUser,
  checkEmail,
  getTeacherCategory,
  getSchoolCategory,
  getQualification,
  getSpecialisation,
  forgotPassword,
  sendVerificationMail,
  resetPassword,
} from "./actions";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    something: false,
    isBtnLoading: false,
    isUserRegistered: false,
    registeredUserEmail: "",
    userData: null,
    userToken: null,
    isUserVerified: false,
    unverifiedUserEmail: "",
    redirectUnverifiedUser: false,
    isUserLoggedIn: false,
    isEmailExisting: null,
    teacherCategory: [],
    teacherCategoryState: "Loading categories",
    schoolCategory: [],
    schoolCategoryState: "Loading categories",
    qualification: [],
    qualificationState: "Loading qualifications",
    specialisation: [],
    specialisationState: "Choose a category",
    forgotPasswordEmail: null,
    pageTitle: "Safeticha - Safe recruitment for schools, professional development for educators."
  },
  reducers: {
    setPageTitle: (state, {payload}) => {
      state.pageTitle = payload;
    },
    getUnregisteredEmail: (state, {payload}) => {
      state.unverifiedUserEmail = payload;
    },
    clearRegisteredUserEmail: (state) => {
      state.isUserVerified = false;
      state.registeredUserEmail = "";
    },
    clearUnverifiedUserState: (state) => {
      state.redirectUnverifiedUser = false;
    },
    clearIsEmailExistingState: (state, payload) => {
      state.isEmailExisting = null;
    },
    setUserInfo: (state, {payload}) =>{
      for (const key in payload) {
        state[key] = payload[key];
      }
    },
    setUserDataInfo: (state, {payload}) =>{
      state.userData = {...state.userData, ...payload}
    },
    setUserState: (state) =>{
      const userData = localStorage.getItem("userData");
      if(userData){
        state.userData = {...state.userData,...JSON.parse(userData)}
      }
    },
    logout: (state) => {
      localStorage.removeItem("storageData");
      localStorage.removeItem("userData");
      localStorage.removeItem("userToken");
      localStorage.removeItem("profileData");
      localStorage.removeItem("token");

      state.userData = null;
      state.userToken = null;

      state.redirectUnverifiedUser = false;
      state.isUserVerified = false;
      state.isUserLoggedIn = false;

      state.registeredUserEmail = "";
      state.isEmailExisting = null;
    },
  },
  extraReducers: {
    // ---------REGISTER TEACHER---------/
    [registerTeacher.pending]: (state) => {
      state.isBtnLoading = true;
    },
    [registerTeacher.fulfilled]: (state, { payload }) => {
      //check
      if (payload.status === "success") {
        localStorage.setItem("storageData", JSON.stringify(payload.data));
        localStorage.setItem("userData", JSON.stringify(payload.data.user));
        localStorage.setItem("userToken", JSON.stringify(payload.data.tokens));

        state.userData = payload.data.user;
        state.userToken = payload.data.tokens;
        state.isUserLoggedIn = true;

        state.isUserRegistered = true;
        state.registeredUserEmail = payload.data.email;
        state.isBtnLoading = false;
      } else {
        state.isBtnLoading = false;
      }
    },
    [registerTeacher.rejected]: (state, load) => {
      state.isBtnLoading = false;
    },

    // ---------REGISTER SCHOOL---------/
    [registerSchool.pending]: (state) => {
      state.isBtnLoading = true;
    },
    [registerSchool.fulfilled]: (state, { payload }) => {
      //check
      if (payload.status === "success") {
        localStorage.setItem("storageData", JSON.stringify(payload.data));
        localStorage.setItem("userData", JSON.stringify(payload.data.user));
        localStorage.setItem("userToken", JSON.stringify(payload.data.tokens));

        state.userData = payload.data.user;
        state.userToken = payload.data.tokens;
        state.isUserLoggedIn = true;
        
        state.isUserRegistered = true;
        state.registeredUserEmail = payload.data.email;
        state.isBtnLoading = false;
      } else {
        state.isBtnLoading = false;
      }
    },
    [registerSchool.rejected]: (state, load) => {
      state.isBtnLoading = false;
    },

    // ---------VERIFY---------/
    [verifyUserEmail.pending]: (state) => {
      state.isBtnLoading = true;
    },
    [verifyUserEmail.fulfilled]: (state, { payload }) => {
      //check
      if (payload.status === "success") {
        state.isUserVerified = true;

        state.isBtnLoading = false;
      } else {
        state.isBtnLoading = false;
      }
    },
    [verifyUserEmail.rejected]: (state, load) => {
      state.isBtnLoading = false;
    },

    // ---------CHECK EMAIL---------/
    [checkEmail.pending]: (state) => {
      state.isBtnLoading = true;
    },
    [checkEmail.fulfilled]: (state, { payload }) => {
      //check
      if (payload.status === "success") {
        if (payload.data.isExist === true) {
          state.isEmailExisting = true;
        } else {
          state.isEmailExisting = false;
        }
        state.isBtnLoading = false;
      } else {
        state.isBtnLoading = false;
      }
    },
    [checkEmail.rejected]: (state, load) => {
      state.isBtnLoading = false;
    },

    // ---------LOGIN---------/
    [loginUser.pending]: (state) => {
      state.isBtnLoading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      //check
      if (payload.status === "success") {
        localStorage.setItem("storageData", JSON.stringify(payload.data));
        localStorage.setItem("userData", JSON.stringify(payload.data.user));
        localStorage.setItem("userToken", JSON.stringify(payload.data.tokens));

        state.userData = payload.data.user;
        state.userToken = payload.data.tokens;
        state.isUserLoggedIn = true;
      } else {
        const errorMessage = payload?.response?.data?.message;

        if (errorMessage?.includes("verify your email")) {
          state.redirectUnverifiedUser = true;
        }
      }
      state.isBtnLoading = false;
    },
    [loginUser.rejected]: (state, load) => {
      state.isBtnLoading = false;
    },

    // ---------GET TEACHER CATEGORY---------/
    [getTeacherCategory.pending]: (state) => {},
    [getTeacherCategory.fulfilled]: (state, { payload }) => {
      //check
      if (payload.status === "success") {
        state.teacherCategory = payload.data;
        state.teacherCategoryState = "Please select a category";
      } else {
        state.isTeacherCategoryLoading = false;
        state.teacherCategoryState = "Couldn't load categories";
      }
    },
    [getTeacherCategory.rejected]: (state, load) => {
      state.teacherCategoryState = "Couldn't load categories";
    },

    // ---------GET SCHOOL CATEGORY---------/
    [getSchoolCategory.pending]: (state) => {},
    [getSchoolCategory.fulfilled]: (state, { payload }) => {
      //check
      if (payload.status === "success") {
        state.schoolCategory = payload.data;
        state.schoolCategoryState = "Please select one or more categories";
      } else {
        state.isSchoolCategoryLoading = false;
        state.schoolCategoryState = "Couldn't load categories";
      }
    },
    [getSchoolCategory.rejected]: (state, load) => {
      state.schoolCategoryState = "Couldn't load categories";
    },

    // ---------GET SPECIALISATION---------/
    [getSpecialisation.pending]: (state) => {
      state.specialisationState = "Loading specialisations";
    },
    [getSpecialisation.fulfilled]: (state, { payload }) => {
      //check
      if (payload.status === "success") {
        state.specialisation = payload.data;
        state.specialisationState = "Please select a specialisation";
      } else {
        state.specialisationState = "Couldn't load specialisations";
      }
    },
    [getSpecialisation.rejected]: (state, load) => {
      state.specialisationState = "Couldn't load specialisations";
    },

    // ---------GET QUALIFICATION---------/
    [getQualification.pending]: (state) => {},
    [getQualification.fulfilled]: (state, { payload }) => {
      //check
      if (payload.status === "success") {
        state.qualification = payload.data;
        state.qualificationState = "Please select a qualification";
      } else {
        state.qualificationState = "Couldn't load qualifications";
      }
    },
    [getQualification.rejected]: (state, load) => {
      state.qualificationState = "Couldn't load qualifications";
    },
    // ---------Forget Password---------/
    [forgotPassword.pending]: (state) => {
      state.isBtnLoading = true;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      if (payload.status === "success"){
        state.forgotPasswordEmail = payload.data?.email;
      }
      state.isBtnLoading = false;
    },
    [forgotPassword.rejected]: (state, load) => {
      state.isBtnLoading = false;
    },
     // ---------Reset Password---------/
     [resetPassword.pending]: (state) => {
      state.isBtnLoading = true;
    },
    [resetPassword.fulfilled]: (state, { meta }) => {
      var params = meta.arg.data;
      state.forgotPasswordEmail = null;
      state.isBtnLoading = false;
    },
    [resetPassword.rejected]: (state, load) => {
      state.isBtnLoading = false;
    },
    // ---------Send verification email---------/
    [sendVerificationMail.pending]: (state) => {
      // state.isBtnLoading = true;
    },
    [sendVerificationMail.fulfilled]: (state, { meta }) => {
      // var params = meta.arg.data;
      // state.forgotPasswordEmail = params.email;
      // state.isBtnLoading = false;
    },
    [sendVerificationMail.rejected]: (state, load) => {
      // state.isBtnLoading = false;
    },
  },
});

export const {
  logout,
  getUnregisteredEmail,
  clearRegisteredUserEmail,
  clearUnverifiedUserState,
  clearIsEmailExistingState,
  setUserInfo,
  setUserDataInfo,
  setUserState
} = authSlice.actions;

export default authSlice.reducer;
