import React from "react";
import { Box, Typography } from "@mui/material";
import QuoteIcon from "../../../assets/svg/aboutus-icons/QuoteIcon.svg";

const AboutUs = () => {
  return (
    <Box maxWidth="1600px" margin="0 auto" sx={styles.aboutUs}>
      <Box sx={styles.aboutUsText}>
        <Typography
          component="h3"
          fontSize={{ xs: "18px", md: "54px" }}
          fontWeight="600"
          lineHeight="1.2"
        >
          Strengthening Africa's education system
        </Typography>
        <Typography
          fontSize={{ xs: "15px", md: "18px" }}
          marginTop={{ xs: "10px", md: "20px" }}
          width={{ xs: "100%", md: "90%" }}
        >
          Resolving the critical challenges faced by Africa's teacher training
          and recruitment systems.
        </Typography>
      </Box>

      <Box sx={styles.aboutUsQuote}>
        <Box sx={styles.quoteIcon}>
          <img src={QuoteIcon} alt="quote" />
        </Box>
        <Typography fontSize="15px" fontWeight="600">
          This has opened our eyes to the basic measures that need to be taken
          by school authorities to ensure that appropriate measures are taken to
          keep children safe.
        </Typography>
        <Typography fontSize="15px" fontWeight="600">
          - Pinefield School Teacher
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutUs;

const styles = {
  aboutUs: {
    padding: { xs: "40px 10px 74px", md: "40px 10px 74px" },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: { xs: "column", md: "row" },
    width: { xs: "95%", md: "85%" },
    margin: "0 auto",
    gap: { xs: "50px", md: "0" },
  },
  aboutUsText: {
    width: { xs: "100%", md: "50%" },
    textAlign: { xs: "center", md: "start" },
  },
  aboutUsQuote: {
    background: "rgba(196, 196, 196, 0.5)",
    borderRadius: "20px",
    width: { xs: "100%", md: "40%" },
    padding: "40px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    position: "relative",
  },
  quoteIcon: {
    position: "absolute",
    top: "-20px",
  },
};
