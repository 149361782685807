import { Autocomplete, Box, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, TextField, Typography, IconButton, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import AppButton from "../../components/buttons/AppButton";
import AppInput from "../../components/inputs/AppInput";
import { useNavigate, useParams } from "react-router-dom";
import { createJob, getJobDetails, updateJob, } from "../../redux/jobs/action";
import { useDispatch, useSelector } from "react-redux";
import { APP_COLOURS, JOB_ROLES } from "../../utils/constant";
import AppSelect from "../../components/inputs/AppSelect";
import {
    BtnBold,
    BtnItalic,
    BtnUnderline,
    BtnUndo,
    BtnRedo,
    BtnBulletList, BtnLink, BtnNumberedList, BtnStrikeThrough,
    Editor,
    EditorProvider,
    Toolbar,
    BtnStyles
} from 'react-simple-wysiwyg';
import { formatAsCurrency } from "../../utils/numberFunctions";
import { decodeHTML, textIsDigits } from "../../utils/stringFunctions";
import UploadDocumentsPage from "../../components/dashboard/School/SchoolProfile/UploadDocuments/UploadDocumentsPage";
import { createResource, editResource, getResourceById  } from "../../redux/resource/action";
import CloseIcon from '@mui/icons-material/Close';

const EditResource = ({ isEdit }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams(); // resource ID from the route params

    const { isSubmitting, resourceDetails, isFetching, resourceInview } = useSelector(state => state.resourceReducer);
    const [showEmployer, setShowEmployer] = useState(true);
    const [category, setCategory] = useState(0)
    const [ageRange, setAgeRange] = useState([]);
    const [base64s, setBase64s] = useState([]);
    const [files, setFiles] = useState([]);
    const [coverImagebase64s, setCoverImageBase64s] = useState([]);
    const [coverImagefiles, setCoverImageFiles] = useState([]);
    const [tags, setTags] = useState([]);
    const { jobId } = useParams();
    const [form, setForm] = useState({
        title: "",
        description: "",
        age: "",
        category: "",
        tags: "",
        file: null,
        coverImage: null,
        price:null
    })
    const [html, setHtml] = useState('');

    const [changeCoverImage, setChangeCoverImage] = useState(false);


    const cancelUploadCoverImage = () => { // Function to cancel updating cover image
        setCoverImageFiles([]);
        setCoverImageBase64s([]);
        setChangeCoverImage(false);
    }

    const onChange = (e) => {
        setHtml(e.target.value);
    }

    useEffect(() => {
        if (jobId && isEdit) {
            dispatch(getJobDetails({ jobId }))
        }
    }, [jobId])


    const [formError, setFormError] = useState({
        title: null,
        files: null,
        coverImagefiles: null,
        terms: null,
    })

    const onSubmitForm = () => {
        console.log("submitting")
        let formErrorCopy = { ...formError };
        if (form.title.length < 3) {
            formErrorCopy.title = "Please select a valid role"
        } else {
            formErrorCopy.title = null
        }
        if (!files.length) {
            formErrorCopy.files = "Please select a file to upload"
        } else {
            formErrorCopy.files = null
        }
        console.log("after if else 1")

        if (formErrorCopy.title) {
            setFormError(formErrorCopy);
            return;
        }

        console.log("after if else 2")
        setFormError(formErrorCopy);
        console.log("from before payload", base64s)
        let payload = {
            id, // Resource ID
            title: form.title,
            price: form.price,
            description: html,
            // published: checked,
            category: category,
            ageRanges: ageRange.join(','),
            tags: tags.join(','),
            file: base64s[0],
            coverImage: coverImagebase64s[0],
        }


        console.log("payload is dispatched", payload)
        dispatch(editResource({ data: payload, callBack: () => {} }))

    }

    const handleInputChange = (event, isAutoComplete) => {
        let formErrorCopy = { ...formError };
        let formCopy = { ...form, [event.target.name]: event.target.value }
        setForm(formCopy);

        if (formCopy.title.length < 3) {
            formErrorCopy.title = "Please enter a valid title"
        } else {
            formErrorCopy.title = null
        }
        if(!textIsDigits(formCopy.price)){
            formErrorCopy.price = "Please enter a valid amount"
        }else {
            formErrorCopy.price = null
        }
        setFormError(formErrorCopy);
    }

    const handleCategory = (event) => {
        setCategory(event.target.value)
    }


    // Fetch resource details when the component mounts
    useEffect(() => {
        if (id) {
        dispatch(getResourceById({ id }));
        }
    }, [id, dispatch]);

  // Populate form with fetched resource details
    useEffect(() => {
        if (resourceInview) {
            console.log("resource in view", resourceInview)
        setForm({
            title: resourceInview.title,
            description: resourceInview.description,
            category: resourceInview.category,
            age: resourceInview.age,
            tags: resourceInview.tags,
            // file: resourceInview.file,
            // coverImage: resourceInview.coverImage,
            price: resourceInview.price
        });
        setCategory(resourceInview.category || 0); 
        setTags(resourceInview.tags ? resourceInview.tags.split(",") : []);  // Tags array
        setAgeRange(resourceInview.ageRanges ? resourceInview.ageRanges.split(",") : []);  // Age ranges array
        setHtml(resourceInview.description); // Populate editor content
        
        }
    }, [resourceInview]);

    return (
        <>
            <Box component="section" sx={{ padding: { xs: "0", lg: "30px" }, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Box backgroundColor="#d5d6e933" p="30px" borderRadius="15px" sx={{ width: "100%", maxWidth: { xs: "95%", lg: "800px" } }}>
                    {isEdit && isFetching && <CircularProgress />}
                    {(!isEdit || (!isFetching && resourceDetails?.id)) && <>
                        <Typography fontSize={16} textAlign="center" color="#2E3192">Edit your resource</Typography>
                        <Box mt={2}>
                            <AppInput
                                onChange={handleInputChange}
                                value={form.title}
                                type="text"
                                name="title"
                                label="Title"
                                placeholder="Name of Resource"
                                error={formError.title != null}
                                helperText={formError.title}
                            />


                            <Typography sx={{ color: APP_COLOURS.lightDark, marginBottom: 1, marginTop: 1 }}>Description</Typography>
                            <EditorProvider>
                                <Editor value={html} onChange={onChange} disabled={isEdit}>
                                    <Toolbar>
                                        <BtnUndo />
                                        <BtnRedo />
                                        <BtnBold />
                                        <BtnItalic />
                                        <BtnUnderline />
                                        <BtnBulletList />
                                        <BtnNumberedList />
                                        <BtnStyles />
                                    </Toolbar>
                                </Editor>
                            </EditorProvider>
                            {formError.description && <Typography component="em" color="error" mt="15px" fontSize="14px">
                                {formError.description}
                            </Typography>}
                            <Box sx={{height:"20px"}}></Box>

                            {/* Price */}
                            <AppInput
                                onChange={handleInputChange}
                                value={form.price}
                                type="text"
                                name="price"
                                label="Price"
                                placeholder="Price"
                                // error={formError.price != null || !isNaN(parseFloat(form.price))}
                                helperText={formError.price || formatAsCurrency(form.price, "₦")}
                            />

                           {/* Resource Category */}
                            <AppSelect label="Category" value={category}
                                name="category"
                                handleInputChange={(e) => handleCategory(e)}
                                selectArray={[
                                    { id: 0, name: "" },
                                    { id: 1, name: "Academic" },
                                    { id: 2, name: "Professional Development" },
                                ]} errorState={!!formError.category} />

                            {/* Age Ranges */}
                            {category === 1 && (
                                <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    sx={{ mt: 3, mb: 6 }}
                                    options={ageRanges.map((option) => `${option} years`)}
                                    freeSolo
                                    value={ageRange}  
                                    onChange={(event, newValue) => {
                                        setAgeRange(newValue);  
                                    }}
                                    renderTags={(value, getTagProps) => {
                                        return value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ));
                                    }}
                                    renderInput={(params) => (
                                        <AppInput
                                            {...params}
                                            variant="filled"
                                            label="Age Ranges"
                                            placeholder="Select 2 max."
                                        />
                                    )}
                                />
                            )}
                            {formError.ageRanges && <Typography component="em" color="error" mt="15px" fontSize="14px">
                                {formError.ageRanges}
                            </Typography>}

                            {/* Tags */}
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                sx={{ marginBottom: "-10px", mt: 3 }}
                                options={tagList.map((option) => option)}
                                freeSolo
                                value={tags}  
                                onChange={(event, newValue) => {
                                    setTags(newValue);  
                                }}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                }
                                renderInput={(params) => (
                                    <AppInput
                                        {...params}
                                        variant="filled"
                                        label="Tags"
                                        placeholder=""
                                    />

                                )}
                            />


                            <Typography sx={{ color: APP_COLOURS.lightDark, marginBottom: 1, marginTop: 10 }}>Cover Image</Typography>
                        
                            {/* Cover Image View */}

                            {
                                !changeCoverImage && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Box
                                    sx={{
                                    width: "100%",
                                    height: 400,
                                    border: '2px dashed #ccc',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#f9f9f9',
                                    position: 'relative',
                                    textAlign: 'center',
                                    }}
                                >      
                                    <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img
                                        src={resourceInview?.coverImage}
                                        alt="Uploaded cover"
                                        style={{ width: '80px', objectFit: 'cover', borderRadius: '5px' }}
                                        />

                                    
                                        <AppButton fullWidth={false} btnText="Change Cover Image" onClick={()=>{setChangeCoverImage(true)}} customStyles={{ mt:5, mr: { lg: "20px" }, mb: { xs: "20px", lg: 0 } }} />
                                       
                                    </Box>
                                
                                </Box>
                            </Box>
                            }


                             {/* Cover Image Change */}
                           {
                            changeCoverImage && 
                            
                            <>
                                 <UploadDocumentsPage
                                    setFiles={setCoverImageFiles}
                                    files={coverImagefiles}
                                    setBase64s={setCoverImageBase64s}
                                    base64s={coverImagebase64s}
                                />

                                <Box display="flex" justifyContent="flex-end">
                                    <AppButton color="error" fullWidth={false} btnText="Cancel" onClick={cancelUploadCoverImage} customStyles={{ mt:0,}} />
                                </Box>

                                {formError.coverImagefiles && <Typography component="em" color="error" mt="15px" fontSize="14px">
                                    {formError.coverImagefiles}
                                </Typography>}
                            </>
                           }



                        </Box>
                        <Box sx={{ display: "flex", mb: { xs: "50px", lg: "20px", }, mt: 4, flexDirection: { xs: "column", lg: "row", justifyContent: "center" } }}>
                            <AppButton isLoading={isSubmitting} loadingComponent="Processing..." fullWidth={false} btnText="Update" onClick={onSubmitForm} customStyles={{ mr: { lg: "20px" }, mb: { xs: "20px", lg: 0 } }} />
                            {!isSubmitting && <AppButton fullWidth={false} btnText="Go Back" onClick={() => navigate(-1)} variant="outlined" />}
                        </Box>
                    </>}
                </Box>
            </Box>
        </>
    );
};

export default EditResource;

const ageRanges = [
    '3-5',
    '5-7',
    '7-11',
    '11-14',
    '14-16',
    '16+',
];

const tagList = [
    "Classroom Management",
    "Communication Skills",
    "Patience",
    "Adaptability",
    "Creativity",
    "Organization",
    "Time Management",
    "Flexibility",
    "Problem-Solving",
    "Critical Thinking",
    "Empathy",
    "Motivation",
    "Leadership",
    "Teamwork",
    "Technology Integration",
    "Curriculum Development",
    "Lesson Planning",
    "Assessment and Evaluation",
    "Differentiation",
    "Active Listening",
    "Conflict Resolution",
    "Cultural Competence",
    "Emotional Intelligence",
    "Collaboration",
    "Decision-Making",
    "Classroom Technology",
    "Classroom Assessment Techniques",
    "Data Analysis",
    "Instructional Design",
    "Literacy Skills",
    "Numeracy Skills",
    "Classroom Innovation",
    "Parent-Teacher Communication",
    "Positive Reinforcement",
    "Classroom Engagement",
    "Learning Styles",
    "Adaptation to Diverse Learners",
    "Adaptation to Special Needs",
    "Public Speaking",
    "Research Skills",
    "Networking",
    "Flexibility",
    "Classroom Discipline",
    "Conflict Management",
    "Reflective Practice",
    "Resourcefulness",
    "Presentation Skills",
    "Classroom Environment Management",
    "Classroom Adaptation to Remote Learning",
    "Lifelong Learning",
    "Curiosity",
    "Global Awareness",
    "Inclusive Teaching",
    "Interpersonal Skills",
    "Differentiated Instruction",
    "Classroom Motivation",
    "Timeless Enthusiasm",
    "Classroom Safety Awareness",
    "Adapting to New Technologies",
    "Digital Literacy",
    "Mentoring",
    "Innovative Teaching Methods",
    "Assistive Technology",
    "Positive Classroom Culture",
    "Parental Involvement",
    "Behavior Management",
    "Classroom Community Building",
    "Data-Driven Decision Making",
    "Assessment Literacy",
    "Growth Mindset",
    "Flipped Classroom Techniques",
    "Crisis Management",
    "Project-Based Learning",
    "STEM Education",
    "Outdoor Education",
    "Mindfulness Practices",
    "Classroom Democracy",
    "Critical Reflection",
    "Learning Environment Design",
    "Resilience",
    "Learning Resourcefulness",
    "Cross-Curricular Integration",
    "Peer Collaboration",
    "Facilitation Skills",
    "Socratic Questioning",
    "Responsive Teaching",
    "Environmental Awareness",
    "Adaptation to Educational Trends",
    "Formative Assessment",
    "Multicultural Competence",
    "Hybrid Teaching Skills",
    "Inquiry-Based Learning",
    "Data Privacy Awareness",
    "SEL (Social-Emotional Learning) Integration",
    "Instructional Adaptation for Learning Styles",
    "Neurodiversity Understanding",
    "Critical Assessment of Educational Resources",
    "Action Research Skills",
    "Classroom Routine Management",
    "Strategic Use of Classroom Space",
    "Classroom Resourcefulness",
    "Project Management",
    "Cross-Cultural Communication",
    "Microsoft Office",
    "Graphic Design"
];

