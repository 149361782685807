import React from "react";
import { Box, TextField, Button, Typography, styled } from '@mui/material';
import BgPattern from "../../../assets/svg/partner-with-us/bg-pattern.svg";
import LogoSvg from '../../../assets/svg/logo.svg';
import FormSvg from '../../../assets/svg/partner-with-us/partner-with-us-form.svg'
import PartnerWithUsImg from '../../../assets/svg/partner-with-us/partner-with-us.svg'


const PartnerWithUs = () =>{


  const Logo = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '50px',
    marginBottom: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
  }));
  
  const FormContainer = styled(Box)(({ theme }) => ({
    paddingBottom: "75px",
    marginRight: "auto",
    border: '1px solid #ddd',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    position: "relative",
    borderBottomLeftRadius: "60px",
    borderTopRightRadius: "60px",
    gap: 10
  }));
  
  const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    backgroundColor: "white",
    borderRadius: "20px",
    border: "none",
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', // Remove the border
      },
      '&:hover fieldset': {
        border: 'none', // Remove border on hover
      },
      '&.Mui-focused fieldset': {
        border: 'none', // Remove border when focused
      },
      '& .MuiInputLabel-root': {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      }

    },
  }));
  
  const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
  }));
  

  return(
    <Box alignItems="center" sx={{ bgcolor: "rgba(39, 60, 94, 1)" }} mt={{ md: 10}}>
      <Box sx={{ width: "100%", backgroundImage: `url('${BgPattern}')`, }} display="flex" padding={{xs: "50px 0px", md: "0px 50px"}}flexDirection={{ xs: "column", md: "row"}} px={{ }}>
        <Box display="flex" flexDirection="column" gap="15px" maxWidth="600px" alignItems="center" justifyContent="center" color="white" mx="auto">
          <Typography
            width="100%"
            fontWeight="600"
            textAlign="center"
            lineHeight={{ md: "35px", lg: "60px" }}
            mb={{ xs: "10px", lg: "15px" }}
            fontSize={{ xs: "30px", md: "30px", lg: "50px" }}
          >
            We are here to help Schools and Teachers
          </Typography>
          <Typography fontSize={{ xs: "16px" }} width="90%" textAlign="center">
            Get our latest news notification by subscribing to our news update
          </Typography>

          <Box component="a" fontWeight={600} maxWidth="220px" fontSize="14px" color="rgba(46, 49, 146, 1)" bgcolor="#ffffff" border="1px solid rgba(170, 170, 170, 1)" px={5} py={2} borderRadius="25px" mt={4} textAlign="center">Partner with us</Box>
        </Box>


        <Box
          display={{ xs: "flex" }}
          height={{md: "400px", lg: "500px"}}
          width="auto"
          maxWidth="100%"
          margin={{ xs: "50px 0px auto", md: "auto" }}
          position="relative"
        >
          <img src={PartnerWithUsImg} style={{margin: "auto", maxWidth: "100%"}} alt="Safetich welcomes teachers" />
        </Box>
      </Box>
    </Box>
  )
}

export default PartnerWithUs;



const styles = {
  whyChooseUs: {
    display: "flex",
    width: { xs: "100%", md: "90%" },
    flexDirection: { xs: "column", md: "row" },
    //margin: "0 auto 100px",
    gap: {xs: "20px"},
    justifyContent: "space-between",
  },
  checkmarkList: {
    display: "flex",
    gap: "10px",
  },
};