import React, { forwardRef } from "react";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { APP_COLOURS } from "../../utils/constant";

const AppInputPassword = forwardRef(
  (
    {
      onChange,
      value,
      name,
      label,
      placeholder,
      error,
      helperText,
      noBottomPad,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        sx={styles.box(noBottomPad)}
      >
        <Typography sx={styles.inputText}>{label}</Typography>
        <Box>
          <OutlinedInput
            fullWidth={true}
            type={showPassword ? "text" : "password"}
            value={value}
            name={name}
            onChange={onChange}
            inputRef={ref}
            error={error}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            placeholder={placeholder}
          />
          {error && (
            <Typography color="error" component="em" mt="10px" fontSize="14px">
              {helperText}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }
);

export default AppInputPassword;

const styles = {
  box: (noBottomPad) => {
    return { marginBottom: noBottomPad ? "0px" : "24px" };
  },
  inputText: { color: APP_COLOURS.lightDark, marginBottom: 1 },
  inputBg: (bgColor) => {
    return { bgcolor: bgColor };
  },
};
