import React from 'react';
import Navbar from '../../../../components/landing-page/Navbar/Navbar';
import EarnMoney from './EarnMoney/EarnMoney';
import Steps from './Steps/Steps';
import Footer from '../../../../components/landing-page/Footer/Footer';

import styles from './ResourcesLandingPage.module.scss';

export default function ResourcesLandingPage() {
  return (
    <div className={`${styles.resources_landing_page}`}>

        <div className={`${styles.navbar}`}>
             <Navbar />
        </div>



        <EarnMoney />

        <Steps />   



        <div className={`${styles.footer_wrapper}`}>
            <Footer />
        </div>

        
    </div>
  )
}
