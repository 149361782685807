import React from "react";
import { Typography, Box } from "@mui/material";
import AppButton from "../../buttons/AppButton";
import EmptyCardImg from "../../../assets/svg/dashboard-icons/Cart/EmptyCartImage.svg";
import { useNavigate } from "react-router-dom";

const EmptyCart = ({text, btnAction, btnText}) => {
  const navigate = useNavigate();

  return (
    <>
      <hr
        style={{ height: "2px", backgroundColor: "#EFEFEF", border: "none" }}
      />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="24px"
        pt="30px"
        pb="70px"
      >
        <Box component="img" src={EmptyCardImg} alt="EmptyCardImg" />
        <Typography fontSize="20px" fontWeight="700" color="#5C5C5C">
          {text || 'Your cart is empty. Keep shopping to add to your cart'}
        </Typography>

        <AppButton
          customStyles={{
            fontSize: "16px",
            fontWeight: "600",
            borderRadius: "8px",
            padding: "7px 25px",
          }}
          btnText={btnText || "Back to courses"}
          onClick={btnAction? btnAction : () => {
            navigate(-1);
          }}
          fullWidth={false}
        />
      </Box>
    </>
  );
};

export default EmptyCart;
